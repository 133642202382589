<div class="webzone-popup-main">
  <div class="datastream-popup-header">
    <div>
      <h1>Import Web Zone</h1>
    </div>
    <div>
      <mat-icon style="cursor:pointer" (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div class="datastream-popup-body">
    <div class="form-field" [formGroup]="webzone">
      <label for="" class="form-label">Web Zone</label>
      <select class="form-select" formControlName="Webzone_Id">
        <option value="" [selected]="true">Select the Web Zone</option>
        <option
          class="form-option"
          value="{{item.zonename}}"
          *ngFor="let item of webzonevalue"
        >
          {{item.zonename}}
        </option>
      </select>
    </div>
  </div>
  <div class="webzone-btn-div">
    <div class="btn-divall">
      <button class="btn btn-danger" mat-dialog-close>Cancel</button>
      <button
        class="btn btn-success"
        mat-dialog-close
        [disabled]="this.webzone.invalid"
        (click)="import()"
      >
        Import
      </button>
    </div>
  </div>
</div>
