<div class="about">
  <img
    style="width: 120px"
    src="../../assets/organization/devsbot-favicon-13.png"
    alt=""
  />

  <div>
    <form  [formGroup]="resetpassword">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>New Password</mat-label>
          <input
            matInput
            [type]="newhide ? 'password' : 'text'"
            formControlName="newpassword"
            [ngClass]="{ error: submitted && f['newpassword'].errors }"
          />
          <!-- password show and hide icon -->
       
            <mat-icon  style="height: 10px"
            mat-icon-button
            matSuffix
            (click)="newhide = !newhide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="newhide" style="margin-left: 30px ;cursor:pointer"
              >{{ newhide ? "visibility_off" : "visibility" }}</mat-icon
            >
       
          <!-- password valiadation errorMessage -->
          <mat-error *ngIf="f['newpassword'].errors?.['required']"
            >Password is required</mat-error
          >
          <mat-error *ngIf="f['newpassword'].errors?.['minlength']"
            >Password must be at least 6 characters</mat-error
          >
          <mat-error *ngIf="f['newpassword'].errors?.['maxlength']"
            >Password must not exceed 15 characters</mat-error
          >
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            [type]="confirmhide ? 'password' : 'text'"
            formControlName="confirmpassword"
            [ngClass]="{ error: submitted && f['confirmpassword'].errors }"
          />
          <!-- password show and hide icon -->
        
            <mat-icon  mat-icon-button
           matSuffix
            (click)="confirmhide = !confirmhide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="confirmhide" style="margin-left: 30px;cursor:pointer"
              >{{ confirmhide ? "visibility_off" : "visibility" }}</mat-icon>
         
          <!-- password valiadation errorMessage -->
          <mat-error *ngIf="f['confirmpassword'].errors?.['required']"
            >Password is required</mat-error
          >
          <mat-error *ngIf="f['confirmpassword'].errors?.['minlength']"
            >Password must be at least 6 characters</mat-error
          >
          <mat-error *ngIf="f['confirmpassword'].errors?.['maxlength']"
            >Password must not exceed 15 characters</mat-error
          >
        </mat-form-field>
      </div>

      <div class="form-btn1">
        <button (click)="onSubmit()" type="submit"  class="button" mat-raised-button color="primary">Submit</button>
      </div>
    </form>
  </div>
</div>
