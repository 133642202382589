<div class="main">
    <div class="matdialog-head">
        <mat-icon style="color: red;">info</mat-icon>
        <p>Are You Sure?</p>
    </div>
    <div class="matdialog-body-delete">
        <span>Do you want to Logout?</span>

    </div>
    <div class="matdialog-footer">
        <button class="btn btn-primary" (click)="cancel()">Cancel</button>
        <button class="btn btn-danger" (click)="confirm()">Logout</button>

    </div>
</div>

<!-- <div class="logout-matdialog">
    <div class="matdialog-head">
        <span class="logout-text">Do you want to Logout?</span>
    </div>
    <div class="btn-div">
     
    </div>
</div> -->