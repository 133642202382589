<div class="option">
  <mat-icon routerLink="/app/roles-permissions" class="close-icon"
    >arrow_back</mat-icon
  >
  <div class="option-head">
    <h1>{{this.group_name}}</h1>
    <div class="btn-div">
      <button
        class="btn btn-danger"
        [disabled]="this.isdefault=='1'"
        (click)="Rolesdelete()"
      >
        Delete
      </button>
      <button
        class="btn btn-success"
 
        (click)="open()"
      >
        Edit
      </button>
    </div>
  </div>
  <mat-tab-group [selectedIndex]="0">
    <mat-tab label="Info" class="role-tab">
      <div class="roles-table">
        <table>
          <thead>
            <tr>
              <th>Components</th>
              
              <th>View</th>
              <th>Create / Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of menuNames">
              <td>
                {{item.menu_name}}
                <p>{{item.description}}</p>
              </td>
                <td>
                <mat-checkbox
                  color="primary"
                  class="custom-frame"
                  [checked]="item.read==1"
                  disabled
                  (change)="onChange(item.menu_Id, 'read', $event.checked)"
                ></mat-checkbox>
              </td>
              <td>
                <mat-checkbox
                  color="primary"
                  class="custom-frame"
                  [checked]="item.create==1"
                  disabled
                ></mat-checkbox>
              </td>
            
              <td>
                <mat-checkbox
                  color="primary"
                  class="custom-frame"
                  [checked]="item.delete==1"
                  disabled
                  (change)="onChange(item.menu_Id, 'delete', $event.checked)"
                ></mat-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<mat-sidenav position="end" style="width: 100%" [opened]="Rolesinfo">
  <div class="option">
    <div>
      <mat-icon (click)="exit()" class="close-icon">arrow_back</mat-icon>
    </div>
    <div class="option-head">
      <form [formGroup]="groupForm">
        <div>
          <input
            type="text"
            id="group_name"
            class="form-control"
            formControlName="group_name"
            style="text-transform: capitalize; font-weight: 500"
          />
          <div *ngIf="groupForm.controls['group_name'].dirty">
            <span
              *ngIf="groupForm.controls['group_name'].hasError('required')"
              style="color: red; position: absolute"
              >Group name is required.</span
            >
            <span
              *ngIf="groupForm.controls['group_name'].hasError('minlength')"
              style="color: red; position: absolute"
              >Minimum length is 2 characters.</span
            >
            <span
              *ngIf="groupForm.controls['group_name'].hasError('maxlength')"
              style="color: red; position: absolute"
              >Maximum length is 20 characters.</span
            >
            <span
              *ngIf="groupForm.controls['group_name'].hasError('pattern')"
              style="color: red; position: absolute"
              >Name is invalid.</span
            >
          </div>
        </div>
      </form>
      <div class="btn-div">
        <button class="btn btn-danger" (click)="exit()">Cancel</button>
        <button class="btn btn-success" (click)="editRole()" [disabled]="this.groupForm.invalid">Save</button>
      </div>
    </div>
    <mat-tab-group [selectedIndex]="0">
      <mat-tab label="Info">
        <div class="roles-table">
          <table>
            <thead>
              <tr>
                <th>Components</th>
                <th>View</th>
                <th>Create / Update</th>
                
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of menuNames">
                <td>
                  {{item.menu_name}}
                  <p>{{item.description}}</p>
                </td>
                
                <td>
                  <mat-checkbox
                    color="primary"
                    class="custom-frame"
                    [checked]="item.read==1"
                    [disabled]="item.create==1"
                    (change)="onChange(item.menu_Id, 'read', $event.checked)"
                  >
                  </mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    class="custom-frame"
                    [checked]="item.create==1"
                    [disabled]="item.read==0"
                    (change)="onChange(item.menu_Id, 'createOrUpdate', $event.checked)"
                  >
                  </mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    class="custom-frame"
                    [checked]="item.delete==1"
                    [disabled]="item.read==0"
                    (change)="onChange(item.menu_Id, 'delete', $event.checked)"
                  >
                  </mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-sidenav>
