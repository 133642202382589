<!-- PRODUCT INFO PAGE START -->

<div class="option">
  <mat-icon class="close-icon" (click)="back()">arrow_back</mat-icon>
  <div class="option-head">
    <div class="productt-name d-flex" style="gap: 10px">
      <h1>{{ this.productname }}</h1>
      <span *ngIf="this.app_mode=='0'" class="badge badge-pill badge-warning"
        style="font-size: 13px; background-color: #9d2395">Developer</span>
    </div>

    <div class="btn-div-edit">
      <button class="btn btn-danger" *ngIf="iscreate" (click)="productdelete(this.product_id)">
        Delete
      </button>
      <button class="btn btn-success" style="padding: 0px 35px" (click)="open()" [disabled]="selectedTabIndex==2">
        Edit
      </button>
    </div>
  </div>
  <div class="mat-tab-title">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="tabClick($event)">
      <!-- PRODUCT INFO START -->
      <mat-tab label="Info">
        <div class="option-body">
          <div>
            <!-- <div class="option-content">
              <h3>hardware</h3>
              <p>{{ this.hardware }}</p>
            </div> -->
            <div class="option-content">
              <h3>connection type</h3>
              <p>{{ this.connection }}</p>
            </div>
            <div class="option-content">
              <h3>Gateway Id</h3>
              <p class="firmware-config">{{ this.product_api_id }}</p>
            </div>
            <!-- <div class="option-content">
              <h3>Gateway Version</h3>
              <p class="firmware-config">{{this.cluster_version}}</p>
            </div> -->

            <div class="option-content" *ngIf="this.verticalname">
              <h3>Vertical Name</h3>
              <p>{{ this.verticalname }}</p>
            </div>
            <div class="option-content">
              <h3>Organization Name</h3>
              <p>{{ this.manufacturer }}</p>
            </div>
            <div class="option-content" *ngIf="this.mac_id">
              <h3>Mac Id</h3>
              <p>{{ this.mac_id }}</p>
            </div>
            <div class="option-content">
              <h3>Ping Interval</h3>
              <p>{{ this.heartbeat }} sec</p>
            </div>
            <div class="option-content">
              <h3>Data Interval</h3>
              <p>{{ this.data_interval }} sec</p>
            </div>
            <div class="option-content" *ngIf="this.Webzone_Id">
              <h3>Web Zone</h3>
              <p>{{ this.Webzone_Id }}</p>
            </div>
          </div>
          <div class="right-side-image">
            <div class="input-div" *ngIf="this.product_logo?.length >= 1">
              <img src="../../assets/product/{{ this.product_logo }}" alt=""
                style="width: 100%; padding: 25px; max-height: 200px" />
            </div>
            <div class="option-content" *ngIf="this.description">
              <h3>description</h3>
              <p>{{ this.description }}</p>
            </div>
            <div *ngIf="user == 'Admin'">
              <h3>FIRMWARE CONFIGURATION</h3>
              <div class="clipboard" ngbTooltip="Click to copy Code"
                (click)="copyText(this.product_api_id,this.productname)">
                <div class="clipboard-content">
                  <span>#define</span>
                  <span class="copytitle">NTS_Gateway_id</span>
                  <span class="copyvalue">"{{ this.product_api_id }}"</span>
                </div>
                <div class="clipboard-content">
                  <span>#define</span>
                  <span class="copytitle">NTS_Gateway_Name</span>
                  <span class="copyvalue">"{{ this.productname }}"</span>
                </div>
              </div>
              <h4>Gateway ID declared at the very top of the firmware code.</h4>
            </div>
            <div *ngIf="user == 'Admin'">
              <div class="d-flex" style="gap: 10px">
                <mat-slide-toggle color="primary" [checked]="this.live_data=='1'" disabled
                  (change)="onliveToggle($event)"></mat-slide-toggle>
                <div class="dev-mode">
                  <h3>Live DataFlow</h3>
                </div>
              </div>
            </div>
            <div *ngIf="user == 'Admin'" class="advanced-slider2">
              <div class="d-flex slider">
                <mat-slide-toggle color="primary" [checked]="this.app_mode=='0'" disabled
                  (change)="onToggleChange($event)"></mat-slide-toggle>
                <div class="dev-mode">
                  <h4>Developer Mode</h4>
                </div>
              </div>
              <p>{{ toggleText }}</p>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- PRODUCT INFO END -->
      <!-- PRODUCT METADATA START -->
      <!-- <mat-tab class="metadata" label="Device data">
        <div class="search-field">
          <h3>No Of Device data : {{this.metadatalist?.length}}</h3>
          <div class="search-div search-div1">
            <input
              class="form-control datastream-search-input"
              type="search"
              #datastreamsearch
              placeholder="Search Device data"
              [(ngModel)]="devicedatafilter"
            />
            <mat-icon class="search-icon" (click)="search.focus()"
              >search</mat-icon
            >
          </div>
        </div>
        <div class="devices-table info-table">
          <table>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Value</th>
              <th>Description</th>
            </tr>
            <tr *ngIf="metadatalist?.length==0" class="no-data">
              <td colspan="7">
                <mat-icon> insert_drive_file</mat-icon>
                <h5>No Data</h5>
              </td>
            </tr>
            <tr
              *ngFor="let item of metadatalist|filter:devicedatafilter| paginate : devicedataconfig; let i=index"
            >
              <td>{{(this.devicedataconfig.currentPage - 1) * 5 + i + 1}}</td>
              <td>{{ item.meta_key }}</td>
              <td>{{ item.meta_value }}</td>
              <td class="device-content">{{ item.description }}</td>
            </tr>
          </table>
        </div>
      </mat-tab> -->
      <!-- PRDUCT METADATA END -->
      <!-- PRDUCT DATASTREAMS START -->

      <!-- PRODUCT DATASTREAMS END -->

      <!-- PRDUCT EVENTS START -->

      <!-- PRODUCT EVENT END -->

      <!-- PRODUCT AUTOMATIONS START -->
      <!-- <mat-tab label="Automations"> </mat-tab> -->
      <!-- PRODUCT AUTOMATIONS END  -->
      <!-- PRODUCT DEVICES START -->
      <mat-tab label="Devices">
        <div class="search-field">
          <h3>No Of Devices : {{deviceslist?.length}}</h3>
          <div class="search-div search-div1">
            <input type="search" #search class="form-control" placeholder="Search Devices" [(ngModel)]="devicefilter" />
            <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
          </div>
        </div>
        <div class="devices-table info-table">
          <table>
            <thead class="">
              <tr>
                <th style="position: sticky; left: 0; width: 8%">S.No</th>
                <th>Device Name</th>
                <th>Version</th>
                <!-- <th>Device Model</th> -->
                <th>Last Updated</th>
                <!-- <th>Zone Name</th> -->
                <th>Auto Update</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngIf="deviceslist?.length==0" class="no-data">
                <td colspan="7">
                  <mat-icon> insert_drive_file</mat-icon>
                  <h5>No Data</h5>
                </td>
              </tr>
              <tr *ngFor="
                          let item of deviceslist |filter:devicefilter | paginate:deviceconfig; let i=index">
                <td>
                  <span class="data-info" style="position: sticky; left: 0; width: 100%">{{
                    (this.deviceconfig.currentPage - 1) * 5 + i + 1 }}</span>
                </td>
                <td>
                  <span class="data-info">{{ item.device_name }}</span>
                </td>

                <td>{{item.device_version}}</td>
                <!-- <td>
                  <span>{{item.hardware_name}}</span>
                </td> -->
                <td>
                  <span>{{item.last_online|date:"medium"}}</span>
                </td>
                <!-- <td *ngIf="item.zonename==null"></td>
                <td *ngIf="item.zonename!=null">
                  <span class="data-info">{{item.zonename }}</span>
                </td> -->
                <td>
                  <mat-slide-toggle color="primary" [checked]="item.update_mode=='0'"
                    [disabled]="true"></mat-slide-toggle>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls class="pagination" id="devices"
            (pageChange)="devicespageChanged($event)"></pagination-controls>
        </div>
      </mat-tab>
      <mat-tab label="Integration" *ngIf="user == 'Admin'">
        <div class="Integration-body">
          <div class="Integration-left">
            <div>
              <h1 class="Integration-head">Device Integration in Devsbot</h1>
            </div>
            <div class="Integration-content">
              <span>
                <span class="row-no">1 =></span> Open Arduino IDE and create
                new File from the File Menu
              </span>
              <br />
              <span>
                <span class="row-no">2 =></span> Goto -> Tools -> Manage
                Libraries and search for DevsBot and install the Latest
                Version (or) Download the Zip file
                <span style="color: rgba(9, 123, 223, 0.848); cursor: pointer" (click)="downloadzip()">here.</span>
              </span>
              <br />
              <span>
                <span class="row-no">3 =></span> Goto -> Tools -> Boards ->
                Esp32 -> choose your board[Mostly you need to choose Esp32 Dev
                module/NodeMCU 1.0]
              </span>
              <br />
              <span>
                <span class="row-no">4 =></span> Copy the code from this App
                and paste into the newly created file. [Make sure you have
                cleared all the contents in new file before pasting]
              </span>
              <br />
              <span>
                <span class="row-no">5 =></span> Compile and upload the code
                into your device.
              </span>
              <br />
              <span>
                <span class="row-no">6 =></span> Check Serial Monitor for the
                connection message. If it is succesfull you will see the
                following messages.
              </span>
            </div>
          </div>
          <div class="Integration-right">
            <div class="clipboard-integration">
              <div class="clipboard-integration-head">
                <h3 style="
                    margin-bottom: 0;
                    font-size: 21px;
                    font-weight: 500;
                    color: #c5c5c5;
                  ">
                  FIRMWARE FILE
                </h3>
                <mat-icon class="copy-icon" (click)="
              copyTextt(productdata?.cluster_api_Id)" ngbTooltip="Click to copy Code">file_copy</mat-icon>
              </div>
              <div class="clipboard-integration-body">
                <div class="clipboard-content">
                  <span class="clipboard-content-text">#include</span>
                  <span class="clipboard-content-text copyvalue"><span>
                      <  </span>Devsbot<span>></span>
                    </span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">#define</span>
                  <span class="copytitle">DBOT_GATEWAY_ID</span>
                  <span class="copyvalue">"{{ productdata?.cluster_api_Id }}"</span>
                </div>
                <!-- <div class="clipboard-content">
                  <span class="clipboard-content-text">#define</span>
                  <span class="copytitle">DBOT_AUTH_TOKEN</span>
                  <span class="copyvalue"
                    >"{{ item.device_auth_token }}"</span
                  >
                </div> -->
                <div class="clipboard-content">
                  <span class="clipboard-content-text">#define</span>
                  <span class="copytitle">WIFI_SSID</span>
                  <span class="copyvalue">""&nbsp;&nbsp;&nbsp;//Replace Your WIFI_SSID</span>
                </div>
                <div class="clipboard-content" style="margin-bottom: 5px">
                  <span class="clipboard-content-text">#define</span>
                  <span class="copytitle">WIFI_PASSWORD</span>
                  <span class="copyvalue">""&nbsp;&nbsp;&nbsp;//Replace Your WIFI_PASSWORD</span>
                </div>

                <div class="clipboard-content">
                  <span class="clipboard-content-text" style="color: #dbdb7b">void setup<span
                      style="color: yellow">()</span></span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">{{"{"}}</span>
                </div>
                <div class="clipboard-content"></div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">&nbsp;&nbsp;&nbsp;Serial.begin<span
                      style="color: yellow">(</span>115200<span style="color: yellow">)</span>;</span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">&nbsp;&nbsp;&nbsp; dBot.begin<span
                      style="color: yellow">(</span><span class="copytitle">DBOT_GATEWAY_ID</span>,
                    <!-- <span class="copytitle">DBOT_AUTH_TOKEN</span>, -->
                    <span class="copytitle">WIFI_SSID</span>,
                    <span class="copytitle">WIFI_PASSWORD</span><span
                      style="color: yellow">)</span><span>;</span></span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">{{"}"}}</span>
                </div>

                <div class="clipboard-content">
                  <span class="clipboard-content-text" style="color: #dbdb7b">void loop<span
                      style="color: yellow">()</span></span>
                </div>

                <div class="clipboard-content">
                  <span class="clipboard-content-text">{{"{"}}</span>
                </div>
                <div class="clipboard-content">
                  <span style="color: #39b840">// Put your main code here to run repeatedly</span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">&nbsp;&nbsp;&nbsp;dBot.Loop<span
                      style="color: yellow">()</span>;</span>
                </div>
                <div class="clipboard-content">
                  <span class="clipboard-content-text">{{"}"}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <!-- </div> -->
  </div>

  <!-- PRODUCT INFO PAGE END -->

  <!-- PRODUCT EDIT PAGE START -->

  <mat-sidenav class="sidenav" [opened]="this.clusterinfo" position="end" #editproduct style="width: 100%">
    <div class="option">
      <div>
        <mat-icon (click)="exit()" class="close-icon">arrow_back</mat-icon>
      </div>
      <div class="option-head">
        <div class="d-flex" style="gap: 10px">
          <h1>{{ this.productname }}</h1>

          <i class="fa-solid fa-ellipsis" [matMenuTriggerFor]="list" id="more-vert"></i>
        </div>
        <div class="btn-div">
          <mat-menu #list="matMenu">
            <!-- <button mat-menu-item (click)="dublicate()">
            <mat-icon>control_point_duplicate</mat-icon>Dublicate
          </button> -->
            <button mat-menu-item (click)="productdelete(this.product_id)">
              <mat-icon>delete</mat-icon>Delete
            </button>
          </mat-menu>
          <button class="btn btn-danger res-btn" (click)="exit()">
            Cancel
          </button>
          <!-- <button
          class="btn btn-success res-btn"
          *ngIf="this.edit"
          (click)="dublicate()"
        >
          Save And Apply
        </button> -->
          <button class="btn btn-success res-btn" *ngIf="!this.edit" (click)="Editproduct()"
            [disabled]="selectedTabIndex ==0 ? this.productform.invalid:false">
            Save And Apply
          </button>
        </div>
      </div>
      <div class="mat-tab-title">
        <mat-tab-group [selectedIndex]="selectedTabIndex">
          <!-- PRDUCT INFO START -->
          <mat-tab label="Info" [disabled]="selectedTabIndex!=0">
            <div class="editproduct-main">
              <div class="left-side">
                <form [formGroup]="productform">
                  <div class="form-field">
                    <label for="" class="form-label">Gateway Name <span style="color:red">*</span></label>
                    <input type="text" class="form-control" formControlName="productname" />
                    <!-- <div
                      *ngIf="
                productform.controls['productname'].invalid &&
                (productform.controls['productname'].dirty ||
                  productform.controls['productname'].touched)
              "
                    >
                      <span
                        *ngIf="productform.controls['productname']?.errors"
                        style="color: red"
                      >
                        *GatewayName is required.
                      </span>
                    </div> -->
                    <div>
                      <span *ngIf="productform.controls['productname']?.errors?.['required']" style="color: red;">
                        *Device name is required
                      </span>
                      <span
                        *ngIf="productform.controls['productname']?.errors?.['minlength']&&!productform.controls['productname']?.errors?.['pattern']"
                        style="color: red;">
                        *Minimum 2 characters
                      </span>
                      <span *ngIf="productform.controls['productname']?.errors?.['maxlength']" style="color: red;">
                        *Maximum 20 characters
                      </span>
                      <span *ngIf="productform.controls['productname']?.errors?.['pattern']" style="color: red;">
                        Special character not allowed
                      </span>
                      <span *ngIf="productform.controls['productname']?.errors?.['noSpaceInFirstTwoCharacters']"
                        style="color: red">
                        *Spaces are not allowed
                      </span>
                    </div>
                  </div>

                  <div class="double-input">
                    <div class="field">
                      <label for="" class="form-label">Hardware <span style="color:red">*</span></label>
                      <select class="form-select" formControlName="hardware">
                        <option class="form-option" value="Esp32" [selected]="this.hardware == 'Esp32'">
                          Esp32
                        </option>
                        <option class="form-option" value="Esp8266" [selected]="this.hardware == 'Esp8266'">
                          Esp8266
                        </option>
                      </select>
                      <div>
                        <span *ngIf="productform.controls['hardware']?.errors?.['required']" style="color: red;">
                          *Please Select the hardware
                        </span>

                      </div>
                    </div>
                    <div class="field">
                      <label for="" class="form-label">Connection Type <span style="color:red">*</span></label>
                      <select class="form-select" formControlName="connection">
                        <option class="form-option" value="WiFi" [selected]="this.connection == 'WiFi'">
                          WiFi
                        </option>
                      </select>
                      <div>
                        <span *ngIf="productform.controls['connection']?.errors?.['required']" style="color: red;">
                          *Please Select the connection
                        </span>

                      </div>
                    </div>
                  </div>
                  <div class="form-field">
                    <label for="" class="form-label">Description</label>
                    <textarea formControlName="description" class="form-control description-input" rows="1"
                      cols="50"></textarea>
                    <div *ngIf="
              
              productform.controls['description'].dirty 
                
            ">
                      <span *ngIf="productform.controls['description']?.errors?.['maxlength']" style="color: red">
                        Maximum 75 characters only
                      </span>
                    </div>
                  </div>
                  <div class="double-input">
                    <div class="field">
                      <label for="" class="form-label">Ping Interval <span style="color:red">*</span></label>
                      <input type="number" class="form-control" formControlName="heartbeat" min="0" />
                      <span *ngIf="productform.controls['heartbeat']?.errors?.['required']" style="color: red;">
                        *Heartbeat is required
                      </span>
                      <span *ngIf="productform.controls['heartbeat']?.errors?.['min']" style="color: red;">
                        *Minimum value should be 25
                      </span>

                    </div>
                    <div class="field">
                      <label for="" class="form-label">Data Interval <span style="color:red">*</span></label>
                      <input type="number" class="form-control" formControlName="data_interval" min="0" />
                      <span *ngIf="productform.controls['data_interval']?.errors?.['required']" style="color: red;">
                        *Data Interval is required
                      </span>
                      <span *ngIf="productform.controls['data_interval']?.errors?.['min']" style="color: red;">
                        *Minimum value should be 60
                      </span>
                    </div>
                    <div class="field">
                      <label for="" class="form-label">Web Zone</label>
                      <select class="form-select" formControlName="Webzone_Id">
                        <option [selected]="true" [value]="''">Select Web Zone</option>
                        <option class="form-option" value="{{item.zonename}}" *ngFor="let item of webzonevalue">
                          {{item.zonename}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="double-input">


                    <div class="field">
                      <label for="" class="form-label">Mac Id</label>
                      <div class="mac-val">
                        <div class="device-mac-id-filed mac-id">
                          <input maxlength="2" class="form-control" formControlName="first" placeholder="XX" />:
                          <input maxlength="2" class="form-control" placeholder="XX" #second
                            formControlName="second" />:
                          <input maxlength="2" class="form-control" placeholder="XX" #three formControlName="three" />:
                          <input maxlength="2" class="form-control" placeholder="XX" #four formControlName="four" />:
                          <input maxlength="2" class="form-control" placeholder="XX" #five formControlName="five" />:
                          <input maxlength="2" class="form-control" placeholder="XX" #six formControlName="six" />
                        </div>


                      </div>
                     
                    </div>
                  </div>

                  <div class="double-input">
                    <div class="field">
                      <label for="" class="form-label">Gateway Id</label>
                      <input type="text" class="form-control" formControlName="product_api_id" ng-disabled="true" />
                    </div>
                    <div class="field" *ngIf="this.verticalname">
                      <label for="" class="form-label">Vertical Name</label>
                      <input type="text" class="form-control" formControlName="vertical" ng-disabled="true" />
                    </div>
                    <div class="field">
                      <label for="" class="form-label">Organization Name</label>
                      <input style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 100%;
                        " type="text" class="form-control" formControlName="manufacturer" ng-disabled="true" />
                    </div>
                  </div>
                </form>
              </div>
              <div class="right-side">
                <div class="form-field" style="padding-top: 10px">
                  <label for="" class="form-label">FIRMWARE CONFIGURATION</label>
                  <div class="clipboard" ngbTooltip="Click to copy Code"
                    (click)="copyText(this.product_api_id,this.productname)">
                    <div class="clipboard-content">
                      <span>#define</span>
                      <span class="copytitle">NTS_Gateway_id</span>
                      <span class="copyvalue">"{{ this.product_api_id }}"</span>
                    </div>
                    <div class="clipboard-content">
                      <span>#define</span>
                      <span class="copytitle">NTS_Gateway_Name</span>
                      <span class="copyvalue">"{{ this.productname }}"</span>
                    </div>
                  </div>
                  <h4>
                    Gateway ID declared at the very top of the firmware code.
                  </h4>
                </div>

                <div class="d-flex" style="gap: 10px">
                  <mat-slide-toggle color="primary" [checked]="this.live_data=='1'"
                    (change)="onliveToggle($event)"></mat-slide-toggle>
                  <div class="dev-mode">
                    <h3>Live DataFlow</h3>
                  </div>
                </div>

                <!-- <div class="advanced-slider1">
                <mat-slide-toggle
                  (change)="onToggleChange($event)"
                  [checked]="this.app_mode=='0'"
                  [disabled]="this.app_mode_force=='1'"
                  color="primary"
                ></mat-slide-toggle>
                <h4>
                  Developer Mode
                  <mat-icon
                    class="instructions"
                    matTooltip="Strictly Validate is turned on"
                    matTooltipPosition="right"
                    matTooltipClass="my-tooltip"
                    >info
                  </mat-icon>
                </h4>
              </div> -->

                <div class="advanced-slider2">
                  <div class="d-flex slider">
                    <mat-slide-toggle color="primary" [checked]="this.app_mode=='0'"
                      [disabled]="this.app_mode_force=='1'" (change)="onToggleChange($event)"></mat-slide-toggle>
                    <div class="dev-mode">
                      <h4>Developer Mode</h4>
                    </div>
                  </div>
                  <p>{{ toggleText }}</p>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- PRODUCT INFO END -->
          <!-- PRDUCT METADATA START -->
          <!-- <mat-tab
            class="metadata"
            label="Device data"
            [disabled]="selectedTabIndex!=1"
          >
            <div class="new-one" style="padding-top: 10px">
              <button
                class="btn btn-primary"
                (click)="Create('devicedata', '', '0')"
              >
                + New Device data
              </button>
              
            </div>
            <div class="search-field">
              <h3>No Of Device data : {{this.metadatalist?.length}}</h3>
              <div class="search-div search-div1">
                <input
                  class="form-control datastream-search-input"
                  type="search"
                  #datastreamsearch
                  placeholder="Search Device data"
                  [(ngModel)]="devicedatafilter"
                />
                <mat-icon class="search-icon" (click)="search.focus()"
                  >search</mat-icon
                >
              </div>
            </div>

            <div class="devices-table">
              <table>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
                <tr *ngIf="metadatalist?.length==0" class="no-data">
                  <td colspan="7">
                    <mat-icon> insert_drive_file</mat-icon>
                    <h5>No Data</h5>
                  </td>
                </tr>

                <tr
                  *ngFor="let item of metadatalist|filter:devicedatafilter|paginate: devicedataconfig;let i=index"
                >
                  <td (click)="editdevicedata(item.meta_Id,i)">
                    {{(this.devicedataconfig.currentPage - 1) * 5 + i + 1}}
                  </td>
                  <td (click)="editdevicedata(item.meta_Id,i)">
                    {{ item.meta_key }}
                  </td>
                  <td (click)="editdevicedata(item.meta_Id,i)">
                    {{ item.meta_value }}
                  </td>
                  <td
                    class="device-content"
                    (click)="editdevicedata(item.meta_Id,i)"
                  >
                    {{ item.description }}
                  </td>
                  <td
                    (click)="
                deletedevicedata(i, item.meta_Id)
              "
                  >
                    <div class="action">
                      <div class="delete-icon">
                        <mat-icon>delete</mat-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <pagination-controls
                class="pagination"
                id="devicedata"
                (pageChange)="devicedatapageChanged($event)"
              >
              </pagination-controls>
            </div>
          </mat-tab> -->

          <!-- PRODUCT DEVICES START -->
          <mat-tab class="devicestab" label="Devices" [disabled]="selectedTabIndex!=4">
            <div class="search-field">
              <h3 *ngIf="this.device_Id.length <= 0">
                No Of Devices : {{deviceslist?.length}}
              </h3>
              <h3 *ngIf="this.device_Id.length >= 1">
                No Of Devices Selected : {{ this.device_Id.length }} &nbsp;

              </h3>
              <div class="search-div search-div1">
                <!-- <div
                  class="disabe-tool"
                  [matTooltip]="device_Id.length <= 0 ? 'Select Devices to Import Web zone' : ''"
                >
                  <button
                    [disabled]="device_Id.length <= 0"
                    class="btn btn-primary import"
                    (click)="importzone()"
                  >
                    Import Web zone +
                  </button>
                </div> -->

                <div class="search">
                  <input type="search" #search class="form-control" placeholder="Search Devices"
                    [(ngModel)]="devicefilter" />
                  <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
                </div>
                <div>
                  <mat-menu #options="matMenu">
                    <button mat-menu-item class="mat-menu-button" (click)="importzone()">
                      <mat-icon class="menu-btn-icon">wifi_tethering</mat-icon>Import Zone
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>

            <div class="devices-table">
              <table>
                <thead>
                  <tr>
                    <th id="checkbox">
                      <mat-checkbox style="width: 30px; margin-top: 8px" color="primary" [indeterminate]="
                    this.device_Id.length >= 1 &&
                    this.allselect==false
                  " (change)="allCheckboxChange($event)" [checked]="this.allselect==true">
                      </mat-checkbox>
                    </th>
                    <th>Device Name</th>
                    <th>Version</th>
                    <!-- <th>Device Model</th> -->
                    <th>Last Updated</th>
                    <!-- <th>Zone Name</th> -->
                    <th>Auto Update</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngIf="deviceslist?.length==0" class="no-data">
                    <td colspan="7">
                      <mat-icon>insert_drive_file</mat-icon>
                      <h5>No Data</h5>
                    </td>
                  </tr>
                  <tr *ngFor="
                      let item of deviceslist | filter:devicefilter | paginate:deviceconfig;  let i=index">
                    <td>
                      <span class="data-info" style="position: sticky; left: 0; width: 100%" [ngClass]="{
                      'svg-hide':
                      item.isSelected == true || this.checked==true,
                      'svg':item.isSelected == false
                    }">{{ (this.deviceconfig.currentPage - 1) * 5 + i + 1
                        }}</span>
                      <!-- <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="device-icon"
                        style="width: 30px"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#3f51b5"
                        stroke-width="2"
                        [ngClass]="{
                          'svg-hide':
                            this.checked == true || this.device_Id.length >= 1,
                          'svg': this.checked == false && this.device_Id.length <= 0
                        }"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                        />
                      </svg>
                    </div> -->
                      <mat-checkbox (change)="onCheckboxChange($event, item.device_id)" class="example-margin"
                        color="primary" [checked]="item.isSelected" [ngClass]="{
                        'example-margin': this.device_Id.length <= 0
                      }"></mat-checkbox>
                    </td>
                    <td>
                      <span class="data-info">{{ item.device_name }}</span>
                    </td>
                    <td>{{item.device_version}}</td>
                    <!-- <td>
                      <span>{{item.hardware_name}}</span>
                    </td> -->
                    <td>
                      <span>{{item.last_online|date:"medium"}}</span>
                    </td>
                    <!-- <td *ngIf="item.zonename==null"></td>
                    <td *ngIf="item.zonename!=null">{{item.zonename}}</td> -->
                    <td>
                      <mat-slide-toggle color="primary" [checked]="item.update_mode=='0'"
                        (change)="updatemode($event,item.device_id)"></mat-slide-toggle>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination-controls [ngClass]="{ 'disabled-table': this.device_Id.length === 5 }" class="pagination"
              id="devices" (pageChange)="devicespageChanged($event)"></pagination-controls>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-sidenav>
</div>