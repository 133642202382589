<div class="matdialog">
  <div class="matdialog-head">
    <div>
      <h1>Create Schedule</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div>

    <div class="form-field">
      <label>Firmware Name</label>
      <input readonly type="text" class="form-control" [(ngModel)]="this.data.firmwarename">
    </div>
    <div class="form-field">
      <label>Version</label>
      <input readonly type="text" class="form-control" [(ngModel)]="this.data.version">
    </div>
    <div class="form-field" style="    display: flex;
        flex-direction: column;">
      <label for="startDate">Schedule</label>
      <mat-slide-toggle [color]="'primary'" [checked]="this.data.schedule_action == 1"
        (change)="onToggleChange($event)"></mat-slide-toggle>
    </div>
    <div class="form-field">
      <label for="startDate">Schedule Time</label>
      <input id="startDate" [readOnly]="this.data.schedule_action==0" type="datetime-local" class="form-control"
        [(ngModel)]="startDate" [min]="minDate" [max]="maxDate">
    </div>


  </div>
  <div class="matdialog-footer">
    <button class="btn btn-danger" (click)="onCancelClick()">Cancel</button>
    <button class="btn btn-primary" (click)="onApplyClick()">Create</button>
  </div>
</div>