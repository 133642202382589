<!-- digital datastream popup html page  start -->
<div class="datastream-popup-main" *ngIf="this.btn=='Digital'">
  <div class="datastream-popup-header">
    <div>
      <h1>Digital Data feeds</h1>
    </div>
    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div>
    <form [formGroup]="digitaldatastream">
      <div class="datastream-popup-body">
        <div class="dbl-input-first">
          <div class="icon-picker-div" (click)="iconpicker.click()">
            <div class="input-group" (click)="iconpicker.click()">
              <span class="input-group-addon" (click)="iconpicker.click()"
                ><i [ngClass]="iconCss.value"></i
              ></span>
              <input
                type="text"
                #iconpicker
                class="form-control"
                style="opacity: 0; cursor: pointer"
                formControlName="iconCss"
                [iconPicker]="iconCss.value"
                [ipIconPack]="icons"
                [ipPosition]="'bottom'"
                [ipWidth]="'250px'"
                [ipIconSize]="'16px'"
                [ipIconVerticalPadding]="'6px'"
                [ipIconHorizontalPadding]="'10px'"
                [ipKeepSearchFilter]="'false'"
                [ipPlaceHolder]="'Choose an icon'"
                [ipFallbackIcon]="fallbackIcon"
                (iconPickerSelect)="onIconPickerSelect($event)"
              />
            </div>
          </div>
          <div class="form-field">
            <label for="">Name</label>
            <input class="form-control" formControlName="name" type="text" />
            <div *ngIf="digitaldatastream.controls['name'].dirty">
              <span
                *ngIf="digitaldatastream.controls['name']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="digitaldatastream.controls['name']?.errors?.['minlength']&&!digitaldatastream.controls['name']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="digitaldatastream.controls['name']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Alias</label>
            <input class="form-control" formControlName="alias" type="text" />
            <div *ngIf="digitaldatastream.controls['alias'].dirty">
              <span
                *ngIf="digitaldatastream.controls['alias']?.errors?.['required']"
                style="color: red"
              >
                *Alias is required.
              </span>
              <span
                *ngIf="digitaldatastream.controls['alias']?.errors?.['minlength']&&!digitaldatastream.controls['alias']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="digitaldatastream.controls['alias']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="color-picker-div">
            <input
              class="color-picker"
              [(colorPicker)]="color"
              [style.background]="color"
              [cpPresetLabel]="this.color"
              formControlName="color"
              [cpCmykEnabled]="true"
            />
          </div>
        </div>

        <div class="dbl-input-second">
          <div class="form-field">
            <label for="">Pin</label>
            <select
              #selectpin
              *ngIf="this.digitaldatastream.controls.pinmode.value=='Output'"
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [selected]="0"
                [value]="list"
                *ngFor="let list of DigitalpinOut"
              >
                {{list}}
              </option>
            </select>
            <select
              #selectpin
              *ngIf="this.digitaldatastream.controls.pinmode.value=='Input'"
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [selected]="0"
                [value]="list"
                *ngFor="let list of Digitalpin"
              >
                {{list}}
              </option>
            </select>
            <select
              #selectpin
              name=""
              id=""
              *ngIf="this.digitaldatastream.controls.pinmode.value=='Input_pullup'"
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [selected]="0"
                [value]="list"
                *ngFor="let list of Digitalpinpull"
              >
                {{list}}
              </option>
            </select>
          </div>
          <div class="form-field">
            <label for="">PinMode</label>
            <select
              #pinmodeSelect
              class="form-select"
              formControlName="pinmode"
            >
              <option
                class="form-option"
                *ngFor="let pinout of pinmode"
                [value]="pinout"
              >
                {{ pinout }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
    <div class="datastream-popup-footer">
      <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
      <button
        class="btn btn-success"
        *ngIf="!this.editdatastream"
        (click)="Digital()"
        [disabled]="this.digitaldatastream.invalid"
      >
        Create
      </button>
      <button
        class="btn btn-success"
        *ngIf="this.editdatastream "
        (click)="UpdateDigital()"
        [disabled]="this.digitaldatastream.invalid"
      >
        Update
      </button>
    </div>
  </div>
</div>
<!-- digital datastream popup html page end  -->

<!-- Analog datastream html start  -->
<div class="datastream-popup-main" *ngIf="this.btn=='Analog'">
  <div class="datastream-popup-header">
    <div>
      <h1>Analog Data feeds</h1>
    </div>
    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div>
    <form [formGroup]="analogdatastream">
      <div class="datastream-popup-body">
        <div class="dbl-input-first">
          <div class="icon-picker-div">
            <div class="input-group" (click)="iconpicker.click()">
              <span class="input-group-addon" (click)="iconpicker.click()"
                ><i [ngClass]="iconCss.value"></i
              ></span>
              <input
                type="text"
                name="iconCss"
                #iconpicker
                class="form-control"
                style="opacity: 0"
                formControlName="iconCss"
                [iconPicker]="iconCss.value"
                [ipIconPack]="icons"
                [ipPosition]="'bottom'"
                [ipWidth]="'250px'"
                [ipIconSize]="'16px'"
                [ipIconVerticalPadding]="'6px'"
                [ipIconHorizontalPadding]="'10px'"
                [ipKeepSearchFilter]="'false'"
                [ipPlaceHolder]="'Choose an icon'"
                [ipFallbackIcon]="fallbackIcon"
                (iconPickerSelect)="onIconPickerSelect($event)"
              />
            </div>
          </div>
          <div class="form-field">
            <label for="">Name</label>
            <input class="form-control" formControlName="name" type="text" />

            <div *ngIf="analogdatastream.controls['name'].dirty">
              <span
                *ngIf="analogdatastream.controls['name']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="analogdatastream.controls['name']?.errors?.['minlength']&&!analogdatastream.controls['name']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="analogdatastream.controls['name']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Alias</label>
            <input class="form-control" formControlName="alias" type="text" />
            <div *ngIf="analogdatastream.controls['alias'].dirty">
              <span
                *ngIf="analogdatastream.controls['alias']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="analogdatastream.controls['alias']?.errors?.['minlength']&&!analogdatastream.controls['alias']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="analogdatastream.controls['alias']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="color-picker-div">
            <input
              class="color-picker"
              formControlName="color"
              [(colorPicker)]="color"
              [style.background]="this.color"
              [cpPresetLabel]="this.color"
              [cpCmykEnabled]="true"
            />
          </div>
        </div>

        <div class="dbl-input-second">
          <div class="form-field">
            <label for="">Pin</label>
            <select
              #pin
              name=""
              id=""
              *ngIf="this.analogdatastream.controls.pinmode.value=='Output'"
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [selected]="list=='A0'"
                [value]="list"
                *ngFor="let list of AnalogpinOut"
              >
                {{list}}
              </option>
            </select>
            <select
              #pin
              *ngIf="this.analogdatastream.controls.pinmode.value=='Input'"
              name=""
              id=""
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [selected]="list=='A0'"
                [value]="list"
                *ngFor="let list of AnalogpinIn"
              >
                {{list}}
              </option>
            </select>
          </div>
          <div class="form-field">
            <label for="">PinMode</label>
            <select
              #pinmodeSelect
              class="form-select"
              formControlName="pinmode"
            >
              <option
                class="form-option"
                [value]="pinout"
                *ngFor="let pinout of pinmode"
              >
                {{pinout}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-field">
          <div>
            <label for="">Units</label>
            <select class="form-select" formControlName="units">
              <option
                value=""
                class="form-option"
                [value]="unit.unit"
                *ngFor="let unit of units"
              >
                {{unit.unit}}
              </option>
            </select>
          </div>
        </div>
        <div class="three-input">
          <div class="form-field">
            <label for="">Min</label>
            <input class="form-control" type="number" formControlName="min" />
          </div>
          <div class="form-field">
            <label for="">Max</label>
            <input class="form-control" type="number" formControlName="max" />
          </div>

          <div class="form-field">
            <div>
              <label for="">Default Value</label>
              <input
                class="form-control"
                type="number"
                formControlName="default_value"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="datastream-popup-footer">
      <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
      <button
        class="btn btn-success"
        *ngIf="!this.editdatastream "
        (click)="Analog()"
        [disabled]="this.analogdatastream.invalid"
      >
        Create
      </button>
      <button
        class="btn btn-success"
        *ngIf="this.editdatastream "
        (click)="UpdateAnalog()"
        [disabled]="this.analogdatastream.invalid"
      >
        Update
      </button>
    </div>
  </div>
</div>
<!-- Analog datastream html end  -->

<!-- virtualpin datastream html start  -->
<div class="datastream-popup-main" *ngIf="this.btn=='Virtual pin'">
  <div class="datastream-popup-header">
    <div>
      <h1>Virtual Pin Data feeds</h1>
    </div>

    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div>
    <form [formGroup]="virtualpindatastream">
      <div class="datastream-popup-body">
        <div class="dbl-input-first">
          <div class="icon-picker-div">
            <div class="input-group" (click)="iconpicker.click()">
              <span class="input-group-addon" (click)="iconpicker.click()"
                ><i [ngClass]="iconCss.value"></i
              ></span>
              <input
                type="text"
                name="iconCss"
                #iconpicker
                class="form-control"
                style="opacity: 0"
                formControlName="iconCss"
                [iconPicker]="iconCss.value"
                [ipIconPack]="icons"
                [ipPosition]="'bottom'"
                [ipWidth]="'250px'"
                [ipIconSize]="'16px'"
                [ipIconVerticalPadding]="'6px'"
                [ipIconHorizontalPadding]="'10px'"
                [ipKeepSearchFilter]="'false'"
                [ipPlaceHolder]="'Choose an icon'"
                [ipFallbackIcon]="fallbackIcon"
                (iconPickerSelect)="onIconPickerSelect($event)"
              />
            </div>
          </div>
          <div class="form-field">
            <label for="">Name</label>
            <input class="form-control" formControlName="name" type="text" />

            <div *ngIf="virtualpindatastream.controls['name'].dirty">
              <span
                *ngIf="virtualpindatastream.controls['name']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="virtualpindatastream.controls['name']?.errors?.['minlength']&&!virtualpindatastream.controls['name']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="virtualpindatastream.controls['name']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Alias</label>
            <input class="form-control" formControlName="alias" type="text" />
            <div *ngIf="virtualpindatastream.controls['alias'].dirty">
              <span
                *ngIf="virtualpindatastream.controls['alias']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="virtualpindatastream.controls['alias']?.errors?.['minlength']&&!virtualpindatastream.controls['alias']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="virtualpindatastream.controls['alias']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="color-picker-div">
            <input
              class="color-picker"
              formControlName="color"
              [(colorPicker)]="color"
              [style.background]="color"
              [cpPresetLabel]="this.color"
              [cpCmykEnabled]="true"
            />
          </div>
        </div>

        <div class="dbl-input-second">
          <div class="form-field">
            <label for="">Pin</label>
            <select
              #pin
              name=""
              id=""
              class="form-select"
              formControlName="pin"
              onfocus="this.size=5;"
              onblur="this.size=1;"
              onchange="this.size=1; this.blur();"
            >
              <option
                class="form-option"
                [value]="list"
                [selected]="list=='V0'"
                *ngFor="let list of Virtualpin"
              >
                {{list}}
              </option>
            </select>
          </div>
          <div class="form-field">
            <label for="">Data Type</label>
            <select
              class="form-select"
              #pinmodeSelect
              formControlName="datastream_type"
            >
              <option
                class="form-option"
                [value]="type"
                *ngFor="let type of datastream_type"
              >
                {{type}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-field">
          <div>
            <label for="">Units</label>
            <select class="form-select" formControlName="units">
              <option
                value=""
                class="form-option"
                [value]="unit.unit"
                *ngFor="let unit of units"
              >
                {{unit.unit}}
              </option>
            </select>
          </div>
        </div>
        <div
          class="three-input"
          *ngIf="this.virtualpindatastream.controls.datastream_type.value=='Integer'"
        >
          <div class="form-field">
            <label for="">Min</label>
            <input class="form-control" type="number" formControlName="min" />
          </div>
          <div class="form-field">
            <label for="">Max</label>
            <input class="form-control" type="number" formControlName="max" />
          </div>

          <div class="form-field">
            <div>
              <label>Default Value</label>
              <input
                class="form-control"
                type="number"
                formControlName="default_value"
              />
            </div>
          </div>
        </div>
        <div
          class="three-input"
          *ngIf="this.virtualpindatastream.controls.datastream_type.value=='Double'"
        >
          <div class="form-field">
            <label for="">Min</label>
            <input class="form-control" type="number" formControlName="min" />
          </div>
          <div class="form-field">
            <label for="">Max</label>
            <input class="form-control" type="number" formControlName="max" />
          </div>
          <div class="form-field">
            <label for="">Decimal</label>
            <select
              name=""
              id=""
              class="form-select"
              formControlName="decimal_value"
            >
              <option
                [value]="decimal"
                class="form-option"
                *ngFor="let decimal of decimallist"
              >
                {{decimal}}
              </option>
            </select>
          </div>

          <div class="form-field">
            <div>
              <label for="">Default Value</label>
              <input
                class="form-control"
                type="number"
                formControlName="default_value"
              />
            </div>
          </div>
        </div>
        <div
          class="form-field"
          *ngIf="this.virtualpindatastream.controls.datastream_type.value=='String'"
        >
          <div>
            <label for="">Default Value</label>
            <input
              class="form-control"
              type="text"
              formControlName="default_value"
            />
          </div>
        </div>
      </div>
    </form>
    <div class="datastream-popup-footer">
      <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
      <button
        class="btn btn-success"
        *ngIf="!this.editdatastream "
        (click)="Virtual()"
        [disabled]="this.virtualpindatastream.invalid"
      >
        Create
      </button>
      <button
        class="btn btn-success"
        *ngIf="this.editdatastream"
        (click)="UpdateVirtual()"
        [disabled]="this.virtualpindatastream.invalid"
      >
        Update
      </button>
    </div>
  </div>
</div>
<!-- virtualpin datastream html end  -->

<!-- Add new event popup -->
<div *ngIf="this.btn=='Event'" style="width: 726px">
  <div class="event-popup-header">
    <div>
      <h1>Add New Event</h1>
    </div>
    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div class="event-body">
    <div [formGroup]="event">
      <!-- <mat-tab-group> -->
      <!-- <mat-tab label="General"> -->
      <div class="scroll-content">
        <div class="event-input-first">
          <div class="form-field">
            <label for="">Event Name</label>
            <input class="form-control" formControlName="name" type="text" />
            <div *ngIf="event.controls['name'].dirty">
              <span
                *ngIf="event.controls['name']?.errors?.['required']"
                style="color: red"
              >
                *Name is required.
              </span>
              <span
                *ngIf="event.controls['name']?.errors?.['minlength']&&!event.controls['name']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="event.controls['name']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Type</label>
            <input
              class="form-control"
              formControlName="event_type"
              type="text"
            />
            <div *ngIf="event.controls['event_type'].dirty">
              <span
                *ngIf="event.controls['event_type']?.errors?.['required']"
                style="color: red"
              >
                *EventType is required.
              </span>
              <span
                *ngIf="event.controls['event_type']?.errors?.['minlength']&&!event.controls['event_type']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="event.controls['event_type']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="color-picker-div">
            <input
              class="color-picker"
              formControlName="color"
              [(colorPicker)]="color"
              [style.background]="color"
              [cpPresetLabel]="this.color"
              [cpCmykEnabled]="true"
            />
          </div>
        </div>

        <div class="event-form-field">
          <label>Description (Optional)</label>
          <textarea
            placeholder="Event Description"
            formControlName="description"
            class="form-control"
            id="description"
            rows="3"
            cols="50"
          ></textarea>
          <div
            *ngIf="
          
          event.controls['description'].dirty 
            
        "
          >
            <span
              *ngIf="event.controls['description']?.errors?.['maxlength']"
              style="color: red"
            >
              Maximum 50 characters only
            </span>
          </div>
        </div>

        <div class="notify-form-field">
          <h3>Notifications</h3>
        </div>
        <div class="notify-form-field">
          <label for="">E-Mail To (Optional)</label>
          <mat-form-field style="width: 100%" class="example-chip-list">
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip
                *ngFor="let fruit of fruits"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(fruit)"
              >
                {{fruit}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                #fruitInput
                [formControl]="fruitCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              />
              <mat-error *ngIf="fruitCtrl.hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-chip-list>

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
            >
              <mat-option
                *ngFor="let fruit of filteredFruits | async"
                [value]="fruit"
              >
                {{fruit}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="event-form-field">
          <h3>Limit</h3>
          <div class="message-trigger">
            <p>
              Every
              <input
                type="number"
                formControlName="message_count"
                class="form-control trigger"
              />
              message will trigger the event
            </p>
            <div
              *ngIf="
              
              event.controls['message_count'].dirty 
                
            "
            >
              <span
                *ngIf="event.controls['message_count']?.errors?.['min']"
                style="color: red"
              >
                *Minimum value is 0
              </span>
            </div>
            <p>
              Event will be sent to user only once per
              <mat-form-field *ngIf="!this.editdatastream">
                <mat-select formControlName="eventtime_delay" value="0">
                  <mat-option value="0">No Restrictions</mat-option>
                  <mat-option value="60">1 minute</mat-option>
                  <mat-option value="300">5 minutes</mat-option>
                  <mat-option value="900">15 minutes</mat-option>
                  <mat-option value="1800">30 minutes</mat-option>
                  <mat-option value="3600">1 hour</mat-option>
                  <mat-option value="21600">6 hours</mat-option>
                  <mat-option value="43200">12 hours</mat-option>
                  <mat-option value="86400">1 day</mat-option>
                  <mat-option value="172800">2 days</mat-option>
                  <mat-option value="259200">3 days</mat-option>
                  <mat-option value="604800">1 week</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="this.editdatastream">
                <mat-select
                  formControlName="eventtime_delay"
                  [(ngModel)]="this.editdatastream.eventtime_delay"
                >
                  <mat-option value="0">No Restrictions</mat-option>
                  <mat-option value="60">1 minute</mat-option>
                  <mat-option value="300">5 minutes</mat-option>
                  <mat-option value="900">15 minutes</mat-option>
                  <mat-option value="1800">30 minutes</mat-option>
                  <mat-option value="3600">1 hour</mat-option>
                  <mat-option value="21600">6 hours</mat-option>
                  <mat-option value="43200">12 hours</mat-option>
                  <mat-option value="86400">1 day</mat-option>
                  <mat-option value="172800">2 days</mat-option>
                  <mat-option value="259200">3 days</mat-option>
                  <mat-option value="604800">1 week</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field *ngIf="this.editdatastream ">
                <mat-select formControlName="eventtime_delay">
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='1'"
                    value="1"
                    >1 second</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='60'"
                    value="60"
                    >1 minute</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='300'"
                    value="300"
                    >5 minutes</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='900'"
                    value="900"
                    >15 minutes</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='1800'"
                    value="1800"
                    >30 minutes</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='3600'"
                    value="3600"
                    >1 hour</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='21600'"
                    value="21600"
                    >6 hours</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='43200'"
                    value="43200"
                    >12 hours</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='86400'"
                    value="86400"
                    >1 day</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='172800'"
                    value="172800"
                    >2 days</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='259200'"
                    value="259200"
                    >3 days</mat-option
                  >
                  <mat-option
                    [selected]="this.editdatastream?.eventtime_delay=='604800'"
                    value="604800"
                    >1 week</mat-option
                  >
                </mat-select>
              </mat-form-field> -->
            </p>
          </div>
          <!-- <div class="message-trigger">
                                <h3></h3><select class="form-control trigger">
                                    <option></option>
                                </select>
                            </div> -->
        </div>
        <!-- <div class="toggle-switch">
           <div>
            <mat-slide-toggle color="primary"></mat-slide-toggle>
          </div> -->
        <!-- <div>
            <h3>Send event to Notifications tab</h3>
            <h4>
              Make event visible in the notifications tab in the mobile app
            </h4>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="event-popup-footer">
    <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
    <button
      class="btn btn-success"
      *ngIf="!this.editdatastream "
      [disabled]="this.event.invalid"
      (click)="eventcreate()"
    >
      Create
    </button>
    <button
      class="btn btn-success"
      *ngIf="this.editdatastream"
      [disabled]="this.event.invalid"
      (click)="eventUpdate()"
    >
      Update
    </button>
  </div>
</div>
<!-- Add new event end -->

<!--Switch datastream create  -->
<div *ngIf="this.btn.widget_name=='Switch'">
  <div class="widget-datastream-popup" [formGroup]="switchdatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="switchdatastream.controls['title'].dirty">
            <span
              *ngIf="switchdatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="switchdatastream.controls['title']?.errors?.['minlength']&&!switchdatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="switchdatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Datastream</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Datastream</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="switchdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="switchdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="switchdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="switchdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.switchdatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.switchdatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              style="display: none"
              *ngIf="this.switchdatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Digital
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <div
            class="widget-dbl-input"
            *ngIf="this.switchdatastream.controls.datastream_Id.value!=''"
          >
            <div
              *ngIf="this.btn.widget_name=='Switch'"
              class="choose-datastream"
            >
              <label for="">On Value</label>
              <input
                type="number"
                class="form-control"
                formControlName="onvalue"
              />
            </div>
            <div
              *ngIf="this.btn.widget_name=='Switch'"
              class="choose-datastream"
            >
              <label for="">Off Value</label>
              <input
                type="number"
                class="form-control"
                formControlName="Offvalue"
              />
            </div>
            <div style="display: none" class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="this.color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
                formControlName="color"
              />
            </div>
          </div>
          <div
            style="display: none"
            class="toggle-switch"
            *ngIf="this.switchdatastream.controls.datastream_Id.value!=''"
          >
            <div>
              <mat-slide-toggle
                color="primary"
                [checked]="this.label"
                (change)="labeltoggle()"
              >
              </mat-slide-toggle>
            </div>
            <div>
              <h3>Show on/off labels</h3>
            </div>
          </div>
          <div
            class="widget-dbl-input"
            *ngIf="this.label&&this.switchdatastream.controls.datastream_Id.value!=''"
          >
            <div>
              <label for="">On Label</label>
              <input
                type="text"
                placeholder="ex:Switch On"
                class="form-control"
                formControlName="onlabel"
              />
            </div>
            <div>
              <label for="">Off Label</label>
              <input
                type="text"
                placeholder="ex:Switch Off"
                class="form-control"
                formControlName="offlabel"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div class="widget-body">
            <div>
              <mat-slide-toggle
                [checked]="this.checked"
                (change)="toggleChanges($event)"
                [color]="this.color"
              ></mat-slide-toggle>
            </div>

            <div *ngIf="this.checked">
              <h3>{{this.onlabel}}</h3>
            </div>
            <div *ngIf="!this.checked">
              <h3>{{this.offlabel}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          [disabled]="this.switchdatastream.invalid"
          (click)="createswitch()"
        >
          Create
        </button>
        <button
          [disabled]="this.switchdatastream.invalid"
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="createswitch()"
        >
          Update
        </button>
        <!-- <button
          class="btn btn-success"
          *ngIf="this.switchdatastream.value.title!=null||undefined"
          (click)="updateswitch()"
        >
          Update
        </button> -->
      </div>
    </div>
  </div>
</div>

<!-- Switch datastream end  -->

<!-- led datastream start -->
<div *ngIf="this.btn.widget_name=='LED'">
  <div class="widget-datastream-popup" [formGroup]="leddatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="leddatastream.controls['title'].dirty">
            <span
              *ngIf="leddatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="leddatastream.controls['title']?.errors?.['minlength']&&!leddatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="leddatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="leddatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="leddatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="leddatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="leddatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.leddatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.leddatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              style="display: none"
              *ngIf="this.leddatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Digital
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Analog
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <div
            class="widget-dbl-input"
            *ngIf="this.leddatastream.controls.datastream_Id.value!=''"
          >
            <div style="display: none" class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
                formControlName="color"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box-led">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div class="ledon" [style.background]="color"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          (click)="createled()"
          [disabled]="this.leddatastream.invalid"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="createled()"
          [disabled]="this.leddatastream.invalid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- led datastream end -->

<!-- Gauge datastream start -->
<div *ngIf="this.btn.widget_name=='Gauge'">
  <div class="widget-datastream-popup" [formGroup]="gaugedatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="gaugedatastream.controls['title'].dirty">
            <span
              *ngIf="gaugedatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="gaugedatastream.controls['title']?.errors?.['minlength']&&!gaugedatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="gaugedatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="gaugedatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="gaugedatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="gaugedatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="gaugedatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.gaugedatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.gaugedatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              style="display: none"
              *ngIf="this.gaugedatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Analog
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <!-- <div class="toggle-switch" *ngIf="this.gaugedatastream.controls.datastream_Id.value!=''">
                        <div>
                            <mat-slide-toggle color="primary"></mat-slide-toggle>
                        </div>
                        <div>
                            <h3>Override Datastream’s Min/Max fields</h3>
                        </div>
                    </div> -->
          <!-- <div class="widget-dbl-input" *ngIf="this.gaugedatastream.controls.datastream_Id.value!=''">
                        <div>
                            <label for="">Min</label>
                            <input type="number" class="form-control" formControlName="min">
                        </div>
                        <div>
                            <label for="">Max</label>
                            <input type="number" class="form-control" formControlName="min">
                        </div> -->
          <div
            style="display: none"
            class="color-picker-div"
            *ngIf="this.gaugedatastream.controls.datastream_Id.value!=''"
          >
            <input
              class="color-picker"
              [(colorPicker)]="color"
              [style.background]="this.color"
              [cpPresetLabel]="this.color"
              [cpCmykEnabled]="true"
            />
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div class="widget-body widget-body-gauge">
            <p-knob
              [min]="this.min"
              class="gauge"
              [max]="this.max"
              [valueColor]="this.color"
            ></p-knob>
            <span class="gauge-min">{{this.min}}</span>
            <span class="gauge-max">{{this.max}}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          (click)="creategauge()"
          [disabled]="this.gaugedatastream.invalid"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="creategauge()"
          [disabled]="this.gaugedatastream.invalid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Gauge datastream end  -->

<!-- Slider datastream start -->

<div *ngIf="this.btn.widget_name=='Slider'">
  <div class="widget-datastream-popup" [formGroup]="sliderdatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="sliderdatastream.controls['title'].dirty">
            <span
              *ngIf="sliderdatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="sliderdatastream.controls['title']?.errors?.['minlength']&&!sliderdatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="sliderdatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="sliderdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="sliderdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="sliderdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="sliderdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.sliderdatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.sliderdatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              style="display: none"
              *ngIf="this.sliderdatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Digital
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Analog
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <div
            style="display: none"
            class="color-picker-div"
            *ngIf="this.sliderdatastream.controls.datastream_Id.value!=''"
          >
            <input
              class="color-picker"
              [(colorPicker)]="color"
              [style.background]="color"
              [cpPresetLabel]="this.color"
              [cpCmykEnabled]="true"
            />
          </div>
          <!-- <div class="toggle-switch">
                        <div>
                            <mat-slide-toggle color="primary"></mat-slide-toggle>
                        </div>
                        <div>
                            <h3>Send values on release only (optimal)</h3>
                        </div>
                    </div> -->
          <div
            style="width: 40%; padding: 10px 0px"
            *ngIf="this.sliderdatastream.controls.datastream_Id.value!=''"
          >
            <label for="">Handle Step</label>
            <input
              type="number"
              class="form-control"
              formControlName="handlestep"
            />
            <div *ngIf="sliderdatastream.controls['handlestep'].dirty">
              <span
                *ngIf="sliderdatastream.controls['handlestep']?.errors?.['required']"
                style="color: red"
              >
                *Handlestep is required.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box">
          <h3>
            {{this.widgetname}}<span *ngIf="this.pin?.length>=1"
              >({{this.pin}})</span
            >
          </h3>
          <mat-slider
            thumbLabel
            color="primary"
            [tickInterval]=""
            [step]="this.step"
            [min]="this.min"
            [max]="this.max"
            aria-label="units"
          >
          </mat-slider>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          [disabled]="this.sliderdatastream.invalid"
          (click)="createslider()"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          [disabled]="this.sliderdatastream.invalid"
          (click)="createslider()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Slider datastream end -->

<!-- label datastream start -->
<div *ngIf="this.btn.widget_name=='Label'">
  <div class="widget-datastream-popup" [formGroup]="labeldatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="labeldatastream.controls['title'].dirty">
            <span
              *ngIf="labeldatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="labeldatastream.controls['title']?.errors?.['minlength']&&!labeldatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="labeldatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="labeldatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="labeldatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="labeldatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="labeldatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              *ngIf="this.labeldatastream.controls.datastream_Id.value!=''"
              (click)="removedatastream()"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.labeldatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              style="display: none"
              *ngIf="this.labeldatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Digital
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Analog
                </button>
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <!-- <div class="widget-dbl-input">
                        <div class="color-picker-div">
                            <input class="color-picker" [(colorPicker)]="color" [style.background]="color"
                                [cpPresetLabel]="this.color" [cpCmykEnabled]="true" formControlName="color" />
                        </div>
                    </div> -->
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div>
            <h3>--</h3>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          (click)="createlabel()"
          [disabled]="this.labeldatastream.invalid"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="createlabel()"
          [disabled]="this.labeldatastream.invalid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- label datastream end  -->

<!-- terminal datastream start -->

<div *ngIf="this.btn.widget_name=='Terminal'">
  <div class="widget-datastream-popup" [formGroup]="terminaldatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="terminaldatastream.controls['title'].dirty">
            <span
              *ngIf="terminaldatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="terminaldatastream.controls['title']?.errors?.['minlength']&&!terminaldatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="terminaldatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="terminaldatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="terminaldatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="terminaldatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="terminaldatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.terminaldatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div
              *ngIf="this.terminaldatastream.controls.datastream_Id.value==''"
            >
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.terminaldatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="this.create">
            <h2>Data feeds</h2>
          </div>
          <div class="inputhint">
            <label for="">Input Hint</label>
            <input
              type="text"
              class="form-control"
              formControlName="inputhint"
            />
            <div *ngIf="terminaldatastream.controls['inputhint'].dirty">
              <span
                *ngIf="terminaldatastream.controls['inputhint']?.errors?.['required']"
                style="color: red"
              >
                *Input Hint is required.
              </span>
              <span
                *ngIf="terminaldatastream.controls['inputhint']?.errors?.['minlength']&&!terminaldatastream.controls['inputhint']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="terminaldatastream.controls['inputhint']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="widget-dbl-input">
            <div class="color-picker-div-terminal">
              <label for="">Screen color</label>
              <input
                class="color-picker"
                [(colorPicker)]="screencolor"
                [style.background]="screencolor"
                [cpPresetLabel]="this.screencolor"
                [cpCmykEnabled]="true"
                formControlName="screencolor"
              />
            </div>
            <div class="color-picker-div-terminal">
              <label for="">Text color</label>
              <input
                class="color-picker"
                [(colorPicker)]="textcolor"
                [style.background]="textcolor"
                [cpPresetLabel]="textcolor"
                [cpCmykEnabled]="true"
                formControlName="textcolor"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box terminal">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div class="widget-body widget-terminal">
            <div
              class="success-msg"
              [style.background]="this.screencolor"
            ></div>
          </div>
          <div>
            <input
              type="text"
              class="form-control"
              [style.color]="this.textcolor"
              [style.background]="this.screencolor"
              [value]="this.inputhint"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          (click)="createterminal()"
          [disabled]="this.terminaldatastream.invalid"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="createterminal()"
          [disabled]="this.terminaldatastream.invalid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
<!-- terminal datastream end  -->

<!-- Chart datastream start -->
<div *ngIf="this.btn.widget_name=='Chart'">
  <div class="widget-datastream-popup" [formGroup]="Chartdatastream">
    <div class="widget-datastream-header">
      <div>
        <h1>{{this.btn.widget_name}} Setting</h1>
      </div>
      <div>
        <mat-icon (click)="MatdialogClose()">close</mat-icon>
      </div>
    </div>
    <div class="widget-datastream-body">
      <div class="left-popup-div">
        <div class="widget-form-field">
          <label for="">Title(Optional)</label>
          <input type="text" class="form-control" formControlName="title" />
          <div *ngIf="Chartdatastream.controls['title'].dirty">
            <span
              *ngIf="Chartdatastream.controls['title']?.errors?.['required']"
              style="color: red"
            >
              *Title is required.
            </span>
            <span
              *ngIf="Chartdatastream.controls['title']?.errors?.['minlength']&&!Chartdatastream.controls['title']?.errors?.['pattern']"
              style="color: red"
            >
              *Minimum 2 characters
            </span>
            <span
              *ngIf="Chartdatastream.controls['title']?.errors?.['maxlength']"
              style="color: red"
            >
              *Maximum 10 characters
            </span>
          </div>
        </div>
        <div class="widget-form-field">
          <div class="form-field-create-btn" *ngIf="this.show">
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length<=0"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option value="" selected="true">Choose Data feeds</option>
                <option
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}}({{item.pin}})
                </option>
              </select>
              <div *ngIf="Chartdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="Chartdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.datastreamname?.length>=1"
            >
              <label for="">Data feeds</label>
              <select
                class="form-select"
                formControlName="datastream_Id"
                (change)="selecteddatastream($event)"
              >
                <option
                  [selected]="this.btn.datastream_Id===item.datastream_Id"
                  [value]="item.datastream_Id"
                  *ngFor="let item of datastreamdata"
                >
                  {{item.name}} ({{item.pin}})
                </option>
              </select>
              <div *ngIf="Chartdatastream.controls['datastream_Id'].dirty">
                <span
                  *ngIf="Chartdatastream.controls['datastream_Id']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Data Feeds.
                </span>
              </div>
            </div>
            <div
              class="datastream-delete"
              (click)="removedatastream()"
              *ngIf="this.Chartdatastream.controls.datastream_Id.value!=''"
            >
              <mat-icon>delete</mat-icon>
            </div>
            <div *ngIf="this.Chartdatastream.controls.datastream_Id.value==''">
              <!-- <h3 style="font-size: 21px">or</h3> -->
            </div>
            <div
              class="choose-datastream"
              *ngIf="this.Chartdatastream.controls.datastream_Id.value==''"
            >
              <!-- <button [disabled]="true"
                class="btn btn-primary"
                [mat-menu-trigger-for]="datastreambtn"
              >
                + Create Data feeds
              </button> -->
              <mat-menu #datastreambtn="matMenu">
                <button mat-menu-item (click)="createdatastream($event)">
                  Virtual Pin
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="datarange-dbl-input">
            <label>Data Label</label>
            <div>
              <!-- <label for="">Timeseries</label> -->
              <input type="text" formControlName="label" class="form-control" />
            </div>
          </div>
          <div class="datarange-dbl-input">
            <label>Data Range (x-axis)</label>
            <div>
              <!-- <label for="">Timeseries</label> -->
              <input
                type="text"
                formControlName="timeseries"
                class="form-control"
                [readonly]="true"
              />
            </div>
          </div>
          <div class="datarange-dbl-input">
            <label>Data Range (y-axis)</label>
            <div class="chart-dbl-input">
              <div>
                <label for="">Min</label>
                <input
                  type="number"
                  formControlName="Min"
                  class="form-control"
                />
                <div *ngIf="Chartdatastream.controls['Min'].dirty">
                  <span
                    *ngIf="Chartdatastream.controls['Min']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
              <div class="">
                <label for="">Max</label>
                <input
                  type="number"
                  formControlName="Max"
                  class="form-control"
                />
                <div *ngIf="Chartdatastream.controls['Max'].dirty">
                  <span
                    *ngIf="Chartdatastream.controls['Max']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-popup-div">
        <div class="example-box chart-widget">
          <div class="widget-head">
            <h3>
              {{this.widgetname}}
              <span *ngIf="this.pin?.length>=1">({{this.pin}})</span>
            </h3>
          </div>
          <div class="widget-body chart-widget-body">
            <p-chart
              type="line"
              [data]="basicData"
              [options]="chartoptions"
            ></p-chart>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="event-popup-footer">
        <button class="btn btn-danger" (click)="MatdialogClose()">
          Cancel
        </button>
        <button
          class="btn btn-success"
          *ngIf="!this.btn.datastream_Id"
          (click)="createchart()"
          [disabled]="this.Chartdatastream.invalid"
        >
          Create
        </button>
        <button
          class="btn btn-success"
          *ngIf="this.btn.datastream_Id"
          (click)="createchart()"
          [disabled]="this.Chartdatastream.invalid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Chart datastream end -->

<!-- custom Devicedata start -->
<div *ngIf="this.btn=='devicedata'" style="width: 400px">
  <div class="event-popup-header">
    <div *ngIf="!this.update">
      <h1>Add New Device data</h1>
    </div>
    <div *ngIf="this.update">
      <h1>Update Device data</h1>
    </div>
    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div class="devicedata-body">
    <div>
      <div class="scroll-content1">
        <div class="device-input-first" [formGroup]="devicedata">
          <div class="form-field">
            <label for="">Device data Name</label>
            <input
              class="form-control"
              formControlName="meta_key"
              type="text"
            />
            <div *ngIf="devicedata.controls['meta_key'].dirty">
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['required']"
                style="color: red"
              >
                *Device data Name is required.
              </span>
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['minlength']&&!devicedata.controls['meta_key']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Device data Value</label>
            <input
              class="form-control"
              formControlName="meta_value"
              type="text"
            />
            <div *ngIf="devicedata.controls['meta_value'].dirty">
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['required']"
                style="color: red"
              >
                *Device data Value is required.
              </span>
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['minlength']&&!devicedata.controls['meta_value']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Description</label>
            <textarea
              formControlName="description"
              placeholder="This is my device data"
              class="form-control"
              id="description"
              rows="3"
              cols="50"
            ></textarea>
            <div
              *ngIf="
              
                    devicedata.controls['description'].dirty 
                
            "
            >
              <span
                *ngIf="devicedata.controls['description']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 50 characters only
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="event-popup-footer">
    <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
    <button
      class="btn btn-success"
      (click)="createDeviceData()"
      *ngIf="!this.update"
      [disabled]="this.devicedata.invalid"
    >
      Create
    </button>
    <button
      class="btn btn-success"
      (click)="updateDeviceData()"
      *ngIf="this.update"
      [disabled]="this.devicedata.invalid"
    >
      Update
    </button>
  </div>
</div>

<!-- Devicedata end -->

<!-- ssid Devicedata start -->
<div *ngIf="this.btn=='ssid-devicedata'" style="width: 400px">
  <div class="event-popup-header">
    <div>
      <h1>Add New SSID Password</h1>
    </div>
    <div>
      <mat-icon (click)="MatdialogClose()">close</mat-icon>
    </div>
  </div>
  <div class="devicedata-body">
    <div>
      <div class="scroll-content1">
        <div class="device-input-first" [formGroup]="devicedata">
          <div class="form-field">
            <label for="">SSID</label>
            <input
              class="form-control"
              formControlName="meta_key"
              type="text"
            />
            <div *ngIf="devicedata.controls['meta_key'].dirty">
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['required']"
                style="color: red"
              >
                *SSID is required.
              </span>
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['minlength']&&!devicedata.controls['meta_key']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="devicedata.controls['meta_key']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Password</label>
            <input
              class="form-control"
              formControlName="meta_value"
              type="text"
            />
            <div *ngIf="devicedata.controls['meta_value'].dirty">
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['required']"
                style="color: red"
              >
                *Password is required.
              </span>
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['minlength']&&!devicedata.controls['meta_value']?.errors?.['pattern']"
                style="color: red"
              >
                *Minimum 2 characters
              </span>
              <span
                *ngIf="devicedata.controls['meta_value']?.errors?.['maxlength']"
                style="color: red"
              >
                *Maximum 10 characters
              </span>
            </div>
          </div>
          <div class="form-field">
            <label for="">Description</label>
            <textarea
              formControlName="description"
              placeholder="This is my device data"
              class="form-control"
              id="description"
              rows="3"
              cols="50"
            ></textarea>
            <div
              *ngIf="
              
                    devicedata.controls['description'].dirty 
                
            "
            >
              <span
                *ngIf="devicedata.controls['description']?.errors?.['maxlength']"
                style="color: red"
              >
                Maximum 50 characters only
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="event-popup-footer">
    <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
    <button
      class="btn btn-success"
      (click)="createhotspot()"
      *ngIf="!this.update"
      [disabled]="this.devicedata.invalid"
    >
      Create
    </button>
    <button
      class="btn btn-success"
      (click)="updateDeviceData()"
      *ngIf="this.update"
      [disabled]="this.devicedata.invalid"
    >
      Update
    </button>
  </div>
</div>

<!-- ssid end -->
