import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Dataservice } from '../services/dataservice';
import { HomeComponent } from '../home/home.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../Authentication/authentication.service';
import { AppRoutingModule } from '../app-routing.module';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  // declare the properity 
  loading: boolean;
  isAuthenticated: boolean;
  copyright: any;
  searchicon: boolean = true;
  oraganizationicon: boolean = false;
  settingicon: boolean = false;
  otaicon: boolean = false;
  accounticon: boolean = false;
  public isSearch = false;
  public isSetting = true;

  data: any;
  userdata: any;
  id: any;
  user_Id: any;
  devicedata: any;
  locationdata: any;
  org_name: any;
  org_id: any;
  org_value: any;
  uservalue: any;
  role: any;
  myverticaluser: any;
  alluser: any;
  mydevice: any;
  alldevice: any;
  mylocation: any;
  alllocation: any;
  show: boolean;
  isgeneral!: boolean;
  isvertical!: boolean;
  isuser!: boolean;
  iscluster!: boolean;
  iswebzone!: boolean;
  isota!: boolean;

  isclouds!: boolean;
  isdownloads!: boolean;
  isdevices!: boolean;
  isPermission!: boolean;
  isanalytics!: boolean;
  disabled!: boolean;
  event!: boolean;


  @Output() public sidenavToggle = new EventEmitter();
  @Input() isMenuOpen: boolean = false; // Default value is false
  @Input() inputFromParent !: string;
  @Input() inputFromParent2 !: string;
  collapse: any;
  roles: any;
  page: any;
  isread: any;



  //  Menu hide  but for loop pannalam irrunthalum ithala pandran
  General: any;
  Vertical: any;
  Routes: any;
  Cluster: any;
  Downloads: any;
  Analytics: any;
  OTA: any;
  Roles: any;
  User: any;
  WebZone: any;
  Clouds: any;
  Devices: boolean = false;
  Vertical_Id: any;
  deviceurl: any;
  gateway: any;
  clusterurl: any;
  settingurl: any;
  userurl:any;
  gatewayurl: boolean;
  Ads:any;
  dashboardurl: any;
  dashboard: any;
  Jobs:any;
  //  constructor mention the another component or service,router mention our also use for typescript function
  /**
 * Constructs an instance of the component.
 * 
 * @param {Router} router - The Angular router service for navigation.
 * @param {MatSidenav} sidenav1 - The Material Design sidenav component.
 * @param {Dataservice} dataservice - The service used for data operations.
 * @param {MatDialog} matdialog - The dialog service for displaying dialogs.
 * @param {HomeComponent} homecomponent - The HomeComponent instance.
 * @param {AuthenticationService} authentication - The service for user authentication operations.
 * @param {ActivatedRoute} activateroute - The activated route service for route information.
 * @param {Location} _location - The Location service for interacting with the browser's history.
 * @param {ToastrService} toastr - The service for displaying toast notifications.
 * @param {AppRoutingModule} approute - The routing configuration for the application.
 * @returns {void}
 */
  constructor(
    private router: Router,
    private sidenav1: MatSidenav,
    private dataservice: Dataservice,
    private matdialog: MatDialog,
    private homecomponent: HomeComponent,
    private authentication: AuthenticationService,
    private activateroute: ActivatedRoute,
    private _location: Location,
    private toastr: ToastrService,
    private approute: AppRoutingModule
  ) {
    this.loading = false;
    this.isAuthenticated = false;
    this.show = true;
    this.disabled = false;
    this.event = true;
    this.deviceurl = false;
    this.gatewayurl = false;
    this.clusterurl = false;
    this.settingurl = false;
    this.userurl=false;
this.dashboardurl= false
    this.Vertical_Id = this.authentication.getUserData().vertical_Id;


    this.roles = this.authentication.getUserRole();
    this.roles = [this.roles];
    Object.values(this.roles[0]).map((x: any, index: any) => {
      if (index == 0) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.General = true;
            } else {
              this.General = false;
            }

          }
        })

      }
      if (index == 1) {
        x.map((role: any, i: any) => {


          if (i == 1) {
            if (role == 1) {
              this.Vertical = true;
            } else {
              this.Vertical = false;
            }
          }

        })
      }
      if (index == 2) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.User = true;
            } else {
              this.User = false;
            }
          }

        })
      }
      if (index == 3) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Devices = true;

            } else {
              this.Devices = false;

            }
          }

        })
      }
      if (index == 4) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.gateway = true;
            } else {
              this.gateway = false;
            }
          }

        })
      }
      if (index == 5) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.WebZone = true;
            } else {
              this.WebZone = false;
            }
          }

        })
      }
      if (index == 6) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.OTA = true;
            } else {
              this.OTA = false;
            }
          }

        })
      }
      if (index == 7) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Roles = true;
            } else {
              this.Roles = false;
            }
          }

        })
      }
      if (index == 8) {
        x.map((role: any, i: any) => {
          if (i == 1) {
            if (role == 1) {
              this.Clouds = true;
            } else {
              this.Clouds = false;
            }
          }

        })
      }
      if (index == 9) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Analytics = true;
            } else {
              this.Analytics = false;
            }
          }

        })
      }
      if (index == 10) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Downloads = true;
            } else {
              this.Downloads = false;
            }
          }

        })
      }
      if (index == 11) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Routes = true;
            } else {
              this.Routes = false;
            }
          }
        })
      }
      if (index == 12) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Cluster = true;
            } else {
              this.Cluster = false;
            }
          }
        })
      }
      if (index == 13) {
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.dashboard = true;
            } else {
              this.dashboard = false;
            }
          }
        })
      }
      if(index==14){
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Ads = true;
            } else {
              this.Ads = false;
            }
          }
        })
      }
      if(index==15){
        x.map((role: any, i: any) => {

          if (i == 1) {
            if (role == 1) {
              this.Jobs = true;
            } else {
              this.Jobs = false;
            }
          }
        })
      }


    })

    // Get the path from the activated route snapshot
    this.activateroute.snapshot.children[0].url[0].path;
  }


  /**
  * Angular lifecycle hook called after component initialization.
  * 
  * @returns {void}
  */
  ngOnInit(): void {

    this.router.events.subscribe((event: any) => {
      // console.log(event)
      if (event instanceof NavigationEnd) {
        event.url = String(event.url);
        if (event.url.search("cluster") != -1) {
          this.dashboardurl = false
          this.clusterurl = false;
          this.deviceurl = false;
          this.gatewayurl = true;
          this.userurl=false;
          this.settingurl = false;
        } 
        else if (event.url.search("group") != -1) {
          this.gatewayurl = false;
          this.clusterurl = true;
          this.dashboardurl = false
          this.deviceurl = false;
          this.userurl=false;
          this.settingurl = false;
        } 
        else if (event.url.search("dashboard") != -1 || event.url.search("device") != -1) {
          console.log("devicepage")
          this.deviceurl = true;
          this.clusterurl = false;
          this.dashboardurl = false
          this.userurl=false;
          this.gatewayurl = false;
          this.settingurl = false;
        } else if (event.url.search("general") != -1 || event.url.search("app/vertical") != -1 || event.url.search("cronjobs") != -1 || event.url.search("roles") != -1 || event.url.search("verticalinfo") != -1) {
          this.settingurl = true;
          this.deviceurl = false;
          this.clusterurl = false;
          this.dashboardurl = false
          this.gatewayurl = false;
          this.userurl=false;
        } 
        else if(event.url.search('userinfo')!=-1){
          this.userurl=true;
          this.settingurl = false;
          this.deviceurl = false;
          this.clusterurl = false;
          this.dashboardurl = false
          this.gatewayurl = false;
        }
        else{
          this.settingurl = false;
          this.deviceurl = false;
          this.clusterurl = false;
          this.dashboardurl = false
          this.gatewayurl = false;
          this.userurl=false;
        }

      }
    })
    /**
     * Subscribe to an event that indicates that the router link should be disabled.
     * @param {any} response - The response from the event.
     */
    this.dataservice.RouterlinkInActive.subscribe((response: any) => {
      this.disabled = true;
    });

    /**
     * Subscribe to an event that indicates that the router link should be enabled.
     * @param {any} response - The response from the event.
     */
    this.dataservice.RouterlinkActive.subscribe((response: any) => {
      this.disabled = false;
    });

    /**
     * Periodically update the value of the 'collapse' property based on input from parent.
     * This allows for dynamic collapsing behavior.
     */
    setInterval(() => {
      this.collapse = JSON?.parse(this.inputFromParent);
    });

    // Retrieve user data for the component's use
    const data = this.authentication.getUserData();
  }



  /**
 * Opens a dialog to confirm user logout and triggers the logout process if confirmed.
 * 
 * @returns {void}
 */
  userlogout() {
    // Open a dialog to confirm user logout
    const MatDialogConfig = this.matdialog.open(logout, { disableClose: true });

    // Subscribe to the dialog's afterClosed event to handle the user's response
    MatDialogConfig.afterClosed().subscribe((result: any) => {
      // Check if the user confirmed the logout
      if (result.event == "confirm") {
        // Call the logout method to perform the actual logout
        this.logout();
      }
    });
  }


  /**
 * Logs the user out by clearing local storage and navigating to the signin page.
 * 
 * @returns {void}
 */
  logout() {
    // Clear the user's data from local storage
    localStorage.clear();

    // Display a success notification
    this.toastr.success("Successfully Logged Out");

    // Navigate to the signin page
    this.router.navigate(['/app/signin']);
  }

  /**
 * Toggles the search feature on or off and manages the icons for search and settings.
 * 
 * This method toggles the search feature on or off, changes the display of search and settings icons,
 * and updates the corresponding flags.
 * 
 * @returns {void}
 */
  public search() {
    // Toggle the search state
    this.isSearch = !this.isSearch;

    // Set the settings state to true
    this.isSetting = true;

    // Toggle the search icon state
    this.searchicon = !this.searchicon;

    // Set the setting icon state to false
    this.settingicon = false;
  }
  /**
 * Toggles the settings feature on or off and manages the icons for settings and search.
 * 
 * This method toggles the settings feature on or off, changes the display of settings and search icons,
 * and invokes the `onToolbarMenuopen` method of the `homecomponent`.
 * 
 * @returns {void}
 */
  public setting() {
    // Set the search state to true
    this.isSearch = true;

    // Toggle the settings state
    this.isSetting = !this.isSetting;

    // Toggle the settings icon state
    this.settingicon = !this.settingicon;

    // Set the search icon state to false
    this.searchicon = false;

    // Invoke the onToolbarMenuopen method of the homecomponent
    this.homecomponent.onToolbarMenuopen();
  }


  /**
 * Triggers the routes method of the homecomponent.
 * 
 * This method invokes the `routes` method of the `homecomponent`,
 * which is responsible for managing the routing behavior.
 * 
 * @returns {void}
 */
  public route() {
    // Invoke the routes method of the homecomponent
    this.homecomponent.routes();
  }

  /**
  * Restores the default state of the component's UI.
  * 
  * This method resets various UI elements to their default states.
  * If the current route matches "routes", it triggers the sidenav to close
  * using the `sidenavclose` method of the `homecomponent`.
  * 
  * @returns {void}
  */
  public default() {
    if (this.event) {
      // Check if the current route matches "routes"
      if (this.activateroute.snapshot.children[0].url[0].path === "routes") {
        // Close the sidenav using the sidenavclose method of the homecomponent
        this.homecomponent.sidenavclose();
      }
      else if (this.activateroute.snapshot.children[0].url[0].path === "analytics") {
        // Close the sidenav using the sidenavclose method of the homecomponent
        this.homecomponent.sidenavclose();
      }
      else if (this.activateroute.snapshot.children[0].url[0].path === "dashboard") {
        // Close the sidenav using the sidenavclose method of the homecomponent
        this.homecomponent.onToolbarMenuToogle();
      }
    }

    // Reset UI elements to default states
    this.isSearch = true;
    this.isSetting = true;
    this.settingicon = false;
    this.searchicon = false;
  }



  /**
  * Confirms navigation or prompts to save changes before leaving the page.
  * 
  * This method is used to confirm navigation or prompt the user to save changes before leaving the page.
  * If the `disabled` flag is `true`, it checks if the user wants to leave the page without saving changes.
  * If confirmed, it navigates to the provided `routerlink` using the Angular Router and triggers the `sidenavclose`
  * method of the `homecomponent` to close the sidenav. If canceled, it redirects to the "/app/routes" route and
  * triggers the `routes` method of the `homecomponent`.
  * 
  * @param {any} routerlink - The router link to navigate to if confirmed.
  * @returns {void}
  */
  public Confirm(routerlink: any) {
    if (this.disabled == true) {
      // Prompt user to leave the page without saving changes
      if (window.confirm('Leave this page without saving your changes?')) {
        this.disabled = false;
        // Navigate to the provided routerlink
        this.router.navigate([routerlink]);
        // Close the sidenav using the sidenavclose method of the homecomponent
        this.homecomponent.sidenavclose();
        this.event = true;
      } else {
        // User canceled leaving the page, navigate to "/app/routes" and trigger the routes method
        this.event = false;
        this.router.navigate(['/app/routes']);
        this.homecomponent.routes();
      }
    }
  }


  /**
  * Toggles the sidenav based on the screen width.
  * 
  * This method is used to toggle the sidenav based on the screen width.
  * If the screen width matches the provided media query ("max-width:424px"),
  * it emits an event using the `sidenavToggle` EventEmitter.
  * If the screen width does not match the media query, it unsubscribes from
  * the `sidenavToggle` event.
  * 
  * @returns {void}
  */
  public onToggleSidenav = () => {
    // Check if the screen width matches the media query
    if (this.x.matches) {
      // Emit the sidenavToggle event with the value of x (media query)
      this.sidenavToggle.emit(this.x);
    } else {
      // Unsubscribe from the sidenavToggle event
      this.sidenavToggle.unsubscribe();
    }
  };
  // Define a media query for the screen width
  x = window.matchMedia("(max-width:424px)");






}



// PRODUCT NEW CREATE POPUP
@Component({
  selector: 'logout',
  templateUrl: 'logout.html',
  styleUrls: ['./menu.component.css'],
})
export class logout implements OnInit {
  /**
 * Constructs an instance of the MenuComponent.
 * 
 * @param {MatDialogRef<MenuComponent>} dialogRef - The reference to the dialog.
 * @param {any} data - The data passed to the dialog.
 * @returns {void}
 */
  constructor(
    public dialogRef: MatDialogRef<MenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }
  ngOnInit(): void {

  }

  /**
 * Closes the dialog with a "cancel" event.
 * 
 * @returns {void}
 */
  cancel() {
    this.dialogRef.close({ event: "cancel" });
  }

  /**
   * Closes the dialog with a "confirm" event.
   * 
   * @returns {void}
   */
  confirm() {
    this.dialogRef.close({ event: "confirm" });
  }
}
