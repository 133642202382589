import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
  SimpleChanges,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import * as Chart from 'chart.js';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { SocketService } from '../socket.io/socket.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { newproduct } from '../product/product.component';
import { newvertical } from '../vertical/vertical.component';
import { changepassword } from '../account/account.component';
import { DOCUMENT, JsonPipe, Location } from '@angular/common';
import { AuthenticationService } from '../Authentication/authentication.service';
import { UIChart } from 'primeng/chart';
import { GridsterComponent, GridsterConfig } from 'angular-gridster2';
import { Options } from '@angular-slider/ngx-slider';
import { HomeComponent, tutorial } from '../home/home.component';
import { MatSidenav } from '@angular/material/sidenav';
import {
  LegendPosition,
  NgxChartsModule,
  ScaleType,
} from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { Subject, filter, tap } from 'rxjs';
import { async, tick } from '@angular/core/testing';
import { MapsAPILoader } from '@agm/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-mydevices',
  templateUrl: './mydevices.component.html',
  styleUrls: ['./mydevices.component.css'],
})
export class MydevicesComponent implements OnInit {
  @Output()
  @ViewChild('sidenav')
  sidenav!: MatSidenav;
  input!: EventEmitter<MatSliderChange>;
  devicevalue: any;
  alldevicevalue: any;
  isconfirm: any;
  loading: boolean;
  data: any;
  show: any;
  productdata: any;
  device_id: any;
  devicedata: any;
  allComplete: boolean = false;
  time: any;
  id: any;
  user_Id: any;
  role: any;
  verticalvalue: any;
  devicename: any;
  selection: any;
  productvalue: any;
  userdata: any;
  config: any;
  devicelog: any;
  filterTerm!: string;
  devicesidenav: any;

  userdevice: any;
  labelPosition: 'before' | 'after' = 'after';
  dataRefresher: any;

  value: any;
  acceleration!: any[];
  rotation!: any[];
  temparature: any;
  currenttime: any;
  status: any;
  statusdata: any;
  last_online: string[] = [];
  deviceversion: any[] = [];
  status_Id: any[] = [];
  basicdata: any;
  led: any;
  checked: boolean;
  refresh: boolean;
  allselect: boolean;
  device_data: any[] = [];
  device_value: any[] = [];
  slidervalue: any;
  terminalmsg: any[] = [];
  devicedashboard: any[] = [];
  deletedevice_id: any[] = [];

  // sidenav variable
  isShowing: boolean;
  interval: any;
  widget: any;
  labeldata: any;
  pin: any;
  widget_pin: any;
  product_Id: any;
  logrefresh: boolean;
  statusinterval: any[] = [];
  dashboardloader: boolean;
  digitaldata: any[] = [];
  analogdata: any[] = [];
  virtualdata: any[] = [];
  overlapTrigger!: boolean;

  selectedTabIndex = 0; // The index of the tab that you want to be selected
  uniqueValues: any;
  selectedCategoriescluster = new Set();
  selectedCategoriesvertical = new Set();
  selectedCategoriesstatus = new Set();
  selectedCategorieswifistatus = new Set();
  statusarray: any[] = []
  filterdevicevalue: any;
  productvalues: any;
  tokenanalog: any;
  tokenvirtual: any;
  tokendigital: any;
  device_token: any;
  analog: any[] = [];
  virtual: any[] = [];
  digital: any[] = [];
  objectdigital: any;
  objectanalog: any;
  objectvirtual: any;
  timeout: any;
  digitalpin: any;
  analogpin: any;
  virtualpin: any;
  online: boolean;
  offline: boolean;
  refreshdevicevalue: any;
  metadatalist: any;
  devicedataconfig: { id: string; itemsPerPage: number; currentPage: number };
  devicedatafilter: any;
  statusloading!: boolean;
  selectedFilter!: string;
  timelineItems: any;
  allTimelineItems: any;
  devicetableconfig: { id: string; itemsPerPage: number; currentPage: number };
  usertableconfig: { id: string; itemsPerPage: number; currentPage: number };
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  devicestatusdata: any[] = [];
  locationreload: any;
  page: any = 'mydevice';
  verticalvalues: any;
  Excelent: any = true;
  Good: any = true;
  Average: any = true;
  Bad: any = true;
  Disconnected: any = true;
  tempstore: any[] = [];
  selectedwifi = new Set();
  wifistatus: any[] = [{ name: "Excellent", ischecked: true }, { name: "Good", ischecked: true }, { name: "Average", ischecked: true }, { name: "Bad", ischecked: true }, { name: "Disconnected", ischecked: true }];
  devicesByToken: any

  // @ViewChildren('searchfilter') searchfilter: any;

  /**
   * Configuration options for the slider component.
   */
  options: Options = {
    floor: 0,
    ceil: 250,
  };
  /**
   * Flag indicating whether the value should not be cleared.
   */
  donotclearValue!: boolean;
  Inactive: boolean;
  /**
   * Constructor for the component.
   * @param matdialog - Reference to the MatDialog service.
   * @param dataservice - Reference to the Dataservice.
   * @param router - Reference to the Router.
   * @param toastr - Reference to the ToastrService.
   * @param clipboard - Reference to the Clipboard.
   * @param socketService - Reference to the SocketService.
   * @param formbuilder - Reference to the FormBuilder.
   * @param authentication - Reference to the AuthenticationService.
   */
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private router: Router,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private socketService: SocketService,
    private formbuilder: FormBuilder,
    private authentication: AuthenticationService

  ) {

    this.selectedwifi.add('Excellent');
    this.selectedwifi.add('Good');
    this.selectedwifi.add('Average');
    this.selectedwifi.add('Bad');
    this.selectedwifi.add('Disconnected');
    // Initialize roles array and set permission flags based on user roles
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[4];

    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
    // Initialize other properties
    this.loading = false;

    this.isShowing = false;
    this.checked = false;
    this.refresh = false;
    this.logrefresh = false;
    this.allselect = false;
    this.dashboardloader = false;
    this.online = true;
    this.offline = true;
    this.Inactive = true;
    this.locationreload = false;
    // Configuration for table pagination
    this.devicetableconfig = {
      id: 'device-table',
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.usertableconfig = {
      id: 'user-device',
      itemsPerPage: 6,
      currentPage: 1,
    };

    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 4,
      currentPage: 1,
    };
    this.statusloading = true;
  }
  /**
   * Form group for the terminal input.
   */
  terminalform = this.formbuilder.group({
    terminal: [''],
  });
  /**
   * Form group for checkbox input.
   */
  verticalfilter = this.formbuilder.group({
    checked: JSON.parse('true'),
  });
  clusterfilter = this.formbuilder.group({
    checked: JSON.parse('true'),
  });
  /**
   * Form group for status .
   */
  statusform = this.formbuilder.group({
    online: JSON.parse('true'),
    offline: JSON.parse('true'),
    Inactive: JSON.parse('true')
  });
  // onDrop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.devicedashboard, event.previousIndex, event.currentIndex);
  // }
  // entered(event: CdkDragEnter) {
  //   moveItemInArray(this.devicedashboard, event.item.data, event.container.data);
  // }
  // dragEntered(event: CdkDragEnter<number>) {
  //   const drag = event.item;
  //   const dropList = event.container;
  //   const dragIndex = drag.data;
  //   const dropIndex = dropList.data;

  //   const phContainer = dropList.element.nativeElement;
  //   const phElement = phContainer.querySelector('.cdk-drag-placeholder')!;
  //   phContainer.removeChild(phElement);
  //   phContainer.parentElement!.insertBefore(phElement, phContainer);

  //   moveItemInArray(this.devicedashboard, dragIndex, dropIndex);
  // }

  /**
   * Lifecycle hook that is called after data-bound properties have been initialized.
   * This method is used for setting up initial data and subscribing to observables.
   */
  getColorForWifiStatus(wifiStatus: number): string {

    if (wifiStatus <= -30 && wifiStatus >= -50) {
      return "purple";
    } else if (wifiStatus <= -50 && wifiStatus >= -70) {
      return "orange";
    } else if (wifiStatus <= -70 && wifiStatus >= -90) {
      return "red";
    } else {


      return "black";
    }
  }

  ngOnInit(): void {


    this.dataservice.singleDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    // Get user data from authentication service
    const data = this.authentication.getUserData();
    this.loading = true;
    // Get device data for the user
    this.getmydevicedata(data);
    this.dataservice.setPaginationState(null, 'alldevice');
    this.dataservice.setPaginationState(null, 'myverticaluser');
    this.dataservice.setPaginationState(null, 'allverticaluser');
    // Retrieve the pagination state from the data service
    let paginationState: any = this.dataservice.getPaginationState(this.page);
    if (paginationState) {
      this.devicetableconfig.currentPage = paginationState;
    }
    // Subscribe to Devicerefresh event
    this.dataservice.Devicerefresh.subscribe((response: any) => {
      this.getmydevicedata(data);
    });
    // Subscribe to ClusterCreate event
    this.dataservice.ClusterCreate.subscribe((response: any) => {
      this.getmydevicedata(data);
    });
    this.dataservice.MyDevicestatus.subscribe((response: any) => {
      this.ngOnDestroy();
    });
  }

  /**
   * Retrieves and processes user-specific device data, device status data, and product data.
   * @param data - User data used to fetch device-related information.
   */
  async getmydevicedata(data: any) {

    let filterhistroy: any = null;

    filterhistroy = await this.dataservice.Getfilter("My");
    if (filterhistroy == undefined) {
      filterhistroy = null
    }
    // let filterhistroy: any = null;
    if (filterhistroy) {
      this.online = filterhistroy.online;
      this.offline = filterhistroy.offline;
      this.Inactive = filterhistroy.inactive;
      this.statusform.controls.online.setValue(this.online);
      this.statusform.controls.offline.setValue(this.offline);
      this.statusform.controls.Inactive.setValue(this.Inactive);
    }
    // Fetch device data using the dataservice's getmydevicedata method
    this.dataservice.getmydevicedata(data).subscribe(async (res: any) => {

      this.loading = false;

      let value: any[] = [];
      this.currenttime = new Date();
      if (res.status == '200') {
        this.data = res.data;
        // console.log('Devicerefresh')
        this.selectedCategoriescluster.clear()

        await Promise.all(this.devicevalue = await this.data.map((x: any) => {

          x.last_online = new Date(x.last_online);
          if (Number(x.heartbeat) > 20) {
            x.heartbeat = Number(x.heartbeat) + 5
            x.heartbeat = Number(x.heartbeat) * 12.5
          }
          if (x.vertical_name == null) {
            x.vertical_name = "Super Admin"
          }
          if (
            +this.currenttime - +x.last_online < 100 * x.heartbeat &&
            (x.status_Id == 1 || x.status_Id == 2)
          ) {
            x.isOnline = true;
            if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
              x.wifiStrength = 'Excellent';
            } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
              x.wifiStrength = 'Good';
            } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
              x.wifiStrength = 'Average';
            } else if (Number(x.wifi_status) <= -70) {
              x.wifiStrength = 'Bad';
            } else {
              x.wifiStrength = 'Average';
            }
          } else if (x.status_Id == 0) {
            x.isActive = true;
            x.wifiStrength = 'Disconnected';
          } else {
            x.isOnline = false;
            x.wifiStrength = 'Disconnected';
          }
          return {
            ...x,
            isSelected: false,
          };
        }))

        // Initialize devicesByToken as an empty object
        this.devicesByToken = {};

        this.devicevalue.forEach((device: any) => {
          if (!this.devicesByToken[device.device_auth_token]) {
            this.devicesByToken[device.device_auth_token] = [];
          }
          this.devicesByToken[device.device_auth_token].push(device);
        });
        // console.log(this.devicesByToken);

        // // Assign parentdevice to devices within the same group
        Object.values(this.devicesByToken).forEach((group: any) => {
          const parentDevice = group.find((device: any) => device.slave_id === 1);
          if (parentDevice) {
            const parentDeviceName = parentDevice.device_name;
            group.forEach((device: any) => {
              device.slave_id_name = parentDeviceName;
            });
          }
        });


        await Promise.all(this.filterdevicevalue = await this.devicevalue);


        // filter the unique values for filter
        await Promise.all(this.uniqueValues = this.devicevalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.status_Id === x.status_Id);
          }
        ))
        await Promise.all(this.productvalues = this.devicevalue.filter(
          (x: any, y: any, z: any) => {
            return (
              y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))

          }
        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        ))

        await Promise.all(this.verticalvalues = this.devicevalue.filter((x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.vertical_Id === x.vertical_Id))

        }).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        ))

        await Promise.all(this.verticalvalues.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.vertical.size >= 1) {
              if (filterhistroy.vertical.has(x.vertical_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesvertical = filterhistroy.vertical;
            } else {
              this.selectedCategoriesvertical.add(x.vertical_name)
            }
          } else {
            this.selectedCategoriesvertical.add(x.vertical_name)
          }

        }))

        await Promise.all(this.productvalues.map((x: any) => {
          if (filterhistroy) {

            if (filterhistroy.cluster.size >= 1) {
              if (filterhistroy.cluster.has(x.cluster_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriescluster = filterhistroy.cluster;
            } else {

              this.selectedCategoriescluster.add(x.cluster_name);
            }



          } else {
            // console.log(x.cluster_name);

            this.selectedCategoriescluster.add(x.cluster_name);
          }

        }))

        await Promise.all(this.wifistatus.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.wifistatus.size >= 1) {
              if (filterhistroy.wifistatus.has(x.name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedwifi = filterhistroy.wifistatus;
            } else {
              this.selectedwifi.add(x.name)
            }
          } else {
            this.selectedwifi.add(x.name)
          }

        }))

        this.statusinterval.push(
          setInterval(async () => {
            // Loop through each item in devicestatusdata
            this.devicestatusdata.forEach((x: any) => {
              const index = this.filterdevicevalue.findIndex(
                (device: any) => device.device_id === x.device_id
              );
              const foundindex = this.devicevalue.findIndex((d: any) => d.device_id === x.device_id);

              if (index >= 0) {
                this.filterdevicevalue[index].last_online = new Date(
                  x.last_online
                );
                this.filterdevicevalue[index].status_Id = x.status_Id;
                this.filterdevicevalue[index].device_version = x.device_version;
                this.filterdevicevalue[index].wifi_status = x.wifi_status;
              }
              if (foundindex >= 0) {
                this.devicevalue[foundindex].last_online = new Date(
                  x.last_online
                );
                this.devicevalue[foundindex].status_Id = x.status_Id;
                this.devicevalue[foundindex].device_version = x.device_version;
                this.devicevalue[foundindex].wifi_status = x.wifi_status;
              }
            });

            await Promise.all(this.filterdevicevalue.map((x: any) => {
              if (
                +this.currenttime - +x.last_online < 100 * x.heartbeat &&
                (x.status_Id == 1 || x.status_Id == 2)
              ) {
                x.isOnline = true;
                if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
                  x.wifiStrength = 'Excellent';
                } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
                  x.wifiStrength = 'Good';
                } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
                  x.wifiStrength = 'Average';
                } else if (Number(x.wifi_status) <= -70) {
                  x.wifiStrength = 'Bad';
                } else {
                  x.wifiStrength = 'Average';
                }
              } else if (x.status_Id == 0) {
                x.isActive = true;
                x.wifiStrength = 'Disconnected';
              } else {
                x.isOnline = false;
                x.wifiStrength = 'Disconnected';
              }
            }));
            await Promise.all(this.devicevalue.map((x: any) => {
              if (
                +this.currenttime - +x.last_online < 100 * x.heartbeat &&
                (x.status_Id == 1 || x.status_Id == 2)
              ) {
                x.isOnline = true;
                if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
                  x.wifiStrength = 'Excellent';
                } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
                  x.wifiStrength = 'Good';
                } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
                  x.wifiStrength = 'Average';
                } else if (Number(x.wifi_status) <= -70) {
                  x.wifiStrength = 'Bad';
                } else {
                  x.wifiStrength = 'Average';
                }
              } else if (x.status_Id == 0) {
                x.isActive = true;
                x.wifiStrength = 'Disconnected';
              } else {
                x.isOnline = false;
                x.wifiStrength = 'Disconnected';
              }
            }))
          }, 0.5)
        );


        //  console.log(this.filterdevicevalue)
        await Promise.all(this.filterdevicevalue = this.filterdevicevalue.filter((x: any) =>
          (this.selectedCategoriescluster.has(x.cluster_name) && this.selectedCategoriesvertical.has(x.vertical_name)) && this.selectedwifi.has(x.wifiStrength)
        ))
        // console.log(this.filterdevicevalue)


        if (this.online && this.offline && this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
            ...x,
            isSelected: false,
          }));
        } else if (this.online && this.offline && !this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.status_Id == 1 || x.status_Id == 2;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (this.online && !this.offline && !this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == true;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (this.online && !this.offline && this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == true || x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && !this.offline && !this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
            ...x,
            isSelected: false,
          }));
        } else if (!this.online && this.offline && this.Inactive) {
          this.online = false;

          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == false || x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && !this.offline && this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && this.offline && !this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == false && x.status_Id == 1;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        }


      } else {
        this.toastr.error('Error occurred');
      }
    });

    //  GET MY DEVICESTATUS DATA
    this.statusinterval.push(
      setInterval(() => {
        this.currenttime = new Date();
        // Fetch device status data using the dataservice's getmydevicestatusdata method
        this.dataservice.getmydevicestatusdata(data).subscribe((res: any) => {
          this.statusloading = false;
          if (res.status == '200') {
            let timedata = res.data;
            const devicedata = timedata;
            this.devicestatusdata = timedata;
            while (this.last_online?.length > 0) {
              this.last_online.pop();
            }
            while (this.status_Id?.length > 0) {
              this.status_Id.pop();
            }
            while (this.deviceversion?.length > 0) {
              this.deviceversion.pop();
            }
            // while (this.deviceversion?.length > 0) {
            //   this.deviceversion.pop();
            // }

            // devicedata?.foreach((x: any) => {
            //   x.last_online = new Date(x.last_online);
            //   this.last_online.push(x.last_online);
            //   this.status_Id.push(x.status_Id);
            //   this.deviceversion.push(x.device_version);
            // });
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }, 5000)
    );

    // Fetch cluster data using the dataservice's getdevicepageproductdata method
    this.dataservice.getdevicepageproductdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });



    // GET DEVICEPAGE  VERTICAL DATA
    // this.dataservice.getdevicepageverticaldata(data).subscribe((res: any) => {
    //   if (res.status == '200') {
    //     this.data = res.data;
    //     this.verticalvalue = this.data;
    //   } else {
    //     this.toastr.error('Error occurred');
    //   }
    // });
  }

  /**
   * Redirects to the device information page and sets the selected device and pagination state.
   * @param device_Id - The unique identifier of the selected device.
   * @param product_Id - The unique identifier of the associated product.
   */
  deviceinfo(device_Id: any, product_Id: any) {
    this.donotclearValue = true;
    this.device_id = device_Id;
    this.router.navigate(['/app/dashboard', this.device_id, product_Id]);
  }

  onFilterTermChange() {
    this.devicetableconfig.currentPage = 1;
  }

  /**
   * Redirects to the device information page and sets the selected device and pagination state.
   * * @param user_Id - The unique identifier of the selected user.
   *  */
  userinfo(user_Id: any) {
    this.router.navigate(['/app/userinfo', user_Id]);
  }

  /**
   * Filters timeline items based on the selected filter option.
   */
  onFilterChange() {
    if (this.selectedFilter === 'latest') {
      // show only latest items
      this.timelineItems = this.allTimelineItems.slice(0, 2);
    } else if (this.selectedFilter === '1week') {
      // show items from last 1 week
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      this.timelineItems = this.allTimelineItems.filter(
        (item: { time: string | number | Date }) =>
          new Date(item.time) >= oneWeekAgo
      );
    } else {
      // show all items
      this.timelineItems = this.allTimelineItems;
    }
  }

  /**
   * Updates the current page of the device table when the pagination event occurs.
   * @param event - The event containing the new page number.
   */
  devicetable(event: any) {
    this.devicetableconfig.currentPage = event;
    this.dataservice.setPaginationState(event, this.page);
  }

  showall() {
    this.devicetableconfig.itemsPerPage = this.devicevalue.length;
    this.devicetableconfig.currentPage = 1;

  }

  collapse() {
    this.devicetableconfig.itemsPerPage = 6;
    this.devicetableconfig.currentPage = 1;
  }

  /**
   * Filters the list of devices based on the filter selection.
   * @param event - The event containing the checked state of the online filter.
   */
  deviceonlinefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();

    if (event.checked && this.offline && this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (event.checked && this.offline && !this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.offline && !this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.offline && this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.offline && !this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && this.offline && this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.offline && this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.offline && !this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }

    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name));

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }

  /**
   * Filters the list of devices based on the filter selection.
   * @param event - The event containing the checked state of the online filter.
   */
  deviceofflinefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();

    if (event.checked && this.online && this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (event.checked && this.online && !this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && !this.Inactive) {
      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id != 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.online && !this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && !this.online && this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));

    } else if (!event.checked && this.online && !this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }

    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  deviceInactivefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();
    if (event.checked && this.online && this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    }
    else if (event.checked && this.online && !this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && !this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0 || x.isOnline == false;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.online && !this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && !this.online && this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && !this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id != 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }


    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  /**
   * Resets all filters and selections to their default state.
   */
  async filterreset() {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.selectedCategoriesvertical.clear();
    this.selectedCategoriescluster.clear();
    this.selectedwifi.clear();
    this.online = true;
    this.offline = true;
    this.Inactive = true;
    this.statusform.controls.online.setValue(this.online);
    this.statusform.controls.offline.setValue(this.offline);
    this.statusform.controls.Inactive.setValue(this.Inactive);





    this.filterdevicevalue = this.devicevalue.map((x: any) => {
      return {
        ...x,
        isSelected: false,
      };
    });
    // filter the unique values for filter
    await Promise.all(this.uniqueValues = this.devicevalue.filter(
      (x: any, y: any, z: any) => {
        return y === z.findIndex((t: any) => t.status_Id === x.status_Id);
      }
    ))
    await Promise.all(this.productvalues = this.devicevalue.filter(
      (x: any, y: any, z: any) => {
        return (
          y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))

      }
    ).map((x: any) =>
    ({
      ...x,
      ischecked: true
    })
    ))

    await Promise.all(this.verticalvalues = this.devicevalue.filter((x: any, y: any, z: any) => {
      return (
        y === z.findIndex((t: any) => t.vertical_Id === x.vertical_Id))

    }).map((x: any) =>
    ({
      ...x,
      ischecked: true
    })
    ))

    await Promise.all(this.verticalvalues.map((x: any) => {

      this.selectedCategoriesvertical.add(x.vertical_name)


    }))

    await Promise.all(this.productvalues.map((x: any) => {

      this.selectedCategoriescluster.add(x.cluster_name);


    }))

    await Promise.all(this.wifistatus.map((x: any) => {

      this.selectedwifi.add(x.name)


    }))

    await Promise.all(this.filterdevicevalue = this.filterdevicevalue.filter((x: any) =>
      (this.selectedCategoriescluster.has(x.cluster_name) && this.selectedCategoriesvertical.has(x.vertical_name)) && this.selectedwifi.has(x.wifiStrength)
    ))

    if (this.online && this.offline && this.Inactive) {

      this.online = true;
      this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (this.online && this.offline && !this.Inactive) {

      this.online = true;
      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.status_Id == 1 || x.status_Id == 2;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (this.online && !this.offline && !this.Inactive) {
      this.online = true;

      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (this.online && !this.offline && this.Inactive) {
      this.online = true;

      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!this.online && !this.offline && !this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!this.online && this.offline && this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.isOnline == false || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!this.online && !this.offline && this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!this.online && this.offline && !this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.filterdevicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));


    }




    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
  }

  /**
   * Filters devices based on the selected cluster name and event (checked/unchecked).
   * @param cluster - The cluster name to filter by.
   * @param event - The event containing the checked state of the cluster filter.
   */
  filterClusterName(cluster: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      this.selectedCategoriescluster.add(cluster);
    } else {
      this.selectedCategoriescluster.delete(cluster);
    }
    if (this.selectedCategoriescluster.size >= 1) {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
        .map((x: any) => ({ ...x, isSelected: false }));
      this.verticalvalues.map((x: any) => {
        const Find = this.filterdevicevalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1 || x.status_Id == 2;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }

    } else {

      this.filterdevicevalue = this.devicevalue.filter((x: any) => this.selectedCategoriesvertical.has(x.vertical_name)).map((x: any) => ({
        ...x,
        isSelected: false,
      }));

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1 || x.status_Id == 2;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }

    }

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }



  filterVerticalName(vertical: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      this.selectedCategoriesvertical.add(vertical);
    } else {
      this.selectedCategoriesvertical.delete(vertical);
    }
    if (this.selectedCategoriesvertical.size >= 1) {

      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriesvertical.has(x.vertical_name))
        .map((x: any) => ({ ...x, isSelected: false }));
      this.selectedCategoriescluster.clear()
      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });
      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1 || x.status_Id == 2;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }

    } else {
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));


      this.selectedCategoriescluster.clear();

      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1 || x.status_Id == 2;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }

    }

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  i = 0;
  filterWifiName(wifi: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      if (wifi == "Excellent") {
        this.Excelent = true;
      } else if (wifi == "Good") {
        this.Good = true;
      } else if (wifi == "Average") {
        this.Average = true;
      } else if (wifi == "Bad") {
        this.Bad = true;
      } else if (wifi == "Disconnected") {
        this.Disconnected = true;
      }
      this.selectedwifi.add(wifi);
    } else {
      if (wifi == "Excellent") {
        this.Excelent = false;
      } else if (wifi == "Good") {
        this.Good = false;
      } else if (wifi == "Average") {
        this.Average = false;
      } else if (wifi == "Bad") {
        this.Bad = false;
      } else if (wifi == "Disconnected") {
        this.Disconnected = false;
      }
      this.selectedwifi.delete(wifi);
    };

    if (this.selectedwifi.size >= 1) {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name) && this.selectedwifi.has(x.wifiStrength))
        .map((x: any) => ({ ...x, isSelected: false }));

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }


    } else {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
        .map((x: any) => ({ ...x, isSelected: false }));

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }
    }


    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  SelectallFilters(event: any, eventname: any) {

    if (event) {
      if (eventname == "Cluster") {
        this.selectedCategoriescluster.clear();
        let config = { checked: true }
        this.productvalues = this.productvalues.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.cluster_name);
          this.filterClusterName(x.cluster_name, config);
          return { ...x }
        })
      } else if (eventname == "Status") {
        this.online = true;
        this.offline = true;
        this.Inactive = true;
        this.statusform.controls.online.setValue(this.online);
        this.statusform.controls.offline.setValue(this.offline);
        this.statusform.controls.Inactive.setValue(this.Inactive);
        let config = { checked: true }
        this.deviceofflinefilter(config)

      } else if (eventname == "Wifistatus") {
        this.selectedwifi.clear()
        let config = { checked: true }
        this.wifistatus = this.wifistatus.map((x: any) => {
          x.ischecked = true;
          this.selectedwifi.add(x.name)
          this.filterWifiName(x.name, config);
          return { ...x }
        })

      } else if (eventname == "Vertical") {
        this.selectedCategoriesvertical.clear();
        let config = { checked: true }
        this.verticalvalues = this.verticalvalues.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
          this.filterVerticalName(x.vertical_name, config)
          return { ...x }
        })
      }
    } else {
      if (eventname == "Cluster") {
        this.selectedCategoriescluster.clear();
        let config = { checked: false }
        this.productvalues = this.productvalues.map((x: any) => {
          x.ischecked = false;

          this.filterClusterName(x.cluster_name, config);
          return { ...x }
        })
      } else if (eventname == "Status") {
        this.online = false;
        this.offline = false;
        this.Inactive = false;
        this.statusform.controls.online.setValue(this.online);
        this.statusform.controls.offline.setValue(this.offline);
        this.statusform.controls.Inactive.setValue(this.Inactive);
        let config = { checked: false }
        this.deviceofflinefilter(config)

      } else if (eventname == "Wifistatus") {
        this.selectedwifi.clear()
        let config = { checked: false }
        this.wifistatus = this.wifistatus.map((x: any) => {
          x.ischecked = false;

          this.filterWifiName(x.name, config);
          return { ...x }
        })

      } else if (eventname == "Vertical") {
        this.selectedCategoriesvertical.clear();
        let config = { checked: false }
        this.verticalvalues = this.verticalvalues.map((x: any) => {
          x.ischecked = false;

          this.filterVerticalName(x.vertical_name, config)
          return { ...x }
        })
      }
    }

  }





  /**
   * Fetches dashboard data for a specific device.
   * @param setPageFlag - Flag indicating whether to set the page state.
   * @param id - The device ID to fetch dashboard data for.
   */
  getData(setPageFlag: any, id: any) {
    this.device_id = id;
    this.refresh = true;
    // Fetch dashboard data for the selected device.
    this.dataservice.Getdashboarddata(this.device_id).subscribe((res: any) => {
      this.refresh = false;
      if (res.status == '400') {
        this.toastr.error('Error occurred');
      } else if (res.status == '200') {
        this.data = res.data;
        this.device_data = this.data;
      }
    });
  }

  /**
   * Opens a dialog to add a new device (if permitted).
   */
  newdevice() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(fromproduct, {
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Opens a dialog to add a new vertical.
   */
  newvertical() {
    const MatDialogConfig = this.matdialog.open(newvertical, {
      disableClose: true,
    });
  }

  /**
   * Opens a dialog to add a new product (if permitted).
   */
  newproduct() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(newproduct, {
        width: '600px',
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Opens a dialog to add a new device (if permitted).
   */
  open() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(newdevice, {
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Opens a dialog to rename a device (if permitted).
   * @param device_id - The ID of the device to be renamed.
   * @param devicename - The current name of the device.
   */
  rename(item: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(rename, {
        disableClose: true,
        data: item,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Deletes a device (if permitted) after user confirmation.
   * @param device_id - The ID of the device to be deleted.
   */
  deletedevice(device_id: any, cluster_id: any) {
    console.log("checkkkkkkkkk11111", device_id, cluster_id);
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_id, cluster_id: cluster_id };
          this.dataservice.deletedevice(data).subscribe(async (res: any) => {
            this.loading = false;
            if (res.status == '200') {
              this.toastr.info('process failed');
            }
            if (res.status == '201') {

              this.toastr.success('Device Deleted');

            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Opens a dialog to change the user's password.
   */
  changepassword() {
    const MatDialogConfig = this.matdialog.open(changepassword, {
      disableClose: true,
    });
  }

  /**
   * Downloads the device log report.
   * @param device_Id - The ID of the device.
   * @param devicename - The name of the device.
   */
  devicereport(device_Id: any, devicename: any) {
    const data = { device_Id, devicename };
    this.dataservice.devicereport(data).subscribe((res: any) => {

      if (res.status == '400') {
        this.toastr.error('Error occurred');
      } else if (res.size == '34') {
        this.toastr.info("No log Found")
      } else {
        const a = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(res);
        a.href = fileUrl;
        a.download = devicename + '.log';
        a.click();
        window.URL.revokeObjectURL(fileUrl);
      }
    });
  }
  /**
   * Reboots a device (if permitted) after user confirmation.
   * @param Key - The device key.
   * @param last_online - The timestamp of the device's last online status.
   */
  devicereboot(Key: any, last_online: any, heartbeat: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'reboot',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          let Method = 'Restart';
          if (+this.currenttime - +last_online > 100 * heartbeat) {
            this.toastr.error('Device is offline');
          } else {
            const data = { Key, Method };
            this.socketService.emit('message', data);
            this.toastr.success('Device Reboot Succesfully');
          }
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Erases device data (if permitted) after user confirmation.
   * @param device_Id - The ID of the device.
   * @param devicename - The name of the device.
   */

  erasedevicedata(device_Id: any, devicename: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_Id };
          this.dataservice.erasedevicedata(data).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.info('No devicedata');
            }
            if (res.status == '201') {
              this.toastr.success('Events log Deleted');
              // window.location.reload();
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Handles the checkbox change event for individual devices.
   * @param event - The checkbox change event.
   * @param device_Id - The ID of the device.
   */
  onCheckboxChange(event: any, device_Id: any, result: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        this.deletedevice_id.push(device_Id);
        this.filterdevicevalue.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = true;
          }
        });
        if (
          this.filterdevicevalue.length === this.deletedevice_id.length ||
          this.devicetableconfig.itemsPerPage === this.deletedevice_id.length
        ) {
          this.allselect = true;
        } else if (result.length === this.deletedevice_id.length) {
          this.allselect = true;
        }
        this.checked = true;
      } else {
        var index = this.deletedevice_id.indexOf(device_Id);
        this.deletedevice_id.splice(index, 1);
        this.filterdevicevalue.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = false;
          }
        });
        if (this.deletedevice_id.length <= 0) {
          this.checked = false;
        }
        if (this.filterdevicevalue.length !== this.deletedevice_id.length) {
          this.allselect = false;
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }
  isCheckboxChecked: boolean = false;
  /**
   * Handles the checkbox change event for selecting all devices.
   * @param event - The checkbox change event.
   */
  allCheckboxChange(event: any, result: any) {

    if (this.iscreate == true) {
      const itemsPerPage = 6;
      const currentPage = this.devicetableconfig.currentPage;
      const startIndex = (currentPage - 1) * this.devicetableconfig.itemsPerPage;
      const endIndex = startIndex + this.devicetableconfig.itemsPerPage;




      if (event.checked == true) {
        if (result.length > this.devicetableconfig.itemsPerPage) {

          result.slice(startIndex, endIndex).forEach((x: any) => {
            x.isSelected = true;
          });
          this.checked = true;
          this.allselect = true;
          result.slice(startIndex, endIndex).forEach((x: any) => {
            if (this.deletedevice_id.indexOf(x.device_id) === -1) {
              this.deletedevice_id.push(x.device_id);
            }
          });
        } else {
          result.forEach((x: any) => {
            x.isSelected = true;
          });
          this.checked = true;
          this.allselect = true;
          result.forEach((x: any) => {
            if (this.deletedevice_id.indexOf(x.device_id) === -1) {
              this.deletedevice_id.push(x.device_id);
            }
          });
        }


      } else {

        if (result.length > this.devicetableconfig.itemsPerPage) {
          result.slice(startIndex, endIndex).forEach((x: any) => {
            x.isSelected = false;
          });
          this.allselect = false;
          this.checked = false;
          while (this.deletedevice_id.length > 0) {
            this.deletedevice_id.pop();
          }
        } else {
          result.forEach((x: any) => {
            x.isSelected = false;
          });
          this.allselect = false;
          this.checked = false;
          while (this.deletedevice_id.length > 0) {
            this.deletedevice_id.pop();
          }
        }


      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Validates user permission and prevents an action if the user is not permitted.
   * @param $event - The event triggering the action.
   */
  validatepermission($event: any) {
    if (!this.iscreate) {
      this.toastr.info('User not permitted');
      $event.preventDefault();
    }
  }

  /**
   * Deletes multiple devices (if permitted) after user confirmation.
   */
  multipledevicesdelete() {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          let data = [];
          data = this.deletedevice_id;
          this.dataservice.multipledevicesdelete(data).subscribe(async (res: any) => {
            if (res.status == '200') {

              this.toastr.success('Devices Deleted Successfully');

              this.allselect = false;
              this.checked = false;
              while (this.deletedevice_id?.length > 0) {
                this.deletedevice_id.pop();
              }
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Downloads a CSV file containing the user's device data.
   */
  mydevicedatacsv() {

    const csvData = this.dataservice.convertDeviceToCSV(this.filterdevicevalue)

    this.dataservice.devicedownloadCSV(csvData, 'mydevice.csv')





    // this.dataservice.mydevicedatacsv(data).subscribe((res: any) => {
    //   if (res.status == '400') {
    //     this.toastr.error('Error occurred');
    //   } else {
    //     const a = document.createElement('a');
    //     const fileUrl = window.URL.createObjectURL(res);

    //     a.href = fileUrl;
    //     a.download = 'mydevice.csv';
    //     a.click();
    //     window.URL.revokeObjectURL(fileUrl);
    //   }
    // });
  }

  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * Clears the intervals set for updating device status and cleans up resources.
   */
  ngOnDestroy() {
    for (const intervalId of this.statusinterval) {
      clearInterval(intervalId);
    }

    const data = { cluster: this.selectedCategoriescluster, vertical: this.selectedCategoriesvertical, online: this.online, offline: this.offline, inactive: this.Inactive, wifistatus: this.selectedwifi };

    this.dataservice.Storefilter(data, "My")


  }


}

// ALL DEVICE TYPESCRIPT FILE
@Component({
  selector: 'alldevices',
  templateUrl: 'alldevice.html',
  styleUrls: ['./mydevices.component.css'],
})
export class alldevices implements OnInit {
  devicevalue: any;
  alldevicevalue: any;
  isconfirm: any;
  loading: boolean;
  data: any;
  show: any;
  productdata: any;
  device_id: any;
  devicedata: any;
  allComplete: boolean = false;
  time: any;
  id: any;
  user_Id: any;
  role: any;
  verticalvalue: any;
  Active: 'green';
  inactive: 'red';
  devicename: any;
  selection: any;
  productvalue: any;
  userdata: any;
  filter: any;
  config: any;
  devicelog: any;
  filterTerm!: string;
  dataRefresher: any;

  value: any;
  acceleration!: any[];
  rotation!: any[];
  temparature: any;
  currenttime: any;
  status: any;
  statusdata: any;
  last_online: string[] = [];
  basicdata: any;
  led: any;
  checked: boolean;
  refresh: boolean;
  allselect: boolean;
  device_data: any[] = [];
  username: any[] = [];
  slidervalue: any;
  terminalmsg: any[] = [];
  isChecked1!: boolean;
  devicedashboard: any[] = [];
  deletedevice_id: any[] = [];
  selectedCategoriesvertical = new Set();

  // sidenav variable
  isShowing: boolean;
  interval: any;
  widget: any;
  labeldata: any;
  pin: any;
  widget_pin: any;
  tokenanalog: any;
  tokenvirtual: any;
  tokendigital: any;
  device_token: any;
  product_Id: any;
  logrefresh: boolean;
  statusinterval: any[] = [];
  datarefreshinterval: any;
  dashboardloader: boolean;
  selectedTabIndex = 0;
  userdevice: any;
  uniqueValues: any;
  selectedCategoriesuser = new Set();
  selectedCategoriescluster = new Set();
  filterdevicevalue: any;
  productvalues: any;
  status_Id: any[] = [];
  analog: any[] = [];
  virtual: any[] = [];
  digital: any[] = [];
  objectdigital: any;
  objectanalog: any;
  objectvirtual: any;
  timeout: any;
  digitalpin: any;
  analogpin: any;
  virtualpin: any;
  online: boolean;
  offline: boolean;
  digitaldata: any;
  analogdata: any;
  overlapTrigger!: boolean;
  virtualdata: any;
  statusloading: boolean;
  devicedataconfig: any;
  deviceversion: any[] = [];
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  roles: any;
  Vertical_Id: any;
  devicetableconfig: { id: string; itemsPerPage: number; currentPage: number };
  devicestatusdata: any[] = [];
  page: any = 'alldevice';
  verticalvalues: any;
  Excelent: any = true;
  Good: any = true;
  Average: any = true;
  Bad: any = true;
  Disconnected: any = true;
  tempstore: any[] = [];
  Inactive: boolean;
  selectedwifi = new Set();
  devicesByToken: any
  wifistatus: any[] = [{ name: "Excellent", ischecked: true }, { name: "Good", ischecked: true }, { name: "Average", ischecked: true }, { name: "Bad", ischecked: true }, { name: "Disconnected", ischecked: true }];
  /**
   * Constructor of the component, used to initialize member variables and services.
   *
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice providing data-related functionalities.
   * @param router - Router service for navigating between routes.
   * @param clipboard - Clipboard service for managing clipboard operations.
   * @param toastr - ToastrService for displaying toast notifications.
   * @param formbuilder - FormBuilder for creating and managing forms.
   * @param socketService - SocketService for managing WebSocket connections.
   * @param authentication - AuthenticationService for managing user authentication.
   */
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,

    private router: Router,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private socketService: SocketService,
    private authentication: AuthenticationService
  ) {
    this.selectedwifi.add('Excellent');
    this.selectedwifi.add('Good');
    this.selectedwifi.add('Average');
    this.selectedwifi.add('Bad');
    this.selectedwifi.add('Disconnected');
    this.Vertical_Id = this.authentication.getUserData().vertical_Id;
    // Initialize roles based on user data
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[4];
    // Map user roles to component permissions
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
    this.loading = false;
    this.Active = 'green';
    this.inactive = 'red';
    this.isShowing = false;
    this.checked = false;
    this.refresh = false;
    this.logrefresh = false;
    this.allselect = false;
    this.dashboardloader = false;
    this.online = true;
    this.offline = true;
    this.Inactive = true;
    // Configuration for pagination
    this.devicetableconfig = {
      id: 'device-table',
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 4,
      currentPage: 1,
    };
    this.statusloading = true;
  }
  // Form groups for terminal, checkbox, and status filtering
  terminalform = this.formbuilder.group({
    terminal: [''],
  });

  formGroup = this.formbuilder.group({
    checked: JSON.parse('true'),
  });
  statusform = this.formbuilder.group({
    online: JSON.parse('true'),
    offline: JSON.parse('true'),
    Inactive: JSON.parse('true')
  });

  /**
   * Lifecycle hook called when the component is initialized.
   * Initializes necessary data and subscribes to relevant service observables.
   */
  ngOnInit(): void {
    this.dataservice.singleDevicestatus.next();
    this.dataservice.MyDevicestatus.next();
    const data = this.authentication.getUserData();
    this.loading = true;
    // Call function to fetch all device data
    this.getalldevicedata(data);
    this.dataservice.setPaginationState(null, 'mydevice');
    this.dataservice.setPaginationState(null, 'myverticaluser');
    this.dataservice.setPaginationState(null, 'allverticaluser');
    let paginationState: any = this.dataservice.getPaginationState(this.page);
    if (paginationState) {
      this.devicetableconfig.currentPage = paginationState;
    }
    this.dataservice.Devicerefresh.subscribe((response: any) => {
      this.getalldevicedata(data);
    });
    this.dataservice.ClusterCreate.subscribe((response: any) => {
      this.getalldevicedata(data);
    });
    this.dataservice.AllDevicestatus.subscribe((response: any) => {
      this.ngOnDestroy();
    });
  }


  getColorForWifiStatus(wifiStatus: number): string {

    if (wifiStatus <= -30 && wifiStatus >= -50) {
      return "purple";
    } else if (wifiStatus <= -50 && wifiStatus >= -70) {
      return "orange";
    } else if (wifiStatus <= -70 && wifiStatus >= -90) {
      return "red";
    } else {


      return "unknown";
    }
  }

  showall() {
    this.devicetableconfig.itemsPerPage = this.devicevalue.length;
    this.devicetableconfig.currentPage = 1;

  }

  collapse() {
    this.devicetableconfig.itemsPerPage = 6;
    this.devicetableconfig.currentPage = 1;
  }
  /**
   * Fetches all device data based on user data.
   *
   * @param data - User data used to fetch device data.
   */
  async getalldevicedata(data: any) {
    // Fetch device data using getalldevicedata method from the dataservice
    let filterhistroy: any = null;
    filterhistroy = await this.dataservice.Getfilter("All");
    // console.log(filterhistroy)
    if (filterhistroy == undefined) {
      filterhistroy = null
    }

    if (filterhistroy) {
      this.online = filterhistroy.online;
      this.offline = filterhistroy.offline;
      this.Inactive = filterhistroy.inactive;
      this.statusform.controls.online.setValue(this.online);
      this.statusform.controls.offline.setValue(this.offline);
      this.statusform.controls.Inactive.setValue(this.Inactive)
    }
    this.dataservice.getalldevicedata(data).subscribe(async (res: any) => {
      this.loading = false;

      let value: any[] = [];
      this.currenttime = new Date();

      if (res.status == '200') {
        this.selectedCategoriescluster.clear()
        this.data = res.data;
        await Promise.all(this.devicevalue = this.data.map((x: any) => {
          x.last_online = new Date(x.last_online);
          if (Number(x.heartbeat) > 20) {
            x.heartbeat = Number(x.heartbeat) + 5
            x.heartbeat = Number(x.heartbeat) * 12.5
          }
          if (x.vertical_name == null) {
            x.vertical_name = "Super Admin"
          }
          if (
            +this.currenttime - +x.last_online < 100 * x.heartbeat &&
            (x.status_Id == 1 || x.status_Id == 2)
          ) {
            x.isOnline = true;
            if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
              x.wifiStrength = 'Excellent';
            } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
              x.wifiStrength = 'Good';
            } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
              x.wifiStrength = 'Average';
            } else if (Number(x.wifi_status) <= -70) {
              x.wifiStrength = 'Bad';
            } else {
              x.wifiStrength = 'Average';
            }
          } else if (x.status_Id == 0) {
            x.isActive = true;
            x.wifiStrength = 'Disconnected';
          } else {
            x.isOnline = false;
            x.wifiStrength = 'Disconnected';
          }
          return {
            ...x,
            isSelected: false,
          };
        }));

        await Promise.all(this.filterdevicevalue = await this.devicevalue)

        // filter the unique values for filter
        await Promise.all(this.uniqueValues = this.devicevalue.filter(
          (x: any, y: any, z: any) => {
            return y === z.findIndex((t: any) => t.firstname === x.firstname);
          }
        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        ))
        await Promise.all(this.productvalues = this.devicevalue.filter(
          (x: any, y: any, z: any) => {
            return (
              y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))

          }
        ).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        ))

        await Promise.all(this.verticalvalues = this.devicevalue.filter((x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.vertical_Id === x.vertical_Id))

        }).map((x: any) =>
        ({
          ...x,
          ischecked: true
        })
        ))

        await Promise.all(this.verticalvalues.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.vertical.size >= 1) {
              if (filterhistroy.vertical.has(x.vertical_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesvertical = filterhistroy.vertical;
            } else {
              this.selectedCategoriesvertical.add(x.vertical_name)
            }
          } else {
            this.selectedCategoriesvertical.add(x.vertical_name)
          }

        }))
        await Promise.all(this.uniqueValues.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.user.size >= 1) {
              if (filterhistroy.user.has(x.firstname)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriesuser = filterhistroy.user;
            } else {
              this.selectedCategoriesuser.add(x.firstname)
            }
          } else {
            this.selectedCategoriesuser.add(x.firstname)
          }
          // this.selectedCategoriesuser.add(x.firstname);
        }))

        await Promise.all(this.productvalues.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.cluster.size >= 1) {
              if (filterhistroy.cluster.has(x.cluster_name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedCategoriescluster = filterhistroy.cluster;
            } else {
              this.selectedCategoriescluster.add(x.cluster_name);
            }



          } else {
            this.selectedCategoriescluster.add(x.cluster_name);
          }

        }))
        this.statusinterval.push(
          setInterval(() => {

            this.devicestatusdata.forEach((x: any) => {
              const index = this.filterdevicevalue.findIndex(
                (device: any) => device.device_id === x.device_id
              );
              const foundindex = this.devicevalue.findIndex((d: any) => d.device_id === x.device_id);
              if (index >= 0) {
                this.filterdevicevalue[index].last_online = new Date(
                  x.last_online
                );
                this.filterdevicevalue[index].status_Id = x.status_Id;
                this.filterdevicevalue[index].device_version = x.device_version;
                this.filterdevicevalue[index].wifi_status = x.wifi_status;
              }
              if (foundindex >= 0) {
                this.devicevalue[foundindex].last_online = new Date(
                  x.last_online
                );
                this.devicevalue[foundindex].status_Id = x.status_Id;
                this.devicevalue[foundindex].device_version = x.device_version;
                this.devicevalue[foundindex].wifi_status = x.wifi_status;
              }
            });

            this.filterdevicevalue.map((x: any) => {
              if (
                +this.currenttime - +x.last_online < 100 * x.heartbeat &&
                (x.status_Id == 1 || x.status_Id == 2)
              ) {
                x.isOnline = true;
                if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
                  x.wifiStrength = 'Excellent';
                } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
                  x.wifiStrength = 'Good';
                } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
                  x.wifiStrength = 'Average';
                } else if (Number(x.wifi_status) <= -70) {
                  x.wifiStrength = 'Bad';
                } else {
                  x.wifiStrength = 'Average';
                }
              } else {
                x.isOnline = false;
                x.wifiStrength = 'Disconnected';
              }
            });
            this.devicevalue.map((x: any) => {
              if (
                +this.currenttime - +x.last_online < 100 * x.heartbeat &&
                (x.status_Id == 1 || x.status_Id == 2)
              ) {
                x.isOnline = true;
                if (Number(x.wifi_status) >= -50 && x.wifi_status !== null) {
                  x.wifiStrength = 'Excellent';
                } else if (Number(x.wifi_status) < -50 && Number(x.wifi_status) >= -60) {
                  x.wifiStrength = 'Good';
                } else if (Number(x.wifi_status) < -60 && Number(x.wifi_status) > -70) {
                  x.wifiStrength = 'Average';
                } else if (Number(x.wifi_status) <= -70) {
                  x.wifiStrength = 'Bad';
                } else {
                  x.wifiStrength = 'Average';
                }
              } else if (x.status_Id == 0) {
                x.isActive = true;
                x.wifiStrength = 'Disconnected';
              } else {
                x.isOnline = false;
                x.wifiStrength = 'Disconnected';
              }
            });
          }, 0.5)
        );

        await Promise.all(this.wifistatus.map((x: any) => {
          if (filterhistroy) {
            if (filterhistroy.wifistatus.size >= 1) {
              if (filterhistroy.wifistatus.has(x.name)) {
                x.ischecked = true;
              } else {
                x.ischecked = false;
              }
              this.selectedwifi = filterhistroy.wifistatus;
            } else {
              this.selectedwifi.add(x.name)
            }
          } else {
            this.selectedwifi.add(x.name)
          }

        }))
        // Initialize devicesByToken as an empty object
        this.devicesByToken = {};

        this.devicevalue.forEach((device: any) => {
          if (!this.devicesByToken[device.device_auth_token]) {
            this.devicesByToken[device.device_auth_token] = [];
          }
          this.devicesByToken[device.device_auth_token].push(device);
        });
        // console.log(this.devicesByToken);

        // // Assign parentdevice to devices within the same group
        Object.values(this.devicesByToken).forEach((group: any) => {
          const parentDevice = group.find((device: any) => device.slave_id === 1);
          if (parentDevice) {
            const parentDeviceName = parentDevice.device_name;
            group.forEach((device: any) => {
              device.slave_id_name = parentDeviceName;
            });
          }
        });
        // console.log(this.devicevalue);
        await Promise.all(this.filterdevicevalue = this.filterdevicevalue.filter((x: any) =>
          (this.selectedCategoriescluster.has(x.cluster_name) && this.selectedCategoriesvertical.has(x.vertical_name)) && this.selectedwifi.has(x.wifiStrength)
        ))

        if (this.online && this.offline && this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
            ...x,
            isSelected: false,
          }));
        } else if (this.online && this.offline && !this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.status_Id == 1 || x.status_Id == 2;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (this.online && !this.offline && !this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == true;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (this.online && !this.offline && this.Inactive) {
          this.online = true;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == true || x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && !this.offline && !this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
            ...x,
            isSelected: false,
          }));
        } else if (!this.online && this.offline && this.Inactive) {
          this.online = false;

          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == false || x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && !this.offline && this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.status_Id == 0;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        } else if (!this.online && this.offline && !this.Inactive) {
          this.online = false;
          this.filterdevicevalue = this.filterdevicevalue
            .filter((x: any) => {
              return x.isOnline == false && x.status_Id == 1;
            })
            .map((x: any) => ({ ...x, isSelected: false }));
        }

      } else {
        this.toastr.error('Error occurred');
      }
    });

    // Set up interval for device status updates
    this.statusinterval.push(
      setInterval(() => {
        this.currenttime = new Date();
        this.dataservice.getalldevicestatusdata(data).subscribe((res: any) => {
          this.statusloading = false;
          if (res.status == '200') {
            let timedata = res.data;
            const devicedata = timedata;
            this.devicestatusdata = timedata;
            while (this.last_online?.length > 0) {
              this.last_online.pop();
            }
            while (this.status_Id?.length > 0) {
              this.status_Id.pop();
            }
            while (this.deviceversion?.length > 0) {
              this.deviceversion.pop();
            }
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }, 5000)
    );

    // Fetch cluster data using getdevicepageproductdata method from the dataservice
    this.dataservice.getdevicepageproductdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }

  // pagination
  devicetable(event: any) {
    this.devicetableconfig.currentPage = event;
    this.dataservice.setPaginationState(event, this.page);
  }

  /**
   * opens modal to create cluster if permitted.

   */
  newproduct() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(newproduct, {
        width: '600px',
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  onFilterTermChange() {
    // Update previousFilterTerm whenever filterTerm changes
    this.devicetableconfig.currentPage = 1;
  }

  /**
   * opens modal to create device if permitted.

   */
  newdevice() {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(fromproduct, {
        disableClose: true,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Validates user permission and prevents an action if the user is not permitted.
   * @param $event - The event triggering the action.
   */
  validatepermission($event: any) {
    if (!this.iscreate) {
      this.toastr.info('User not permitted');
      $event.preventDefault();
    }
  }
  /**
   * Filters the list of devices based on the filter selection.
   * @param event - The event containing the checked state of the online filter.
   */
  deviceonlinefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();

    if (event.checked && this.offline && this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (event.checked && this.offline && !this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.offline && !this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.offline && this.Inactive) {
      this.online = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.offline && !this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && this.offline && this.Inactive) {
      this.online = false;

      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.offline && this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.offline && !this.Inactive) {
      this.online = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }

    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }

  /**
   * Filters the list of devices based on the filter selection.
   * @param event - The event containing the checked state of the online filter.
   */
  deviceofflinefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();

    if (event.checked && this.online && this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (event.checked && this.online && !this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && !this.Inactive) {
      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id != 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && this.Inactive) {

      this.offline = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false || x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.online && !this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && !this.online && this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));

    } else if (!event.checked && this.online && !this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && this.Inactive) {

      this.offline = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }

    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }

  deviceInactivefilter(event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.devicetableconfig.currentPage = 1;
    this.allselect = false;
    this.checked = false;
    while (this.deletedevice_id.length > 0) {
      this.deletedevice_id.pop();
    }
    this.currenttime = new Date();
    if (event.checked && this.online && this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    }
    else if (event.checked && this.online && !this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true || x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && !this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (event.checked && !this.online && this.offline) {
      this.Inactive = true;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id == 0 || x.isOnline == false;

        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && !this.online && !this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));
    } else if (!event.checked && !this.online && this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == false && x.status_Id == 1;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && !this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.isOnline == true;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    } else if (!event.checked && this.online && this.offline) {
      this.Inactive = false;
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => {
          return x.status_Id != 0;
        })
        .map((x: any) => ({ ...x, isSelected: false }));
    }


    this.filterdevicevalue = this.filterdevicevalue.filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  /**
   * Filters the device list based on the selected user owner.
   *
   * @param user - The user owner for filtering.
   * @param event - The event object indicating whether the checkbox is checked or not.
   */
  filterDeviceOwner(user: any, event: any) {
    this.isCheckboxChecked = false;
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      this.selectedCategoriesuser.add(user);
    } else {
      this.selectedCategoriesuser.delete(user);
    }
    if (this.selectedCategoriesuser.size >= 1) {
      this.filterdevicevalue = this.devicevalue.filter((x: any) =>
        this.selectedCategoriesuser.has(x.firstname)
      );
      this.verticalvalues.map((x: any) => {
        const Find = this.filterdevicevalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })

      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });

    } else {
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));

      this.selectedCategoriescluster.clear();
      this.selectedCategoriesvertical.clear();
      this.verticalvalues.map((x: any) => {
        x.ischecked = true;
        this.selectedCategoriesvertical.add(x.vertical_name)
      })


      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });

      if (this.filterdevicevalue.length <= 6) {
        this.onFilterTermChange()
      }

    }
  }
  /**
   * Resets all filters and selections to their default state.
   */
  async filterreset() {
    this.isCheckboxChecked = false;
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    this.selectedCategoriesvertical.clear();
    this.selectedCategoriescluster.clear();
    this.selectedwifi.clear();
    this.selectedCategoriesuser.clear();
    this.online = true;
    this.offline = true;
    this.Inactive = true;
    this.statusform.controls.online.setValue(this.online);
    this.statusform.controls.offline.setValue(this.offline);
    this.statusform.controls.Inactive.setValue(this.Inactive);

    this.filterdevicevalue = this.devicevalue.map((x: any) => ({
      ...x,
      isSelected: false,
    }));


    await Promise.all(this.uniqueValues = this.devicevalue.filter(
      (x: any, y: any, z: any) => {
        return y === z.findIndex((t: any) => t.firstname === x.firstname);
      }
    ).map((x: any) =>
    ({
      ...x,
      ischecked: true
    })
    ))
    await Promise.all(this.productvalues = this.devicevalue.filter(
      (x: any, y: any, z: any) => {
        return (
          y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))

      }
    ).map((x: any) =>
    ({
      ...x,
      ischecked: true
    })
    ))

    await Promise.all(this.verticalvalues = this.devicevalue.filter((x: any, y: any, z: any) => {
      return (
        y === z.findIndex((t: any) => t.vertical_Id === x.vertical_Id))

    }).map((x: any) =>
    ({
      ...x,
      ischecked: true
    })
    ))

    await Promise.all(this.verticalvalues.map((x: any) => {

      this.selectedCategoriesvertical.add(x.vertical_name)


    }))
    await Promise.all(this.uniqueValues.map((x: any) => {

      this.selectedCategoriesuser.add(x.firstname)

      // this.selectedCategoriesuser.add(x.firstname);
    }))
    await Promise.all(this.productvalues.map((x: any) => {

      this.selectedCategoriescluster.add(x.cluster_name);


    }))

    await Promise.all(this.wifistatus.map((x: any) => {

      this.selectedwifi.add(x.name)


    }))


  }
  /**
   * Filters devices based on the selected cluster name and event (checked/unchecked).
   * @param cluster - The cluster name to filter by.
   * @param event - The event containing the checked state of the cluster filter.
   */
  filterClusterName(cluster: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      this.selectedCategoriescluster.add(cluster);
    } else {
      this.selectedCategoriescluster.delete(cluster);
    }
    if (this.selectedCategoriescluster.size >= 1) {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
        .map((x: any) => ({ ...x, isSelected: false }));
      this.verticalvalues.map((x: any) => {
        const Find = this.filterdevicevalue.some((y: any) => y.vertical_name == x.vertical_name);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesvertical.delete(x.vertical_name)
        } else {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
        }
      })
      this.uniqueValues.map((x: any) => {
        const Find = this.filterdevicevalue.some((y: any) => y.firstname == x.firstname);
        if (!Find) {
          x.ischecked = false;
          this.selectedCategoriesuser.delete(x.firstname)
        } else {
          x.ischecked = true;
          this.selectedCategoriesuser.add(x.firstname)
        }
      })
    } else {

      this.filterdevicevalue = this.devicevalue.filter((x: any) => this.selectedCategoriesvertical.has(x.vertical_name)).map((x: any) => ({
        ...x,
        isSelected: false,
      }));

    }

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }


  }

  filterVerticalName(vertical: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      this.selectedCategoriesvertical.add(vertical);
    } else {
      this.selectedCategoriesvertical.delete(vertical);
    }
    if (this.selectedCategoriesvertical.size >= 1) {

      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriesvertical.has(x.vertical_name))
        .map((x: any) => ({ ...x, isSelected: false }));
      this.selectedCategoriescluster.clear()
      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });

    } else {
      this.filterdevicevalue = this.devicevalue.map((x: any) => ({
        ...x,
        isSelected: false,
      }));


      this.selectedCategoriescluster.clear();

      this.productvalues = this.filterdevicevalue.filter(
        (x: any, y: any, z: any) => {
          return (
            y === z.findIndex((t: any) => t.cluster_name === x.cluster_name))
        }
      ).map((x: any) =>
      ({
        ...x,
        ischecked: true
      })
      )
      this.productvalues.forEach((x: any) => {
        this.selectedCategoriescluster.add(x.cluster_name);
      });

    }

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }

  i = 0;
  filterWifiName(wifi: any, event: any) {
    this.deletedevice_id = [];
    this.allselect = false;
    this.checked = false;
    this.devicetableconfig.currentPage = 1;
    if (event.checked == true) {
      if (wifi == "Excellent") {
        this.Excelent = true;
      } else if (wifi == "Good") {
        this.Good = true;
      } else if (wifi == "Average") {
        this.Average = true;
      } else if (wifi == "Bad") {
        this.Bad = true;
      } else if (wifi == "Disconnected") {
        this.Disconnected = true;
      }
      this.selectedwifi.add(wifi);
    } else {
      if (wifi == "Excellent") {
        this.Excelent = false;
      } else if (wifi == "Good") {
        this.Good = false;
      } else if (wifi == "Average") {
        this.Average = false;
      } else if (wifi == "Bad") {
        this.Bad = false;
      } else if (wifi == "Disconnected") {
        this.Disconnected = false;
      }
      this.selectedwifi.delete(wifi);
    };

    if (this.selectedwifi.size >= 1) {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name) && this.selectedwifi.has(x.wifiStrength))
        .map((x: any) => ({ ...x, isSelected: false }));

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }


    } else {
      this.filterdevicevalue = this.devicevalue
        .filter((x: any) => this.selectedCategoriescluster.has(x.cluster_name))
        .map((x: any) => ({ ...x, isSelected: false }));

      if (this.online && this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (this.online && this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && !this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (this.online && !this.offline && this.Inactive) {
        this.online = true;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == true || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue.map((x: any) => ({
          ...x,
          isSelected: false,
        }));
      } else if (!this.online && this.offline && this.Inactive) {
        this.online = false;

        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false || x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && !this.offline && this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.status_Id == 0;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      } else if (!this.online && this.offline && !this.Inactive) {
        this.online = false;
        this.filterdevicevalue = this.filterdevicevalue
          .filter((x: any) => {
            return x.isOnline == false && x.status_Id == 1;
          })
          .map((x: any) => ({ ...x, isSelected: false }));
      }
    }

    if (this.filterdevicevalue.length <= 6) {
      this.onFilterTermChange()
    }
  }


  SelectallFilters(event: any, eventname: any) {

    if (event) {
      if (eventname == "Cluster") {
        this.selectedCategoriescluster.clear();
        let config = { checked: true }
        this.productvalues = this.productvalues.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.cluster_name);
          this.filterClusterName(x.cluster_name, config);
          return { ...x }
        })
      } else if (eventname == "Status") {
        this.online = true;
        this.offline = true;
        this.Inactive = true;
        this.statusform.controls.online.setValue(this.online);
        this.statusform.controls.offline.setValue(this.offline);
        this.statusform.controls.Inactive.setValue(this.Inactive);
        let config = { checked: true }
        this.deviceofflinefilter(config)

      } else if (eventname == "Wifistatus") {
        this.selectedwifi.clear()
        let config = { checked: true }
        this.wifistatus = this.wifistatus.map((x: any) => {
          x.ischecked = true;
          this.selectedwifi.add(x.name)
          this.filterWifiName(x.name, config);
          return { ...x }
        })

      } else if (eventname == "Vertical") {
        this.selectedCategoriesvertical.clear();
        let config = { checked: true }
        this.verticalvalues = this.verticalvalues.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesvertical.add(x.vertical_name)
          this.filterVerticalName(x.vertical_name, config)
          return { ...x }
        })
      } else if (eventname == "User") {
        this.selectedCategoriesuser.clear();
        let config = { checked: true }
        this.uniqueValues = this.uniqueValues.map((x: any) => {
          x.ischecked = true;
          this.selectedCategoriesuser.add(x.firstname)
          this.filterVerticalName(x.firstname, config)
          return { ...x }
        })
      }
    } else {
      if (eventname == "Cluster") {
        this.selectedCategoriescluster.clear();
        let config = { checked: false }
        this.productvalues = this.productvalues.map((x: any) => {
          x.ischecked = false;

          this.filterClusterName(x.cluster_name, config);
          return { ...x }
        })
      } else if (eventname == "Status") {
        this.online = false;
        this.offline = false;
        this.Inactive = false;
        this.statusform.controls.online.setValue(this.online);
        this.statusform.controls.offline.setValue(this.offline);
        this.statusform.controls.Inactive.setValue(this.Inactive);
        let config = { checked: false }
        this.deviceofflinefilter(config)

      } else if (eventname == "Wifistatus") {
        this.selectedwifi.clear()
        let config = { checked: false }
        this.wifistatus = this.wifistatus.map((x: any) => {
          x.ischecked = false;

          this.filterWifiName(x.name, config);
          return { ...x }
        })

      } else if (eventname == "Vertical") {
        this.selectedCategoriesvertical.clear();
        let config = { checked: false }
        this.verticalvalues = this.verticalvalues.map((x: any) => {
          x.ischecked = false;

          this.filterVerticalName(x.vertical_name, config)
          return { ...x }
        })
      } else if (eventname == "User") {
        this.selectedCategoriesuser.clear();
        let config = { checked: false }
        this.uniqueValues = this.uniqueValues.map((x: any) => {
          x.ischecked = false;

          this.filterVerticalName(x.firstname, config)
          return { ...x }
        })
      }
    }

  }



  /**
   * Downloads a CSV file containing the user's device data.
   */
  alldevicedatacsv() {
    const csvData = this.dataservice.convertDeviceToCSV(this.filterdevicevalue)

    this.dataservice.devicedownloadCSV(csvData, 'alldevice.csv')
  }

  /**
   * Navigates to device information page.
   * @param {any} device_Id - The ID of the device.
   * @param {any} product_Id - The ID of the product.
   */
  deviceinfo(device_Id: any, product_Id: any) {
    this.device_id = device_Id;
    this.router.navigate(['/app/dashboard', this.device_id, product_Id]);
  }
  /**
   * Navigates to user information page.
   * @param {any} user_Id - The ID of the user.
   */
  userinfo(user_Id: any) {
    this.router.navigate(['/app/userinfo', user_Id]);
  }

  /**
   * method to rename the device.
   * @param {any} device_id - The ID of the device.
   * @param {any} devicename - The name of the device.
   */
  rename(item: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(rename, {
        disableClose: true,
        data: item,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Deletes a device (if permitted) after user confirmation.
   * @param device_id - The ID of the device to be deleted.
   */
  deletedevice(device_id: any, cluster_id: any) {
    // console.log("checkkkkkkkkk", device_id, cluster_id);
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_id, cluster_id: cluster_id };


          this.dataservice.deletedevice(data).subscribe((res: any) => {
            this.loading = false;
            if (res.status == '200') {
              this.toastr.info('process failed');
            }
            if (res.status == '201') {

              this.toastr.success('Device Deleted');


            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Downloads the device log report.
   * @param device_Id - The ID of the device.
   * @param devicename - The name of the device.
   */
  devicereport(device_Id: any, devicename: any) {
    const data = { device_Id, devicename };
    this.dataservice.devicereport(data).subscribe((res: any) => {

      if (res.status == '400') {
        this.toastr.error('Error occurred');
      } else if (res.size == '34') {
        this.toastr.info("No log Found")
      } else {
        const a = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(res);
        a.href = fileUrl;
        a.download = devicename + '.log';
        a.click();
        window.URL.revokeObjectURL(fileUrl);
      }
    });
  }
  /**
   * Reboots a device (if permitted) after user confirmation.
   * @param Key - The device key.
   * @param last_online - The timestamp of the device's last online status.
   */
  devicereboot(Key: any, last_online: any, heartbeat: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'reboot',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          let Method = 'Restart';
          if (+this.currenttime - +last_online > 100 * heartbeat) {
            this.toastr.error('Device is offline');
          } else {
            const data = { Key, Method };
            this.socketService.emit('message', data);
            this.toastr.success('Device Reboot Succesfully');
          }
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Erases device data (if permitted) after user confirmation.
   * @param device_Id - The ID of the device.
   * @param devicename - The name of the device.
   */
  erasedevicedata(device_Id: any, devicename: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_Id };
          this.dataservice.erasedevicedata(data).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.info('No devicedata');
            }
            if (res.status == '201') {
              this.toastr.success('Events log Deleted');
              // window.location.reload();
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Handles the checkbox change event for individual devices.
   * @param event - The checkbox change event.
   * @param device_Id - The ID of the device.
   */
  onCheckboxChange(event: any, device_Id: any, result: any) {
    if (this.iscreate == true) {
      if (event.checked == true) {
        this.deletedevice_id.push(device_Id);
        this.filterdevicevalue.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = true;
          }
        });
        if (
          this.filterdevicevalue.length === this.deletedevice_id.length ||
          this.devicetableconfig.itemsPerPage === this.deletedevice_id.length
        ) {
          this.allselect = true;
        } else if (result.length === this.deletedevice_id.length) {
          this.allselect = true;
        }
        this.checked = true;
      } else {
        var index = this.deletedevice_id.indexOf(device_Id);
        this.deletedevice_id.splice(index, 1);
        this.filterdevicevalue.map((x: any) => {
          if (x.device_id == device_Id) {
            x.isSelected = false;
          }
        });
        if (this.deletedevice_id.length <= 0) {
          this.checked = false;
        }
        if (this.filterdevicevalue.length !== this.deletedevice_id.length) {
          this.allselect = false;
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }
  isCheckboxChecked: boolean = false;
  /**
   * Handles the checkbox change event for selecting all devices.
   * @param event - The checkbox change event.
   */
  allCheckboxChange(event: any, result: any) {

    if (this.iscreate == true) {
      const itemsPerPage = 6;
      const currentPage = this.devicetableconfig.currentPage;
      const startIndex = (currentPage - 1) * this.devicetableconfig.itemsPerPage;
      const endIndex = startIndex + this.devicetableconfig.itemsPerPage;
      console.log(result.length, this.devicetableconfig.itemsPerPage)



      if (event.checked == true) {
        if (result.length > this.devicetableconfig.itemsPerPage) {

          result.slice(startIndex, endIndex).forEach((x: any) => {
            x.isSelected = true;
          });
          this.checked = true;
          this.allselect = true;
          result.slice(startIndex, endIndex).forEach((x: any) => {
            if (this.deletedevice_id.indexOf(x.device_id) === -1) {
              this.deletedevice_id.push(x.device_id);
            }
          });
        } else {
          result.forEach((x: any) => {
            x.isSelected = true;
          });
          this.checked = true;
          this.allselect = true;
          result.forEach((x: any) => {
            if (this.deletedevice_id.indexOf(x.device_id) === -1) {
              this.deletedevice_id.push(x.device_id);
            }
          });
        }


      } else {

        if (result.length > this.devicetableconfig.itemsPerPage) {
          result.slice(startIndex, endIndex).forEach((x: any) => {
            x.isSelected = false;
          });
          this.allselect = false;
          this.checked = false;
          while (this.deletedevice_id.length > 0) {
            this.deletedevice_id.pop();
          }
        } else {
          result.forEach((x: any) => {
            x.isSelected = false;
          });
          this.allselect = false;
          this.checked = false;
          while (this.deletedevice_id.length > 0) {
            this.deletedevice_id.pop();
          }
        }


      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Deletes multiple devices (if permitted) after user confirmation.
   */
  multipledevicesdelete() {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          let data = [];
          data = this.deletedevice_id;
          this.dataservice.multipledevicesdelete(data).subscribe((res: any) => {
            if (res.status == '200') {


              this.toastr.success('Devices Deleted Successfully');

              this.allselect = false;
              this.checked = false;
              while (this.deletedevice_id?.length > 0) {
                this.deletedevice_id.pop();
              }
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * Clears the intervals set for updating device status and cleans up resources.
   */
  ngOnDestroy() {
    for (const intervalId of this.statusinterval) {
      clearInterval(intervalId);
    }

    const data = { cluster: this.selectedCategoriescluster, vertical: this.selectedCategoriesvertical, user: this.selectedCategoriesuser, online: this.online, offline: this.offline, inactive: this.Inactive, wifistatus: this.selectedwifi };
    this.dataservice.Storefilter(data, "All")
  }
}
// from product devices
@Component({
  selector: 'newdevice',
  templateUrl: 'devicepopup.html',
  styleUrls: ['popupstyles.css'],
})
export class newdevice implements OnInit {
  changetext = true;
  hidetext = true;
  hiddentext = true;

  constructor(public matdialog: MatDialog) { }
  ngOnInit(): void { }
  product() {
    const MatDialogConfig = this.matdialog.open(fromproduct, {
      disableClose: true,
    });
  }
  qrcode() {
    const MatDialogConfig = this.matdialog.open(qrcode, { disableClose: true });
  }
  manual() {
    const MatDialogConfig = this.matdialog.open(manual, { disableClose: true });
  }
}

// from product devices
@Component({
  selector: 'fromproduct',
  templateUrl: 'fromproduct.html',
  styleUrls: ['popupstyles.css'],
})
export class fromproduct implements OnInit {

  @ViewChild('first') first: ElementRef | undefined;
  @ViewChild('second') second: ElementRef | undefined;
  @ViewChild('three') three: ElementRef | undefined;
  @ViewChild('four') four: ElementRef | undefined;
  @ViewChild('five') five: ElementRef | undefined;
  @ViewChild('six') six: ElementRef | undefined;
  @ViewChild('qrcode') qrcode: ElementRef | undefined;
  @ViewChild('newInput', { static: true }) newInput!: ElementRef;
  loading: boolean;
  id: any;
  productvalue: any;
  data: any;
  devicedata: any;
  file: any;
  selectedTabIndex = 0;
  reader: any;
  fileContent: any;
  inputdisable: any;
  /**
   * Represents a form to collect device information.
   *
   */
  noSpaceAsSecondCharacter = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;

    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {
        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceAsSecondCharacter': true };
        }
      }
    }

    return null;
  };


  noSpaceInFirstTwoCharactersValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;


    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {

        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceInFirstTwoCharacters': true };
        }
      }
    }


    return null; // Validation passed
  };
  device = this.formbuilder.group({
    devicename: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ \\-]*$'),
        this.noSpaceInFirstTwoCharactersValidator
      ]],
    latitude: [
      '',
      [Validators.required, Validators.minLength(2)],
    ],
    longitude: [
      '',
      [Validators.required, Validators.minLength(2)],
    ],
    city: [
      '',
    ],
    product: ['', Validators.required],
    // gateway: ['', Validators.required],
    // Devicetype: ['', Validators.required],
    slave_id: ['', [Validators.required, Validators.min(1), Validators.max(256)]],
    first: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
    ],
    second: ['', [Validators.minLength(2), Validators.maxLength(2)]],
    three: ['', [Validators.minLength(2), Validators.maxLength(2)]],
    four: ['', [Validators.minLength(2), Validators.maxLength(2)]],
    five: ['', [Validators.minLength(2), Validators.maxLength(2)]],
    six: ['', [Validators.minLength(2), Validators.maxLength(2)]],
    qrcode: ['', Validators.required],
    device_mac_id: [''],
    Webzone_Id: [''],
    modelNumber: [''],
    deviceModel: ['', Validators.required]
  });
  subdevice = this.formbuilder.group({
    devicename: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        this.noSpaceInFirstTwoCharactersValidator
      ]],

  });
  slavedevice = this.formbuilder.group({
    product_Id: ['', Validators.required],
    dynamicInputs: this.formbuilder.array([])
  });
  /**
   * Represents a form to handle bulk device MAC ID creation.
   */
  devicemacidbulkform = this.formbuilder.group({
    product_Id: ['', Validators.required],
    deviceprefix: ['', [Validators.required, Validators.maxLength(3)]],
  });
  productid: any;
  webzonevalue: any;
  app_mode: any = 0;
  app_mode_force: any;
  undisable: any = 0;
  flag: boolean = false;
  cluster_id: any;
  device_id: any;
  dynamicInputValues: string[] = [];
  gatewayDevice: any;
  selectedGateway: any;
  slaveid: any[] = [];
  invalidslaveId: any;
  deviceModel: any;

  /**
   * Constructor of the component, used to initialize member variables and services.
   *
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice providing data-related functionalities.
   * @param clipboard - Clipboard service for managing clipboard operations.
   * @param toastr - ToastrService for displaying toast notifications.
   * @param formbuilder - FormBuilder for creating and managing forms.
   * @param authentication - AuthenticationService for managing user authentication.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataa: any,
    private formbuilder: UntypedFormBuilder,
    private dataservice: Dataservice,
    private matdialog: MatDialog,
    private toastr: ToastrService,
    private navigater: Router,
    private authentication: AuthenticationService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    $("body").on("click", "#DeleteRow", function () {
      $(this).parents("#row").remove();
    });
    if (this.dataa) {
      this.flag = this.dataa.flag;
      this.device_id = this.dataa.device_id
      this.cluster_id = this.dataa.cluster_id
    } else {
      this.flag = false;
      this.invalidslaveId = false;
    }



    this.loading = false;


  }


  pastelatlng(event: any) {

    navigator.clipboard.readText().then(async (clipboardtext: any) => {
      if (clipboardtext) {
        await Promise.all(clipboardtext = clipboardtext.split(',').map((x: any) => {
          return (x.trim())
        }))
        this.device.controls['latitude'].setValue(clipboardtext[0])
        this.device.controls['longitude'].setValue(clipboardtext[1])
        this.onLatLongChange()
      } else {
        this.toastr.info("Latitude and Longitude not found")
      }

    })

  }

  onSelectChange(event: any) {
    this.selectedGateway = event.target.value;


    this.dataservice.slavedeviceexist(this.selectedGateway).subscribe((res: any) => {
      let result = res.data

      this.slaveid = result.map((x: any) => {
        return x['slave_id']
      })
      // console.log(this.slaveid);
    })

  }
  /**
   * Called when a file is selected from the file input.
   * Reads the selected file's content using FileReader.
   * @param event - The file input change event.
   */
  onFileSelected(event: any) {
    this.file = event.target.files[0];
    this.reader = new FileReader();
    this.reader.onloadend = () => {
      this.fileContent = this.reader.result as string;
    };
    this.reader.readAsText(this.file);
  }

  /**
    * Initiates the download of a sample file using DataService.
    */
  download() {
    this.dataservice.samplefile();
  }
  /**
   * Angular lifecycle hook: This method is called after the component's view and content are initialized.
   */

  ngOnInit(): void {
    this.device.controls['slave_id'].disable()
    this.device.controls['slave_id'].valueChanges.subscribe((res: any) => {
      if (this.slaveid.includes(res)) {
        this.invalidslaveId = true;
      } else {
        this.invalidslaveId = false;
      }
    })
    this.device.controls['product'].valueChanges.subscribe((res: any) => {
      if (this.device.controls['product'].invalid) {
        this.device.controls['slave_id'].reset()
        this.device.controls['slave_id'].disable()
      } else {
        this.device.controls['slave_id'].enable()
      }
    })
    this.loading = true;
    // if (this.app_mode == '0') {
    this.device.controls['first'].disable();
    this.device.controls['second'].disable();
    this.device.controls['three'].disable();
    this.device.controls['four'].disable();
    this.device.controls['five'].disable();
    this.device.controls['six'].disable();
    this.device.controls['qrcode'].disable();
    // }
    // const vertical_Id = user.vertical_Id;
    const data = this.authentication.getUserData();
    this.dataservice.getGatewayDevice(data).subscribe((res: any) => {
      this.gatewayDevice = res.data
    })
    this.dataservice.getdevicecreateproductdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });
    this.dataservice.getwebzonedata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.webzonevalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });

    this.dataservice.getDeviceModel().subscribe((res: any) => {
      if (res.status == '200') {
        this.deviceModel = res.data;


      } else {
        this.toastr.error('Error occurred');
      }
    })
    // this.device.controls['first'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['first'].valid) {
    //     this.second?.nativeElement.focus();
    //   }
    // });

    // this.device.controls['second'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['second'].valid) {
    //     this.three?.nativeElement.focus();
    //   }
    // });

    // this.device.controls['three'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['three'].valid) {
    //     this.four?.nativeElement.focus();
    //   }
    // });

    // this.device.controls['four'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['four'].valid) {
    //     this.five?.nativeElement.focus();
    //   }
    // });

    // this.device.controls['five'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['five'].valid) {
    //     this.six?.nativeElement.focus();
    //   }
    // });

    // this.device.controls['six'].valueChanges.subscribe((x: any) => {
    //   if (this.device.controls['six'].valid) {
    //     this.qrcode?.nativeElement.focus();
    //   }
    // });

    this.device.controls['product'].valueChanges.subscribe((x: any) => {
      this.productvalue.find((y: any) => {
        if (y.cluster_id == x) {
          this.app_mode = y.app_mode;
          this.app_mode_force = y.app_mode_force;
          this.undisable = y.app_mode_force;
          // if (this.undisable == '1') {
          //   this.device.controls['first'].enable();
          //   this.device.controls['second'].enable();
          //   this.device.controls['three'].enable();
          //   this.device.controls['four'].enable();
          //   this.device.controls['five'].enable();
          //   this.device.controls['six'].enable();
          //   this.device.controls['qrcode'].enable();
          // }
          // if (this.app_mode == '1' && this.undisable == '0') {
          //   this.device.controls['first'].enable();
          //   this.device.controls['second'].enable();
          //   this.device.controls['three'].enable();
          //   this.device.controls['four'].enable();
          //   this.device.controls['five'].enable();
          //   this.device.controls['six'].enable();
          //   this.device.controls['qrcode'].enable();
          // }
          // if (this.app_mode == '0' && this.undisable == '0') {
          //   this.device.controls['first'].disable();
          //   this.device.controls['second'].disable();
          //   this.device.controls['three'].disable();
          //   this.device.controls['four'].disable();
          //   this.device.controls['five'].disable();
          //   this.device.controls['six'].disable();
          //   this.device.controls['qrcode'].disable();
          // }
        }
      });
    });
  }



  onLatLongChange() {
    const latitude = this.device.get('latitude')?.value;
    const longitude = this.device.get('longitude')?.value;

    if (latitude && longitude) {
      let _dsSecret = "aa4cf81bfe55a14d82d20e5d60149528";
      var proxy = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${_dsSecret}`;
      var URLRequest = proxy
      $.getJSON(URLRequest)
        //Success promise
        .done((data: any) => {
          const city = data.name
          this.device.patchValue({ city });
        })
        //Error promise
        .fail(function () {
        }
        );
    }
  }
  open() {
    const MatDialogConfig = this.matdialog.open(newdevice, {
      disableClose: true,
    });
  }
  /**
   * Sets the product ID when a number is received.
   * @param id - The received product ID.
   */
  num(id: any) {
    this.productid = id;
  }

  /**
   * Called when the toggle switch's value changes.
   * Enables or disables specific form controls based on the toggle's state.
   * @param event - The toggle change event.
   */
  onToggleChange(event: any) {
    if (event.checked == true) {
      this.app_mode = '1';
      this.device.controls['first'].enable();
      this.device.controls['second'].enable();
      this.device.controls['three'].enable();
      this.device.controls['four'].enable();
      this.device.controls['five'].enable();
      this.device.controls['six'].enable();
      this.device.controls['qrcode'].enable();
    } else {
      this.app_mode = '0';
      this.device.controls['first'].disable();
      this.device.controls['second'].disable();
      this.device.controls['three'].disable();
      this.device.controls['four'].disable();
      this.device.controls['five'].disable();
      this.device.controls['six'].disable();
      this.device.controls['qrcode'].disable();
    }
  }
  /**
   * Called when the checkbox value changes.
   * Updates the `app_mode_force` based on whether the checkbox is checked or not.
   * @param event - The checkbox change event.
   */
  onCheckboxChange(event: any) {
    if (event.checked == true) {
      this.app_mode_force = '1';
    } else {
      this.app_mode_force = '0';
    }
  }
  /**
   * Creates a new device using the entered device data.
   * Makes an API call to create a device and navigates to device information page on success.
   */
  newdevice() {
    this.id = this.authentication.getUserData();
    const user_id = this.id.user_Id;
    const org_Id = this.id.org_Id;
    this.device.controls['devicename'].setValue(
      this.device.controls['devicename'].value.replace(/[^\S\r\n]{2,}/g, ' ')
    );
    const devicedata = this.device.value;
    const data = { devicedata, user_id, org_Id };
    this.dataservice.createdevice(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '0') {
        this.toastr.error('Device not Created');
      }
      if (res.status == '201') {
        this.toastr.success('Device Successfully Created');
        this.navigater.navigate([
          '/app/deviceinfo',
          res.device_Id,
          res.product_Id,
        ]);
      } else {
        this.toastr.error('Error occured');
      }
    });
  }

  get dynamicInputControls() {
    return (this.slavedevice.get('dynamicInputs') as FormArray);
  }

  addForm() {
    this.dynamicInputControls.push(this.formbuilder.group({ value: '' }));
  }

  removeDynamicInput(index: number) {
    this.dynamicInputControls.removeAt(index);
  }

  submitslavedevice() {
    const formValues = this.slavedevice.value;
    // console.log(formValues);
    this.id = this.authentication.getUserData();
    const user_id = this.id.user_Id;
    const org_Id = this.id.org_Id;
    this.subdevice.controls['devicename'].setValue(
      this.subdevice.controls['devicename'].value.replace(/[^\S\r\n]{2,}/g, ' ')
    );

    const devicedata = this.subdevice.value;
    // console.log(devicedata);

    const data = {
      devicedata, user_id, org_Id, parent_device_id: this.device_id,
      cluster_id: this.cluster_id
    };
    this.dataservice.createsubdevice(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '0') {
        this.toastr.error('Device not Created');
      }
      if (res.status == '201') {
        this.toastr.success('Device Successfully Created');
      } else {
        this.toastr.error('Error occured');
      }
    });
  }
  CreateSubdevice() {
    this.id = this.authentication.getUserData();
    const user_id = this.id.user_Id;
    const org_Id = this.id.org_Id;
    this.subdevice.controls['devicename'].setValue(
      this.subdevice.controls['devicename'].value.replace(/[^\S\r\n]{2,}/g, ' ')
    );

    const devicedata = this.subdevice.value;
    // console.log(devicedata);

    const data = {
      devicedata, user_id, org_Id, parent_device_id: this.device_id,
      cluster_id: this.cluster_id
    };
    this.dataservice.createsubdevice(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '0') {
        this.toastr.error('Device not Created');
      }
      if (res.status == '201') {
        this.toastr.success('Device Successfully Created');
      } else {
        this.toastr.error('Error occured');
      }
    });
  }
  /**
   * Creates a new device with MAC ID using the entered device data.
   * Makes an API call to create a device with MAC ID and navigates to device information page on success.
   */
  newdeviceWithMac() {
    this.id = this.authentication.getUserData();
    const user_id = this.id.user_Id;
    const org_Id = this.id.org_Id;
    this.device.controls['devicename'].setValue(
      this.device.controls['devicename'].value.replace(/[^\S\r\n]{2,}/g, ' ')
    );
    this.device.value.device_mac_id =
      String(this.device.value.first) +
      ':' +
      this.device.value.second +
      ':' +
      this.device.value.three +
      ':' +
      this.device.value.four +
      ':' +
      this.device.value.five +
      ':' +
      this.device.value.six;
    const devicedata = this.device.value;
    if (this.app_mode == '1') {
      this.app_mode = '0';
    }
    if (this.app_mode_force == '1') {
      this.app_mode = '1';
    }

    const data = { devicedata, user_id, org_Id, app_mode: this.app_mode };

    // Make API call
    this.dataservice.createdevicesWithMac(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '0') {
        this.toastr.error('Device not Created');
      } else if (res.status == '100') {
        this.toastr.info('Device with MAC ID already exists');
      } else if (res.status == '201') {
        this.toastr.success('Device Successfully Created');
        // setTimeout(() => {
        this.navigater.navigate([
          '/app/deviceinfo',
          res.device_Id,
          res.product_Id,
        ]);
        // }, 1500);
      } else if (res.status != '0' || '100' || '201') {
        this.toastr.error('Error occured');
      }
    });
  }
  /**
   * Uploads multiple device data using a file upload.
   * Prepares form data and makes an API call for importing devices.
   */
  upload() {
    this.id = this.authentication.getUserData();

    const user_id = this.id.user_Id;
    const org_Id = this.id.org_Id;

    const formData = new FormData();
    formData.append('File', this.file, this.file.name);
    formData.append(
      'product_Id',
      String(this.devicemacidbulkform.value.product_Id)
    );
    formData.append(
      'deviceprefix',
      this.devicemacidbulkform.value.deviceprefix.replace(/[^\S\r\n]{2,}/g, ' ')
    );
    formData.append('user_Id', user_id);
    formData.append('org_Id', org_Id);

    this.dataservice.multipledeviceimport(formData).subscribe((res: any) => {
      if (res.status == '201') {
        this.toastr.success('Devices Import Successfully');
        // window.location.reload();
        this.matdialog.closeAll();
      } else {
        this.toastr.error('error occurred');
      }
    });
  }
  get deviceform(): { [key: string]: AbstractControl } {
    return this.device.controls;
  }
}

// qr code invite matdialog
@Component({
  selector: 'qrcode',
  templateUrl: 'qrcode.html',
  styleUrls: ['popupstyles.css'],
})
export class qrcode implements OnInit {
  scannerEnabled = true;
  constructor(private matdialog: MatDialog) { }
  ngOnInit(): void { }
  // qr code scanner function
  public enableScanner() {
    this.scannerEnabled = !this.scannerEnabled;
  }

  open() {
    const MatDialogConfig = this.matdialog.open(newdevice, {
      disableClose: true,
    });
  }
}

// manual invite matdialog
@Component({
  selector: 'manual',
  templateUrl: 'manual.html',
  styleUrls: ['popupstyles.css'],
})
export class manual {
  constructor(
    private formbuilder: UntypedFormBuilder,
    private matdialog: MatDialog
  ) { }
  manual = this.formbuilder.group({
    code: ['', Validators.required],
  });
  get code(): { [key: string]: AbstractControl } {
    return this.manual.controls;
  }

  open() {
    const MatDialogConfig = this.matdialog.open(newdevice, {
      disableClose: true,
    });
  }
}

//edit devicename ts file
@Component({
  selector: 'rename',
  templateUrl: 'rename.html',
  styleUrls: ['popup.css'],
})
export class rename implements OnInit {
  isedit = false;
  isdelete = false;
  loading: boolean;
  slaveid: any[] = [];
  invalidslaveId: any;
  errorMessage: '' | undefined;
  deviceModel: any[] = [];
  /**
* Constructor of the component, used to initialize member variables and services.
*
* @param dialogRef - MatDialog service for opening dialog windows.
* @param dataservice - Dataservice providing data-related functionalities.
* @param formbuilder - FormBuilder for creating and managing forms.

*/
  constructor(
    private dataservice: Dataservice,
    public formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<MydevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.invalidslaveId = false;
  }

  noSpaceInFirstTwoCharactersValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;


    if (value && value.length >= 2) {
      // Check if the second character is a space
      if (value.charAt(1) === ' ') {

        // Check if there are two or more spaces after the first character
        if (value.substring(2).includes(' ')) {
          return { 'noSpaceInFirstTwoCharacters': true };
        }
      }
    }


    return null; // Validation passed
  };


  deviceform = this.formbuilder.group({
    devicename: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[A-Za-z_][A-Za-z0-9_ \\-]*$'), this.noSpaceInFirstTwoCharactersValidator],
    ],
    slave_id: ['', [Validators.required, Validators.min(1), Validators.max(256)]],
    deviceModel: ['', [Validators.required]],
    model_number: ['']
  });
  /**
     Set initial value for devicename input based on passed data
     */
  ngOnInit(): void {
    this.deviceform.get('devicename')?.setValue(this.data?.device_name);
    this.deviceform.get('slave_id')?.setValue(this.data?.slave_id);
    this.deviceform.get('deviceModel')?.setValue(this.data?.model_id);
    this.deviceform.get('model_number')?.setValue(this.data?.model_number);




    this.dataservice.slavedeviceexist(this.data.authtoken).subscribe((res: any) => {
      let result = res.data

      this.slaveid = result.map((x: any) => {
        if (this.data.slave_id != x.slave_id) {
          return x['slave_id'];
        }

      })
    });
    this.deviceform.controls['slave_id'].valueChanges.subscribe((res: any) => {


      if (this.slaveid.includes(res)) {
        this.invalidslaveId = true;
      } else {
        this.invalidslaveId = false;
      }
    })



    this.dataservice.getDeviceModel().subscribe((res: any) => {
      if (res.status == '200') {
        this.deviceModel = res.data;


      } else {
        this.toastr.error('Error occurred');
      }
    })




  }

  /**
Function to handle the device name editing

*/
  editname() {
    this.loading = true;
    const device_id = this.data.device_id;
    this.deviceform.controls.devicename.setValue(
      String(this.deviceform.value.devicename).replace(/[^\S\r\n]{2,}/g, ' ')
    );





    const data = { ...this.deviceform.value, device_id };

    // Make API call to update device name
    this.dataservice.updatedevicename(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '200') {
        this.toastr.info('Update failed');
      }
      if (res.status == '201') {
        this.toastr.success('Updated Successfully');
        this.dialogRef.close();
      } else {
        this.toastr.error('Error occured');
      }
    });
  }
  get devicenameform(): {
    [key: string]: AbstractControl;
  } {
    return this.deviceform.controls;
  }
}

//delete device ts file
@Component({
  selector: 'deletepopup',
  templateUrl: 'delete.html',
  styleUrls: ['popup.css'],
})
export class deletepopup implements OnInit {
  confirmation: any;
  /**
   * Constructor of the component, used to initialize member variables and services.
   *gj6nm987
   *
   * @param dialogRef - MatDialog service for opening dialog windows.
   * @param data - Data injected into the dialog. Contains information about the device.
   */
  constructor(
    public dialogRef: MatDialogRef<MydevicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  /**
   * Lifecycle hook called when the component is initialized.
   * Logs the injected data about the device.
   */
  ngOnInit(): void {
    // console.log(this.data);
  }
  /**
   * Function to cancel the operation and close the dialog.
   * Sets the confirmation status to false and closes the dialog with the status.
   */
  cancelfun() {
    this.confirmation = false;
    this.dialogRef.close({ confirmation: this.confirmation });
  }
  /**
   * Function to confirm the deletion operation and close the dialog.
   * Sets the confirmation status to true and closes the dialog with the status.
   */
  deletefun() {
    this.confirmation = true;

    this.dialogRef.close({ confirmation: this.confirmation });
  }
}

// devices info page start

@Component({
  selector: 'deviceinfo',
  templateUrl: 'deviceinfo.html',
  styleUrls: ['./mydevices.component.css'],
})
export class deviceinfo implements OnInit {
  @ViewChild('gauge') gauge: ElementRef | undefined;
  @HostListener('document:fullscreenchange', ['$event'])
  isFullscreen = false;
  eventtabindex: any = 0;
  fullscreenChangeHandler(event: Event) {
    this.isFullscreen = document.fullscreenElement !== null;
  }
  @ViewChild('dbdesign_sidenav') sidenav_dbdesign!: MatSidenav;
  @ViewChild('gridster') gridster!: GridsterComponent;
  @ViewChild('lineChart') lineChart!: UIChart;
  @ViewChildren('lineCharts') charts!: QueryList<UIChart>;

  @Output()
  input!: EventEmitter<MatSliderChange>;
  devicevalue: any;
  alldevicevalue: any;
  isconfirm: any;
  loading: boolean;
  data: any;
  show: any;
  productdata: any;
  device_id: any;
  devicedata: any;
  allComplete: boolean = false;
  time: any;
  id: any;
  user_Id: any;
  role: any;
  verticalvalue: any;
  devicename: any;
  selection: any;
  productvalue: any;
  userdata: any;
  config: any;
  devicelog: any[] = [];
  filterTerm!: string;
  devicesidenav: any;

  userdevice: any;
  labelPosition: 'before' | 'after' = 'after';
  dataRefresher: any;

  value: any;
  acceleration!: any[];
  rotation!: any[];
  temparature: any;
  currenttime: any;
  status: any;
  statusdata: any;
  last_online: any;
  deviceversion: any;
  status_Id: any;
  basicdata: any;
  led: any;
  checked: boolean;
  refresh: boolean;
  allselect: boolean;
  device_data: any[] = [];
  device_value: any[] = [];
  slidervalue: any;
  terminalmsg: any[] = [];
  devicedashboard: any[] = [];
  deletedevice_id: any[] = [];

  // sidenav variable
  isShowing: boolean;
  interval: any;
  widget: any;
  labeldata: any;
  pin: any;
  widget_pin: any;
  product_Id: any;
  logrefresh: boolean;

  datarefreshinterval: any[] = [];
  dashboardloader: boolean;
  digitaldata: any[] = [];
  analogdata: any[] = [];
  virtualdata: any[] = [];
  overlapTrigger!: boolean;

  selectedTabIndex = 0; // The index of the tab that you want to be selected
  uniqueValues: any;
  selectedCategoriescluster = new Set();
  filterdevicevalue: any;
  productvalues: any;
  tokenanalog: any;
  tokenvirtual: any;
  tokendigital: any;
  device_token: any;
  analog: any[] = [];
  virtual: any[] = [];
  digital: any[] = [];
  objectdigital: any;
  objectanalog: any;
  objectvirtual: any;
  timeout: any;
  digitalpin: any;
  analogpin: any;
  virtualpin: any;
  online: boolean;
  offline: boolean;
  refreshdevicevalue: any;
  metadatalist: any;
  devicedatafilter: any;
  selectedFilter!: string;
  timelineItems: any;
  allTimelineItems: any;
  devicetoken: any;
  device_Id: any;
  device_name: any;
  device_version: any;
  device_mac_Id: any;
  device_activation: any;
  device_auth_token: any;
  user_id: any;
  product_name: any;
  hardware_name: any;
  product_logo: any;
  heartbeat: any;
  product_api_Id: any;
  firstname: any;
  email_id: any;
  isactive: any;
  role_name: any;
  reg_date: any;
  lastlogin: any;
  user_profile: any;
  org_Name: any;
  zonename: any;
  timezone: any;
  latitude: any;
  longitude: any;
  outputdata: any[] = [];
  loaded = false;
  position: any[] = [];
  chartlabeltime: any;
  chartoptionsnoyaxis: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  highcount: any = 0;
  lowcount: any = 0;
  events: any[] = [];
  chartoptions: any;
  chartlabel: any[] = [];
  Chartwidth: any = '500px';
  // Chartheight: any = "300px";
  mattabindex: any;
  Infomessage: any[] = [];
  Warningmessage: any[] = [];
  Criticalmessage: any[] = [];

  // ngx charts
  animations: boolean = true;
  showXAxis = true;
  showYAxis = true;

  showLegend = true;
  legendTitle = 'Legend';
  gradient = false;
  showXAxisLabel = true;
  xAxisLabel = 'TimeSeries';
  yAxisLabel = 'Value';
  // line, area
  autoScale = true;
  timeline = false;
  legendPosition = LegendPosition.Right;
  showYAxisLabel = true;
  xScaleMin!: any;
  xScaleMax!: any;
  yScaleMin!: any;
  yScaleMax!: any;
  rangeFillOpacity: number = 0.15;
  roundDomains = false;
  tooltipDisabled = false;
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  wrapTicks = false;
  showGridLines = true;
  schemeType = ScaleType.Ordinal;
  xAxisticks: any[] = [];
  yAxisTicks: any[] = [];
  chartfilter = 'Day';
  filter: any = 'Day';
  strokeColor: any = '#ffffff';
  dark: any = 'light';
  ChartRefresh: boolean = false;
  curves: any = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear,
  };

  // Configuration for gauge markers
  markersconfig = {
    '0': { color: 'blue', size: 5, label: '0', type: 'line' },
    '100': { color: 'red', size: 5, label: '100', type: 'line' },
  };
  gaugeType = 'arch';
  gaugeLabel = 'Speed';
  Type = 'full';
  // slider
  gaugeValueExceededMax: boolean = false;
  subdevices: any[] = [];
  // Configuration options for the gridster layoutca
  gridsterOptions: GridsterConfig = {
    gridType: 'fixed',
    fixedColWidth: 1000,
    fixedRowHeight: 100,
    compactType: 'none',
    margin: 10,
    outerMargin: false,
    minCols: 16,
    maxCols: 16,
    minRows: 5,
    maxRows: 100,
    displayGrid: 'onDrag&Resize',
    // defaultItemCols: 2,
    // defaultItemRows: 1,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,

    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    pushItems: true,
    pushResizeItems: false,
    swap: true,
  };
  Barchartoptions: any;
  /**
   *  Function to open the side navigation
   */
  // Function to toggle fullscreen
  tooglefullscreen() {
    const element = document.documentElement;

    if (!this.isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    // console.log(document.fullscreenElement);
    // console.log(this.isFullscreen);

    // Update the fullscreen state
    this.isFullscreen = !!document.fullscreenElement;
  }


  Sidenavopen() {
    this.gridsterOptions.fixedColWidth = 63;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridster?.optionsChanged();
    this.Chartwidth = '545px';
    // this.Chartheight = "300px ";
  }
  /**
   *  Function to CLOSE the side navigation
   */
  Sidenavclose() {
    this.gridsterOptions.fixedColWidth = 75;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridster?.optionsChanged();
    this.Chartwidth = '600px';

    // this.Chartheight = "300px ";
  }
  darkMode = false;

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    if (!this.darkMode) {
      this.dark = 'light';
      this.strokeColor = '#ccc';
      localStorage.setItem('mode', JSON.stringify(this.darkMode));
    }
    if (this.darkMode) {
      this.dark = 'dark';
      this.strokeColor = '#ccc';
      localStorage.setItem('mode', JSON.stringify(this.darkMode));
    }
  }
  /**
   *   Form group for terminal input
   */
  terminalform = this.formbuilder.group({
    terminal: [''],
  });
  item: any;
  devicedataconfig: { id: string; itemsPerPage: number; currentPage: number };
  options: any = {
    draggable: true,
    resizable: true,
    // Other gridster options
  };

  /**
   * Constructor of the component.
   * Initializes member variables, services, and configurations.
   *
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice for handling data operations.
   * @param toastr - ToastrService for displaying notifications.
   * @param formbuilder - FormBuilder for creating and managing form groups.
   * @param clipboard - Clipboard service for copying content to the clipboard.
   * @param socketService - SocketService for handling socket communication.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param router - Router for navigation between routes.
   * @param _location - Location for interacting with the browser's history.
   * @param authentication - AuthenticationService for handling user authentication.
   * @param homecomponent - HomeComponent for managing home-related components.
   * @param changeDetectorRef - ChangeDetectorRef for manually triggering change detection.
   * @param navigater - Router for navigation management.
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private clipboard: Clipboard,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private authentication: AuthenticationService,
    private homecomponent: HomeComponent,
    private changeDetectorRef: ChangeDetectorRef,
    private navigater: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loading = false;
    this.isShowing = false;
    this.checked = false;
    this.refresh = false;
    this.logrefresh = false;
    this.allselect = false;
    this.dashboardloader = false;
    this.online = true;
    this.offline = true;
    this.ChartRefresh = true;
    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 4,
      currentPage: 1,
    };
    // Retrieve user roles from authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[4];
    // Map user roles to component-specific permissions
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });

    // Subscribe to Deviceinfo event for updates

    this.dataservice.Deviceinfo.subscribe((response: any) => {
      this.ngOnInit();
    });
  }

  /**
   * Lifecycle hook called after the component's view has been initialized.
   * Initializes component data and subscribes to data services.
   */

  ngOnInit() {
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();

    this.loading = true;

    this.device_Id = this.route.snapshot.paramMap.get('id1');
    this.product_Id = this.route.snapshot.paramMap.get('id2');
    this.mattabindex = this.route.snapshot.paramMap.get('id3');
    this.eventtabindex = this.route.snapshot.paramMap.get('id4');

    this.info(this.device_Id, this.product_Id);
    this.dataservice.Devicerefresh.subscribe((response: any) => {
      this.info(this.device_Id, this.product_Id);
    });
    this.dataservice.singleDevicestatus.subscribe((response: any) => {
      this.ngOnDestroy();
    });

    this.dataservice.Sidenavclose.subscribe((response: any) => {
      this.Sidenavclose();
    });
    this.dataservice.Sidenavopen.subscribe((response: any) => {
      this.Sidenavopen();
    });
  }



  // subdevices create
  newsubdevice() {
    if (this.iscreate == true) {
      const dialogConfig = new MatDialogConfig();
      // console.log(dialogConfig);
      this.device_Id
      this.product_Id
      // Set data property to send a flag
      dialogConfig.data = { flag: true, device_id: this.device_Id, cluster_id: this.product_Id };

      // Open the dialog with MatDialogConfig
      const dialogRef = this.matdialog.open(fromproduct, dialogConfig);

    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }







  cluster_api_Id: any;
  // Method to change the selected tab
  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }

  async info(device_id: any, product_Id: any) {
    if (this.mattabindex) {
      this.selectTab(this.mattabindex);
    } else {
      this.selectTab(0);
    }
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    /**
     * Sets up chart options for displaying data over time.
     */
    this.Barchartoptions = {
      fill: true,
      borderWidth: 1,
      tension: 0.4,
      backgroundColor: [
        'rgba(255, 159, 64, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
      ],
      borderColor: [
        'rgb(255, 159, 64)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
      ],
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                minute: 'HH:mm',
                hour: 'HH:mm',
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              display: false,
            },
          },
        ],

        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Value',
            },
          },
        ],
      },
      animation: false,
    };
    this.chartoptions = {
      fill: true,
      borderColor: '#FFA726',
      tension: 0.4,
      backgroundColor: 'rgba(255,167,38,0.2)',
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                minute: 'HH:mm',
                hour: 'HH:mm',
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              display: false,
            },
          },
        ],

        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Value',
            },
          },
        ],
      },
      animation: false,
    };

    // this.chartlabeltime = new Date().toLocaleTimeString();
    // setInterval(() => {

    /**
     * Fetches device information from the server.
     */

    this.device_id = device_id;
    this.product_Id = product_Id;
    this.dashboardloader = true;

    const data = { device_id };
    // Fetch initial device information and set up real-time updates
    await this.dataservice.singledeviceinfo(data).subscribe((res: any) => {
      this.loading = false;
      this.currenttime = new Date();
      if (res.status == '200') {
        this.data = res.data;
        this.item = res.data;
        if (Number(this.item?.heartbeat) > 20) {
          this.item.heartbeat = Number(this.item.heartbeat) + 5
          this.item.heartbeat = Number(this.item.heartbeat) * 12.5
        }
        // Convert the last_online property to a Date object for proper display
        this.item.last_online = new Date(this.item.last_online);
        const token = res.data.device_auth_token;
        const clusterid = res.data.cluster_api_Id;
        this.device_auth_token = token;
        this.cluster_api_Id = clusterid


        // Emit a socket event to join a specific channel
        this.socketService.emit('joinchannel', {
          devicelink: token,
          clusterid: clusterid,
        });
        this.datarefreshinterval.push(
          setInterval(() => {
            // Set up an interval to refresh socket connection every 300 seconds (5 minutes)
            this.socketService.emit('joinchannel', {
              devicelink: token,
              clusterid: clusterid,
            });
          }, 60000)
        );
      } else {
        this.toastr.error('error occurred');
      }
    });
    // Set up interval to periodically fetch updated device information
    this.datarefreshinterval.push(
      setInterval(() => {
        this.currenttime = new Date();
        // Fetch device information from the server
        this.dataservice.singledevicestatus(data).subscribe((res: any) => {
          if (res.status == '200') {
            this.data = res.data;
            let timedata = this.data;
            // Update local data and item with updated device status
            this.item.last_online = new Date(timedata.last_online);
            this.item.device_version = timedata.device_version;
            this.item.status_Id = timedata.status_Id;
            this.item.wifi_status = timedata.wifi_status;
            if (
              +this.currenttime - +this.item.last_online <
              100 * this.item.heartbeat &&
              this.item.status_Id == 1
            ) {
              this.item['isOnline'] = true;
            } else {
              this.item['isOnline'] = false;
            }
          } else {
            this.toastr.error('error occurred');
          }
        });
      }, 5000)
    );
    // Fetch product metadata for the specific product
    await this.dataservice
      .getproductmetadata(product_Id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.metadatalist = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });

    // this.datarefreshinterval = setInterval(() => {

    // Fetch dashboard data for the specific device
    await this.dataservice
      .Getdashboarddata(this.device_id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.outputdata = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });
    // Fetch device events and categorize them based on event_type
    await this.dataservice
      .Getsingledeviceevents(this.device_id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.events = res.data;
          // Categorize events into different arrays based on event_type
          // console.log(this.events);
          this.Infomessage = []
          this.Warningmessage = []
          this.Criticalmessage = []
          this.events.map((x: any) => {


            // if (x.event_type == 0 && x.device_Id == this.device_id) {
            //   this.Infomessage = x
            // } else if (x.event_type == 1 && x.device_Id == this.device_id) {
            //   this.Warningmessage = x;
            // } else if(x.event_type == 2 && x.device_Id == this.device_id) {
            //   this.Criticalmessage = x;
            // }

            if (x.event_type == 0) {
              this.Infomessage.push(x)
            } else if (x.event_type == 1) {
              this.Warningmessage.push(x);
            } else if (x.event_type == 2) {
              this.Criticalmessage.push(x);
            }
          });
        } else {
          this.toastr.error('error occurred');
        }
      });
    // Fetch device routes and dashboard configuration for the specified product
    // await this.dataservice.getRoutes(product_Id).subscribe((res: any) => {
    //   this.dashboardloader = false;
    //   if (res.status == '200') {
    //     // Extract device dashboard configuration from the response
    //     let device_dashboard: any = res?.data[1][0].device_d_data;
    //     // Parse the JSON data from device_dashboard

    //     device_dashboard = JSON.parse(device_dashboard);
    //     // console.log(device_dashboard);

    //     this.devicedashboard = device_dashboard;

    //     // console.log(this.devicedashboard)

    //     this.devicedashboard.map(async (x: any) => {
    //       if (x.widgetname == "Chart") {
    //         x.devicedata = [];
    //       }
    //       if (x.widgetname == "Label") {
    //         x.devicedata = []
    //         if (x?.pin) {
    //           this.GetLabelData(this.device_Id, x.pin)
    //         }
    //       }
    //     });
    //   } else {
    //     this.toastr.error('Error occured');
    //   }
    // });

    // setTimeout(() => {

    //   this.devicedashboard.map(async (x: any) => {
    //     if (x.widgetname == "Chart") {
    //       if (x?.pin) {
    //         await this.Getchartdata(this.device_Id, x.pin, x.widget_Id, x.chartfilter);
    //       }
    //     }
    //   });
    // }, 6000)

    // Set up socket listeners for different types of inputs and outputs

    // Listen for analog input data
    // this.socketService.listen('analoginput').subscribe((data: any) => {
    //   // Add received analog data to the existing analog data array
    //   this.analog = this.analog ? [...this.analog, data] : [data];
    // });

    // // Listen for digital input data
    // this.socketService.listen('digitalinput').subscribe((data: any) => {
    //   this.digital = this.digital ? [...this.digital, data] : [data];
    // });
    // // Listen for virtual input data
    // this.socketService.listen('virtualinput').subscribe((data: any) => {
    //   this.virtual = this.virtual ? [...this.virtual, data] : [data];

    //   const labelIndices = this.devicedashboard
    //     .map((x, i) => ({ widget: x }))
    //     .filter((item) => item.widget.widgetname === 'Label')
    //     .map((item) => item.widget);
    //   // const cOSTIndices = this.devicedashboard
    //   //   .map((x, i) => ({ widget: x }))
    //   //   .filter((item) => item.widget.widgetname === 'Cost' && item.widget.pin === 'V1')
    //   //   .map((item) => item.widget);
    //   const GaugeIndices = this.devicedashboard
    //     .map((x, i) => ({ widget: x }))
    //     .filter((item) => item.widget.widgetname === 'Gauge')
    //     .map((item) => item.widget);

    //   this.virtual.find((x) => {
    //     // if (x.input_pin == 'V1') {

    //     //   if (labelIndices.length > 0) {

    //     //   labelIndices[0].params = x.device_value.kWh;
    //     //   labelIndices[1].params = x.device_value.kwhPerDay;
    //     //   labelIndices[2].params = x.device_value.kwhPerMonth;
    //     //   }
    //     //   if (GaugeIndices.length > 0) {
    //     //   GaugeIndices[0].params = x.device_value.kWh;
    //     //   GaugeIndices[1].params = x.device_value.kwhPerDay;
    //     //   GaugeIndices[2].params = x.device_value.kwhPerMonth;

    //     //   }

    //     //   const gaugeindex = this.devicedashboard.map((y:any)=>{
    //     //     y.param =  x.device_value.kWh
    //     //   })

    //     // }
    //   });
    // });

    // Listen for output data
    this.socketService.listen('output').subscribe((data: any) => {
      // console.log(data)
      this.outputdata = this.outputdata ? [...this.outputdata, data] : [data];
    });

    // Initialize variables FOR CHART
    let chartTime = 5000;
    let chartLoaded = false;
    let chartCurrentTime = 0;
    let count = 0;
    // Set up a periodic interval to update device data
    // this.datarefreshinterval.push(
    //   setInterval(() => {
    //     let latestDeviceData = [
    //       ...this.digital,
    //       ...this.virtual,
    //       ...this.analog,
    //       ...this.outputdata,
    //     ];

    //     if (latestDeviceData?.length >= 1) {
    //       let latestWidgetPin: any = {};
    //       for (let i = 0; i < latestDeviceData.length; i++) {
    //         const { input_pin, device_value } = latestDeviceData[i];
    //         latestWidgetPin[input_pin] = device_value;
    //       }

    //       for (let i = 0; i < this.devicedashboard.length; i++) {
    //         let date = new Date();
    //         const { pin, devicedata } = this.devicedashboard[i];
    //         const widgetData = latestWidgetPin[pin];
    //         if (widgetData != undefined && widgetData != devicedata) {

    //           // if (this.devicedashboard[i]?.widgetname == 'Chart') {
    //           //   // this.lineChart.refresh()
    //           //   if (this.chartlabel.length < 7) {
    //           //     // console.log(count)
    //           //     if (count === 0) {
    //           //       ++count;
    //           //       this.chartlabel.push(new Date().toLocaleTimeString());
    //           //     }

    //           //     // this.devicedashboard[i].devicedata.push(0);
    //           //     this.chartlabel.push(
    //           //       new Date(
    //           //         Date.now() + Number(count) * 5000
    //           //       ).toLocaleTimeString()
    //           //     );
    //           //     ++count;
    //           //   }

    //           //   if (chartCurrentTime > chartTime || !chartLoaded) {
    //           //     if (this.chartlabel.length >= 7) {
    //           //       chartCurrentTime = 0;

    //           //       // this.devicedashboard[i].devicedata = [];
    //           //       if (!chartLoaded) {
    //           //         this.chartlabel.push(new Date().toLocaleTimeString());
    //           //       }

    //           //       // this.devicedashboard[i].devicedata.push(widgetData);

    //           //       this.chartlabel.push(
    //           //         new Date(Date.now() + 1 * 5000).toLocaleTimeString()
    //           //       );

    //           //       this.lineChart.refresh();
    //           //       chartLoaded = true;
    //           //       if (this.devicedashboard[i].devicedata.length > 7) {
    //           //         this.devicedashboard[i].devicedata.shift();
    //           //       }
    //           //       if (this.chartlabel.length > 7) {
    //           //         this.chartlabel.shift();
    //           //       }
    //           //     }
    //           //   }

    //           //   chartCurrentTime += 5;

    //           //   this.charts.forEach((chart: UIChart) => {
    //           //     chart.refresh();
    //           //   });

    //           //   this.lineChart.refresh();
    //           // } else {
    //           if (this.devicedashboard[i].widgetname != "Chart") {
    //             this.devicedashboard[i].devicedata = widgetData;
    //           }
    //         }

    //       }
    //     }
    //   }, 5)
    // );
  }

  /**
   * Function to handle tab clicks and fetch device logs when necessary.
   *
   * @param event - The tab click event.
   * @param device_Id - The ID of the selected device.
   */

  tabClick(event: any, device_Id: any) {
    this.device_id = device_Id;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    // this.gridsterRef.optionsChanged();
    // Check if the clicked tab is the log tab (index 4
    if (event.index == 3) {
      this.logrefresh = true;
      // Fetch device logs from the server
      this.dataservice.getdevicelog(this.device_id).subscribe((res: any) => {
        if (res.status == '200') {
          this.logrefresh = false;
          this.data = res.data;
          this.devicelog = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });

      this.datarefreshinterval.push(
        setInterval(() => {
          this.refreshdevicelog(this.device_id);
        }, 5000)
      )
    }
  }
  /**
   * Function to manually refresh device logs.
   *
   * @param device_Id - The ID of the selected device.
   */
  refreshdevicelog(device_Id: any) {
    this.device_id = device_Id;
    // this.logrefresh = true;
    // Fetch updated device logs from the server
    this.dataservice.getdevicelog(this.device_id).subscribe((res: any) => {
      // this.logrefresh = false;
      if (res.status == '200') {
        this.data = res.data.reverse();
        // console.log(this.data)
        this.data.map((x: any) => {
          const Find = this.devicelog.some((y: any) => y.device_log_id == x.device_log_id);
          if (!Find) {
            // console.log(x)
            this.devicelog.unshift(x);
          }
        })

      } else {
        this.toastr.error('Error occurred');
      }
    });
  }

  /**
   * Function triggered when the Enter key is pressed on an input element (like a checkbox toggle).
   *
   * @param event - The event triggered by the key press.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param onvalue - The value representing the "on" state.
   * @param offvalue - The value representing the "off" state.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  onEnter(
    event: any,
    Key: any,
    last_online: any,
    onvalue: any,
    offvalue: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any
  ) {
    if (this.iscreate == true) {
      // Map pin labels to corresponding pin numbers
      if (Pin == 'D0') {
        Pin = 16;
      }
      if (Pin == 'D1') {
        Pin = 5;
      }
      if (Pin == 'D2') {
        Pin = 4;
      }
      if (Pin == 'D3') {
        Pin = 0;
      }
      if (Pin == 'D4') {
        Pin = 2;
      }
      if (Pin == 'D5') {
        Pin = 14;
      }
      if (Pin == 'D6') {
        Pin = 12;
      }
      if (Pin == 'D7') {
        Pin = 13;
      }
      if (Pin == 'D8') {
        Pin = 15;
      }
      if (Pin == 'SD3') {
        Pin = 10;
      }
      if (Pin == 'SD2') {
        Pin = 9;
      }
      // Check if the device is offline based on last online timestamp
      if (+this.currenttime - +last_online > 100 * 25) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {
        // console.log(event);

        let Payload;
        // var Id = event.source?.id;
        // var checked = event.source?.checked;
        // document.getElementById(Id)?.setAttribute('checked', 'checked');
        if (event == true) {
          if (Pinmode === 'Output') {
            // Set maximum value for the corresponding pin in the dashboard
            this.devicedashboard.map((x: any) => {
              if (x.pin == Pin) {
                x.max = 1;
              }
            });

            Payload = 1;
            const data = { Key, Method, Pin, Pinmode, Payload };
            this.led = true;
            this.socketService.emit('message', data);
          } else {
            this.toastr.info('This is not an Output pin');
          }
        } else {
          if (Pinmode === 'Output') {
            Payload = 0;
            this.devicedashboard.map((x: any) => {
              if (x.pin == Pin) {
                x.max = 0;
              }
            });
            const data = { Key, Method, Pin, Pinmode, Payload };

            this.led = false;
            this.socketService.emit('message', data);
          } else {
            this.toastr.info('This is not an Output pin');
          }
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function triggered when the value of a slider input changes.
   *
   * @param event - The event containing the changed value of the slider.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  onInputChange(
    event: any,
    Key: any,
    last_online: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any
  ) {
    if (this.iscreate == true) {
      // Map pin labels to corresponding pin numbers
      if (Pin == 'D0') {
        Pin = 16;
      }
      if (Pin == 'D1') {
        Pin = 5;
      }
      if (Pin == 'D2') {
        Pin = 4;
      }
      if (Pin == 'D3') {
        Pin = 0;
      }
      if (Pin == 'D4') {
        Pin = 2;
      }
      if (Pin == 'D5') {
        Pin = 14;
      }
      if (Pin == 'D6') {
        Pin = 12;
      }
      if (Pin == 'D7') {
        Pin = 13;
      }
      if (Pin == 'D8') {
        Pin = 15;
      }
      if (Pin == 'SD3') {
        Pin = 10;
      }
      if (Pin == 'SD2') {
        Pin = 9;
      }
      // Check if the device is offline based on last online timestamp
      if (+this.currenttime - +last_online > 100 * 25) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {
        // this.devicedashboard.map((x: any) => {
        //   if (x.pin == Pin) {
        //     return {
        //       ...x,
        //       devicedata: event,
        //     };
        //   }
        // });
        if (Pinmode === 'Output') {
          this.slidervalue = event;

          let Payload = this.slidervalue;
          const data = { Key, Method, Pin, Pinmode, Payload };
          this.socketService.emit('message', data);
        } else {
          this.toastr.info('This is not an Output pin');
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function triggered when sending a message to the device's terminal.
   *
   * @param event - The event containing the input terminal value.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  sendmsg(
    event: any,
    Key: any,
    last_online: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any
  ) {
    if (this.iscreate == true) {
      if (+this.currenttime - +last_online > 100 * 25) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {
        let Payload = this.terminalform.controls.terminal.value;
        this.terminalmsg.push(this.terminalform.controls.terminal.value);

        const data = { Key, Method, Pin, Pinmode, Payload };

        this.socketService.emit('message', data);
        this.terminalform.controls.terminal.setValue('');
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to delete a device.
   *
   * @param device_id - The ID of the device to be deleted.
   */
  deletedevice(device_id: any, cluster_id: any) {
    // console.log("checkkkkkkkkk222222", device_id, cluster_id);
    if (this.isdelete == true) {
      // Open a dialog to confirm the deletion
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_id, cluster_id: cluster_id };
          this.dataservice.deletedevice(data).subscribe((res: any) => {
            this.loading = false;
            if (res.status == '200') {
              this.toastr.info('process failed');
            }
            if (res.status == '201') {
              this.toastr.success('Device Deleted');
              this.navigater.navigate(['/app/mydevices']);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to initiate downloading a ZIP file.
   */
  downloadzip() {
    this.dataservice.downloadzip();
  }

  /**
   * Function to copy text to the clipboard.
   *
   * @param authtoken - The authentication token to copy.
   * @param product_api_Id - The product API ID to copy.
   */
  copyText(authtoken: any, product_api_Id: any) {
    if (!authtoken) {
      authtoken = '';
    }

    const value =
      '#include <Devsbot.h>' +
      '\n' +
      '\n' +
      '#define DBOT_AUTH_TOKEN' +
      ' ' +
      '"' +
      authtoken +
      '"' +
      '\n' +
      '#define DBOT_CLUSTER_ID' +
      ' ' +
      '"' +
      product_api_Id +
      '"' +
      '\n' +
      '#define WIFI_SSID' +
      ' ' +
      '"' +
      '' +
      '"' +
      ' //Replace Your WIFI_SSID' +
      '\n' +
      '#define WIFI_PASSWORD' +
      ' ' +
      '"' +
      '' +
      '"' +
      ' //Replace Your WIFI_PASSWORD' +
      '\n' +
      '\n' +
      'void setup()' +
      '\n' +
      '{' +
      '\n' +
      'Serial.begin(115200);' +
      '\n' +
      'dBot.begin(DBOT_CLUSTER_ID,DBOT_AUTH_TOKEN,WIFI_SSID,WIFI_PASSWORD);' +
      '\n' +
      '}' +
      '\n' +
      '\n' +
      'void loop()' +
      '\n' +
      '{' +
      '\n' +
      '// put your main code here, to run repeatedly' +
      '\n' +
      'dBot.Loop();' +
      '\n' +
      '}';

    // const value = '#include "devsbot.h"' + '\n' + '\n' + '#define DBOT_AUTH_TOKEN' + ' ' + '"' + authtoken + '"' + '\n' + '#define DBOT_CLUSTER_ID' + ' ' + '"' + product_api_Id + '"' + '\n' + '\n' + 'cls_devsbot iot;' + '\n' + '\n' + 'void setup()' + '\n' + '{' + '\n' + '// put your setup code here, to run once:' + '\n' + 'Serial.begin(115200);' + '\n' + 'iot.devsbotBegin(DBOT_CLUSTER_ID, "", "",DBOT_AUTH_TOKEN);' + '\n' + '}' + '\n' + '\n' + 'void loop()' + '\n' + '{' + '\n' + '// put your main code here, to run repeatedly:' + '\n' + 'iot.devsbotRun();' + '\n' + '}'
    // const token =
    //   '#define NTS_device_auth_token' + ' ' + '"' + authtoken + '"' + '\n';
    // const Id = '#define NTS_cluster_id' + ' ' + '"' + product_api_Id + '"';
    // const value: any = [token + Id];
    this.clipboard.copy(value);
    this.toastr.success('Copied to Clipboard');
  }

  /**
   * Function to generate and download a device report.
   *
   * @param device_Id - The ID of the device for which the report is generated.
   * @param devicename - The name of the device.
   */
  devicereport(device_Id: any, devicename: any) {
    const data = { device_Id, devicename };
    this.dataservice.devicereport(data).subscribe((res: any) => {

      if (res.status == '400') {
        this.toastr.error('Error occurred');
      } else if (res.size == '34') {
        this.toastr.info("No log Found")
      } else {
        // Create a download link for the generated repor
        const a = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(res);
        a.href = fileUrl;
        a.download = devicename + '.log';
        a.click();
        window.URL.revokeObjectURL(fileUrl);
      }
    });
  }

  /**
   * Function to initiate device reboot.
   *
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   */
  devicereboot(Key: any, last_online: any, heartbeat: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'reboot',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          let Method = 'Restart';
          if (+this.currenttime - +last_online > 100 * heartbeat) {
            this.toastr.error('Device is offline');
          } else {
            const data = { Key, Method };
            this.socketService.emit('message', data);
            this.toastr.success('Device Reboot Succesfully');
          }
        }
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to erase device data.
   *
   * @param device_Id - The ID of the device for which data will be erased.
   * @param devicename - The name of the device.
   */
  erasedevicedata(device_Id: any, devicename: any) {
    if (this.isdelete == true) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { id: device_Id };
          this.dataservice.erasedevicedata(data).subscribe((res: any) => {
            if (res.status == '200') {
              this.toastr.info('No devicedata');
            }
            if (res.status == '201') {
              this.toastr.success('Events log Deleted');
              // window.location.reload();
            } else {
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to close the current page and optionally apply pagination state from the service.
   */
  close() {
    const currentState = this._location.getState() as { paginationState?: any };
    if (currentState.paginationState) {
      // Navigate back to the previous page
      this._location.back();
      this.homecomponent.onToolbarMenuToogle()
      // Now, in the mydevice component, apply the retrieved pagination state
      // ...
    } else {
      // Navigate back normally if no state is available
      this._location.back();
      this.homecomponent.onToolbarMenuToogle()
    }
  }

  /**
   * Function to handle pagination page change for device data.
   *
   * @param event - The event object containing the new page number.
   */

  devicedatapageChanged(event: any) {
    this.devicedataconfig.currentPage = event;
  }
  /**
   * Function to handle pagination page change for device data.
   *
   * @param event - The event object containing the new page number.
   */
  userinfo(user_Id: any) {
    this.router.navigate(['/app/userinfo', user_Id]);
  }

  /**
   * Function to open a dialog to rename a device.
   *
   * @param device_id - The ID of the device to be renamed.
   * @param devicename - The current name of the device.
   */
  rename(item: any) {
    if (this.iscreate == true) {
      const MatDialogConfig = this.matdialog.open(rename, {
        disableClose: true,
        data: item,
      });
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Lifecycle hook called when the component is about to be destroyed.
   */
  ngOnDestroy() {
    this.socketService.emit('removechannel', {
      devicelink: this.device_auth_token,
      clusterid: this.cluster_api_Id
    });

    clearTimeout(this.timeout);
    for (const intervalId of this.datarefreshinterval) {
      clearInterval(intervalId);
    }

    this.tokendigital = '';
    this.tokenvirtual = '';
    this.tokenanalog = '';
    this.device_auth_token = '';

    this.device_token = '';
    this.objectanalog = '';
    this.objectdigital = '';
    this.objectvirtual = '';
    this.digitaldata?.splice(0, this.digitaldata?.length);
    this.virtualdata?.splice(0, this.virtualdata?.length);
    this.analogdata?.splice(0, this.analogdata?.length);
    this.devicedashboard?.splice(0, this.devicedashboard?.length);
    this.device_data?.splice(0, this.device_data?.length);
    this.selectedTabIndex = 0;
  }
  /**
   * Function to open the dashboard design side navigation.
   */
  opendahsboard() {
    this.sidenav_dbdesign.open();
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridster?.optionsChanged();
    this.Sidenavclose();
    this.homecomponent.routes();
    let mode: any = localStorage.getItem('mode');
    this.darkMode = JSON.parse(mode);
    if (!this.darkMode) {
      this.dark = 'light';
      this.strokeColor = '#ccc';
    }
    if (this.darkMode) {
      this.dark = 'dark';
      this.strokeColor = '#ccc';
    }
  }


}

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.html',
  styleUrls: ['./mydevices.component.css'],
})
export class dashboard implements OnInit {
  @ViewChild('gauge') gauge: ElementRef | undefined;
  device_timeZone: any;
  @HostListener('document:fullscreenchange', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F11') {
      // Your logic when F11 key is pressed
      console.log('F11 key pressed');
    }
  }
  isFullscreen = false;
  last_sync_time: any;
  previousMonthCost: any;
  previousMonthKWH: any;
  previousMonthAVG: any;
  tempFahrenheit: any;
  temp: any;

  topvalue: any;
  tcolor: any;
  city: any;
  geolocation!: boolean;


  fullscreenChangeHandler(event: Event) {
    this.isFullscreen = document.fullscreenElement !== null;
  }
  @ViewChild('oneDay_chart_sidenav') oneDay_chart_sidenav!: MatSidenav;
  @ViewChild('gridster') gridster!: GridsterComponent;
  @ViewChild('lineChart') lineChart!: UIChart;
  @ViewChildren('lineCharts') charts!: QueryList<UIChart>;
  @ViewChildren('singlechart') singlechart!: QueryList<MatSidenav>;

  @Output()
  input!: EventEmitter<MatSliderChange>;
  devicevalue: any;
  pieoptions: any;
  piedata: any;
  alldevicevalue: any;
  approximatelyCost: any;
  approximatelyKWh: any;
  approximatelyKWhWeek: any;
  approximatelyAvgKWh: any
  isconfirm: any;
  loading: boolean;
  data: any;
  show: any;
  productdata: any;
  device_id: any;
  devicedata: any;
  allComplete: boolean = false;
  time: any;
  id: any;
  user_Id: any;
  role: any;
  verticalvalue: any;
  devicename: any;
  selection: any;
  productvalue: any;
  userdata: any;
  config: any;
  devicelog: any;
  filterTerm!: string;
  devicesidenav: any;

  userdevice: any;
  labelPosition: 'before' | 'after' = 'after';
  dataRefresher: any;

  value: any;
  acceleration!: any[];
  rotation!: any[];
  temparature: any;
  currenttime: any;
  status: any;
  statusdata: any;
  last_online: any;
  deviceversion: any;
  status_Id: any;
  basicdata: any;
  led: any;
  checked: boolean;
  refresh: boolean;
  allselect: boolean;
  device_data: any[] = [];
  device_value: any[] = [];
  slidervalue: any;
  terminalmsg: any[] = [];
  devicedashboard: any[] = [];
  deletedevice_id: any[] = [];

  // sidenav variable
  isShowing: boolean;
  interval: any;
  widget: any;
  labeldata: any;
  pin: any;
  widget_pin: any;
  product_Id: any;
  logrefresh: boolean;

  datarefreshinterval: any[] = [];
  dashboardloader: boolean;
  digitaldata: any[] = [];
  analogdata: any[] = [];
  virtualdata: any[] = [];
  overlapTrigger!: boolean;

  selectedTabIndex = 0; // The index of the tab that you want to be selected
  uniqueValues: any;
  selectedCategoriescluster = new Set();
  filterdevicevalue: any;
  productvalues: any;
  tokenanalog: any;
  tokenvirtual: any;
  tokendigital: any;
  device_token: any;
  analog: any[] = [];
  virtual: any[] = [];
  digital: any[] = [];
  objectdigital: any;
  objectanalog: any;
  objectvirtual: any;
  timeout: any;
  digitalpin: any;
  analogpin: any;
  virtualpin: any;
  online: boolean;
  offline: boolean;
  refreshdevicevalue: any;
  metadatalist: any;
  devicedatafilter: any;
  selectedFilter!: string;
  timelineItems: any;
  allTimelineItems: any;
  devicetoken: any;
  device_Id: any;
  device_name: any;
  device_version: any;
  device_mac_Id: any;
  device_activation: any;
  device_auth_token: any;
  user_id: any;
  product_name: any;
  hardware_name: any;
  product_logo: any;
  heartbeat: any;
  product_api_Id: any;
  firstname: any;
  email_id: any;
  isactive: any;
  role_name: any;
  reg_date: any;
  lastlogin: any;
  user_profile: any;
  org_Name: any;
  zonename: any;
  timezone: any;
  latitude: any;
  longitude: any;
  outputdata: any[] = [];
  loaded = false;
  position: any[] = [];
  chartlabeltime: any;
  chartoptionsnoyaxis: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  highcount: any = 0;
  lowcount: any = 0;
  events: any[] = [];
  chartoptions: any;
  chartlabel: any[] = [];
  Chartwidth: any = '500px';
  // Chartheight: any = "300px";
  mattabindex: any;
  Infomessage: any[] = [];
  Warningmessage: any[] = [];
  Criticalmessage: any[] = [];

  // ngx charts
  animations: boolean = true;
  showXAxis = true;
  showYAxis = true;

  showLegend = true;
  legendTitle = 'Legend';
  gradient = false;
  showXAxisLabel = true;
  xAxisLabel = 'TimeSeries';
  yAxisLabel = 'Value';
  // line, area
  autoScale = true;
  timeline = false;
  legendPosition = LegendPosition.Right;
  showYAxisLabel = true;
  xScaleMin!: any;
  xScaleMax!: any;
  yScaleMin!: any;
  yScaleMax!: any;
  rangeFillOpacity: number = 0.15;
  roundDomains = false;

  tooltipDisabled = false;
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  wrapTicks = false;
  showGridLines = true;
  schemeType = ScaleType.Ordinal;
  xAxisticks: any[] = [];
  yAxisTicks: any[] = [];
  chartfilter = 'Day';
  filter: any = 'Day';
  strokeColor: any = '#ffffff';
  dark: any = 'light';
  ChartRefresh: boolean = false;
  curves: any = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear,
  };

  // Configuration for gauge markers
  markersconfig = {
    '0': { color: 'blue', size: 5, label: '0', type: 'line' },
    '100': { color: 'red', size: 5, label: '100', type: 'line' },
  };
  gaugeType = 'arch';
  gaugeLabel = 'Speed';
  Type = 'full';
  // slider
  gaugeValueExceededMax: boolean = false;

  // Configuration options for the gridster layoutca
  gridsterOptions: GridsterConfig = {
    gridType: 'fixed',
    fixedColWidth: 1000,
    fixedRowHeight: 100,
    compactType: 'none',
    margin: 10,
    outerMargin: false,
    minCols: 16,
    maxCols: 16,
    minRows: 5,
    maxRows: 100,
    displayGrid: 'onDrag&Resize',
    // defaultItemCols: 2,
    // defaultItemRows: 1,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,

    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    pushItems: true,
    pushResizeItems: false,
    swap: true,
  };
  Barchartoptions: any;
  /**
   *  Function to open the side navigation
   */
  // Function to toggle fullscreen

  toggleFullscreen() {
    const doc: any = document; // Use 'any' type to avoid TypeScript errors

    if (!this.isFullscreen) {
      // If not in fullscreen mode, go fullscreen
      if (doc.documentElement.requestFullscreen) {
        doc.documentElement.requestFullscreen();
      } else if (doc.documentElement.mozRequestFullScreen) {
        doc.documentElement.mozRequestFullScreen();
      } else if (doc.documentElement.webkitRequestFullscreen) {
        doc.documentElement.webkitRequestFullscreen();
      } else if (doc.documentElement.msRequestFullscreen) {
        doc.documentElement.msRequestFullscreen();
      }
    } else {
      // If already in fullscreen mode, exit fullscreen
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }
  }

  // Listen for fullscreen change events
  @HostListener('document:fullscreenchange', ['$event'])
  fullscreenChange(event: Event) {
    const doc: any = document; // Use 'any' type to avoid TypeScript errors
    this.isFullscreen = !!doc.fullscreenElement;
  }

  @HostListener('document:webkitfullscreenchange', ['$event'])
  webkitFullscreenChange(event: Event) {
    const doc: any = document; // Use 'any' type to avoid TypeScript errors
    this.isFullscreen = !!doc.webkitFullscreenElement;
  }

  Sidenavopen() {
    this.gridsterOptions.fixedColWidth = 63;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridster?.optionsChanged();
    this.Chartwidth = '545px';
    // this.Chartheight = "300px ";
  }
  /**
   *  Function to CLOSE the side navigation
   */
  Sidenavclose() {
    this.gridsterOptions.fixedColWidth = 75;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridster?.optionsChanged();
    this.Chartwidth = '600px';

    // this.Chartheight = "300px ";
  }
  darkMode = true;

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    if (!this.darkMode) {
      this.dark = 'light';
      this.strokeColor = '#ccc';
      localStorage.setItem('mode', JSON.stringify(this.darkMode));
    }
    if (this.darkMode) {
      this.dark = 'dark';
      this.strokeColor = '#ccc';
      localStorage.setItem('mode', JSON.stringify(this.darkMode));
    }
  }
  /**
   *   Form group for terminal input
   */
  terminalform = this.formbuilder.group({
    terminal: [''],
  });
  item: any;
  devicedataconfig: { id: string; itemsPerPage: number; currentPage: number };
  options: any = {
    draggable: true,
    resizable: true,
    // Other gridster options
  };

  /**
   * Constructor of the component.
   * Initializes member variables, services, and configurations.
   *
   * @param matdialog - MatDialog service for opening dialog windows.
   * @param dataservice - Dataservice for handling data operations.
   * @param toastr - ToastrService for displaying notifications.
   * @param formbuilder - FormBuilder for creating and managing form groups.
   * @param clipboard - Clipboard service for copying content to the clipboard.
   * @param socketService - SocketService for handling socket communication.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param router - Router for navigation between routes.
   * @param _location - Location for interacting with the browser's history.
   * @param authentication - AuthenticationService for handling user authentication.
   * @param homecomponent - HomeComponent for managing home-related components.
   * @param changeDetectorRef - ChangeDetectorRef for manually triggering change detection.
   * @param navigater - Router for navigation management.
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private clipboard: Clipboard,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private authentication: AuthenticationService,
    private homecomponent: HomeComponent,
    private changeDetectorRef: ChangeDetectorRef,
    private navigater: Router,

    @Inject(DOCUMENT) private document: Document
  ) {
    this.loading = false;
    this.isShowing = false;
    this.checked = false;
    this.refresh = false;
    this.logrefresh = false;
    this.allselect = false;
    this.dashboardloader = false;
    this.online = true;
    this.offline = true;
    this.ChartRefresh = true;
    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 4,
      currentPage: 1,
    };
    let mode: any = localStorage.getItem('mode');
    if (!mode) {
      localStorage.setItem('mode', JSON.stringify(this.darkMode));
    }
    // this.darkMode = JSON.parse(mode);
    // if (!this.darkMode) {
    //   this.dark = 'light';
    //   this.strokeColor = '#ccc';
    // }
    // if (this.darkMode) {
    //   this.dark = 'dark';
    //   this.strokeColor = '#ccc';
    // }
    // localStorage.setItem('mode', JSON.stringify(this.darkMode));
    // Retrieve user roles from authentication service
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[4];
    // Map user roles to component-specific permissions
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });

    // Subscribe to Deviceinfo event for updates

    this.dataservice.Deviceinfo.subscribe((response: any) => {
      this.ngOnInit();
    });
  }
  close() {
    const currentState = this._location.getState() as { paginationState?: any };
    if (currentState.paginationState) {
      // Navigate back to the previous page
      this._location.back();
      this.homecomponent.onToolbarMenuToogle()
      // Now, in the mydevice component, apply the retrieved pagination state
      // ...
    } else {
      // Navigate back normally if no state is available
      this._location.back();
      this.homecomponent.onToolbarMenuToogle()
    }
  }

  async cleanup() {
    this.socketService.emit('removechannel', {
      devicelink: this.device_auth_token,
      clusterid: this.cluster_api_Id
    });

    clearTimeout(this.timeout);
    for (const intervalId of this.datarefreshinterval) {
      clearInterval(intervalId);
    }

    this.tokendigital = '';
    this.tokenvirtual = '';
    this.tokenanalog = '';
    this.device_auth_token = '';
    this.device_token = '';
    this.objectanalog = '';
    this.objectdigital = '';
    this.objectvirtual = '';
    this.digitaldata.splice(0, this.digitaldata.length);
    this.virtualdata.splice(0, this.virtualdata.length);
    this.analogdata.splice(0, this.analogdata.length);
    this.devicedashboard.splice(0, this.devicedashboard.length);
    this.device_data.splice(0, this.device_data.length);
    this.selectedTabIndex = 0;
  }

  async updateRouteParams() {
    this.device_Id = this.route.snapshot.paramMap.get('id1');
    this.product_Id = this.route.snapshot.paramMap.get('id2');
    this.mattabindex = this.route.snapshot.paramMap.get('id3');
  }
  cluster_api_Id: any;

  ngOnInit(): void {
    this.dataservice.fullScreen.next()
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();

    this.loading = true;
    this.homecomponent.routes();
    this.homecomponent.OpenDashboard(true);
    let mode: any = localStorage.getItem('mode');
    this.darkMode = JSON.parse(mode);
    if (!this.darkMode) {
      this.dark = 'light';
      this.strokeColor = '#ccc';
    }
    if (this.darkMode) {
      this.dark = 'dark';
      this.strokeColor = '#ccc';
    }

    this.route.paramMap.subscribe(async (param: any) => {

      await this.cleanup();
      await this.updateRouteParams();
      await this.info(this.device_Id, this.product_Id);

    })

    this.device_Id = this.route.snapshot.paramMap.get('id1');
    this.product_Id = this.route.snapshot.paramMap.get('id2');
    this.mattabindex = this.route.snapshot.paramMap.get('id3');
    this.info(this.device_Id, this.product_Id);
    this.dataservice.Devicerefresh.subscribe((response: any) => {
      this.info(this.device_Id, this.product_Id);
    });
    this.dataservice.singleDevicestatus.subscribe((response: any) => {
      this.ngOnDestroy();
    });
    this.Sidenavclose();
    this.dataservice.Sidenavclose.subscribe((response: any) => {
      this.Sidenavclose();
    });
    this.dataservice.Sidenavopen.subscribe((response: any) => {
      this.Sidenavopen();
    });
  }

  async info(device_id: any, product_Id: any) {
    /**
     * Fetches device information from the server.
     */

    this.device_id = device_id;
    this.product_Id = product_Id;
    this.dashboardloader = true;

    const data = { device_id, product_Id };
    // Fetch initial device information and set up real-time updates
    this.dataservice.singledeviceinfo(data).subscribe(async (res: any) => {


      this.loading = false;
      this.currenttime = new Date();
      if (res.status == '200') {
        this.data = await res.data;

        this.item = await res.data;

        this.city = res.data.city;
        this.geolocation = false;
        if (!this.city) {
          this.geolocation = true;
        }
        if (Number(this.item?.heartbeat) > 20) {
          this.item.heartbeat = Number(this.item.heartbeat) + 5;
          this.item.heartbeat = Number(this.item.heartbeat) * 12.5;
        }
        let device_latitude = res.data.latitude
        let device_longitude = res.data.longitude

        this.device_timeZone = await this.gettimezone(device_latitude, device_longitude)

        this.timezone = res.data.org_timezone;
        console.log(this.item?.live_data);

        // Convert the last_online property to a Date object for proper display
        this.item.last_online = new Date(this.item.last_online);
        const token = res.data.device_auth_token;
        const clusterid = res.data.cluster_api_Id;
        this.device_auth_token = token;
        this.cluster_api_Id = clusterid
        // Emit a socket event to join a specific channel
        this.socketService.emit('joinchannel', {
          devicelink: token,
          clusterid: clusterid,
        });
        this.datarefreshinterval.push(
          setInterval(() => {
            // Set up an interval to refresh socket connection every 300 seconds (5 minutes)
            this.socketService.emit('joinchannel', {
              devicelink: token,
              clusterid: clusterid,
            });
          }, 60000)
        );
      } else {
        this.toastr.error('error occurred');
      }
    });
    // Set up interval to periodically fetch updated device information
    this.datarefreshinterval.push(
      setInterval(() => {
        this.currenttime = new Date();
        // Fetch device information from the server
        this.dataservice.singledevicestatus(data).subscribe((res: any) => {
          if (res.status == '200') {
            this.data = res.data;
            let timedata = this.data;
            this.city = res.data.city;

            this.geolocation = false
            if (!this.city) {
              this.geolocation = true
            }
            // Update local data and item with updated device status
            this.item.last_online = new Date(timedata.last_online);
            this.item.device_version = timedata.device_version;
            this.item.status_Id = timedata.status_Id;
            this.item.wifi_status = timedata.wifi_status;
            if (
              +this.currenttime - +this.item.last_online <
              100 * this.item.heartbeat &&
              this.item.status_Id == 1
            ) {
              this.item['isOnline'] = true;
            } else {
              this.item['isOnline'] = false;
            }
          } else {
            this.toastr.error('error occurred');
          }
        });
      }, 5000)
    );
    // Fetch product metadata for the specific product
    await this.dataservice
      .getproductmetadata(product_Id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.metadatalist = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });

    // this.datarefreshinterval = setInterval(() => {

    // Fetch dashboard data for the specific device
    await this.dataservice
      .Getdashboarddata(this.device_id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.outputdata = this.data;
        } else {
          this.toastr.error('Error occurred');
        }
      });
    // Fetch device events and categorize them based on event_type
    await this.dataservice
      .Getsingledeviceevents(this.device_id)
      .subscribe((res: any) => {
        if (res.status == '200') {
          this.Infomessage = []
          this.Warningmessage = []
          this.Criticalmessage = []
          this.events = res.data;
          // Categorize events into different arrays based on event_type
          this.events.map((x: any) => {
            // console.log(x);

            if (x.event_type === 0) {
              this.Infomessage.push(x);
            } else if (x.event_type === 1) {
              this.Warningmessage.push(x);
            } else {
              this.Criticalmessage.push(x);
            }
          });
          // console.log(this.Infomessage);

        } else {
          this.toastr.error('error occurred');
        }
      });
      const addvalue={product_Id,"device_id":this.device_id}
    // Fetch device routes and dashboard configuration for the specified product
    this.dataservice.getRoutes(addvalue).subscribe(async (res: any) => {

      this.loading = false;
      if (res.status == '200') {

        // Extract device dashboard configuration from the response
        if (res?.data[1].length >= 1) {
          let device_dashboard: any = await res?.data[1][0].device_d_data;
          // Parse the JSON data from device_dashboard
          device_dashboard = await JSON.parse(device_dashboard)
          this.devicedashboard = await device_dashboard;
          await Promise.all(this.devicedashboard.map(async (x: any) => {
            x['isShow'] = false;
            if (x.widgetname == 'Chart') {
              x.devicedata = [];
              x['Singledaydata'] = [];
            }
            else if (x.widgetname != 'Chart' && x.widgetname != "Switch") {
              if (x?.pin == "V13") {
                x['devicedata'] = { temp: 0, tempFahrenheit: 0, topvalue: 0, tcolor: "green" };
              }
              // if (x?.pin != "V7" && x?.pin != "V11" && x?.pin != "V2" && x?.pin != "V8" && x?.pin != "V6" && x?.pin != "V5" && x?.pin != "V4" && x?.pin != "V13") {
              const Findpin = this.labelpin.some((y: any) => y.pin == x.pin);
              if (!Findpin) {
                this.labelpin.push({ device_Id: this.device_Id, pin: x.pin });
                x.devicedata = [];
                // if (x?.pin) {
                //   await this.GetLabelData(this.device_Id, x.pin);
                // }
              }
              // }
            }
          }));

          if (this.labelpin.length >= 1) {
            this.GetData(this.labelpin, this.device_id, this.item?.slave_id);
            this.datarefreshinterval.push(setInterval(() => {
              this.GetData(this.labelpin, this.device_id, this.item?.slave_id);
            }, Number(this.item.data_interval) * 1000));
          } else {
            this.dashboardloader = false;
          }
        } else {
          this.dashboardloader = false;
        }
      } else {
        this.toastr.error('Error occured');
      }
    });


    setTimeout(() => {
      this.devicedashboard.map(async (x: any) => {
        if (x.widgetname == 'Chart' && x.chartfilter == 'Day') {
          // console.log(x.widget_Id, x.chartfilter)
          if (x?.pin) {
            await this.Getchartdata(
              this.device_Id,
              x.pin,
              x.widget_Id,
              x.chartfilter,
              x.chart_type
            );
          }
        }
        else if (x.widgetname == 'Chart' && x.chartfilter == 'Week') {

          if (x?.pin) {
            await this.GetWeekChartdata(this.device_Id,
              x.pin,
              x.widget_Id,
              x.chartfilter,
              x.chart_type)
          }

        } else if (x.widgetname == 'Chart' && x.chartfilter == 'Month') {

          if (x?.pin) {
            await this.GetMonthChartdata(
              this.device_Id,
              x.pin,
              x.widget_Id,
              x.chartfilter,
              x.chart_type
            )
          }

        }
      });
    }, 2000);







    await this.dataservice.getForecasting(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.approximatelyCost = res.data?.approximatelyCost
        this.approximatelyKWh = res.data?.approximatelyKWh
        this.approximatelyAvgKWh = res.data?.approximatelyAvgKWh
        this.approximatelyKWhWeek = res.data?.approximatelyKWhWeek
        this.previousMonthCost = res.data?.previousMonthCost
        this.previousMonthKWH = res.data?.previousMonthKWH
        this.previousMonthAVG = res.data?.previousMonthAVG
      } else {
        this.toastr.error('Error occured');
      }
    })



    // Set up socket listeners for different types of inputs and outputs

    // Listen for analog input data
    this.socketService.listen('analoginput').subscribe((data: any) => {
      // Add received analog data to the existing analog data array
      this.analog = this.analog ? [...this.analog, data] : [data];
    });

    // Listen for digital input data
    this.socketService.listen('digitalinput').subscribe((data: any) => {
      this.digital = this.digital ? [...this.digital, data] : [data];
    });
    // Listen for virtual input data
    this.socketService.listen('virtualinput').subscribe((data: any) => {
      this.virtual = this.virtual ? [...this.virtual, data] : [data];

    });

    // Listen for output data
    this.socketService.listen('output').subscribe((data: any) => {

      this.outputdata = this.outputdata ? [...this.outputdata, data] : [data];
    });



    // Initialize variables FOR CHART
    let chartTime = 5000;
    let chartLoaded = false;
    let chartCurrentTime = 0;
    let count = 0;
    let RYBxAxis = [];
    // Set up a periodic interval to update device data
    this.datarefreshinterval.push(
      setInterval(() => {


        let latestDeviceData = [
          ...this.digital,
          ...this.virtual,
          ...this.analog,
          ...this.outputdata,
        ];

        if (latestDeviceData?.length >= 1) {


          let latestWidgetPin: any = {};
          for (let i = 0; i < latestDeviceData.length; i++) {
            const { input_pin, device_value } = latestDeviceData[i];
            latestWidgetPin[input_pin] = device_value;
            // delete latestDeviceData[i];
          }


          for (let i = 0; i < this.devicedashboard.length; i++) {

            const { pin, devicedata } = this.devicedashboard[i];
            const widgetData = latestWidgetPin[pin];
            // console.log(this.item?.live_data)
            if (this.item?.live_data == 1) {
              if (widgetData != undefined && widgetData != devicedata) {
                if (pin != "V1" && pin != "V9") {
                  if (this.devicedashboard[i].widgetname != 'Chart' && this.devicedashboard[i].pin != 'V9') {
                    if (pin == "V7") {
                      this.devicedashboard[i].devicedata = widgetData + "tCO2";
                    } else if (pin == "V13") {

                      this.devicedashboard[i].devicedata.temp = Number(widgetData).toFixed(1);

                      if (Number.isNaN(this.devicedashboard[i].devicedata?.temp) || this.devicedashboard[i].devicedata?.temp == "NaN") {
                        this.devicedashboard[i].devicedata.temp = 0;
                        this.devicedashboard[i].devicedata.tempFahrenheit = 0;
                        this.devicedashboard[i].devicedata.topvalue = this.devicedashboard[i].devicedata.temp + '%';
                      } else {
                        this.devicedashboard[i].devicedata.tempFahrenheit = ((this.devicedashboard[i].devicedata.temp * 9 / 5) + 32).toFixed(2);
                        this.devicedashboard[i].devicedata.topvalue = this.devicedashboard[i].devicedata.temp + '%';
                      }
                      if (this.devicedashboard[i].devicedata?.temp <= 30) {
                        this.devicedashboard[i].devicedata.tcolor = "green"
                      }
                      else if (this.devicedashboard[i].devicedata?.temp > 30 && this.devicedashboard[i].devicedata?.temp <= 60) {
                        this.devicedashboard[i].devicedata.tcolor = "yellow"
                      }
                      else if (this.devicedashboard[i].devicedata?.temp > 60) {
                        this.devicedashboard[i].devicedata.tcolor = "red"
                      }
                    } else if (pin == "V5" && this.item.isOnline == false) {
                      this.devicedashboard[i].devicedata = "--";
                    } else {
                      // if (widgetData == 1) {
                      //
                      // }
                      this.devicedashboard[i].devicedata = widgetData;
                      this.outputdata = []
                    }

                  }
                }
              }
            }

          }


        }
      }, 3)
    );

  }
  async gettimezone(latitude: any, longitude: any) {


    this.dataservice.getTimeZone(latitude, longitude)
      .subscribe((data: any) => {
        if (data && data.status === 'OK') {
          this.device_timeZone = data.timeZoneId;
          console.log('Time zone:', this.device_timeZone);
        } else {
          console.error('Failed to get time zone');
        }
      }, error => {
        console.error('Error getting time zone:', error);
      });
  }


  async GetData(data: any, device_Id: any, slave_id: any) {

    this.dataservice.GetDeviceDashboardData(data, device_Id, slave_id).subscribe(async (res: any) => {
      let result: any = [];
      this.last_sync_time = null;

      if (res.status == '200') {
        result = await res.data;
        this.last_sync_time = new Date(result[0].last_sync_time);
        const cOSTIndices = await this.devicedashboard
          .map((x, i) => ({ widget: x }))
          .filter(
            (item) =>
              item.widget.widgetname === 'Cost' && item.widget.pin === 'V1'
          )
          .map((item) => item.widget);


        await Promise.all(this.devicedashboard.map((x: any) => {
          if (this.item?.live_data == 1) {
            if (x?.pin != "V7" && x?.pin != "V11" && x?.pin != "V2" && x?.pin != "V8" && x?.pin != "V6" && x?.pin != "V5" && x?.pin != "V4") {
              const Find = result.find((F: any) => F.pin === x.pin)
              if (Find) {
                if (x.pin == "V1") {
                  if (x.additional_option) {
                    if (x.additional_option === 'todaykwh') {
                      if (x.widgetname === 'Label') {
                        if (Find.value.last24HoursKWh == 'NaN') {
                          Find.value.last24HoursKWh = '0'
                          x.params = isNaN(Find.value.last24HoursKWh) ? "0" : Find.value.last24HoursKWh;

                        } else {
                          x.params = isNaN(Find.value.last24HoursKWh) ? "0" : Find.value.last24HoursKWh;
                        }


                        x.approximatelyAvgKWh = this.approximatelyAvgKWh;
                        x.previousMonthAVG = this.previousMonthAVG
                      } else if (x.widgetname === 'Gauge') {
                        x.params = Find.value.last24HoursKWh;
                      }
                    }
                    else if (x.additional_option == 'thismonthkwh') {
                      if (x.widgetname === 'Label') {
                        if (Find.value.Last30Days == 'NaN') {
                          Find.value.Last30Days = '0'
                          x.params = isNaN(Find.value.Last30Days) ? "0" : Find.value.Last30Days;
                          // x.params = Find.value.Last30Days;

                        } else {
                          x.params = isNaN(Find.value.Last30Days) ? "0" : Find.value.Last30Days;
                        }

                        // x.params = Find.value.Last30Days;
                        x.approximatelyKWh = this.approximatelyKWh;
                        x.previousMonthKWH = this.previousMonthKWH
                      } else if (x.widgetname === 'Gauge') {
                        x.params = Find.value.Last30Days;
                      }
                    }
                    else if (x.additional_option == 'totalkwh') {
                      if (x.widgetname === 'Label') {
                        // x.params = Find.value.currentKWh;
                        x.params = isNaN(Find.value.currentKWh) ? "0" : Find.value.currentKWh;
                      } else if (x.widgetname === 'Gauge') {
                        x.params = Find.value.currentKWh;
                      }
                    } else if (x.additional_option == 'thisweekkwh') {
                      if (x.widgetname === 'Label') {
                        // x.params = Find.value.lastweek;
                        x.params = isNaN(Find.value.lastweek) ? "0" : Find.value.lastweek;
                        x.approximatelyKWhWeek = this.approximatelyKWhWeek
                      } else if (x.widgetname === 'Gauge') {
                        x.params = Find.value.lastweek;
                      }
                    }
                  }

                  if (cOSTIndices.length > 0 && this.Costcount == 0) {

                    if (x.costcount) {
                      this.Costcount++;
                      for (let i = 1; i <= x.costcount; i++) {
                        let type = x["cost-" + i].type;
                        if (type == "Above") {
                          if (x["cost-" + i].above_Kwh <= Find.value.Last30Days) {
                            let Difference = Find.value.Last30Days - x["cost-" + i].above_Kwh;

                            if (x.cost) {
                              x.cost = Number(Difference) * Number(x["cost-" + i].cost) + Number(x.cost);
                            } else {
                              x["cost"] = Number(Difference) * Number(x["cost-" + i].cost);
                            }
                          }
                        } else if (type == "Inbetween") {
                          let Difference = x["cost-" + i].tokwh - x["cost-" + i].fromkwh;

                          if (x["cost-" + i].tokwh <= Find.value.Last30Days) {

                            if (x.cost) {
                              x.cost = Difference * x["cost-" + i].cost + x.cost;
                            } else {
                              x["cost"] = Difference * x["cost-" + i].cost;
                            }

                          } else {
                            if (x.cost) {
                              x.cost = Find.value.Last30Days * x["cost-" + i].cost + x.cost;
                            } else {
                              x["cost"] = Find.value.Last30Days * x["cost-" + i].cost;
                            }

                          }
                        }
                      }
                    } else {
                      x["cost"] = 0;
                    }
                  }
                }
                else if (x.pin == "V13") {
                  x.devicedata.temp = Number(Find.value).toFixed(1);

                  if (Number.isNaN(x.devicedata?.temp) || x.devicedata?.temp == "NaN") {
                    x.devicedata.temp = 0;
                    x.devicedata.tempFahrenheit = 0;
                    x.devicedata.topvalue = x.devicedata.temp + '%';
                  } else {
                    x.devicedata.tempFahrenheit = ((x.devicedata.temp * 9 / 5) + 32).toFixed(2);
                    x.devicedata.topvalue = x.devicedata.temp + '%';
                  }
                  if (x.devicedata?.temp <= 30) {
                    x.devicedata.tcolor = "green"
                  }
                  else if (x.devicedata?.temp > 30 && x.devicedata?.temp <= 60) {
                    x.devicedata.tcolor = "yellow"
                  }
                  else if (x.devicedata?.temp > 60) {
                    x.devicedata.tcolor = "red"
                  }

                }
                else if (x.pin == "V9" && x.widgetname != "Chart") {
                  x.devicedata = Find.value
                } else if (x.pin == "V5" && !this.item.isOnline) {
                  x.devicedata = Find.value == 0 ? '--' : Find.value
                }
                else if (x.widgetname != "Chart") {
                  x.devicedata = Find.value
                }
              }
            }
          } else {

            const Find = result.find((F: any) => F.pin === x.pin);
            if (Find) {
              if (x.pin == "V1") {
                if (x.additional_option) {
                  if (x.additional_option === 'todaykwh') {
                    if (x.widgetname === 'Label') {
                      if (Find.value.last24HoursKWh == 'NaN') {
                        Find.value.last24HoursKWh = '0'
                        x.params = isNaN(Find.value.last24HoursKWh) ? "0" : Find.value.last24HoursKWh;

                      } else {
                        x.params = isNaN(Find.value.last24HoursKWh) ? "0" : Find.value.last24HoursKWh;
                      }

                      x.approximatelyAvgKWh = this.approximatelyAvgKWh;
                      x.previousMonthAVG = this.previousMonthAVG
                    } else if (x.widgetname === 'Gauge') {
                      x.params = Find.value.last24HoursKWh;
                    }
                  }
                  else if (x.additional_option == 'thismonthkwh') {
                    if (x.widgetname === 'Label') {
                      if (Find.value.Last30Days == 'NaN') {
                        Find.value.Last30Days = '0'
                        x.params = isNaN(Find.value.Last30Days) ? "0" : Find.value.Last30Days;
                        // x.params = Find.value.Last30Days;

                      } else {
                        x.params = isNaN(Find.value.Last30Days) ? "0" : Find.value.Last30Days;
                      }
                      x.approximatelyKWh = this.approximatelyKWh;
                      x.previousMonthKWH = this.previousMonthKWH
                    } else if (x.widgetname === 'Gauge') {
                      x.params = Find.value.Last30Days;
                    }
                  }
                  else if (x.additional_option == 'totalkwh') {
                    if (x.widgetname === 'Label') {
                      x.params = isNaN(Find.value.currentKWh) ? "0" : Find.value.currentKWh;
                    } else if (x.widgetname === 'Gauge') {
                      x.params = Find.value.currentKWh;
                    }
                  } else if (x.additional_option == 'thisweekkwh') {
                    if (x.widgetname === 'Label') {
                      // x.params = Find.value.lastweek;
                      x.params = isNaN(Find.value.lastweek) ? "0" : Find.value.lastweek;
                      x.approximatelyKWhWeek = this.approximatelyKWhWeek
                    } else if (x.widgetname === 'Gauge') {
                      x.params = Find.value.lastweek;
                    }
                  }
                }

                if (cOSTIndices.length > 0 && this.Costcount == 0) {

                  if (x.costcount) {
                    this.Costcount++;
                    for (let i = 1; i <= x.costcount; i++) {
                      let type = x["cost-" + i].type;
                      if (type == "Above") {
                        if (x["cost-" + i].above_Kwh <= Find.value.Last30Days) {
                          let Difference = Find.value.Last30Days - x["cost-" + i].above_Kwh;

                          if (x.cost) {
                            x.cost = Number(Difference) * Number(x["cost-" + i].cost) + Number(x.cost);
                          } else {
                            x["cost"] = Number(Difference) * Number(x["cost-" + i].cost);
                          }
                        }
                      } else if (type == "Inbetween") {
                        let Difference = x["cost-" + i].tokwh - x["cost-" + i].fromkwh;

                        if (x["cost-" + i].tokwh <= Find.value.Last30Days) {

                          if (x.cost) {
                            x.cost = Difference * x["cost-" + i].cost + x.cost;
                          } else {
                            x["cost"] = Difference * x["cost-" + i].cost;
                          }

                        } else {
                          if (x.cost) {
                            x.cost = Find.value.Last30Days * x["cost-" + i].cost + x.cost;
                          } else {
                            x["cost"] = Find.value.Last30Days * x["cost-" + i].cost;
                          }

                        }
                      }
                    }
                  } else {
                    x["cost"] = 0;
                  }
                }
              }
              else if (x.pin == "V13") {
                x.devicedata.temp = Number(Find.value).toFixed(1);

                if (Number.isNaN(x.devicedata?.temp) || x.devicedata?.temp == "NaN") {
                  x.devicedata.temp = 0;
                  x.devicedata.tempFahrenheit = 0;
                  x.devicedata.topvalue = x.devicedata.temp + '%';
                } else {
                  x.devicedata.tempFahrenheit = ((x.devicedata.temp * 9 / 5) + 32).toFixed(2);
                  x.devicedata.topvalue = x.devicedata.temp + '%';
                }
                if (x.devicedata?.temp <= 30) {
                  x.devicedata.tcolor = "green"
                }
                else if (x.devicedata?.temp > 30 && x.devicedata?.temp <= 60) {
                  x.devicedata.tcolor = "yellow"
                }
                else if (x.devicedata?.temp > 60) {
                  x.devicedata.tcolor = "red"
                }

              }
              else if (x.pin == "V9" && x.widgetname != "Chart") {
                x.devicedata = Find.value
              } else if (x.pin == "V5" && !this.item.isOnline) {
                // console.log(Find.value)
                x.devicedata = Find.value == 0 ? '--' : Find.value
                // console.log(x.devicedata)
              }
              else if (x.widgetname != "Chart") {

                x.devicedata = Find.value
              }
            }

          }

          // }else{
          //   const Find = result.find((F: any) => F.pin === x.pin);
          //   if (Find) {
          //     if (x.pin == "V1") {
          //       if (x.additional_option) {
          //         if (x.additional_option === 'todaykwh') {
          //           if (x.widgetname === 'Label') {
          //             x.params = Find.value.last24HoursKWh;
          //             x.approximatelyAvgKWh = this.approximatelyAvgKWh;
          //             x.previousMonthAVG = this.previousMonthAVG
          //           } else if (x.widgetname === 'Gauge') {
          //             x.params = Find.value.last24HoursKWh;
          //           }
          //         }
          //         else if (x.additional_option == 'thismonthkwh') {
          //           if (x.widgetname === 'Label') {
          //             x.params = Find.value.Last30Days;
          //             x.approximatelyKWh = this.approximatelyKWh;
          //             x.previousMonthKWH = this.previousMonthKWH
          //           } else if (x.widgetname === 'Gauge') {
          //             x.params = Find.value.Last30Days;
          //           }
          //         }
          //         else if (x.additional_option == 'totalkwh') {
          //           if (x.widgetname === 'Label') {
          //             x.params = Find.value.currentKWh;
          //           } else if (x.widgetname === 'Gauge') {
          //             x.params = Find.value.currentKWh;
          //           }
          //         } else if (x.additional_option == 'thisweekkwh') {
          //           if (x.widgetname === 'Label') {
          //             x.params = Find.value.lastweek;
          //             x.approximatelyKWhWeek = this.approximatelyKWhWeek
          //           } else if (x.widgetname === 'Gauge') {
          //             x.params = Find.value.lastweek;
          //           }
          //         }
          //       }
          //       if (cOSTIndices.length > 0 && this.Costcount == 0) {
          //         if (x.costcount) {
          //           this.Costcount++;
          //           for (let i = 1; i <= x.costcount; i++) {
          //             let type = x["cost-" + i].type;
          //             if (type == "Above") {
          //               if (x["cost-" + i].above_Kwh <= Find.value.Last30Days) {
          //                 if (x.cost) {
          //                   x.cost = Number(Find.value.Last30Days) * Number(x["cost-" + i].cost) + Number(x.cost);
          //                 } else {
          //                   x["cost"] = Number(Find.value.Last30Days) * Number(x["cost-" + i].cost);
          //                 }
          //               }
          //             } else if (type == "Inbetween") {
          //               if (
          //                 x["cost-" + i].fromkwh >= Find.value.Last30Days &&
          //                 x["cost-" + i].tokwh >= Find.value.Last30Days
          //               ) {
          //                 if (x.cost) {
          //                   x.cost = Find.value.Last30Days * x["cost-" + i].cost + x.cost;
          //                 } else {
          //                   x["cost"] = Find.value.Last30Days * x["cost-" + i].cost;
          //                 }
          //               } else if (x["cost-" + i].tokwh <= Find.value.Last30Days) {
          //                 if (x.cost) {
          //                   x.cost = Find.value.Last30Days * x["cost-" + i].cost + x.cost;
          //                 } else {
          //                   x["cost"] = Find.value.Last30Days * x["cost-" + i].cost;
          //                 }
          //               }
          //             }
          //           }
          //         } else {
          //           x["cost"] = 0;
          //         }
          //       }
          //     }
          //     else if (x.pin == "V13") {
          //       x.devicedata.temp = Number(Find.value).toFixed(1);

          //       if (Number.isNaN(x.devicedata?.temp) || x.devicedata?.temp == "NaN") {
          //         x.devicedata.temp = 0;
          //         x.devicedata.tempFahrenheit = 0;
          //         x.devicedata.topvalue = x.devicedata.temp + '%';
          //       } else {
          //         x.devicedata.tempFahrenheit = ((x.devicedata.temp * 9 / 5) + 32).toFixed(2);
          //         x.devicedata.topvalue = x.devicedata.temp + '%';
          //       }
          //       if (x.devicedata?.temp <= 30) {
          //         x.devicedata.tcolor = "green"
          //       }
          //       else if (x.devicedata?.temp > 30 && x.devicedata?.temp <= 60) {
          //         x.devicedata.tcolor = "yellow"
          //       }
          //       else if (x.devicedata?.temp > 60) {
          //         x.devicedata.tcolor = "red"
          //       }

          //     }
          //     else if (x.pin == "V9" && x.widgetname != "Chart") {
          //       x.devicedata = Find.value
          //     }
          //     else if (x.widgetname != "Chart") {
          //       x.devicedata = Find.value
          //     }
          //   }
          // }


        }));
        this.dashboardloader = false;

        // console.log(this.devicedashboard)
      }
      else {
        this.toastr.error('error occurred');
      }
    })

  }


  deviceinfo() {
    this.router.navigate(['/app/deviceinfo', this.device_id, this.product_Id]);
  }

  ngOnDestroy() {
    this.socketService.emit('removechannel', {
      devicelink: this.device_auth_token,
      clusterid: this.cluster_api_Id
    });

    clearTimeout(this.timeout);
    for (const intervalId of this.datarefreshinterval) {
      clearInterval(intervalId);
    }
    this.homecomponent.OpenDashboard(false);
    this.tokendigital = '';
    this.tokenvirtual = '';
    this.tokenanalog = '';
    this.device_auth_token = '';

    this.device_token = '';
    this.objectanalog = '';
    this.objectdigital = '';
    this.objectvirtual = '';
    this.digitaldata?.splice(0, this.digitaldata?.length);
    this.virtualdata?.splice(0, this.virtualdata?.length);
    this.analogdata?.splice(0, this.analogdata?.length);
    this.devicedashboard?.splice(0, this.devicedashboard?.length);
    this.device_data?.splice(0, this.device_data?.length);
    this.selectedTabIndex = 0;
  }

  /**
   * Function triggered when the Enter key is pressed on an input element (like a checkbox toggle).
   *
   * @param event - The event triggered by the key press.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param onvalue - The value representing the "on" state.
   * @param offvalue - The value representing the "off" state.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  onEnter(
    event: any,
    Key: any,
    last_online: any,
    onvalue: any,
    offvalue: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any,
    heartbeat: any
  ) {
    if (this.iscreate == true) {
      // Map pin labels to corresponding pin numbers
      if (Pin == 'D0') {
        Pin = 16;
      }
      if (Pin == 'D1') {
        Pin = 5;
      }
      if (Pin == 'D2') {
        Pin = 4;
      }
      if (Pin == 'D3') {
        Pin = 0;
      }
      if (Pin == 'D4') {
        Pin = 2;
      }
      if (Pin == 'D5') {
        Pin = 14;
      }
      if (Pin == 'D6') {
        Pin = 12;
      }
      if (Pin == 'D7') {
        Pin = 13;
      }
      if (Pin == 'D8') {
        Pin = 15;
      }
      if (Pin == 'SD3') {
        Pin = 10;
      }
      if (Pin == 'SD2') {
        Pin = 9;
      }
      // Check if the device is offline based on last online timestamp
      if (+this.currenttime - +last_online > 100 * heartbeat) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {


        let Payload;
        // var Id = event.source?.id;
        // var checked = event.source?.checked;
        // document.getElementById(Id)?.setAttribute('checked', 'checked');
        if (event == true) {
          if (Pinmode === 'Output') {
            // Set maximum value for the corresponding pin in the dashboard
            this.devicedashboard.map((x: any) => {
              if (x.pin == Pin) {
                x.devicedata = 0;
              }
            });

            Payload = 0;
            const data = { Key, Method, Pin, Pinmode, Payload };
            this.led = true;
            this.socketService.emit('message', data);
          } else {
            this.toastr.info('Thicd s is not an Output pin');
          }
        } else {
          if (Pinmode === 'Output') {
            Payload = 1;
            this.devicedashboard.map((x: any) => {
              if (x.pin == Pin) {
                x.devicedata = 1;
              }
            });
            const data = { Key, Method, Pin, Pinmode, Payload };

            this.led = false;
            this.socketService.emit('message', data);
          }
          else {
            this.toastr.info('This is not an Output pin');
          }
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function triggered when the value of a slider input changes.
   *
   * @param event - The event containing the changed value of the slider.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  onInputChange(
    event: any,
    Key: any,
    last_online: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any,
    heartbeat: any
  ) {
    if (this.iscreate == true) {
      // Map pin labels to corresponding pin numbers
      if (Pin == 'D0') {
        Pin = 16;
      }
      if (Pin == 'D1') {
        Pin = 5;
      }
      if (Pin == 'D2') {
        Pin = 4;
      }
      if (Pin == 'D3') {
        Pin = 0;
      }
      if (Pin == 'D4') {
        Pin = 2;
      }
      if (Pin == 'D5') {
        Pin = 14;
      }
      if (Pin == 'D6') {
        Pin = 12;
      }
      if (Pin == 'D7') {
        Pin = 13;
      }
      if (Pin == 'D8') {
        Pin = 15;
      }
      if (Pin == 'SD3') {
        Pin = 10;
      }
      if (Pin == 'SD2') {
        Pin = 9;
      }
      // Check if the device is offline based on last online timestamp
      if (+this.currenttime - +last_online > 100 * heartbeat) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {
        // this.devicedashboard.map((x: any) => {
        //   if (x.pin == Pin) {
        //     return {
        //       ...x,
        //       devicedata: event,
        //     };
        //   }
        // });
        if (Pinmode === 'Output') {
          this.slidervalue = event;
          let Payload = this.slidervalue;
          const data = { Key, Method, Pin, Pinmode, Payload };
          this.socketService.emit('message', data);
        } else {
          this.toastr.info('This is not an Output pin');
        }
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function triggered when sending a message to the device's terminal.
   *
   * @param event - The event containing the input terminal value.
   * @param Key - The unique key associated with the device.
   * @param last_online - The timestamp of the device's last online status.
   * @param Pin - The pin associated with the action.
   * @param Pinmode - The mode of the pin (e.g., "Input" or "Output").
   * @param Method - The method used for interaction (e.g., "GET" or "POST").
   */
  sendmsg(
    event: any,
    Key: any,
    last_online: any,
    Pin: any,
    Pinmode: any,
    Method: any,
    isonline: any,
    heartbeat: any
  ) {
    if (this.iscreate == true) {
      if (+this.currenttime - +last_online > 100 * heartbeat) {
        this.toastr.error('Device is offline');
      }
      // if (!isonline) {
      //   this.toastr.error('Device is offline');
      // }
      else {
        let Payload = this.terminalform.controls.terminal.value;
        this.terminalmsg.push(this.terminalform.controls.terminal.value);

        const data = { Key, Method, Pin, Pinmode, Payload };

        this.socketService.emit('message', data);
        this.terminalform.controls.terminal.setValue('');
      }
    } else if (this.iscreate == false) {
      this.toastr.info('User not permitted');
    }
  }



  Costcount = 0;
  labelpin: any[] = [];
  // async GetLabelData(device_Id: any, pin: any) {
  //   const data = { device_Id: device_Id, pin: pin };
  //   let result: any = {};
  //   this.dataservice.getHistoricaldata(data).subscribe(async (res: any) => {

  //     if (res.status == '200') {
  //       result = await res.data[0]


  //       this.last_sync_time = new Date(result.last_sync_time)
  //       const cOSTIndices = this.devicedashboard
  //         .map((x, i) => ({ widget: x }))
  //         .filter(
  //           (item) =>
  //             item.widget.widgetname === 'Cost' && item.widget.pin === 'V1'
  //         )
  //         .map((item) => item.widget);

  //       if (result.input_pin == "V1") {
  //         // console.log(result)
  //         this.devicedashboard.map(async (x: any) => {
  //           let devicedata: any[] = []; // Initialize an empty array
  //           if (x.pin === result.input_pin) {
  //             if (x.additional_option) {
  //               if (x.additional_option === 'todaykwh') {
  //                 if (x.widgetname === 'Label') {
  //                   if (result.value.last24HoursKWh == 'NaN') {
  //                     x.params = 0
  //                   } else {
  //                     x.params = result.value.last24HoursKWh;
  //                   }
  //                   x.approximatelyAvgKWh = this.approximatelyAvgKWh;
  //                   x.previousMonthAVG = this.previousMonthAVG
  //                 } else if (x.widgetname === 'Gauge') {
  //                   x.params = result.value.last24HoursKWh;
  //                 }
  //               }
  //               else if (x.additional_option == 'thismonthkwh') {
  //                 if (x.widgetname === 'Label') {
  //                   if (result.value.Last30Days == 'NaN') {
  //                     x.params = 0
  //                   } else {
  //                     x.params = result.value.Last30Days;
  //                     x.approximatelyKWh = this.approximatelyKWh;
  //                     x.previousMonthKWH = this.previousMonthKWH
  //                   }
  //                 } else if (x.widgetname === 'Gauge') {
  //                   x.params = result.value.Last30Days;
  //                 }
  //               }
  //               else if (x.additional_option == 'totalkwh') {
  //                 if (x.widgetname === 'Label') {
  //                   x.params = result.value.currentKWh;
  //                 } else if (x.widgetname === 'Gauge') {
  //                   x.params = result.value.currentKWh;
  //                 }
  //               } else if (x.additional_option == 'thisweekkwh') {
  //                 if (x.widgetname === 'Label') {
  //                   x.params = result.value.lastweek;
  //                   x.approximatelyKWhWeek = this.approximatelyKWhWeek
  //                 } else if (x.widgetname === 'Gauge') {
  //                   x.params = result.value.lastweek;
  //                 }
  //               }
  //             }
  //             if (cOSTIndices.length > 0 && this.Costcount == 0) {
  //               if (x.costcount) {
  //                 this.Costcount++;
  //                 for (let i = 1; i <= x.costcount; i++) {
  //                   let type = x["cost-" + i].type;
  //                   if (type == "Above") {
  //                     if (x["cost-" + i].above_Kwh <= result.value.Last30Days) {
  //                       if (x.cost) {
  //                         x.cost = Number(result.value.Last30Days) * Number(x["cost-" + i].cost) + Number(x.cost);
  //                       } else {
  //                         x["cost"] = Number(result.value.Last30Days) * Number(x["cost-" + i].cost);
  //                       }
  //                     }
  //                   } else if (type == "Inbetween") {
  //                     if (
  //                       x["cost-" + i].fromkwh >= result.value.Last30Days &&
  //                       x["cost-" + i].tokwh >= result.value.Last30Days
  //                     ) {
  //                       if (x.cost) {
  //                         x.cost = result.value.Last30Days * x["cost-" + i].cost + x.cost;
  //                       } else {
  //                         x["cost"] = result.value.Last30Days * x["cost-" + i].cost;
  //                       }
  //                     } else if (x["cost-" + i].tokwh <= result.value.Last30Days) {
  //                       if (x.cost) {
  //                         x.cost = result.value.Last30Days * x["cost-" + i].cost + x.cost;
  //                       } else {
  //                         x["cost"] = result.value.Last30Days * x["cost-" + i].cost;
  //                       }
  //                     }
  //                   }
  //                 }
  //               } else {
  //                 x["cost"] = 0;
  //               }
  //             }
  //           }

  //         });
  //       } else if (result.input_pin == "V9") {
  //         this.devicedashboard.map(async (x: any) => {
  //           if (x.pin == result.input_pin && x.widgetname != "Chart") {
  //             x.devicedata = result.value
  //           }

  //         })
  //       }
  //       else {
  //         // await this.devicedashboard.map(async (x: any) => {
  //         //   if (x.pin == result.input_pin && x.widgetname != "Chart") {
  //         //     x.devicedata = result.value
  //         //   }

  //         // })

  //       }

  //       if (result.input_pin == "V13") {
  //         this.devicedashboard.map(async (x: any) => {
  //           if (x.pin == result.input_pin && x.widgetname === "Thermometer") {

  //             this.temp = Number(x.devicedata.temp).toFixed(1);

  //             if (Number.isNaN(this.temp) || this.temp == "NaN") {
  //               this.temp = 0;
  //               this.tempFahrenheit = 0;
  //               this.topvalue = this.temp + '%';
  //             } else {
  //               this.tempFahrenheit = ((this.temp * 9 / 5) + 32).toFixed(2);
  //               this.topvalue = this.temp + '%';
  //             }

  //             if (this.temp <= 30) {
  //               this.tcolor = "green"
  //             }
  //             else if (this.temp > 30 && this.temp <= 60) {
  //               this.tcolor = "yellow"
  //             }
  //             else if (this.temp > 60) {
  //               this.tcolor = "red"
  //             }
  //           }
  //         })
  //       }



  //     } else if (res.status == '0') {
  //     } else {
  //       this.toastr.error('error occurred');
  //     }
  //   });
  //   this.datarefreshinterval.push(
  //     setInterval(() => {
  //       // this.Costcount = 0;
  //       for (let i = 0; i < this.labelpin.length; i++) {
  //         const data = { device_Id: this.labelpin[i].device_Id, pin: this.labelpin[i].pin }

  //         this.dataservice.getHistoricaldata(data).subscribe(async (res: any) => {
  //           if (res.status == '200') {
  //             result = await res.data[0]
  //             this.last_sync_time = new Date(result.last_sync_time)
  //             const cOSTIndices = this.devicedashboard
  //               .map((x, i) => ({ widget: x }))
  //               .filter(
  //                 (item) =>
  //                   item.widget.widgetname === 'Cost' && item.widget.pin === 'V1'
  //               )
  //               .map((item) => item.widget);
  //             if (result.input_pin == "V1") {
  //               await this.devicedashboard.map(async (x: any) => {
  //                 let devicedata: any[] = []; // Initialize an empty array
  //                 if (x.pin === result.input_pin) {
  //                   if (x.additional_option) {
  //                     if (x.additional_option === 'todaykwh') {
  //                       if (x.widgetname === 'Label') {
  //                         if (result.value.last24HoursKWh == 'NaN') {
  //                           x.params = 0
  //                         } else {
  //                           x.params = result.value.last24HoursKWh;
  //                         }
  //                         x.params = result.value.last24HoursKWh;
  //                       } else if (x.widgetname === 'Gauge') {
  //                         x.params = result.value.last24HoursKWh;
  //                       }
  //                     }
  //                     else if (x.additional_option == 'thismonthkwh') {
  //                       if (x.widgetname === 'Label') {
  //                         x.params = result.value.Last30Days;
  //                       } else if (x.widgetname === 'Gauge') {

  //                         x.params = result.value.Last30Days;
  //                       }
  //                     }
  //                     else if (x.additional_option == 'totalkwh') {
  //                       if (x.widgetname === 'Label') {
  //                         x.params = result.value.currentKWh;
  //                       } else if (x.widgetname === 'Gauge') {
  //                         x.params = result.value.currentKWh;
  //                       }
  //                     }
  //                   }
  //                   // if (cOSTIndices.length > 0 && this.Costcount == 0) {
  //                   //   if (x.costcount) {
  //                   //     this.Costcount++;
  //                   //     for (let i = 1; i <= x.costcount; i++) {
  //                   //       let type = x["cost-" + i].type;
  //                   //       if (type == "Above") {
  //                   //         if (x["cost-" + i].above_Kwh <= result.value.Last30Days) {
  //                   //           if (x.cost) {
  //                   //             x.cost = Number(result.value.Last30Days) * Number(x["cost-" + i].cost) + Number(x.cost);
  //                   //           } else {
  //                   //             x["cost"] = Number(result.value.Last30Days) * Number(x["cost-" + i].cost);
  //                   //           }
  //                   //         }
  //                   //       } else if (type == "Inbetween") {
  //                   //         if (
  //                   //           x["cost-" + i].fromkwh >= result.value.Last30Days &&
  //                   //           x["cost-" + i].tokwh >= result.value.Last30Days
  //                   //         ) {
  //                   //           if (x.cost) {
  //                   //             x.cost = result.value.Last30Days * x["cost-" + i].cost + x.cost;
  //                   //           } else {
  //                   //             x["cost"] = result.value.Last30Days * x["cost-" + i].cost;
  //                   //           }
  //                   //         } else if (x["cost-" + i].tokwh <= result.value.Last30Days) {
  //                   //           if (x.cost) {
  //                   //             x.cost = result.value.Last30Days * x["cost-" + i].cost + x.cost;
  //                   //           } else {
  //                   //             x["cost"] = x.kwhy.value.Last30Days * x["cost-" + i].cost;
  //                   //           }
  //                   //         }
  //                   //       }
  //                   //     }
  //                   //   } else {
  //                   //     x["cost"] = 0;
  //                   //   }
  //                   // }
  //                 }

  //               });
  //             } else if (result.input_pin == "V9") {
  //               await this.devicedashboard.map(async (x: any) => {
  //                 if (x.pin == result.input_pin && x.widgetname != "Chart") {
  //                   x.devicedata = result.value
  //                 }

  //               })
  //             }
  //             else {
  //               // await this.devicedashboard.map(async (x: any) => {
  //               //   if (x.pin == result.input_pin && x.widgetname != "Chart") {
  //               //     x.devicedata = result.value
  //               //   }

  //               // })

  //             }


  //             if (result.input_pin == "V13") {
  //               this.devicedashboard.map(async (x: any) => {
  //                 if (x.pin == result.input_pin && x.widgetname === "Thermometer") {

  //                   this.temp = Number(x.devicedata.temp).toFixed(1);
  //                   if (Number.isNaN(this.temp) || this.temp == "NaN") {
  //                     this.temp = 0;
  //                     this.tempFahrenheit = 0;
  //                     this.topvalue = this.temp + '%';
  //                   } else {
  //                     this.tempFahrenheit = ((this.temp * 9 / 5) + 32).toFixed(2);
  //                     this.topvalue = this.temp + '%';
  //                   }
  //                   if (this.temp <= 30) {
  //                     this.tcolor = "green"
  //                   }
  //                   else if (this.temp > 30 && this.temp <= 60) {
  //                     this.tcolor = "yellow"
  //                   }
  //                   else if (this.temp > 60) {
  //                     this.tcolor = "red"
  //                   }

  //                 }
  //               })
  //             }


  //           } else if (res.status == "0") {

  //           }
  //           else {
  //             this.toastr.error('error occurred');
  //           }
  //         });
  //       }

  //     }, 60000)
  //   );
  // }



  pinconfigname: any[] = [
    { pin: "V1", name: "kWh" }, { pin: "V0", name: "Frequency (Hz)" }, { pin: "V4", name: "Current (A)" }, { pin: "V2", name: "kWh RYB" }
  ]

  getFlag(pin: any, extra: any) {
    if (extra) {
      return extra;
    } else {
      return this.pinconfigname.find(x => x.pin == pin).name;
    }



  }

  Chartfilter(widget_Id: any, filtertype: any) {
    this.chartfilter = filtertype;
    if (this.lastdatacurrenttime) {
      if (this.chartfilter == 'Day') {
        let perday: any[] = [];
        let timeString;
        if (this.item.isOnline) {
          timeString = new Date(Date.now()).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
        } else {
          timeString = new Date(this.lastdatacurrenttime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
        }
        // Parse the time string into a Date object
        let todaydate = new Date().toLocaleDateString();
        const parsedTime = new Date(`${todaydate} ${timeString}`);
        // console.log(new Date (`${todaydate} ${timeString}`))
        // Calculate the nearest 15-minute interval
        const minutes = parsedTime.getMinutes();
        const roundedMinutes = Math.round(minutes / 15) * 15;
        // Set the rounded minutes
        parsedTime.setMinutes(roundedMinutes);
        for (let i = 0; i <= 10; i++) {
          perday.unshift(
            +new Date(new Date(parsedTime).toISOString()) - Number(i) * 900000
          );
        }
        this.xScaleMax = perday[perday.length - 1];
        this.xScaleMin = perday[0];
        this.xAxisticks = [...perday];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      } else if (this.chartfilter == 'Week') {
        let lastweek: any[] = [];
        let lastday: any;
        if (this.item.isOnline) {
          lastday = new Date(Date.now());
        } else {
          lastday = this.lastdatacurrenttime;
        }
        for (let i = 0; i < 7; i++) {
          lastweek.unshift(+new Date(lastday) - Number(i) * (24 * 3600000));
        }
        lastweek.forEach((x: any) => {
          x = new Date(x).toLocaleDateString('en-US', { weekday: 'long' });
        });
        this.xScaleMax = lastweek[lastweek.length - 1];
        this.xScaleMin = lastweek[0];
        this.xAxisticks = [...lastweek];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      } else if (this.chartfilter == 'Month') {
        let lastmonth: any[] = [];
        let lastday: any;
        if (this.item.isOnline) {
          lastday = new Date(Date.now());
        } else {
          lastday = this.lastdatacurrenttime;
        }
        for (let i = 0; i < 7; i++) {
          lastmonth.unshift(+new Date(lastday) - Number(i) * (120 * 3600000));
        }
        lastmonth.forEach((x: any) => {
          x = new Date(x).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
          });
        });
        this.xScaleMax = lastmonth[lastmonth.length - 1];
        this.xScaleMin = lastmonth[0];
        this.xAxisticks = [...lastmonth];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      }
    } else {
      if (this.chartfilter == 'Day') {
        let perday: any[] = [];
        let timeString;

        timeString = new Date(Date.now()).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        let todaydate = new Date().toLocaleDateString();
        const parsedTime = new Date(`${todaydate} ${timeString}`);
        // console.log(new Date (`${todaydate} ${timeString}`))
        // Calculate the nearest 15-minute interval
        const minutes = parsedTime.getMinutes();
        const roundedMinutes = Math.round(minutes / 15) * 15;
        // Set the rounded minutes
        parsedTime.setMinutes(roundedMinutes);
        for (let i = 0; i <= 10; i++) {
          perday.unshift(
            +new Date(new Date(parsedTime).toISOString()) - Number(i) * 900000
          );
        }
        this.xScaleMax = perday[perday.length - 1];
        this.xScaleMin = perday[0];
        this.xAxisticks = [...perday];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      } else if (this.chartfilter == 'Week') {
        let lastweek: any[] = [];
        let lastday: any;

        lastday = new Date(Date.now());

        for (let i = 0; i < 7; i++) {
          lastweek.unshift(+new Date(lastday) - Number(i) * (24 * 3600000));
        }
        lastweek.forEach((x: any) => {
          x = new Date(x).toLocaleDateString('en-US', { weekday: 'long' });
        });
        this.xScaleMax = lastweek[lastweek.length - 1];
        this.xScaleMin = lastweek[0];
        this.xAxisticks = [...lastweek];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      } else if (this.chartfilter == 'Month') {
        let lastmonth: any[] = [];
        let lastday: any;

        lastday = new Date(Date.now());

        for (let i = 0; i < 7; i++) {
          lastmonth.unshift(+new Date(lastday) - Number(i) * (120 * 3600000));
        }
        lastmonth.forEach((x: any) => {
          x = new Date(x).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
          });
        });
        this.xScaleMax = lastmonth[lastmonth.length - 1];
        this.xScaleMin = lastmonth[0];
        this.xAxisticks = [...lastmonth];
        this.devicedashboard.map((x: any) => {
          if (x.widget_Id == widget_Id) {
            x.chartfilter = this.chartfilter;
            x['xScaleMin'] = this.xScaleMin;
            x['xScaleMax'] = this.xScaleMax;
            x['xAxisTicks'] = this.xAxisticks;
          }
        });
      }
    }

  }

  lastdatalen: any;
  lastdatacurrenttime: any;
  chartpin: any[] = [];

  barChartdata: any[] = [];
  //  convertToTimezone(date: any, timezone: any) {
  //   const options = {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     timeZone: timezone
  //   };
  //   return new Date(date).toLocaleTimeString([], options);
  // }
  async Getchartdata(
    device_Id: any,
    pin: any,
    widget_Id: any,
    chartfilter: any,
    chart_type: any
  ) {

    let currentTimestamp: any = new Date().toLocaleString('en-US', { timeZone: this.device_timeZone });
    currentTimestamp = new Date(currentTimestamp)
    let endOfDay: any = new Date().toLocaleString('en-US', { timeZone: this.device_timeZone });
    endOfDay = new Date(endOfDay)
    console.log(currentTimestamp, endOfDay)
    // this.chartpin.push({ pin, widget_Id, chartfilter });
    this.chartfilter = chartfilter;
    const data = { device_Id: device_Id, pin: pin, start: currentTimestamp, end: endOfDay, chartfilter: chartfilter };
    let Chartdata: any[] = [];
    let lineseries: any[] = [];

    this.dataservice.getchartdata(data).subscribe(async (res: any) => {
      if (res.status == '200') {
        Chartdata = await res.data.reverse();
        this.lastdatalen = Chartdata.length;
        this.lastdatacurrenttime = Chartdata[0]?.datetime;
        // console.log()
        this.chartpin.push({ pin, widget_Id, chartfilter, "lastdatalen": Chartdata.length, "lastdatacurrenttime": this.lastdatacurrenttime });
        if (chartfilter == 'Day') {
          let perday: any[] = [];
          let timeString;

          if (pin == "V1") {
            let Startime;
            Startime = new Date();
            Startime.setHours(0, 0, 0, 0);
            Startime = Startime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });

            if (this.item.isOnline) {
              timeString = new Date(Date.now()).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
            } else {
              timeString = new Date(this.lastdatacurrenttime).toLocaleTimeString(
                [],
                { hour: '2-digit', minute: '2-digit' }
              );
            }
            const currentDate = new Date();

            const todaydate = currentDate.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            });
            const parsedTime = new Date(`${todaydate} ${timeString}`);
            // console.log(parsedTime)
            // Calculate the nearest 15-minute interval
            const minutes = parsedTime.getMinutes();
            const roundedMinutes = Math.round(minutes / 15) * 15;
            // Set the rounded minutes
            parsedTime.setMinutes(0);
            parsedTime.setHours(0);
            // const timezoneOffset = parsedTime.getTimezoneOffset() * 60000; // Offset in milliseconds
            for (let i = 0; i <= 23; i++) {
              // let currentTimestamp = new Date();
              // currentTimestamp = new Date(currentTimestamp.toLocaleString('en-US', { timeZone: this.device_timeZone }))
              if (new Date(Date.now()).getTime() > +new Date(new Date(parsedTime).toISOString()) + Number(i) * (4 * 900000)) {
                perday.push(
                  +new Date(new Date(parsedTime).toISOString()) + Number(i) * (4 * 900000)
                );
              }
            }
            //   for (let i = 0; i <= 23; i++) {
            //     const currentTime = Date.now() - timezoneOffset; // Adjust current time based on timezone
            //     const newParsedTime = new Date(parsedTime.getTime() - timezoneOffset); // Adjust parsed time based on timezone

            //     if (currentTime > +new Date(newParsedTime.toISOString()) + Number(i) * (4 * 900000)) {
            //         perday.push(+newParsedTime + Number(i) * (4 * 900000));
            //     }
            // }
            // console.log(perday)

            this.xScaleMax = perday[perday.length - 1];
            this.xScaleMin = perday[0];
            this.xAxisticks = [...perday];
            this.xAxisticks.shift()
            this.devicedashboard.map((x: any) => {
              if (x.widgetname == 'Chart') {
                if (x.widget_Id == widget_Id) {
                  x['xScaleMin'] = this.xScaleMin;
                  x['xScaleMax'] = this.xScaleMax;
                  x['xAxisTicks'] = this.xAxisticks;
                }
              }
            });

          }
          else {
            if (this.item.isOnline) {
              timeString = new Date(Date.now()).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
            } else {
              timeString = new Date(this.lastdatacurrenttime).toLocaleTimeString(
                [],
                { hour: '2-digit', minute: '2-digit' }
              );
            }
            console.log(timeString)
            const currentDate = new Date();
            const todaydate = currentDate.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            });

            const parsedTime = new Date(`${todaydate} ${timeString}`);
            // console.log(parsedTime)
            // console.log(new Date (`${todaydate} ${timeString}`))
            // Calculate the nearest 15-minute interval
            const minutes = parsedTime.getMinutes();
            const roundedMinutes = Math.round(minutes / 15) * 15;
            // Set the rounded minutes
            parsedTime.setMinutes(roundedMinutes);
            for (let i = 0; i <= 10; i++) {
              perday.unshift(
                +new Date(new Date(parsedTime).toISOString()) - Number(i) * 900000
              );
            }

            this.xScaleMax = perday[perday.length - 1];
            this.xScaleMin = perday[0];
            this.xAxisticks = [...perday];
            this.devicedashboard.map((x: any) => {
              if (x.widgetname == 'Chart') {
                if (x.widget_Id == widget_Id) {
                  x['xScaleMin'] = this.xScaleMin;
                  x['xScaleMax'] = this.xScaleMax;
                  x['xAxisTicks'] = this.xAxisticks;
                }
              }
            });
          }



        }


        if (Array.isArray(pin)) {
          for (let i = 0; i < pin.length; i++) {
            if (chart_type == 'line-chart') {
              if (i == 0) {
                lineseries = [
                  {
                    name: pin[i],
                    series: [],
                  },
                ];
              } else if (i > 0 && i <= pin.length) {
                lineseries.push({
                  name: pin[i],
                  series: [],
                });
              }
            }
          }
        } else {
          if (chart_type == 'line-chart') {
            if (pin == "V2") {
              lineseries = [
                {
                  name: "R",
                  series: [],
                  extra: pin,
                },
                {
                  name: "Y",
                  series: [],
                  extra: pin,
                },
                {
                  name: "B",
                  series: [],
                  extra: pin,
                },
              ];
            } else {
              lineseries = [
                {
                  name: pin,
                  series: [],
                },
              ];
            }

          }

        }

        if (chart_type == 'line-chart') {
          if (pin == "V2") {
            lineseries.map((y: any) => {
              const matches = Chartdata.filter((x) => x.input_pin === y.extra);

              matches.forEach((match) => {

                if (this.xScaleMax < new Date(match.datetime)) {
                  this.xScaleMax = new Date(match.datetime).getTime();
                }
                y.series.push({
                  name: new Date(match.datetime),
                  value: match.value[y.name],
                  extra: y.name
                });
              });
            });
          } if (pin == "V1") {
            lineseries.map((y: any) => {
              const matches = Chartdata.filter((x) => x.input_pin === y.name);

              matches.forEach((match, i) => {

                if (this.xScaleMax < new Date(match.datetime)) {
                  this.xScaleMax = new Date(match.datetime).getTime();
                }
                if (i == 0) {
                  y.series.push({
                    name: new Date(match.datetime),
                    value: Number(match.value).toFixed(2),
                    sub: match.value,
                  });
                } else {
                  y.series.push({
                    name: new Date(match.datetime),
                    value: Number(this.calculateDifference(match.value, y.series[i - 1].sub)).toFixed(2),
                    sub: match.value
                  });
                }

              });
            });
          }

          else {
            lineseries.map((y: any) => {
              const matches = Chartdata.filter((x) => x.input_pin === y.name);
              matches.forEach((match) => {
                if (this.xScaleMax < new Date(match.datetime)) {
                  this.xScaleMax = new Date(match.datetime).getTime();
                }
                y.series.push({
                  name: new Date(match.datetime),
                  value: match.value,
                });
              });
            });
          }

        }

        this.devicedashboard.map((x: any) => {
          if (x.widgetname == 'Chart') {
            if (x.chart_type == 'line-chart') {
              if (Array.isArray(x.dashboard_Id)) {
                if (x.dashboard_Id.includes(widget_Id)) {
                  x['xAxisTicks'] = this.xAxisticks;
                  x['xScaleMin'] = this.xScaleMin;
                  x['xScaleMax'] = this.xScaleMax;
                  x.devicedata = [...lineseries];
                }
              } else {
                if (x.dashboard_Id == widget_Id) {
                  x['xAxisTicks'] = this.xAxisticks;
                  x['xScaleMin'] = this.xScaleMin;
                  x['xScaleMax'] = this.xScaleMax;
                  x.devicedata = [...lineseries];
                }
              }
            }
          }
        });
      }
      else if (res.status == '0') {
        this.lastdatalen = Chartdata.length;
        this.lastdatacurrenttime = Chartdata[0]?.datetime;
        this.chartpin.push({ pin, widget_Id, chartfilter, "lastdatalen": Chartdata.length, "lastdatacurrenttime": this.lastdatacurrenttime });
        if (chartfilter == 'Day') {
          let perday: any[] = [];
          let timeString;

          timeString = new Date(Date.now()).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });


          // Parse the time string into a Date object
          let todaydate = new Date().toLocaleDateString();
          const parsedTime = new Date(`${todaydate} ${timeString}`);

          // Calculate the nearest 15-minute interval
          const minutes = parsedTime.getMinutes();
          const roundedMinutes = Math.round(minutes / 15) * 15;
          // Set the rounded minutes
          parsedTime.setMinutes(roundedMinutes);
          for (let i = 0; i <= 10; i++) {
            perday.unshift(
              +new Date(new Date(parsedTime).toISOString()) - Number(i) * 900000
            );
          }

          this.xScaleMax = perday[perday.length - 1];
          this.xScaleMin = perday[0];
          this.xAxisticks = [...perday];
          this.devicedashboard.map((x: any) => {
            if (x.widgetname == 'Chart') {
              if (x.widget_Id == widget_Id) {
                x['xScaleMin'] = this.xScaleMin;
                x['xScaleMax'] = this.xScaleMax;
                x['xAxisTicks'] = [...this.xAxisticks];
              }
            }
          });
        }

      }
      else {
        this.toastr.error('error occurred');
      }
    });

    this.ChartRefresh = false;

    this.datarefreshinterval.push(
      setInterval(() => {
        if (this.item.isOnline) {
          for (let i = 0; i < this.chartpin.length; i++) {
            if (this.chartpin[i].chartfilter == "Day") {

              const data = { device_Id: device_Id, pin: this.chartpin[i]?.pin, start: currentTimestamp, end: endOfDay, chartfilter: this.chartpin[i].chartfilter };
              this.dataservice.getchartdata(data).subscribe((res: any) => {
                if (res.status == '200') {
                  if (this.chartpin[i]?.pin == "V1") {
                    Chartdata = res.data
                  } else {
                    Chartdata = res.data.reverse();
                    // this.lastdatacurrenttime = Chartdata[0]?.datetime;
                  }


                  if (this.chartpin[i].lastdatalen == 0) {
                    this.devicedashboard.map(async (x: any) => {
                      if (x.widgetname == 'Chart' && x.chartfilter == 'Day') {
                        // console.log(x.widget_Id, x.chartfilter)
                        if (x?.pin) {
                          await this.Getchartdata(
                            this.device_Id,
                            x.pin,
                            x.widget_Id,
                            x.chartfilter,
                            x.chart_type
                          );
                        }
                      }
                      else if (x.widgetname == 'Chart' && x.chartfilter == 'Week') {

                        if (x?.pin) {
                          await this.GetWeekChartdata(this.device_Id,
                            x.pin,
                            x.widget_Id,
                            x.chartfilter,
                            x.chart_type)
                        }

                      } else if (x.widgetname == 'Chart' && x.chartfilter == 'Month') {

                        if (x?.pin) {
                          await this.GetMonthChartdata(
                            this.device_Id,
                            x.pin,
                            x.widget_Id,
                            x.chartfilter,
                            x.chart_type
                          )
                        }

                      }
                    });
                  } else if (Chartdata.length != this.chartpin[i].lastdatalen) {
                    let length = Chartdata.length
                    Chartdata.splice(
                      Chartdata.length - this.chartpin[i].lastdatalen,
                      this.chartpin[i].lastdatalen
                    );
                    this.chartpin[i].lastdatalen = length;


                    this.devicedashboard.map((x: any) => {
                      if (this.chartpin[i]?.pin == "V2") {

                        if (x.widgetname == 'Chart') {
                          x.devicedata.map((c: any) => {
                            const matches = Chartdata.filter(
                              (x) => x.input_pin === c.extra
                            );
                            matches.forEach((match) => {
                              c.series.push({
                                name: new Date(match.datetime),
                                value: match.value[c.name],
                                extra: c.name
                              });
                            });
                          });
                        }

                      } else if (this.chartpin[i]?.pin == "V1") {
                        if (x.widgetname == 'Chart') {

                          x.devicedata.map((c: any) => {
                            const matches = Chartdata.filter(
                              (x) => x.input_pin === c.name
                            );
                            matches.forEach((match) => {
                              c.series.push({
                                name: new Date(match.datetime),
                                value: Number(this.calculateDifference(match.value, c.series[c.series.length - 1].sub)).toFixed(2),
                                sub: match.value
                              });
                            });
                          });
                        }
                      }
                      else {
                        if (x.widgetname == 'Chart') {
                          x.devicedata.map((c: any) => {
                            const matches = Chartdata.filter(
                              (x) => x.input_pin === c.name
                            );
                            matches.forEach((match) => {
                              c.series.push({
                                name: new Date(match.datetime),
                                value: match.value,
                              });
                            });
                          });
                        }
                      }


                    });
                    this.devicedashboard.map((x: any) => {
                      if (x.widgetname == 'Chart') {
                        if (Array.isArray(x.dashboard_Id)) {
                          if (
                            x.dashboard_Id.includes(this.chartpin[i]?.widget_Id)
                          ) {
                            if (x.xScaleMax < new Date()) {
                              // this.xScaleMax = new Date();
                              x.xScaleMax = new Date();
                            }
                            x.devicedata = [...x.devicedata];

                          }
                        } else if (x.dashboard_Id == this.chartpin[i]?.widget_Id) {
                          if (x.xScaleMax < new Date()) {

                            x.xScaleMax = new Date();
                          }
                          x.devicedata = [...x.devicedata];
                        }
                      }
                    });
                  }


                }
                else {
                  // this.toastr.error('error occurred');
                }
              });
            }

          }
        }
      }, 60000)
    );

    this.datarefreshinterval.push(
      setInterval(() => {
        if (this.item.isOnline) {
          this.devicedashboard.map((x: any) => {
            if (x.widgetname == 'Chart') {
              if (x.chart_type == 'line-chart') {
                if (x.chartfilter == 'Day' && (!x.KwhDay || x.KwhDay != "V1")) {

                  if (
                    +new Date().getTime() -
                    x.xAxisTicks[x.xAxisTicks?.length - 1] >
                    900000
                  ) {
                    this.chartfilter = x.chartfilter;

                    if (+new Date().getTime() > x.xScaleMax) {
                      x.xScaleMax =
                        +new Date(
                          new Date(
                            x.xAxisTicks[x.xAxisTicks?.length - 1]
                          ).toISOString()
                        ) +
                        Number(1) * 900000;
                    }
                    x.xAxisTicks.shift();
                    x.xScaleMin = x.xAxisTicks[0];
                    x.xAxisTicks.push(
                      +new Date(
                        new Date(
                          x.xAxisTicks[x.xAxisTicks?.length - 1]
                        ).toISOString()
                      ) +
                      Number(1) * 900000
                    );
                    x.xAxisTicks = [...x.xAxisTicks];
                  }
                } else if (x.chartfilter == 'Day' && x.KwhDay == 'V1') {
                  if (
                    +new Date().getTime() -
                    x.xAxisTicks[x.xAxisTicks?.length - 1] >
                    (4 * 900000)
                  ) {
                    this.chartfilter = x.chartfilter;
                    if (+new Date().getTime() > x.xScaleMax) {
                      x.xScaleMax =
                        +new Date(
                          new Date(
                            x.xAxisTicks[x.xAxisTicks?.length - 1]
                          ).toISOString()
                        ) +
                        Number(1) * 900000;
                    }

                    x.xAxisTicks.push(
                      +new Date(
                        new Date(
                          x.xAxisTicks[x.xAxisTicks?.length - 1]
                        ).toISOString()
                      ) +
                      Number(1) * (4 * 900000)
                    );

                    x.xAxisTicks = [...x.xAxisTicks];
                  }
                }
                //  else if (x.chartfilter == 'Week') {
                //   if (
                //     +new Date().getTime() -
                //     x.xAxisTicks[x.xAxisTicks.length - 1] >
                //     24 * 3600000
                //   ) {
                //     this.chartfilter = x.chartfilter;
                //     if (+new Date().getTime() > x.xScaleMax) {
                //       x.xScaleMax =
                //         +new Date(
                //           new Date(
                //             x.xAxisTicks[x.xAxisTicks.length - 1]
                //           ).toISOString()
                //         ) +
                //         Number(1) * (24 * 3600000);
                //     }
                //     x.xAxisTicks.shift();
                //     x.xScaleMin = x.xAxisTicks[0];
                //     x.xAxisTicks.push(
                //       +new Date(
                //         new Date(
                //           x.xAxisTicks[x.xAxisTicks.length - 1]
                //         ).toISOString()
                //       ) +
                //       Number(1) * (24 * 3600000)
                //     );
                //     x.xAxisTicks = [...x.xAxisTicks];
                //   }
                // } else if (x.chartfilter == 'Month') {
                //   if (
                //     +new Date().getTime() -
                //     x.xAxisTicks[x.xAxisTicks.length - 1] >
                //     120 * 3600000
                //   ) {
                //     this.chartfilter = x.chartfilter;
                //     if (+new Date().getTime() > x.xScaleMax) {
                //       x.xScaleMax =
                //         +new Date(
                //           new Date(
                //             x.xAxisTicks[x.xAxisTicks.length - 1]
                //           ).toISOString()
                //         ) +
                //         Number(1) * (120 * 3600000);
                //     }
                //     x.xAxisTicks.shift();
                //     x.xScaleMin = x.xAxisTicks[0];
                //     x.xAxisTicks.push(
                //       +new Date(
                //         new Date(
                //           x.xAxisTicks[x.xAxisTicks.length - 1]
                //         ).toISOString()
                //       ) +
                //       Number(1) * (120 * 3600000)
                //     );
                //     x.xAxisTicks = [...x.xAxisTicks];
                //   }
                // }
              }
            }
          });
        }



      }, 5000)
    );

  }

  calculateDifference(a: any, b: any) {
    const order = a > b ? [a, b] : [b, a];
    return Number(order[0] - order[1]);
  }

  async GetWeekChartdata(device_Id: any,
    pin: any,
    widget_Id: any,
    chartfilter: any,
    chart_type: any) {
    let currentTimestamp = new Date();
    let endOfDay = new Date();
    const data = { device_Id: device_Id, pin: pin, start: currentTimestamp, end: endOfDay, chartfilter: chartfilter };
    let Chartdata: any[] = [];
    let barchart: any[] = [];
    this.dataservice.getchartdata(data).subscribe(async (res: any) => {
      if (res.status == '200') {
        barchart = await res.data
        barchart.map((x: any) => {
          x['name'] = new Date(x.datetime).toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
          });
          x['extra'] = new Date(x.datetime)
        })
        this.devicedashboard.map((x: any) => {
          if (x.widgetname == 'Chart') {
            if (x.chartfilter == "Week") {
              if (x.chart_type == 'bar-vertical') {
                if (Array.isArray(x.dashboard_Id)) {
                  if (x.dashboard_Id.includes(widget_Id)) {
                    x['xAxisTicks'] = this.xAxisticks;
                    x['xScaleMin'] = this.xScaleMin;
                    x['xScaleMax'] = this.xScaleMax;
                    x.devicedata = [...barchart];
                  }
                } else {
                  if (x.dashboard_Id == widget_Id) {
                    x['xAxisTicks'] = this.xAxisticks;
                    x['xScaleMin'] = this.xScaleMin;
                    x['xScaleMax'] = this.xScaleMax;
                    x.devicedata = [...barchart];
                  }
                }
              }
            }

          }
        });
      }
      else if (res.status == '0') {
        this.lastdatalen = Chartdata.length;
        this.lastdatacurrenttime = Chartdata[0]?.datetime;

        if (chartfilter == 'Week') {
          let lastweek: any[] = [];
          let lastday: any;

          lastday = new Date(Date.now());

          this.barChartdata = [];
          for (let i = 0; i < 7; i++) {
            lastweek.unshift(+new Date(lastday) - Number(i) * (24 * 3600000));

          }
          lastweek.forEach((x: any) => {
            x = new Date(x).toLocaleDateString('en-US', { weekday: 'long' });
          });

          this.xScaleMax = lastweek[lastweek.length - 1];
          this.xScaleMin = lastweek[0];
          this.xAxisticks = [...lastweek];
          this.devicedashboard.map((x: any) => {
            if (x.widgetname == 'Chart') {
              if (x.chartfilter == "Week") {
                if (x.widget_Id == widget_Id) {
                  x['xScaleMin'] = this.xScaleMin;
                  x['xScaleMax'] = this.xScaleMax;
                  x['xAxisTicks'] = [...this.xAxisticks];
                  x.devicedata = []
                }
              }

            }
          });
        }

      }
      else {
        this.toastr.error('error occurred');
      }
    });


  }

  async GetMonthChartdata(device_Id: any,
    pin: any,
    widget_Id: any,
    chartfilter: any,
    chart_type: any) {

    let currentTimestamp = new Date();
    let endOfDay = new Date();
    let Chartdata: any[] = [];
    let barchartmonth: any[] = [];
    const data = { device_Id: device_Id, pin: pin, start: currentTimestamp, end: endOfDay, chartfilter: chartfilter };
    this.dataservice.getchartdata(data).subscribe(async (res: any) => {
      if (res.status == '200') {
        barchartmonth = await res.data
        barchartmonth.map((x: any, i: any) => {
          if (i == 0) {
            x['name'] = new Date(x.datetime).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            });
            x['extra'] = new Date(x.datetime)
          } else {
            x['name'] = new Date(x.datetime).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
            });
            x['extra'] = new Date(x.datetime)
          }

        })
        this.devicedashboard.map((x: any) => {
          if (x.widgetname == 'Chart') {
            if (x.chartfilter == "Month") {
              if (x.chart_type == 'bar-vertical') {
                if (Array.isArray(x.dashboard_Id)) {
                  if (x.dashboard_Id.includes(widget_Id)) {
                    x['xAxisTicks'] = this.xAxisticks;
                    x['xScaleMin'] = this.xScaleMin;
                    x['xScaleMax'] = this.xScaleMax;
                    x.devicedata = [...barchartmonth];
                  }
                } else {
                  if (x.dashboard_Id == widget_Id) {
                    x['xAxisTicks'] = this.xAxisticks;
                    x['xScaleMin'] = this.xScaleMin;
                    x['xScaleMax'] = this.xScaleMax;
                    x.devicedata = [...barchartmonth];
                  }
                }
              }
            }

          }
        });
      }
      else if (res.status == '0') {
        this.lastdatalen = Chartdata.length;
        this.lastdatacurrenttime = Chartdata[0]?.datetime;
        let lastmonth: any[] = [];
        let lastday: any;
        lastday = new Date(Date.now());
        lastday.setDate(1);
        lastday.setDate(lastday.getDate() - 1);

        this.barChartdata = [];
        for (let i = 0; i < 31; i++) {
          lastmonth.unshift(+new Date(lastday) - Number(i) * (24 * 3600000));
        }



        lastmonth = lastmonth.map((x: any, i: any) => {
          return {
            name: new Date(x).toLocaleDateString('en-US', {

              day: 'numeric',
            }),
            value: Math.floor(Math.random() * 1000)
          }
        })

        // lastmonth[0].name=new Date( lastmonth[0].name)

        this.xScaleMax = lastmonth[lastmonth.length - 1];
        this.xScaleMin = lastmonth[0];
        this.xAxisticks = [...lastmonth];
        this.devicedashboard.map((x: any) => {
          if (x.widgetname == 'Chart') {
            if (x.widget_Id == widget_Id) {
              x.devicedata = []
            }
          }
        });


      }
      else {
        this.toastr.error('error occurred');
      }
    });


  }


  closeSingleChart(widget_Id: any) {
    this.devicedashboard.map((x: any) => {
      if (x.widget_Id == widget_Id) {
        x['isShow'] = false;
      }
    });
  }

  onSelect(event: any, show: any, widget_Id: any) {
    let lineseries: any = [
      {
        name: event.name,
        series: [],
      },
    ];
    this.devicedashboard.map((x: any) => {
      if (x.widget_Id == widget_Id) {
        x['oneDay'] = event.name;
        x['Loader'] = true;
        x['isShow'] = true;
      }
    });

    let formattedDate;
    let ticks: any[] = [];

    formattedDate = event.extra;
    // console.log(formattedDate)


    const data = { device_Id: this.device_Id, chartfilter: "Day", start: formattedDate, end: formattedDate };
    this.dataservice.GethistoricaldayChart(data).subscribe((res: any) => {
      if (res.status == '200') {
        res.data.forEach((x: any) => {
          ticks.push(new Date(x.datetime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }));
          x['name'] = new Date(x.datetime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });
        });
        lineseries[0].series = [...res.data]
      } else if (res.status == '0') {
        lineseries[0].series = []
      }
      else {
        this.toastr.error('error occurred');
      }
    })


    setTimeout(() => {
      this.devicedashboard.map((x: any) => {
        if (x.widget_Id == widget_Id) {
          x['Loader'] = false;
          x.Singledaydata = [...lineseries];
          x['ticksmin'] = ticks[0]
          x['tickmax'] = ticks[ticks.length - 1]
          ticks.shift()
          x['ticks'] = [...ticks]

        }
      });
    }, 2000);
  }

  // parseDateString(input: any) {
  //   const [, day, abbr] = input.match(/(\d+)\s+([a-zA-Z]{3})/) || [];

  //   if (!day || !abbr) {
  //     console.error('Invalid input format');
  //     return null;
  //   }

  //   const currentYear = new Date().getFullYear();
  //   const targetDay = new Date(`${currentYear}-11-${day} 12:00:00`); // Assuming November and noon for the time

  //   if (isNaN(targetDay.getTime())) {
  //     console.error('Invalid date');
  //     return null;
  //   }

  //   const targetIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(abbr);

  //   if (targetIndex === -1) {
  //     console.error('Invalid day abbreviation');
  //     return null;
  //   }

  //   const dayDiff = (targetIndex + 7 - targetDay.getDay()) % 7;
  //   const nextDate = new Date(targetDay);
  //   nextDate.setDate(targetDay.getDate() + dayDiff);

  //   return nextDate;
  // }


  xAxisTickFormattingFunction = (value: any) => {
    let formatvalue;
    if (this.chartfilter == 'Day') {
      formatvalue = new Date(value).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    } else if (this.chartfilter == 'Week') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        weekday: 'short',
        day: 'numeric',
      });
    } else if (this.chartfilter == 'Month') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
    }
    return formatvalue;
  };


}




