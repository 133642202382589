
export const single = [
    {
      name: 'Germany',
      value: 40632,
      extra: {
        code: 'de'
      }
    },
    {
      name: 'United States',
      value: 50000,
      extra: {
        code: 'us'
      }
    },
    {
      name: 'France',
      value: 36745,
      extra: {
        code: 'fr'
      }
    },
    {
      name: 'United Kingdom',
      value: 36240,
      extra: {
        code: 'uk'
      }
    },
    {
      name: 'Spain',
      value: 33000,
      extra: {
        code: 'es'
      }
    },
    {
      name: 'Italy',
      value: 35800,
      extra: {
        code: 'it'
      }
    }
  ];
  
  
  export const multi = [
    {
      name: 'Germany',
      series: [
        {
          name: '2010',
          value: 40632,
          extra: {
            code: 'de'
          }
        },
        {
          name: '2000',
          value: 36953,
          extra: {
            code: 'de'
          }
        },
        {
          name: '1990',
          value: 31476,
          extra: {
            code: 'de'
          }
        }
      ]
    },
    {
      name: 'United States',
      series: [
        {
          name: '2010',
          value: 0,
          extra: {
            code: 'us'
          }
        },
        {
          name: '2000',
          value: 45986,
          extra: {
            code: 'us'
          }
        },
        {
          name: '1990',
          value: 37060,
          extra: {
            code: 'us'
          }
        }
      ]
    },
    {
      name: 'France',
      series: [
        {
          name: '2010',
          value: 36745,
          extra: {
            code: 'fr'
          }
        },
        {
          name: '2000',
          value: 34774,
          extra: {
            code: 'fr'
          }
        },
        {
          name: '1990',
          value: 29476,
          extra: {
            code: 'fr'
          }
        }
      ]
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: '2010',
          value: 36240,
          extra: {
            code: 'uk'
          }
        },
        {
          name: '2000',
          value: 32543,
          extra: {
            code: 'uk'
          }
        },
        {
          name: '1990',
          value: 26424,
          extra: {
            code: 'uk'
          }
        }
      ]
    }
  ];
  
  
  export const fiscalYearReport = [
    {
      name: 'Q1',
      series: [
        {
          name: '1001',
          value: -10632
        },
        {
          name: '2001',
          value: -36953
        }
      ]
    },
    {
      name: 'Q2',
      series: [
        {
          name: '1001',
          value: -19737
        },
        {
          name: '2001',
          value: 45986
        }
      ]
    },
    {
      name: 'Q3',
      series: [
        {
          name: '1001',
          value: -16745
        },
        {
          name: '2001',
          value: 0
        }
      ]
    },
    {
      name: 'Q4',
      series: [
        {
          name: '1001',
          value: -16240
        },
        {
          name: '2001',
          value: 32543
        }
      ]
    }
  ];
  
  
  export const bubble = [
    {
      name: 'Germany',
      series: [
        {
          name: '2010',
          x: '2010',
          y: 80.3,
          r: 80.4
        },
        {
          name: '2000',
          x: '2000',
          y: 80.3,
          r: 78
        },
        {
          name: '1990',
          x: '1990',
          y: 75.4,
          r: 79
        }
      ]
    },
    {
      name: 'United States',
      series: [
        {
          name: '2010',
          x: '2010',
          y: 78.8,
          r: 310
        },
        {
          name: '2000',
          x: '2000',
          y: 76.9,
          r: 283
        },
        {
          name: '1990',
          x: '1990',
          y: 75.4,
          r: 253
        }
      ]
    },
    {
      name: 'France',
      series: [
        {
          name: '2010',
          x: '2010',
          y: 81.4,
          r: 63
        },
        {
          name: '2000',
          x: '2000',
          y: 79.1,
          r: 59.4
        },
        {
          name: '1990',
          x: '1990',
          y: 77.2,
          r: 56.9
        }
      ]
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: '2010',
          x: '2010',
          y: 80.2,
          r: 62.7
        },
        {
          name: '2000',
          x: '2000',
          y: 77.8,
          r: 58.9
        },
        {
          name: '1990',
          x: '1990',
          y: 75.7,
          r: 57.1
        }
      ]
    }
  ];
  
  
  export const boxData = [
    {
      name: 'Colombia',
      series: [
        {
          name: '2019',
          value: 12
        },
        {
          name: '2020',
          value: 23
        },
        {
          name: '2021',
          value: 34
        },
        {
          name: '2022',
          value: 27
        },
        {
          name: '2023',
          value: 18
        },
        {
          name: '2024',
          value: 45
        }
      ]
    },
    {
      name: 'Chile',
      series: [
        {
          name: '2019',
          value: 20
        },
        {
          name: '2020',
          value: 28
        },
        {
          name: '2021',
          value: 42
        },
        {
          name: '2022',
          value: 39
        },
        {
          name: '2023',
          value: 31
        },
        {
          name: '2024',
          value: 61
        }
      ]
    },
    {
      name: 'Perú',
      series: [
        {
          name: '2019',
          value: 47
        },
        {
          name: '2020',
          value: 62
        },
        {
          name: '2021',
          value: 55
        },
        {
          name: '2022',
          value: 42
        },
        {
          name: '2023',
          value: 49
        },
        {
          name: '2024',
          value: 71
        }
      ]
    }
  ];
  
  
  export function timelineFilterBarData() {
    const results = [];
    const dataPoints = 30;
    const dayLength = 24 * 60 * 60 * 1000;
    let date = 1473700105009; // Sep 12, 2016
    for (let j = 0; j < dataPoints; j++) {
      // random dates between Sep 12, 2016 and Sep 24, 2016
      results.push({
        name: new Date(date),
        value: Math.floor(Math.random() * 300)
      });
      date += dayLength;
    }
    if (!results.some((r: any) => r.value === 0)) {
      results[Math.floor(Math.random() * results.length)].value = 0;
    }
  
    return results;
  }
  
  
  // export function generateData(seriesLength: number, includeMinMaxRange: boolean, dataPoints: number = 5): MultiSeries {
  //   const results = [];
  
  //   const domain: Date[] = []; // array of time stamps in milliseconds
  
  //   for (let j = 0; j < dataPoints; j++) {
  //     // random dates between Sep 12, 2016 and Sep 24, 2016
  //     domain.push(new Date(Math.floor(1473700105009 + Math.random() * 1000000000)));
  //   }
  
  //   for (let i = 0; i < seriesLength; i++) {
  //     const country = countries[Math.floor(Math.random() * countries.length)];
  //     const series: Series = {
  //       name: country.name,
  //       series: []
  //     };
  
  //     for (let j = 0; j < domain.length; j++) {
  //       const value = Math.floor(2000 + Math.random() * 5000);
  //       // let timestamp = Math.floor(1473700105009 + Math.random() * 1000000000);
  //       const timestamp = domain[j];
  //       if (includeMinMaxRange) {
  //         const errorMargin = 0.02 + Math.random() * 0.08;
  
  //         series.series.push({
  //           value,
  //           name: timestamp,
  //           min: Math.floor(value * (1 - errorMargin)),
  //           max: Math.ceil(value * (1 + errorMargin))
  //         });
  //       } else {
  //         series.series.push({
  //           value,
  //           name: timestamp
  //         });
  //       }
  //     }
  
  //     results.push(series);
  //   }
  //   return results;
  // }