<div class="main">
    <div class="main-head" style="padding: 10px 20px;">
        <div>
            <h1>Edit</h1>
        </div>
        <div>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div  style="padding: 10px 20px;">
        <form  [formGroup]="deviceform">
            <label class="form-label">Device Name</label>
            <input type="text" class="form-control" placeholder="Enter value to send back" value="{{this.data.name}}"
                formControlName="devicename">
                <div>
                    <span *ngIf="deviceform.controls['devicename']?.errors?.['required']" style="color: red;">
                        *Device name is required
                    </span>
                    <span *ngIf="deviceform.controls['devicename']?.errors?.['minlength']&&!deviceform.controls['devicename']?.errors?.['pattern']" style="color: red;">
                        *Minimum 2 characters 
                    </span>
                    <span *ngIf="deviceform.controls['devicename']?.errors?.['maxlength']" style="color: red;">
                        *Maximum 20 characters
                    </span>
                    <span *ngIf="deviceform.controls['devicename']?.errors?.['pattern']" style="color: red;">
                        Special character not allowed
                    </span>
                    
                </div>
                <label class="form-label">
                    Slave Id
                </label>
             <input type="number"  class="form-control" placeholder="Slave Id" value="{{this.data.slave_id}}"
             formControlName="slave_id"  >
             <div *ngIf=" deviceform.controls['slave_id'].dirty||this.invalidslaveId">
                <span *ngIf="deviceform.controls['slave_id']?.errors?.['required']" style="color: red;">
                    *Slave Id is required
                </span>
                <span *ngIf="deviceform.controls['slave_id']?.errors?.['min']" style="color: red">
                    *Minimum Slave Id is 1
                  </span>
                  <span *ngIf="deviceform.controls['slave_id']?.errors?.['max']" style="color: red">
                    *Maximum Slave Id is 256
                  </span>
                <span
                *ngIf="this.invalidslaveId"
                style="color: red"
              >
                Already Slave Id Used
              </span>
            </div>
            <label class="form-label">
                Device Model
            </label>
            <select  class="form-select" formControlName="deviceModel">
            
                <option
                  class="form-option"
                  value="{{item.model_id}}"
                  *ngFor="let item of deviceModel"
                >
                  {{item.device_model_name}}
                </option>
              </select>
              <div
              class="validation"
              *ngIf="deviceform.controls['deviceModel'].invalid && (deviceform.controls['deviceModel'].dirty)"
            >
              <span
                *ngIf="deviceform.controls['deviceModel']?.errors"
                style="color: red"
              >
                *Select The Device Model
              </span>
            </div>
            <label class="form-label">
              Model Number
            </label>
            <input type="text"  class="form-control" placeholder="Model Number" 
            formControlName="model_number"  >

        </form>
    </div>
    <mat-error style="text-align: center">{{
        errorMessage
        }}</mat-error>
    <div class="btn-div">
        <button class="btn btn-danger" mat-dialog-close>Cancel</button>
        <button class="btn btn-success" (click)="editname()" [disabled]="!this.deviceform.valid||invalidslaveId"><span
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="loading"></span>Save</button>
    </div>
</div>