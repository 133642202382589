<div class="info-main">
    <div class="right-side">
      <div  class="close-icon">
        <mat-icon>arrow_back</mat-icon>
      </div>
      <div class="right-side-head">
        <div class="zone-icon-div">
          <mat-icon class="zone-icon">wifi_tethering</mat-icon>
        </div>
        <div class="zone-content">
          <div>
            <span class="zonename">{{item.zonename}}</span>
          </div>
          <div> <i class="fa-solid fa-ellipsis" id="more-vert" [matMenuTriggerFor]="menu">
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="mat-menu-button" (click)="info(item.webzone_Id)"
                 >
                  <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit zone
                </button>
                <button mat-menu-item class="mat-menu-button" (click)="info(item.webzone_Id)"
                  >
                  <mat-icon class="menu-btn-icon">delete</mat-icon>Delete zone
                </button>
              </mat-menu>
            </i></div>

        </div>
      </div>
      <div class="content-body">
        <mat-tab-group selectedIndex="0">
          <mat-tab label="Info">
            <div class="info-body">
              <div style="width: 30%;
              border-right: 1px solid #d7d7d7;">
                <div class="info-content">
                  <h3>Webzone Name</h3>
                  <p>{{item.webzone_name}}</p>
                </div>
                <div class="info-content">
                  <h3>Zone Reference</h3>
                  <p>{{item.zonename}}</p>
                </div>
             
                <div class="info-content">
                  <h3>Address</h3>
                  <p> {{item.address}}</p>
                </div>
                <div class="info-content">
                  <h3>SSID</h3>
                  <p> {{item.SSID}}</p>
                </div>
                <div class="info-content">
                  <h3>Password</h3>
                  <p> {{item.password}}</p>
                </div>
                <div class="info-content">
                  <h3>latitude</h3>
                  <p> {{item.latitude}}</p>
                </div>
                <div class="info-content">
                  <h3>longitude</h3>
                  <p> {{item.longitude}}</p>
                </div>
                <div class="info-content">
                  <h3>Created Date</h3>
                  <p> {{item.created_date}}</p>
                </div>
              
             
              </div>
              <div style=" margin: 0 auto;
              padding: 20px 0px 0px 0px;">
                <div>
                  <agm-map [latitude]="item.latitude" [longitude]="item.longitude"
                    [zoom]="8" mapTypeId="hybrid" style="height: 350px; width: 650px;">
                    <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
                    </agm-marker>
                  </agm-map>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>