// import { Component } from '@angular/core';
import { Component, EventEmitter, Inject, OnInit, Output, OnDestroy } from '@angular/core';
import { Dataservice } from '../services/dataservice';
import { AuthenticationService } from '../Authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { colorSets, LegendPosition } from '@swimlane/ngx-charts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnDestroy {
  deviceslist: any;
  data: any;
  options: any;
  piedata: any;
  pieoptions: any;
  private intervalId: any;
  roles: any;
  iscreate!: boolean;
  isread!: boolean;
  isdelete!: boolean;
  loading: boolean = true;
  vertical_Id: any;
  PluginServiceGlobalRegistrationAndOptions!: any[]
  kwh: any;
  co2: any;
  cost: any;
  Devicecount: any;
  tabledata: any;
  verticalname: any;
  online: any;
  legendPosition = LegendPosition.Right;
  barchart = LegendPosition.Below;
  offline: any;
 advancepie:any[]=[];
 colorScheme: any;
 selectedColorScheme!: string;
 colorSets: any = colorSets;
 RandomColor:any[]=[
  'vivid',
  'natural',
  'cool',
  'fire',
  'solar',
  'air',
  'aqua',
  'flame',
  'ocean',
  'forest',
  'horizon',
  'neons',
  'picnic',
  'night',
  'nightLights'
];

OnColorChange(color:any){
this.setColorScheme(color)
}
 novalue:any;
 selectedColor: string = 'cool';
 advancebarchart:any[]=[]
  constructor(private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private toastr: ToastrService,
    private router: Router, private matdialog: MatDialog,

  ) {
    this.roles = this.authentication.getUserRole();
    this.loading = false;
    this.novalue=false;
    /**
     * Role for create permission.
     * @type {number}
     */

    this.roles = this.roles[14];
    /**
     * Iterate through roles and set permission flags accordingly.
     */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          /**
           * Indicates whether user has create permission.
           * @type {boolean}
           */
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          /**
           * Indicates whether user has read permission.
           * @type {boolean}
           */
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          /**
           * Indicates whether user has delete permission.
           * @type {boolean}
           */
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }
  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }
  ngOnInit() {
    this.setColorScheme('cool')
    this.loading = true;
    let data = this.authentication.getUserData();

    this.dataservice.getverticaldata(data).subscribe((res: any) => {

      if (res.status == '200') {
        // Update the data and vertical value
        this.verticalname = res.data.vertical_name;
        // console.log(res.data);
        this.loading = false;

      } else {
        // Display an error message
        this.toastr.error('Error occurred');
      }
    });
    this.vertical_Id = data.vertical_Id
    const value={vertical_Id:this.vertical_Id,org_Id:data.org_Id}
    this.Dashboard(value)
    this.verticalDeviceData(value)
    this.intervalId = setInterval(() => {
      this.verticalDeviceData(value);
    }, 60000);

    // pie chart and bar chart 
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');


    const piedocumentStyle = getComputedStyle(document.documentElement);
    const pietextColor = piedocumentStyle.getPropertyValue('--text-color');

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1.5,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
    this.pieoptions = {

      cutout: '60%',

      plugins: {
        legend: {
          position: 'right',
          labels: {

            color: pietextColor
          }
        }
      }
    };

    // pie chart and bar chart ends



  }
  verticalDeviceData(data: any) {
    this.dataservice.GetOverallDeviceData(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.tabledata = res.data
      }
    })
  }

  Dashboard(data: any) {
    this.dataservice.GetOverallDashboardData(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.loading = false
        this.vertical_Id = data.vertical_Id
        // this.piedata = res.data
        this.co2 = isNaN(Number(res.card_params.total_co2)) ? 0 : Number(res.card_params.total_co2).toFixed(1);
        this.kwh = isNaN(Number(res.card_params.total_kwh)) ? 0 : Number(res.card_params.total_kwh).toFixed(0);
        this.cost = isNaN(Number(res.card_params.total_kwh_cost)) ? 0 : Number(res.card_params.total_kwh_cost).toFixed(1);
        this.Devicecount = isNaN(Number(res.card_params.noofdevices)) ? 0 : Number(res.card_params.noofdevices).toFixed(0);

        this.online = res.card_params.online
        this.offline = res.card_params.offline
        // console.log(res);
     
        this.advancepie=res.advancepie;
        this.advancebarchart =res.AdvancechartData;

        // this.novalue=this.advancebarchart.every((x:any)=> x.value==0)
        this.novalue=this.advancepie.every((x:any)=> x.value==0)
        this.piedata = {
          labels: res.data.labels,
          datasets: [
            {
              data: res.data.datasets[0].data,
        
            }
          ]
        };
        this.data = res.chartData
        console.log(this.data);

        this.loading = false
      } else {
        // this.toastr.error('Cluster not found')
      }

    })
  }
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


}
