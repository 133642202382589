

<div class="about">
    <img style="width: 120px;"  src="../../assets/organization/devsbot-favicon-13.png" alt="">
  
    <div >

      <form (ngSubmit)="onSubmit()" [formGroup]="forgotemail">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Your Email</mat-label>
          <input
          matInput
          type="email"
          formControlName="email"
          [ngClass]="{ error: submitted && f['email'].errors }"
        />
        <!-- email valiadation errorMessage -->
        <mat-error *ngIf=" f['email'].errors?.['required']"
          >Email is required</mat-error
        >
        <mat-error *ngIf=" f['email'].errors?.['email']"
          >Email is invalid</mat-error
        >
        </mat-form-field>
      </div>
   
      <div class="form-btn1">
        <button
        type="submit"
        class="button"
          mat-raised-button
          color="primary"
         [disabled]="this.disabled"
        >
          Continue
        </button>
  
        <p>By clicking "Continue", you agree to our <span style="color: #3b52ac;">Terms and Privacy policy</span></p>
        <!-- location back-btn-->
    
      </div>
    </form>
    </div>
  </div>
  
  