<mat-spinner
  style="z-index: 999"
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
>
</mat-spinner>

<div
  class="content"
  *ngIf="this.productvalue?.length <= 0 && isread && !loading"
>
  <div>
    <h1 style="font-weight: 500">Start by creating your first Gateway</h1>
    <p style="font-weight: 500; margin: 0; text-transform: capitalize">
      Gateway is a digital model of a physical object. It is used in Devsbot
      platform as a Gateway to be assigned to Routes.
    </p>
  </div>
  <div>
    <button class="btn btn-primary" (click)="newproduct()">
      + New Gateway
    </button>
  </div>
</div>
<div class="content" *ngIf="!isread">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<div
  class="routes-wrapper"
  [ngClass]="{ hidden: !isread || this.productvalue?.length <= 0 }"
>
  <div class="col">
    <h4 class="heading">Feeds</h4>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Digital"
    >
      <i class="fa-solid fa-gear"></i><span> Digital</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Analog"
    >
      <i class="fa fa-light fa-gauge-simple"></i><span> Analog </span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Virtual"
    >
      <i class="fa-solid fa-microchip"></i><span> Virtual</span>
    </div>
    <h4 class="heading">Widgets</h4>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Switch"
    >
      <i class="fa-sharp fa-solid fa-toggle-on"></i><span> Switch </span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Slider"
    >
      <i class="fa-solid fa-sliders"></i><span> Slider</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="LED"
    >
      <i class="fa-solid fa-lightbulb"></i><span>LED</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Gauge"
    >
      <i class="fa-solid fa-gauge"></i><span> Gauge</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Gauge3Phase"
    >
      <i class="fa-solid fa-gauge"></i><span> 3 Phase</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="piechart"
    >
      <i class="fa-solid fa-gauge"></i><span> Pie Chart</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Thermometer"
    >
      <i class="fa-solid fa-gauge"></i><span> Thermometer</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Weather"
    >
      <i class="fa-solid fa-gauge"></i><span> Weather</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Chart"
    >
      <i class="fa fa-area-chart"></i><span> Chart</span>
    </div>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Label"
    >
      <i class="fa-solid fa-font"></i><span> Label</span>
    </div>
    <div
      [ngClass]="{ disabled: this.CostNode }"
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Cost"
    >
      <i class="fa-solid fa-indian-rupee-sign"></i><span> Cost Of KWH</span>
    </div>

    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Terminal"
    >
      <i class="fas fa-code"></i><span> Terminal</span>
    </div>

    <h4 class="heading">Events</h4>
    <div
      class="drag-drawflow"
      draggable="true"
      (dragstart)="drag($event)"
      data-node="Notifications"
    >
      <i class="fa-sharp fa-solid fa-envelope"></i><span> Notifications</span>
    </div>

    <div
      class="drag-drawflow"
      draggable="false"
      style="filter: opacity(0.5)"
      (dragstart)="drag($event)"
      data-node="Custom Events"
    >
      <i class="fa-solid fa-share-nodes"></i><span> Custom Events</span>
    </div>
    <h4 class="heading">Clouds</h4>
    <div
      class="drag-drawflow"
      draggable="false"
      style="filter: opacity(0.5)"
      (dragstart)="drag($event)"
      data-node="AWS"
    >
      <i class="fa-brands fa-aws"></i><span> AWS</span>
    </div>

    <div
      class="drag-drawflow"
      draggable="false"
      style="filter: opacity(0.5)"
      (dragstart)="drag($event)"
      data-node="Azure"
    >
      <i class="fa-brands fa-microsoft"></i><span>Azure</span>
    </div>
  </div>
  <div class="col-right" style="position: relative">
    <div class="routes-nav d-flex">
      <!-- <div id="menu" class="menu">
        <div class="arrowstonav">
          <iconify-icon
            class="overflow nav-left"
            id="scrollLeft"
            icon="solar:arrow-left-broken"
          ></iconify-icon>
          <ul id="menuList">
            <li
              *ngFor="let item of productvalue; let i = index"
              (click)="
                gethardwarename(item.cluster_id, item.cluster_api_Id, $event,item.cluster_name)
              "
            >
              {{ item.cluster_name }}
            </li>
          </ul>
          <iconify-icon
            class="overflow nav-right"
            id="scrollRight"
            icon="solar:arrow-right-broken"
          ></iconify-icon>
        </div>
      </div> -->
      <div id="menu" class="menu" [formGroup]="range">
        <mat-form-field >
        <mat-label>Select a GateWay</mat-label>
        <mat-select
          panelClass="myPanelClass"
          placeholder="Select Cluster"
          disableOptionCentering
          formControlName="cluster_Id"
        >
          <mat-option
            (click)="
              gethardwarename(
                item.cluster_id,
                item.cluster_api_Id,
                $event,
                item.cluster_name
              )
            "
            value="{{ item.cluster_api_Id }}"
            *ngFor="let item of productvalue"
            >{{ item.cluster_name }}</mat-option
          >
        </mat-select></mat-form-field>
      </div>
      <div class="design_DB">
        <button class="btn btn-primary" (click)="designdb(this.cluster_id)">
          <img src="../../assets/icons/gauge.png" alt="Gauge" /> Design
          Dashboard
        </button>
      </div>
    </div>

    <!--     
    <div class="routes-nav d-flex">
      <div class="menu">
        <div class="arrowstonav">
          <iconify-icon class="overflow nav-left" icon="solar:arrow-left-broken"></iconify-icon>
          <ul>
            <li
              *ngFor="let item of productvalue; let i = index"
              (click)="
                gethardwarename(item.cluster_id, item.cluster_name, $event)
              "
            >
              {{ item.cluster_name }}
            </li>
          </ul>
          <iconify-icon class="overflow nav-right" icon="solar:arrow-right-broken"></iconify-icon>
        </div>
       
      </div>
      <div class="design_DB">
        <button class="btn btn-primary" (click)="designdb(this.cluster_id)">
          <img src="../../assets/icons/gauge.png" alt="Gauge" /> Design Dashboard
        </button>
      </div>
    </div> -->

    <div
      #Editor
      id="drawflow"
      (drop)="drop($event)"
      (dragover)="allowDrop($event)"
    >
      <div
        class="btn-action"
        (click)="Import()"
        *ngIf="this.routesdata.length <= 0"
      >
        Import
      </div>
      <div
        class="btn-action"
        (click)="Export()"
        *ngIf="this.routesdata.length >= 1"
      >
        Export
      </div>

      <div class="btn-clear" (click)="clearroutes()">Clear</div>
      <div class="btn-clear btn-success" (click)="Createroutes('Create')">
        Save
      </div>
      <div class="bar-zoom">
        <i class="fas fa-search-minus" (click)="editor.zoom_out()"></i>
        <i class="fas fa-search" (click)="editor.zoom_reset()"></i>
        <i class="fas fa-search-plus" (click)="editor.zoom_in()"></i>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card preview-chart-div" *ngIf="this.nodename == 'Chart'">
  <ngx-charts-bar-vertical
  *ngIf="Chartdatastream.controls['chart_type'].value === 'bar-vertical'"
  class="chart-container"
  [results]="this.Single"
  
  [xAxis]="true"
  [yAxis]="true"
  [yScaleMin]="Chartdatastream.controls['Min'].value"
  [yScaleMax]="Chartdatastream.controls['Max'].value"
  [xAxisLabel]="Chartdatastream.controls['xAxisLabel'].value"
  [yAxisLabel]="Chartdatastream.controls['yAxisLabel'].value"
  [scheme]="Chartdatastream.controls['color_scheme'].value"
>
</ngx-charts-bar-vertical>
<ngx-charts-line-chart
  *ngIf="Chartdatastream.controls['chart_type'].value === 'line-chart'"
  class="chart-container"
  [scheme]="Chartdatastream.controls['color_scheme'].value"
  [schemeType]="schemeType"
  [results]="linechartdata"
  [animations]="animations"
  [legend]="false"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="Chartdatastream.controls['xAxisLabel'].value"
  [yAxisLabel]="Chartdatastream.controls['yAxisLabel'].value"
  [autoScale]="autoScale"
  [xScaleMin]="xScaleMin"
  [xScaleMax]="xScaleMax"
  [yScaleMin]="Chartdatastream.controls['Min'].value"
  [yScaleMax]="Chartdatastream.controls['Max'].value"
  [timeline]="timeline"
  [showGridLines]="showGridLines"
  [curve]="curve"
  [rangeFillOpacity]="rangeFillOpacity"
  [roundDomains]="roundDomains"
  [tooltipDisabled]="tooltipDisabled"
  [trimXAxisTicks]="trimXAxisTicks"
  [trimYAxisTicks]="trimYAxisTicks"
  [rotateXAxisTicks]="rotateXAxisTicks"
  [maxXAxisTickLength]="maxXAxisTickLength"
  [maxYAxisTickLength]="maxYAxisTickLength"
  [wrapTicks]="wrapTicks"
>
</ngx-charts-line-chart>


</div> -->

<!-- NODE PROPERTIES SIDENAV START -->
<mat-sidenav #sidenav position="end" style="right: 0">
  <div class="sidenav-content">
    <!-- digital datastream popup html page  start -->
    <div class="datastream-popup-main" *ngIf="this.nodename == 'Digital'">
      <mat-spinner
        style="z-index: 999; margin: 250px auto"
        role="progressbar"
        diameter="50"
        aria-hidden="true"
        *ngIf="propLoader"
      >
      </mat-spinner>
      <div class="datastream-popup-header" *ngIf="!propLoader">
        <div
          class="d-flex align-items-flex-start"
          style="
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 20px;
          "
        >
          <mat-icon
            style="margin-top: 10px"
            class="close-nav"
            (click)="sidenav.close()"
            >close</mat-icon
          >

          <div class="prop" style="margin-bottom: 25px">
            <h3>Properties</h3>
            <hr style="margin: 10px 0" />
            <h1>{{ this.nodename }} Data feeds</h1>
          </div>
        </div>
      </div>
      <div *ngIf="!propLoader">
        <form [formGroup]="digitaldatastream">
          <div class="datastream-popup-body">
            <div class="dbl-input-first">
              <div class="form-field">
                <label for="">Name</label>
                <input
                  class="form-control"
                  formControlName="name"
                  type="text"
                />
                <div *ngIf="digitaldatastream.controls['name'].dirty">
                  <span
                    *ngIf="digitaldatastream.controls['name']?.errors?.['required']"
                    style="color: red"
                  >
                    *Name is required.
                  </span>
                  <span
                    *ngIf="digitaldatastream.controls['name']?.errors?.['minlength']&&!digitaldatastream.controls['name']?.errors?.['pattern']"
                    style="color: red"
                  >
                    *Minimum 2 characters
                  </span>
                  <span
                    *ngIf="digitaldatastream.controls['name']?.errors?.['maxlength']"
                    style="color: red"
                  >
                    *Maximum 15 characters
                  </span>
                </div>
              </div>
              <!-- <div class="form-field">
								  <label for="">Alias</label>
								  <input class="form-control" formControlName="alias" type="text" />
								  <div *ngIf="digitaldatastream.controls['alias'].dirty">
									  <span *ngIf="digitaldatastream.controls['alias']?.errors?.['required']"
										  style="color: red">
										  *Alias is required.
									  </span>
									  <span
										  *ngIf="digitaldatastream.controls['alias']?.errors?.['minlength']&&!digitaldatastream.controls['alias']?.errors?.['pattern']"
										  style="color: red">
										  *Minimum 2 characters
									  </span>
									  <span *ngIf="digitaldatastream.controls['alias']?.errors?.['maxlength']"
										  style="color: red">
										  *Maximum 15 characters
									  </span>
								  </div>
							  </div> -->
            </div>

            <div class="dbl-input-second">
              <div class="form-field">
                <label for="">PinMode</label>
                <select
                  #pinmodeSelect
                  class="form-select"
                  formControlName="pinmode"
                >
                  <option
                    class="form-option"
                    *ngFor="let pinout of pinmode"
                    [value]="pinout"
                  >
                    {{ pinout }}
                  </option>
                </select>
              </div>

              <div class="form-field">
                <label for="">Pin</label>

                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #selectpin
                  *ngIf="
                    this.digitaldatastream.controls.pinmode.value == 'Output'
                  "
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [selected]="0"
                    [value]="list.pin"
                    *ngFor="let list of DigitalpinOut"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #selectpin
                  *ngIf="
                    this.digitaldatastream.controls.pinmode.value == 'Input'
                  "
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [selected]="0"
                    [value]="list.pin"
                    *ngFor="let list of Digitalpin"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #selectpin
                  name=""
                  id=""
                  *ngIf="
                    this.digitaldatastream.controls.pinmode.value ==
                    'Input_pullup'
                  "
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [selected]="0"
                    [value]="list.pin"
                    *ngFor="let list of Digitalpinpull"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div class="datastream-popup-footer">
          <button
            class="btn btn-success"
            *ngIf="!this.datastream_name"
            (click)="Digital()"
            [disabled]="this.digitaldatastream.invalid"
          >
            Update
          </button>
          <button
            class="btn btn-success"
            *ngIf="this.datastream_name"
            (click)="UpdateDigital()"
            [disabled]="this.digitaldatastream.invalid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- digital datastream popup html page end  -->

    <!-- Analog datastream popup html page  start -->
    <div class="datastream-popup-main" *ngIf="this.nodename == 'Analog'">
      <mat-spinner
        style="z-index: 999; margin: 250px auto"
        role="progressbar"
        diameter="50"
        aria-hidden="true"
        *ngIf="propLoader"
      ></mat-spinner>
      <div class="datastream-popup-header" *ngIf="!propLoader">
        <div
          class="d-flex align-items-flex-start"
          style="
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 20px;
          "
        >
          <mat-icon
            style="margin-top: 10px"
            class="close-nav"
            (click)="sidenav.close()"
            >close</mat-icon
          >

          <div class="prop" style="margin-bottom: 25px">
            <h3>Properties</h3>
            <hr style="margin: 10px 0" />
            <h1>{{ this.nodename }} Data feeds</h1>
          </div>
        </div>
      </div>
      <div *ngIf="!propLoader">
        <form [formGroup]="analogdatastream">
          <div class="datastream-popup-body">
            <div class="dbl-input-first">
              <div class="icon-picker-div"></div>
              <div class="form-field">
                <label for="">Name</label>
                <input
                  class="form-control"
                  formControlName="name"
                  type="text"
                />

                <div *ngIf="analogdatastream.controls['name'].dirty">
                  <span
                    *ngIf="analogdatastream.controls['name']?.errors?.['required']"
                    style="color: red"
                  >
                    *Name is required.
                  </span>
                  <span
                    *ngIf="analogdatastream.controls['name']?.errors?.['minlength']&&!analogdatastream.controls['name']?.errors?.['pattern']"
                    style="color: red"
                  >
                    *Minimum 2 characters
                  </span>
                  <span
                    *ngIf="analogdatastream.controls['name']?.errors?.['maxlength']"
                    style="color: red"
                  >
                    *Maximum 15 characters
                  </span>
                </div>
              </div>
              <!-- <div class="form-field">
				  <label for="">Alias</label>
				  <input
					class="form-control"
					formControlName="alias"
					type="text"
				  />
				  <div *ngIf="analogdatastream.controls['alias'].dirty">
					<span
					  *ngIf="analogdatastream.controls['alias']?.errors?.['required']"
					  style="color: red"
					>
					  *Name is required.
					</span>
					<span
					  *ngIf="analogdatastream.controls['alias']?.errors?.['minlength']&&!analogdatastream.controls['alias']?.errors?.['pattern']"
					  style="color: red"
					>
					  *Minimum 2 characters
					</span>
					<span
					  *ngIf="analogdatastream.controls['alias']?.errors?.['maxlength']"
					  style="color: red"
					>
					  *Maximum 15 characters
					</span>
				  </div>
				</div> -->
            </div>

            <div class="dbl-input-second d-flex" style="gap: 10px">
              <div class="form-field">
                <label for="">PinMode</label>
                <select
                  #pinmodeSelect
                  class="form-select"
                  formControlName="pinmode"
                >
                  <option
                    class="form-option"
                    [value]="pinout"
                    *ngFor="let pinout of pinmode"
                    [hidden]="pinout === 'Input_pullup'"
                  >
                    {{ pinout }}
                  </option>
                </select>
              </div>
              <div class="form-field">
                <label for="">Pin</label>

                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #pin
                  name=""
                  id=""
                  *ngIf="
                    this.analogdatastream.controls.pinmode.value == 'Output'
                  "
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [selected]="list == 'A0'"
                    [value]="list.pin"
                    *ngFor="let list of AnalogpinOut"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #pin
                  *ngIf="
                    this.analogdatastream.controls.pinmode.value == 'Input'
                  "
                  name=""
                  id=""
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [selected]="list == 'A0'"
                    [value]="list.pin"
                    *ngFor="let list of AnalogpinIn"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="form-field">
				<div>
				  <label for="">Units</label>
				  <select class="form-select" formControlName="units">
					<option
					  value=""
					  class="form-option"
					  [value]="unit.unit"
					  *ngFor="let unit of units"
					>
					  {{ unit.unit }}
					</option>
				  </select>
				</div>
			  </div> -->
            <div class="three-input">
              <!-- <div class="form-field">
				  <div>
					<label for="">Default Value</label>
					<input
					  class="form-control"
					  type="number"
					  formControlName="default_value"
					/>
				  </div>
				</div> -->
            </div>
          </div>
        </form>
        <div class="datastream-popup-footer">
          <button
            class="btn btn-success"
            *ngIf="!this.datastream_name"
            (click)="Analog()"
            [disabled]="this.analogdatastream.invalid"
          >
            Update
          </button>
          <button
            class="btn btn-success"
            *ngIf="this.datastream_name"
            (click)="UpdateAnalog()"
            [disabled]="this.analogdatastream.invalid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- Analog datastream popup html page end  -->

    <!-- Virtual datastream popup html page  start -->
    <div class="datastream-popup-main" *ngIf="this.nodename == 'Virtual'">
      <mat-spinner
        style="z-index: 999; margin: 250px auto"
        role="progressbar"
        diameter="50"
        aria-hidden="true"
        *ngIf="propLoader"
      ></mat-spinner>
      <div class="datastream-popup-header" *ngIf="!propLoader">
        <div
          class="d-flex align-items-flex-start"
          style="
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 20px;
          "
        >
          <mat-icon
            style="margin-top: 10px"
            class="close-nav"
            (click)="sidenav.close()"
            >close</mat-icon
          >

          <div class="prop" style="margin-bottom: 25px">
            <h3>Properties</h3>
            <hr style="margin: 10px 0" />
            <h1>{{ this.nodename }} Data feeds</h1>
          </div>
        </div>
      </div>
      <div *ngIf="!propLoader">
        <form [formGroup]="virtualpindatastream">
          <div class="datastream-popup-body">
            <div class="dbl-input-first">
              <div class="form-field">
                <label for="">Name</label>
                <input
                  class="form-control"
                  formControlName="name"
                  type="text"
                />

                <div *ngIf="virtualpindatastream.controls['name'].dirty">
                  <span
                    *ngIf="virtualpindatastream.controls['name']?.errors?.['required']"
                    style="color: red"
                  >
                    *Name is required.
                  </span>
                  <span
                    *ngIf="virtualpindatastream.controls['name']?.errors?.['minlength']&&!virtualpindatastream.controls['name']?.errors?.['pattern']"
                    style="color: red"
                  >
                    *Minimum 2 characters
                  </span>
                  <span
                    *ngIf="virtualpindatastream.controls['name']?.errors?.['maxlength']"
                    style="color: red"
                  >
                    *Maximum 15 characters
                  </span>
                </div>
              </div>
              <!-- <div class="form-field">
				  <label for="">Alias</label>
				  <input
					class="form-control"
					formControlName="alias"
					type="text"
				  />
				  <div *ngIf="virtualpindatastream.controls['alias'].dirty">
					<span
					  *ngIf="virtualpindatastream.controls['alias']?.errors?.['required']"
					  style="color: red"
					>
					  *Name is required.
					</span>
					<span
					  *ngIf="virtualpindatastream.controls['alias']?.errors?.['minlength']&&!virtualpindatastream.controls['alias']?.errors?.['pattern']"
					  style="color: red"
					>
					  *Minimum 2 characters
					</span>
					<span
					  *ngIf="virtualpindatastream.controls['alias']?.errors?.['maxlength']"
					  style="color: red"
					>
					  *Maximum 15 characters
					</span>
				  </div>
				</div> -->
            </div>

            <div class="dbl-input-second d-flex" style="gap: 10px">
              <div class="form-field">
                <label for="">Pin</label>
                <select
                  onfocus="this.size=5;"
                  onblur="this.size=1;"
                  onchange="this.size=1; this.blur();"
                  #pin
                  name=""
                  id=""
                  class="form-select"
                  formControlName="pin"
                >
                  <option
                    class="form-option"
                    [value]="list.pin"
                    [selected]="list == 'V0'"
                    *ngFor="let list of Virtualpin"
                    [disabled]="list.isdisabled === true"
                    [ngClass]="{ disabled: list.isdisabled === true }"
                  >
                    {{ list.pin }}
                  </option>
                </select>
              </div>
              <div class="form-field">
                <label for="">Data Type</label>
                <select
                  class="form-select"
                  #pinmodeSelect
                  formControlName="datastream_type"
                >
                  <option
                    class="form-option"
                    [value]="type"
                    *ngFor="let type of datastream_type"
                  >
                    {{ type }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div class="datastream-popup-footer">
          <button
            class="btn btn-success"
            *ngIf="!this.datastream_name"
            (click)="Virtual()"
            [disabled]="this.virtualpindatastream.invalid"
          >
            Update
          </button>
          <button
            class="btn btn-success"
            *ngIf="this.datastream_name"
            (click)="UpdateVirtual()"
            [disabled]="this.virtualpindatastream.invalid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- Virtual datastream popup html page end  -->

    <!--Switch datastream create  -->
    <div *ngIf="this.nodename == 'Switch'">
      <div class="widget-datastream-popup" [formGroup]="switchdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <input type="text" class="form-control" formControlName="title" />
              <div *ngIf="switchdatastream.controls['title'].dirty">
                <span
                  *ngIf="switchdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="switchdatastream.controls['title']?.errors?.['minlength']&&!switchdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="switchdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.switchdatastream.invalid"
            (click)="createswitch()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            class="btn btn-success"
            [disabled]="this.switchdatastream.invalid"
            (click)="createswitch()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- Switch datastream end  -->

    <!--Slider datastream create  -->
    <div *ngIf="this.nodename == 'Slider'">
      <div class="widget-datastream-popup" [formGroup]="sliderdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <input type="text" class="form-control" formControlName="title" />
              <div *ngIf="sliderdatastream.controls['title'].dirty">
                <span
                  *ngIf="sliderdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="sliderdatastream.controls['title']?.errors?.['minlength']&&!sliderdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="sliderdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
            <div class="form-field">
              <label for="">Handle Step</label>
              <input
                type="number"
                class="form-control"
                formControlName="handlestep"
              />
              <div *ngIf="sliderdatastream.controls['handlestep'].dirty">
                <span
                  *ngIf="sliderdatastream.controls['handlestep']?.errors?.['required']"
                  style="color: red"
                >
                  *Handlestep is required.
                </span>
              </div>
            </div>
            <div class="chart-dbl-input d-flex form-field" style="gap: 10px">
              <div>
                <label for="">Min</label>
                <input
                  type="number"
                  formControlName="Min"
                  class="form-control"
                />
                <div *ngIf="sliderdatastream.controls['Min'].dirty">
                  <span
                    *ngIf="sliderdatastream.controls['Min']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
              <div class="">
                <label for="">Max</label>
                <input
                  type="number"
                  formControlName="Max"
                  class="form-control"
                />
                <div *ngIf="sliderdatastream.controls['Max'].dirty">
                  <span
                    *ngIf="sliderdatastream.controls['Max']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
            </div>
            <div class="form-field">
              <label for="">Default Value</label>
              <input
                type="number"
                class="form-control"
                formControlName="Default_value"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.sliderdatastream.invalid"
            (click)="createSlider()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            class="btn btn-success"
            [disabled]="this.sliderdatastream.invalid"
            (click)="createSlider()"
          >
            Update
          </button>
          <!-- <button
				  [disabled]="this.switchdatastream.invalid"
				  class="btn btn-success"
				  (click)="createswitch()"
				>
				  Update
				</button> -->
          <!-- <button
				  class="btn btn-success"
				  *ngIf="this.switchdatastream.value.title!=null||undefined"
				  (click)="updateswitch()"
				>
				  Update
				</button> -->
        </div>
      </div>
    </div>
    <!-- Slider datastream end  -->

    <!--LED  datastream start  -->
    <div *ngIf="this.nodename == 'LED'">
      <div class="widget-datastream-popup" [formGroup]="leddatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <div class="color-picker-div">
                  <input
                    class="color-picker"
                    [(colorPicker)]="color"
                    [style.background]="this.color"
                    [cpPresetLabel]="this.color"
                    [cpCmykEnabled]="true"
                  />
                </div>
              </div>
              <div *ngIf="leddatastream.controls['title'].dirty">
                <span
                  *ngIf="leddatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="leddatastream.controls['title']?.errors?.['minlength']&&!leddatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="leddatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.leddatastream.invalid"
            (click)="createLed()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            class="btn btn-success"
            [disabled]="this.leddatastream.invalid"
            (click)="createLed()"
          >
            Update
          </button>
          <!-- <button
				  [disabled]="this.switchdatastream.invalid"
				  class="btn btn-success"
				  (click)="createswitch()"
				>
				  Update
				</button> -->
          <!-- <button
				  class="btn btn-success"
				  *ngIf="this.switchdatastream.value.title!=null||undefined"
				  (click)="updateswitch()"
				>
				  Update
				</button> -->
        </div>
      </div>
    </div>
    <!--LED  datastream end   -->

    <!--chart  create  -->
    <div *ngIf="this.nodename == 'Chart'">
      <div class="widget-datastream-popup" [formGroup]="Chartdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <input type="text" class="form-control" formControlName="title" />
              <div *ngIf="Chartdatastream.controls['title'].dirty">
                <span
                  *ngIf="Chartdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="Chartdatastream.controls['title']?.errors?.['minlength']&&!Chartdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="Chartdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
            <div class="widget-form-field form-field">
              <label class="form-label"
                >Chart type <span style="color: red">*</span></label
              >
              <select
                class="form-select"
                formControlName="chart_type"
                onfocus="this.size=5;"
                onblur="this.size=1;"
                onchange="this.size=1; this.blur();"
              >
                <ng-template ngFor let-group [ngForOf]="chartGroups">
                  <!-- <optgroup [label]="group.name"> -->
                  <option
                    *ngFor="let chart of group.charts"
                    [value]="chart.selector"
                  >
                    {{ chart.name }}
                  </option>
                  <!-- </optgroup> -->
                </ng-template>
              </select>
              <div
                class="validation"
                *ngIf="
                  Chartdatastream.controls['chart_type'].invalid &&
                  Chartdatastream.controls['chart_type'].dirty
                "
              >
                <span
                  *ngIf="Chartdatastream.controls['chart_type']?.errors?.['required']"
                  style="color: red"
                >
                  *Select The Chart type
                </span>
              </div>
            </div>
            <div class="widget-form-field form-field">
              <label class="form-label"
                >Chart Filter <span style="color: red">*</span></label
              >
              <select class="form-select" formControlName="chartfilter">
                <option [selected]="true" value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
              </select>
            </div>
            <div class="datarange-dbl-input form-field">
              <div class="chart-dbl-input d-flex" style="gap: 10px">
                <div class="form-field">
                  <label for="">X'axis Label</label>
                  <input
                    type="text"
                    formControlName="xAxisLabel"
                    class="form-control"
                  />
                  <div *ngIf="Chartdatastream.controls['xAxisLabel'].dirty">
                    <span
                      *ngIf="Chartdatastream.controls['xAxisLabel']?.errors?.['required']"
                      style="color: red"
                    >
                      *xAxisLabel is required.
                    </span>
                    <span
                      *ngIf="Chartdatastream.controls['xAxisLabel']?.errors?.['minlength']&&!Chartdatastream.controls['xAxisLabel']?.errors?.['pattern']"
                      style="color: red"
                    >
                      *Minimum 2 characters
                    </span>
                    <span
                      *ngIf="Chartdatastream.controls['xAxisLabel']?.errors?.['maxlength']"
                      style="color: red"
                    >
                      *Maximum 15 characters
                    </span>
                  </div>
                </div>
                <div class="form-field">
                  <label for="">Y'axis Label</label>
                  <input
                    type="text"
                    formControlName="yAxisLabel"
                    class="form-control"
                  />
                  <div *ngIf="Chartdatastream.controls['yAxisLabel'].dirty">
                    <span
                      *ngIf="Chartdatastream.controls['yAxisLabel']?.errors?.['required']"
                      style="color: red"
                    >
                      *yAxisLabel is required.
                    </span>
                    <span
                      *ngIf="Chartdatastream.controls['yAxisLabel']?.errors?.['minlength']&&!Chartdatastream.controls['yAxisLabel']?.errors?.['pattern']"
                      style="color: red"
                    >
                      *Minimum 2 characters
                    </span>
                    <span
                      *ngIf="Chartdatastream.controls['yAxisLabel']?.errors?.['maxlength']"
                      style="color: red"
                    >
                      *Maximum 15 characters
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-form-field">
              <div class="datarange-dbl-input form-field">
                <label>Data Range (x-axis)</label>
                <div>
                  <!-- <label for="">Timeseries</label> -->
                  <input
                    type="text"
                    formControlName="timeseries"
                    class="form-control"
                    [readonly]="true"
                  />
                </div>
              </div>
              <div class="datarange-dbl-input form-field">
                <label>Data Range (y-axis)</label>
                <div
                  class="form-field d-flex"
                  style="gap: 10px; align-items: flex-end; margin: 5px 0px"
                >
                  <label for="">Auto Scale</label>
                  <mat-slide-toggle
                    [checked]="this.Chartdatastream.controls['autoScale'].value"
                    (change)="AutoScale($event)"
                    [color]="'primary'"
                  ></mat-slide-toggle>
                </div>
                <div
                  class="chart-dbl-input d-flex"
                  style="gap: 10px"
                  *ngIf="!this.Chartdatastream.controls['autoScale'].value"
                >
                  <div class="form-field">
                    <label for="">Min</label>
                    <input
                      type="number"
                      formControlName="Min"
                      class="form-control"
                    />
                    <div *ngIf="Chartdatastream.controls['Min'].dirty">
                      <span
                        *ngIf="Chartdatastream.controls['Min']?.errors?.['required']"
                        style="color: red"
                      >
                        *Field is Required.
                      </span>
                    </div>
                  </div>
                  <div class="form-field">
                    <label for="">Max</label>
                    <input
                      type="number"
                      formControlName="Max"
                      class="form-control"
                    />
                    <div *ngIf="Chartdatastream.controls['Max'].dirty">
                      <span
                        *ngIf="Chartdatastream.controls['Max']?.errors?.['required']"
                        style="color: red"
                      >
                        *Field is Required.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="datarange-dbl-input form-field">
                <label>Legend Title</label>
                <div>
                  <!-- <label for="">Timeseries</label> -->
                  <input
                    type="text"
                    formControlName="label"
                    class="form-control"
                  />
                </div>
              </div>
              <div
                class="datarange-dbl-input form-field"
                *ngFor="let item of chartlines"
              >
                <div>
                  <label>{{ item.name }}</label>
                  <input
                    type="text"
                    formControlName="{{ item.name }}"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="form-field">
              <label class="form-label">Color Scheme</label>
              <select
                formControlName="color_scheme"
                onfocus="this.size=5;"
                onblur="this.size=1;"
                onchange="this.size=1; this.blur();"
                class="form-select"
              >
                <option *ngFor="let scheme of colorSets" [value]="scheme.name">
                  {{ scheme.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.Chartdatastream.invalid"
            (click)="createChart()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            class="btn btn-success"
            [disabled]="this.Chartdatastream.invalid"
            (click)="createChart()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- chart datastream end  -->
    <!-- Gauge datastream start  -->
    <div *ngIf="this.nodename == 'Gauge'">
      <div class="widget-datastream-popup" [formGroup]="gaugedatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <div class="color-picker-div">
                  <input
                    class="color-picker"
                    [(colorPicker)]="color"
                    [style.background]="this.color"
                    [cpPresetLabel]="this.color"
                    [cpCmykEnabled]="true"
                  />
                </div>
              </div>
              <div *ngIf="gaugedatastream.controls['title'].dirty">
                <span
                  *ngIf="gaugedatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="gaugedatastream.controls['title']?.errors?.['minlength']&&!gaugedatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="gaugedatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>

            <label for="gaugeType">Gauge Type</label>
            <select
              formControlName="gaugeType"
              id="gaugeType"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="semi">semi</option>
              <option value="arch">arch</option>
              <option value="full">full</option>
              <!-- Add more options as needed -->
            </select>

            <div class="chart-dbl-input d-flex form-field" style="gap: 10px">
              <div>
                <label for="">Min</label>
                <input
                  type="number"
                  formControlName="Min"
                  class="form-control"
                />
                <div *ngIf="gaugedatastream.controls['Min'].dirty">
                  <span
                    *ngIf="gaugedatastream.controls['Min']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
              <div class="">
                <label for="">Max</label>
                <input
                  type="number"
                  formControlName="Max"
                  class="form-control"
                />
                <div *ngIf="gaugedatastream.controls['Max'].dirty">
                  <span
                    *ngIf="gaugedatastream.controls['Max']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="v1pin" class="form-field">
              <label for="">Additional Options</label>
              <select
                class="form-select"
                #additionalSelect
                formControlName="additional_option"
              >
                <option class="form-option" value="totalkwh">Total kWh</option>
                <option class="form-option" value="todaykwh">Today kWh</option>
                <option class="form-option" value="thismonthkwh">
                  This Month kWh
                </option>
                <option class="form-option" value="thisweekkwh">
                  This Week kWh
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.gaugedatastream.invalid"
            (click)="createGauge()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.gaugedatastream.invalid"
            class="btn btn-success"
            (click)="createGauge()"
          >
            Update
          </button>
          <!-- <button
			  class="btn btn-success"
			  *ngIf="this.switchdatastream.value.title!=null||undefined"
			  (click)="updateswitch()"
			>
			  Update
			</button> -->
        </div>
      </div>
    </div>
    <!-- Gauge datastream end  -->

    <!-- gauge for 3 phase  -->

    <div *ngIf="this.nodename == 'Gauge3Phase'">
      <div class="widget-datastream-popup" [formGroup]="gauge3Phasedatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <!-- <div class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="this.color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
              />
            </div> -->
              </div>
              <div *ngIf="gauge3Phasedatastream.controls['title'].dirty">
                <span
                  *ngIf="gauge3Phasedatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="gauge3Phasedatastream.controls['title']?.errors?.['minlength']&&!gauge3Phasedatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="gauge3Phasedatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>

            <label for="gaugeType">Gauge Type</label>
            <select
              formControlName="gaugeType"
              id="gaugeType"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="semi">semi</option>
              <option value="arch">arch</option>
              <option value="full">full</option>
              <!-- Add more options as needed -->
            </select>

            <div class="chart-dbl-input d-flex form-field" style="gap: 10px">
              <div>
                <label for="">Min</label>
                <input
                  type="number"
                  formControlName="Min"
                  class="form-control"
                />
                <div *ngIf="gauge3Phasedatastream.controls['Min'].dirty">
                  <span
                    *ngIf="gauge3Phasedatastream.controls['Min']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
              <div class="">
                <label for="">Max</label>
                <input
                  type="number"
                  formControlName="Max"
                  class="form-control"
                />
                <div *ngIf="gauge3Phasedatastream.controls['Max'].dirty">
                  <span
                    *ngIf="gauge3Phasedatastream.controls['Max']?.errors?.['required']"
                    style="color: red"
                  >
                    *Field is Required.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.gauge3Phasedatastream.invalid"
            (click)="createGauge3Phase()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.gauge3Phasedatastream.invalid"
            class="btn btn-success"
            (click)="createGauge3Phase()"
          >
            Update
          </button>
          <!-- <button
    class="btn btn-success"
    *ngIf="this.switchdatastream.value.title!=null||undefined"
    (click)="updateswitch()"
  >
    Update
  </button> -->
        </div>
      </div>
    </div>
    <!-- pie chart  -->

    <div *ngIf="this.nodename == 'piechart'">
      <div class="widget-datastream-popup" [formGroup]="piechartdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <!-- <div class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="this.color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
              />
            </div> -->
              </div>
              <div *ngIf="piechartdatastream.controls['title'].dirty">
                <span
                  *ngIf="piechartdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="piechartdatastream.controls['title']?.errors?.['minlength']&&!piechartdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="piechartdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>

            <!-- <label for="piechartType">Gauge Type</label>
        <select formControlName="piechartType" id="piechartType" class="form-select" aria-label="Default select example">
          <option value="doughnut">semi</option>
          <option value="pie">arch</option> -->

            <!-- Add more options as needed -->
            <!-- </select> -->
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.piechartdatastream.invalid"
            (click)="createpiechart()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.piechartdatastream.invalid"
            class="btn btn-success"
            (click)="createpiechart()"
          >
            Update
          </button>
          <!-- <button
    class="btn btn-success"
    *ngIf="this.switchdatastream.value.title!=null||undefined"
    (click)="updateswitch()"
  >
    Update
  </button> -->
        </div>
      </div>
    </div>

    <!-- Thermometer datastream start  -->
    <div *ngIf="this.nodename == 'Thermometer'">
      <div class="widget-datastream-popup" [formGroup]="Thermometerdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <!-- <div class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="this.color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
              />
            </div> -->
              </div>
              <div *ngIf="Thermometerdatastream.controls['title'].dirty">
                <span
                  *ngIf="Thermometerdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="Thermometerdatastream.controls['title']?.errors?.['minlength']&&!Thermometerdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="Thermometerdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.Thermometerdatastream.invalid"
            (click)="createThermometer()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.Thermometerdatastream.invalid"
            class="btn btn-success"
            (click)="createThermometer()"
          >
            Update
          </button>
          <!-- <button
    class="btn btn-success"
    *ngIf="this.switchdatastream.value.title!=null||undefined"
    (click)="updateswitch()"
  >
    Update
  </button> -->
        </div>
      </div>
    </div>
    <!-- Thermometer datastream end  -->

    <!-- Weather datastream start  -->
    <div *ngIf="this.nodename == 'Weather'">
      <div class="widget-datastream-popup" [formGroup]="Weatherdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <!-- <div class="color-picker-div">
              <input
                class="color-picker"
                [(colorPicker)]="color"
                [style.background]="this.color"
                [cpPresetLabel]="this.color"
                [cpCmykEnabled]="true"
              />
            </div> -->
              </div>
              <div *ngIf="Weatherdatastream.controls['title'].dirty">
                <span
                  *ngIf="Weatherdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="Weatherdatastream.controls['title']?.errors?.['minlength']&&!Weatherdatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="Weatherdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.Weatherdatastream.invalid"
            (click)="createWeather()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.Weatherdatastream.invalid"
            class="btn btn-success"
            (click)="createWeather()"
          >
            Update
          </button>
          <!-- <button
    class="btn btn-success"
    *ngIf="this.switchdatastream.value.title!=null||undefined"
    (click)="updateswitch()"
  >
    Update
  </button> -->
        </div>
      </div>
    </div>

    <!-- Weather datastream end  -->

    <!-- Label datastream start -->
    <div *ngIf="this.nodename == 'Label'">
      <div class="widget-datastream-popup" [formGroup]="labeldatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <div class="color-picker-div">
                  <input
                    class="color-picker"
                    [(colorPicker)]="color"
                    [style.background]="this.color"
                    [cpPresetLabel]="this.color"
                    [cpCmykEnabled]="true"
                  />
                </div>
              </div>
              <div *ngIf="labeldatastream.controls['title'].dirty">
                <span
                  *ngIf="labeldatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="labeldatastream.controls['title']?.errors?.['minlength']&&!labeldatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="labeldatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
            <div *ngIf="v1pin" class="form-field">
              <label for="">Additional Options</label>
              <select
                class="form-select"
                #additionalSelect
                formControlName="additional_option"
              >
                <option class="form-option" value="totalkwh">Total kWh</option>
                <option class="form-option" value="todaykwh">Today kWh</option>
                <option class="form-option" value="thismonthkwh">
                  This Month kWh
                </option>
                <option class="form-option" value="thisweekkwh">
                  This Week kWh
                </option>
              </select>
            </div>
            <div *ngIf="v7pin" class="form-field">
              <label for="">Emission Factor</label>
              <input class="form-control" formControlName="emissionfactor" />
            </div>
            <!-- <div *ngIf="v1pin" class="form-field">
              <label for="">Max Kwh Limit</label>
              <input formControlName="Max_kwh" class="form-control" />
            </div> -->
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.labeldatastream.invalid"
            (click)="createlabel()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.labeldatastream.invalid"
            class="btn btn-success"
            (click)="createlabel()"
          >
            Update
          </button>
          <!-- <button
    class="btn btn-success"
    *ngIf="this.switchdatastream.value.title!=null||undefined"
    (click)="updateswitch()"
  >
    Update
  </button> -->
        </div>
      </div>
    </div>

    <!-- Label datastream end -->
    <!-- !-- Cost datastream start -->
    <div *ngIf="this.nodename == 'Cost'">
      <div class="widget-datastream-popup" [formGroup]="costdatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Title(Optional)</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                />
                <div class="color-picker-div">
                  <input
                    class="color-picker"
                    [(colorPicker)]="color"
                    [style.background]="this.color"
                    [cpPresetLabel]="this.color"
                    [cpCmykEnabled]="true"
                  />
                </div>
              </div>
              <div *ngIf="costdatastream.controls['title'].dirty">
                <span
                  *ngIf="costdatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="costdatastream.controls['title']?.errors?.['minlength']&&!labeldatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="costdatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>

              <!-- <div>
                <mat-form-field
                  appearance="fill"
                  style="margin: 15px 0px 0px 0px !important"
                >
                  <mat-label>Choose Billing Date</mat-label>
                  <input
                    formControlName="blling_date"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      costdatastream.controls['blling_date'].hasError(
                        'matInput'
                      )
                    "
                    >Invalid date</mat-error
                  >
                </mat-form-field>
              </div> -->
              <!-- Toggle button to show/hide kWh cost inputs -->
              <div
                class="widget-form-field form-field"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-top: 20px;
                "
              >
                <label for="" style="padding-right: 10px">Add kWh Cost</label>
                <button
                  [matMenuTriggerFor]="menu"
                  class="btn btn-primary"
                  [disabled]="
                    this.costcount === 7 || this.costdatastream.invalid
                  "
                >
                  Add
                </button>
                <mat-menu #menu="matMenu">
                  <!-- <button  (click)="AddCost('Lessthan')" mat-menu-item>Less-Than </button> -->
                  <button (click)="AddCost('Inbetween')" mat-menu-item>
                    Inbetween
                  </button>
                  <button (click)="AddCost('Above')" mat-menu-item>
                    Above
                  </button>
                </mat-menu>
              </div>

              <!-- kWh cost input fields, initially hidden -->
              <div
                style="display: flex; gap: 20px; align-items: end"
                *ngFor="let item of costlist; let i = index"
                formGroupName="{{ item?.Groupname }}"
              >
                <div
                  class="widget-form-field form-field"
                  *ngIf="item?.type == 'Inbetween'"
                >
                  <label for="fromkwh">From kWh</label>
                  <input
                    [ngClass]="{ disabled: i >= 1 }"
                    type="number"
                    class="form-control"
                    formControlName="fromkwh"
                  />
                </div>

                <div
                  class="widget-form-field form-field"
                  *ngIf="item?.type == 'Inbetween'"
                >
                  <label for="tokwh">To kWh</label>
                  <input
                    [ngClass]="{
                      disabled: this.costcount >= 2 && i < this.costcount - 1
                    }"
                    type="number"
                    formControlName="tokwh"
                    class="form-control"
                  />
                </div>

                <div
                  class="widget-form-field form-field"
                  *ngIf="item?.type == 'Inbetween'"
                >
                  <label for="cost">Cost/PerUnit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="cost"
                  />
                </div>

                <div
                  class="widget-form-field form-field"
                  *ngIf="item?.type == 'Above'"
                >
                  <label for="above_Kwh">kWh Above</label>
                  <input
                    [ngClass]="{ disabled: i >= 1 }"
                    type="number"
                    formControlName="above_Kwh"
                    class="form-control"
                  />
                </div>

                <div
                  class="widget-form-field form-field"
                  *ngIf="item?.type == 'Above'"
                >
                  <label for="cost">Cost/PerUnit</label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="cost"
                  />
                </div>

                <div
                  *ngIf="i == this.costcount - 1"
                  class="widget-form-field form-field"
                  style="width: 20%"
                >
                  <mat-icon
                    style="color: red; cursor: pointer; margin-bottom: 8px"
                    (click)="RemoveCost(item.Groupname)"
                    >delete</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.costdatastream.invalid"
            (click)="createcost()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.costdatastream.invalid"
            class="btn btn-success"
            (click)="createcost()"
          >
            Update
          </button>
        </div>
      </div>
    </div>

    <!-- Cost datastream end -->

    <!-- terminal datastream start -->

    <div *ngIf="this.nodename == 'Terminal'">
      <div class="widget-datastream-popup" [formGroup]="terminaldatastream">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon
              style="margin-top: 10px"
              class="close-nav"
              (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }} Widget</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field">
              <label for="">Title(Optional)</label>
              <input type="text" class="form-control" formControlName="title" />
              <div *ngIf="terminaldatastream.controls['title'].dirty">
                <span
                  *ngIf="terminaldatastream.controls['title']?.errors?.['required']"
                  style="color: red"
                >
                  *Title is required.
                </span>
                <span
                  *ngIf="terminaldatastream.controls['title']?.errors?.['minlength']&&!terminaldatastream.controls['title']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="terminaldatastream.controls['title']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 10 characters
                </span>
              </div>
            </div>
            <div class="widget-form-field">
              <div class="inputhint">
                <label for="">Input Hint</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="inputhint"
                />
                <div *ngIf="terminaldatastream.controls['inputhint'].dirty">
                  <span
                    *ngIf="terminaldatastream.controls['inputhint']?.errors?.['required']"
                    style="color: red"
                  >
                    *Input Hint is required.
                  </span>
                  <span
                    *ngIf="terminaldatastream.controls['inputhint']?.errors?.['minlength']&&!terminaldatastream.controls['inputhint']?.errors?.['pattern']"
                    style="color: red"
                  >
                    *Minimum 2 characters
                  </span>
                  <span
                    *ngIf="terminaldatastream.controls['inputhint']?.errors?.['maxlength']"
                    style="color: red"
                  >
                    *Maximum 10 characters
                  </span>
                </div>
              </div>
              <div class="widget-dbl-input-terminal">
                <div class="color-picker-div-terminal">
                  <label for="">Screen color</label>
                  <input
                    class="color-picker"
                    [(colorPicker)]="screencolor"
                    [style.background]="screencolor"
                    [cpPresetLabel]="this.screencolor"
                    [cpCmykEnabled]="true"
                    formControlName="screencolor"
                  />
                </div>
                <div class="color-picker-div-terminal">
                  <label for="">Text color</label>
                  <input
                    class="color-picker"
                    [(colorPicker)]="textcolor"
                    [style.background]="textcolor"
                    [cpPresetLabel]="textcolor"
                    [cpCmykEnabled]="true"
                    formControlName="textcolor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="event-popup-footer">
            <button
              class="btn btn-success"
              *ngIf="!this.datastream_name"
              (click)="createterminal()"
              [disabled]="this.terminaldatastream.invalid"
            >
              Update
            </button>
            <button
              class="btn btn-success"
              *ngIf="this.datastream_name"
              (click)="createterminal()"
              [disabled]="this.terminaldatastream.invalid"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- terminal datastream end  -->

    <!-- NOTIFICATION NODE PROPERTIES START -->
    <div *ngIf="this.nodename == 'Notifications'">
      <div class="widget-datastream-popup" [formGroup]="event">
        <div class="datastream-popup-header">
          <div
            class="d-flex align-items-flex-start"
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              gap: 20px;
            "
          >
            <mat-icon class="close-nav" (click)="sidenav.close()"
              >close</mat-icon
            >

            <div class="prop" style="margin-bottom: 25px">
              <h3>Properties</h3>
              <hr style="margin: 10px 0" />
              <h1>{{ this.nodename }}</h1>
            </div>
          </div>
        </div>
        <div class="widget-datastream-body">
          <div class="left-popup-div">
            <div class="widget-form-field form-field">
              <label for="">Event Name</label>
              <div
                class="name-color d-flex"
                style="gap: 10px; align-items: flex-end"
              >
                <input
                  class="form-control"
                  placeholder="Enter Event Name"
                  formControlName="event_name"
                  type="text"
                />

                <div class="color-picker-div">
                  <input
                    class="color-picker"
                    [(colorPicker)]="this.eventcolor"
                    [style.background]="this.eventcolor"
                    [cpPresetLabel]="this.eventcolor"
                    [cpCmykEnabled]="true"
                  />
                </div>
              </div>
              <div *ngIf="event.controls['event_name'].dirty">
                <span
                  *ngIf="event.controls['event_name']?.errors?.['required']"
                  style="color: red"
                >
                  *Name is required.
                </span>
                <span
                  *ngIf="event.controls['event_name']?.errors?.['minlength']&&!event.controls['event_name']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="event.controls['event_name']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 15 characters
                </span>
              </div>
            </div>
            <div class="d-flex" style="gap: 10px;">
              <div class="widget-form-field form-field">
                <label for="">Event Type</label>
                <div class="flex">
                  <select
                    placeholder="Select Type"
                    name=""
                    id=""
                    class="form-select"
                    formControlName="event_type"
                  >
                    <option value="0" class="form-option">Info</option>
                    <option value="1" class="form-option">Warning</option>
                    <option value="2" class="form-option">Critical</option>
                  </select>
                </div>
              </div>
              <div class="widget-form-field form-field">
                <label for="">Devices</label>
                <div class="flex">
                  <select 
                    placeholder="Select Type"
                    name=""
                    id=""
                    class="form-select"
                    formControlName="devices"
                  >
                    <option selected  value="0" class="form-option">All</option>
                    <option *ngFor="let devices of slavedevices" [value]="devices.device_Id" class="form-option">{{devices.device_name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="enable-notifi">
              <p class="noti-head">
                <i class="fa-solid fa-bell"></i> Enable Notifications :
              </p>
              <div>
                <p class="noti">
                  Email
                  <mat-checkbox
                    [checked]="this.emailToggle"
                    color="primary"
                    (change)="onchangeemailevent($event)"
                  ></mat-checkbox>
                </p>
                <p class="noti" style="opacity: 0.6">
                  Whatsapp
                  <mat-checkbox
                    disabled="true"
                    color="primary"
                    [checked]="this.smstoogle"
                    (change)="onchangsmseevent($event)"
                  ></mat-checkbox>
                </p>
              </div>
              <!-- <p>Enable SMS Notification : </p> -->
            </div>

            <div class="" *ngIf="emailToggle">
              <label for="">E-Mail To</label>
              <mat-form-field style="width: 100%" class="example-chip-list">
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip
                    *ngFor="let user of user_Id"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeeEmail(user.email, user.user_Id)"
                  >
                    {{ user?.email }}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    #userInput
                    [formControl]="UserCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addEmail($event)"
                  />
                  <mat-error *ngIf="UserCtrl.hasError('email')">
                    Please enter a valid email address
                  </mat-error>
                </mat-chip-list>

                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="selectedEmail($event)"
                >
                  <mat-option
                    *ngFor="let user of filteredUser | async"
                    [value]="user.user_Id"
                  >
                    {{ user?.email }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="notify-form-field" *ngIf="smstoogle">
              <label for="">SMS</label>
              <mat-form-field style="width: 100%" class="example-chip-list">
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip
                    *ngFor="let user of User_Number"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeeNumber(user.Name, user.user_Id)"
                  >
                    {{ user.Name }}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    #userInput
                    [formControl]="UserNumberCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addNumber($event)"
                  />
                  <mat-error *ngIf="UserNumberCtrl.hasError('email')">
                    Please enter a valid email address
                  </mat-error>
                </mat-chip-list>

                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="selectedNumber($event)"
                >
                  <mat-option
                    *ngFor="let user of filteredUserNumber | async"
                    [value]="user.user_Id"
                  >
                    {{ user.Name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="connect-node">
              <p
                *ngIf="
                  (!GaugeProp && !switchprop && !virtualprop && emailToggle) ||
                  smstoogle
                "
                class="info-message"
              >
                <i class="fas fa-info-circle info-icon"></i>
                Please establish a connection with your Node to view its
                corresponding properties.
              </p>
              <!-- gauge prop  -->
              <div class="switchprop">
                <div class="gauge-prop form-field" *ngIf="GaugeProp">
                  <div class="prophead">
                    <p>
                      Connected Node : <span>{{ this.inputname }}</span>
                    </p>
                  </div>

                  <label for="option">Select Option:</label>
                  <select
                    class="select-event form-field"
                    id="option"
                    (change)="onOptionChange($event)"
                    formControlName="analog_type"
                  >
                    <option class="option" value="0">Min, Max</option>
                    <option class="option" value="1">Range</option>
                  </select>

                  <div class="min-max form-field" *ngIf="analog_type === '0'">
                    <label for="min">Min:</label>
                    <input
                      class="form-control"
                      type="number"
                      id="min"
                      formControlName="analog_min"
                      [ngClass]="{
                    'border-color-red': event.controls['analog_min'].errors?.['required'] && event.controls['analog_min'].touched,
                    'border-color-default': !event.controls['analog_min'].errors?.['required'] || event.controls['analog_min'].dirty
                  }"
                    />

                    <label for="max">Max:</label>
                    <input
                      class="form-control"
                      type="number"
                      id="max"
                      formControlName="analog_max"
                      [ngClass]="{
                        'border-color-red': event.controls['analog_max'].errors?.['required'] && event.controls['analog_max'].touched,
                        'border-color-default': !event.controls['analog_max'].errors?.['required'] || event.controls['analog_max'].dirty
                      }"
                    />
                  </div>

                  <div class="range form-field" *ngIf="analog_type === '1'">
                    <div class="leftminmax form-field">
                      <label for="leftMin">Left Min:</label>
                      <input
                        class="form-control"
                        type="number"
                        id="leftMin"
                        formControlName="analog_leftmin"
                        [ngClass]="{
                          'border-color-red': event.controls['analog_leftmin'].errors?.['required'] && event.controls['analog_leftmin'].touched,
                          'border-color-default': !event.controls['analog_leftmin'].errors?.['required'] || event.controls['analog_leftmin'].dirty
                        }"
                      />
                      <label for="leftMax">Left Max:</label>
                      <input
                        class="form-control"
                        type="number"
                        id="leftMax"
                        formControlName="analog_leftmax"
                        [ngClass]="{
                          'border-color-red': event.controls['analog_leftmax'].errors?.['required'] && event.controls['analog_leftmax'].touched,
                          'border-color-default': !event.controls['analog_leftmax'].errors?.['required'] || event.controls['analog_leftmax'].dirty
                        }"
                      />
                    </div>
                    <div class="rightminmax form-field">
                      <label for="rightMin">Right Min:</label>
                      <input
                        class="form-control"
                        type="number"
                        id="rightMin"
                        formControlName="analog_rightmin"
                        [ngClass]="{
                          'border-color-red': event.controls['analog_rightmin'].errors?.['required'] && event.controls['analog_rightmin'].touched,
                          'border-color-default': !event.controls['analog_rightmin'].errors?.['required'] || event.controls['analog_rightmin'].dirty
                        }"
                      />
                      <label for="rightMax">Right Max:</label>
                      <input
                        class="form-control"
                        type="number"
                        id="rightMax"
                        formControlName="analog_rightmax"
                        [ngClass]="{
                          'border-color-red': event.controls['analog_rightmax'].errors?.['required'] && event.controls['analog_rightmax'].touched,
                          'border-color-default': !event.controls['analog_rightmax'].errors?.['required'] || event.controls['analog_rightmax'].dirty
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- switch prop  -->
              <div class="switchprop">
                <div class="gauge-prop form-field" *ngIf="switchprop">
                  <div class="prophead">
                    <p>
                      Connected Node : <span>{{ this.inputname }}</span>
                    </p>
                  </div>
                  <p>
                    Push notification to you email when the Digital value is
                  </p>

                  <mat-radio-group
                    class="radio"
                    formControlName="digital_value"
                  >
                    <mat-radio-button value="0">On</mat-radio-button>
                    <mat-radio-button value="1">Off</mat-radio-button>
                    <mat-radio-button value="2">Both</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="switchprop">
                <div class="gauge-prop form-field" *ngIf="virtualprop">
                  <div class="prophead">
                    <p>
                      Connected Node : <span>{{ this.inputname }}</span>
                    </p>
                  </div>
                  <p>
                    Push notification to you email when the Virtual value is
                  </p>
                  <div class="three-input" *ngIf="virtual_Int">
                    <div class="form-field">
                      <label for="">Integer</label>
                      <div class="flex">
                        <select
                          name=""
                          id=""
                          placeholder="Select Integer"
                          class="form-select"
                          formControlName="integer_value"(change)="onVirtualintChange($event)"
                        >
                          <option value="0" class="form-option">
                            Greater than
                          </option>
                          <!-- <option value="1" class="form-option">
                            Greater than or Equal to
                          </option> -->
                          <option value="2" class="form-option">
                            Less than
                          </option>
                          <!-- <option value="3" class="form-option">
                            Less than or Equal to
                          </option> -->
                          <option value="4" class="form-option">
                            Is equal to
                          </option>
                          <option value="5" class="form-option">
                            Is not equal to
                          </option>
                          <option value="6" class="form-option">
                            Is Between
                          </option>
                          <option value="7" class="form-option">
                            Is not Between
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-field">
                      <label for="value">Value</label>
                      <input
                        *ngIf="selectedValue != '6' && selectedValue != '7'"
                        class="form-control"
                        placeholder="Enter Integer value"
                        formControlName="Int_Value"
                        type="number"
                      />

                      <div
                        class="min-max"
                        *ngIf="selectedValue === '6' || selectedValue === '7'"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Start Value"
                          formControlName="start_value"
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="End Value"
                          formControlName="end_value"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-field" *ngIf="!virtual_Int">
                    <div class="form-field">
                      <label for="">String</label>
                      <div class="flex">
                        <select
                          placeholder="Select String"
                          (change)="onVirtualstChange($event)"
                          class="form-select"
                          formControlName="string_value"
                        >
                          <option value="0" class="form-option">
                            Text contain
                          </option>
                          <option value="1" class="form-option">
                            Text does not contain
                          </option>
                          <option value="2" class="form-option">
                            Text is exactly
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-field">
                      <label for="value">Value</label>
                      <input
                        class="form-control"
                        formControlName="St_Value"
                        placeholder="Enter String Value"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="event-form-field" *ngIf="emailToggle || smstoogle">
              <p
                style="
                  padding: 20px 0px;
                  color: #0d3066;
                  font-weight: 600;
                  font-size: 14px;
                "
              >
                Limit for Notifications
              </p>
              <div class="message-trigger">
                <p style="display: flex; gap: 10px; align-items: center">
                  Every
                  <input
                    style="width: 20%"
                    type="number"
                    formControlName="event_message_count"
                    class="form-control trigger"
                    min="1"
                  />
                  message will trigger the event
                </p>
                <div></div>
                <p>
                  Event will be sent to user
                  <!-- <mat-form-field style="margin-top: 8px">
                    <mat-select formControlName="event_message_delay" value="0">
                      <mat-option [selected] value="0"
                        >No Restrictions</mat-option
                      >
                   
                      <mat-option value="60">1 minute once</mat-option>
                      <mat-option value="300">5 minutes once</mat-option>
                      <mat-option value="900">15 minutes once</mat-option>
                      <mat-option value="1800">30 minutes once</mat-option>
                      <mat-option value="3600">1 hour once</mat-option>
                      <mat-option value="21600">6 hours once</mat-option>
                      <mat-option value="43200">12 hours once</mat-option>
                      <mat-option value="86400">1 day once</mat-option>
                      <mat-option value="172800">2 days once</mat-option>
                      <mat-option value="259200">3 days once</mat-option>
                      <mat-option value="604800">1 week once</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                 
                    <select class="form-select" formControlName="event_message_delay" >
                      <option [selected]="true" value="0"
                        >No Restrictions</option
                      >
                   
                      <option class="form-option" value="60">1 minute once</option>
                      <option class="form-option" value="300">5 minutes once</option>
                      <option  class="form-option" value="900">15 minutes once</option>
                      <option class="form-option" value="1800">30 minutes once</option>
                      <option class="form-option" value="3600">1 hour once</option>
                      <option class="form-option" value="21600">6 hours once</option>
                      <option class="form-option" value="43200">12 hours once</option>
                      <option class="form-option" value="86400">1 day once</option>
                      <option class="form-option" value="172800">2 days once</option>
                      <option class="form-option" value="259200">3 days once</option>
                      <option class="form-option" value="604800">1 week once</option>
                    </select>
             
                  <!-- <mat-form-field >
					  <mat-select formControlName="event_message_delay">
						<mat-option value="0">No Restrictions</mat-option>
						<mat-option value="60">1 minute</mat-option>
						<mat-option value="300">5 minutes</mat-option>
						<mat-option value="900">15 minutes</mat-option>
						<mat-option value="1800">30 minutes</mat-option>
						<mat-option value="3600">1 hour</mat-option>
						<mat-option value="21600">6 hours</mat-option>
						<mat-option value="43200">12 hours</mat-option>
						<mat-option value="86400">1 day</mat-option>
						<mat-option value="172800">2 days</mat-option>
						<mat-option value="259200">3 days</mat-option>
						<mat-option value="604800">1 week</mat-option>
					  </mat-select>
					</mat-form-field> -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="event-popup-footer">
          <button
            *ngIf="!this.datastream_name"
            class="btn btn-success"
            [disabled]="this.event.invalid || this.user_Id.length === 0"
            (click)="createEmail()"
          >
            Update
          </button>
          <button
            *ngIf="this.datastream_name"
            [disabled]="this.event.invalid || this.user_Id.length === 0"
            class="btn btn-success"
            (click)="createEmail()"
          >
            Update
          </button>
          <!-- <button
			class="btn btn-success"
			*ngIf="this.switchdatastream.value.title!=null||undefined"
			(click)="updateswitch()"
		  >
			Update
		  </button> -->
        </div>
      </div>
    </div>

    <!-- NOTIFICATION NODE PROPERTIES END   -->
  </div>
</mat-sidenav>
<!-- NODE PROPERTIES SIDENAV END -->

<!-- DASHBOARD WIDGET SIDENAV START -->

<mat-sidenav #dbdesign_sidenav class="sidenav-dbdesign" position="end">
  <div class="dbsidenav">
    <!-- <mat-icon   
	style="margin-top: 10px"
	class="close-nav"
	(click)="dbdesign_sidenav.close()"
	>close</mat-icon> -->
    <div class="db-side">
      <div class="routes-buttons">
        <h1>{{ this.clustername }} Dashboard</h1>
        <div class="btn-clear cancel-db db-cancel" (click)="closedashboard()">
          Back to Routes
        </div>
        <div class="btn-clear cancel-db btn-success" (click)="Createdashbord()">
          Save
        </div>
      </div>

      <div class="widgets">
        <div class="wed-dash-right">
          <div class="dash-right-body">
            <gridster [options]="gridsterOptions" #gridsteredit>
              <gridster-item
                *ngFor="let dropwidget of dashboardwidget; let i = index"
                [item]="dropwidget"
                [cdkDragData]="dropwidget"
              >
                <div
                  class="box-head"
                  *ngIf="dropwidget?.widgetname != 'Weather'"
                >
                  <div>
                    <h2 *ngIf="dropwidget?.title == undefined || null">
                      {{ dropwidget?.widgetname }}
                    </h2>
                    <h2 *ngIf="dropwidget?.title != undefined || null">
                      {{ dropwidget?.title }}
                      <span *ngIf="dropwidget?.pin != undefined || null"
                        >({{ dropwidget?.pin }})</span
                      >
                    </h2>
                  </div>
                </div>
                <div
                  class="widget-body"
                  *ngIf="dropwidget?.widgetname == 'Switch'"
                >
                  <div>
                    <ui-switch
                      (mousedown)="$event.stopPropagation()"
                      (touchstart)="$event.stopPropagation()"
                      (change)="toggleChanges($event)"
                    ></ui-switch>
                  </div>
                </div>

                <div *ngIf="dropwidget?.widgetname == 'Slider'">
                  <ngx-slider></ngx-slider>
                </div>
                <div class="ledoff" *ngIf="dropwidget?.widgetname == 'LED'">
                  <label class="switch1">
                    <input class="chk" type="checkbox" />
                    <span class="slider"
                      ><span class="slider-off">OFF</span>
                    </span>
                  </label>
                </div>
                <div *ngIf="dropwidget?.widgetname == 'Label'">
                  <h3>--</h3>
                </div>
                <div class="gauge-container">
                  <div *ngIf="dropwidget?.widgetname == 'Gauge'">
                    <ngx-gauge
                      [thick]="25"
                      [size]="180"
                      [type]="dropwidget?.gaugeType"
                      cap="butt"
                    >
                    </ngx-gauge>
                  </div>
                </div>

                <div class="gauge-container gauge3">
                  <div *ngIf="dropwidget?.widgetname == 'Gauge3Phase'">
                    <ngx-gauge
                      [thick]="20"
                      [size]="175"
                      [type]="dropwidget?.gaugeType"
                      cap="butt"
                    >
                    </ngx-gauge>
                    <ngx-gauge
                      [thick]="20"
                      [size]="175"
                      [type]="dropwidget?.gaugeType"
                      cap="butt"
                    >
                    </ngx-gauge>
                    <ngx-gauge
                      [thick]="20"
                      [size]="175"
                      [type]="dropwidget?.gaugeType"
                      cap="butt"
                    >
                    </ngx-gauge>
                  </div>
                </div>

                <div *ngIf="dropwidget?.widgetname == 'piechart'">
                  <p-chart
                    width="550"
                    height="550"
                    #chart
                    type="pie"
                    [data]="piedata"
                    [options]="pieoptions"
                  ></p-chart>
                </div>

                <div
                  *ngIf="dropwidget?.widgetname == 'Chart'"
                  style="width: 600px"
                >
                  <!-- <p-chart
                    *ngIf="dropwidget.Min"
                    [type]="dropwidget?.chartType"
                    [data]="{
                      labels: this.chartlabel,
                      datasets: [
                        {
                          label: dropwidget.label,
                          data: [dropwidget.Min, dropwidget.Max]
                        }
                      ]
                    }"
                    [options]="chartoptions"
                  ></p-chart>
                  <p-chart
                    *ngIf="!dropwidget.Min"
                    [type]="dropwidget?.chartType"
                    [data]="basicData"
                    [options]="chartoptions"
                  ></p-chart> -->
                  <ngx-charts-bar-vertical
                    *ngIf="dropwidget?.chart_type === 'bar-vertical'"
                    class="chart-container"
                    [results]="[]"
                    [view]="
                      dropwidget?.chartfilter === 'Month'
                        ? [1300, 180]
                        : [530, 170]
                    "
                    [xAxis]="true"
                    [yAxis]="true"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [scheme]="dropwidget?.color_scheme"
                    [xAxisLabel]="dropwidget?.xAxisLabel"
                    [yAxisLabel]="dropwidget?.yAxisLabel"
                    [yScaleMin]="
                      dropwidget?.autoScale === true ? '' : dropwidget?.Min
                    "
                    [yScaleMax]="
                      dropwidget?.autoScale === true ? '' : dropwidget?.Max
                    "
                  >
                  </ngx-charts-bar-vertical>
                  <ngx-charts-line-chart
                    *ngIf="dropwidget?.chart_type === 'line-chart'"
                    [view]="
                      dropwidget?.KwhDay === 'V1' ? [1300, 180] : [600, 180]
                    "
                    class="chart-container"
                    [scheme]="dropwidget?.color_scheme"
                    [schemeType]="schemeType"
                    [results]="[]"
                    [animations]="animations"
                    [legend]="false"
                    [legendTitle]="legendTitle"
                    [legendPosition]="legendPosition"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="dropwidget.xAxisLabel"
                    [yAxisLabel]="dropwidget.yAxisLabel"
                    [autoScale]="autoScale"
                    [xScaleMin]="xScaleMin"
                    [xScaleMax]="xScaleMax"
                    [yScaleMin]="
                      dropwidget?.autoScale === true ? '' : dropwidget?.Min
                    "
                    [yScaleMax]="
                      dropwidget?.autoScale === true ? '' : dropwidget?.Max
                    "
                    [timeline]="timeline"
                    [showGridLines]="false"
                    [curve]="curve"
                    [rangeFillOpacity]="rangeFillOpacity"
                    [roundDomains]="roundDomains"
                    [tooltipDisabled]="tooltipDisabled"
                    [trimXAxisTicks]="trimXAxisTicks"
                    [trimYAxisTicks]="trimYAxisTicks"
                    [rotateXAxisTicks]="rotateXAxisTicks"
                    [maxXAxisTickLength]="maxXAxisTickLength"
                    [maxYAxisTickLength]="maxYAxisTickLength"
                    [wrapTicks]="wrapTicks"
                    [xAxisTickFormatting]="xAxisTickFormattingFunction"
                  >
                  </ngx-charts-line-chart>
                </div>
                <div
                  class="terminal-div"
                  *ngIf="dropwidget?.widgetname == 'Terminal'"
                >
                  <div class="widget-body widget-terminal">
                    <div
                      *ngIf="dropwidget?.screencolor"
                      class="success-msg"
                      [style.background]="dropwidget?.screencolor"
                    ></div>
                    <div
                      *ngIf="!dropwidget?.screencolor"
                      class="success-msg"
                      style="background-color: black"
                    ></div>
                  </div>
                  <div>
                    <input
                      *ngIf="dropwidget?.screencolor"
                      type="text"
                      class="form-control disabled"
                      [readonly]="true"
                      [style.color]="dropwidget?.textcolor"
                      [style.background]="dropwidget?.screencolor"
                      [placeholder]="dropwidget?.inputhint"
                    />
                    <input
                      *ngIf="!dropwidget?.screencolor"
                      type="text"
                      class="form-control disabled"
                      [readonly]="true"
                      style="background-color: black; color: white"
                      placeholder="Input hint"
                    />
                  </div>
                </div>
                <div
                  class="thermo"
                  *ngIf="dropwidget?.widgetname == 'Thermometer'"
                >
                  <div class="donation-meter">
                    <!-- <strong class="goal">100<sup>o</sup></strong> -->
                    <span class="glass">
                      <strong class="total" [style.bottom]="topvalue"
                        >{{ temp }}<sup>o</sup></strong
                      >
                      <span
                        class="amount"
                        [style.height]="topvalue"
                        [style.background]="tcolor"
                      ></span>
                    </span>
                    <div class="bulb">
                      <span
                        class="red-circle"
                        [style.background]="tcolor"
                      ></span>
                      <span class="filler" [style.background]="tcolor">
                        <span></span>
                      </span>
                    </div>
                  </div>
                  <div class="value">
                    <h2 [style.color]="tcolor">{{ temp }}<sup>°</sup>C</h2>
                    <p>84 ° Fahrenheit</p>
                  </div>
                </div>
                <div class="thermo" *ngIf="dropwidget?.widgetname == 'Weather'">
                  <angular-weather-widget
                    [APIKEY]="'1a517ebf85b397d774c1ff67cee37c03'"
                    [width]="'330px'"
                    [height]="'auto'"
                    [backgroundColor]="'transparent'"
                    [isBoxShadow]="true"
                    [borderRadius]="'5px'"
                    [locationFontSize]="'20px'"
                    [locationFontColor]="'#fff'"
                    [status]="false"
                    [statusFontColor]="'#fff'"
                    [statusFontSize]="'18px'"
                    [temperature]="true"
                    [tempratureFontColor]="'#fff'"
                    [tempratureFontSize]="'45px'"
                    [weatherImages]="true"
                    [weatherImageWidth]="'110px'"
                    [weatherImageHeight]="'110px'"
                    [geoLocation]="true"
                    [location]="'porur'"
                    [isWind]="true"
                    [windFontColor]="'#fff'"
                    [windFontSize]="'17px'"
                    [humidityFontColor]="'#fff'"
                    [humidityFontSize]="'17px'"
                  >
                  </angular-weather-widget>
                </div>
              </gridster-item>
              <div class="new-widget" *ngIf="this.dashboardwidget?.length == 0">
                <h1>Add new widget</h1>
                <p>Drag it to the canvas</p>
              </div>
            </gridster>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav>
<!-- DASHBOARD WIDGET SIDENAV END -->
