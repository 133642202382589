<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p
      style="font-weight: 500; text-transform: capitalize; margin: 0 !important"
    >
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>
<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
>
</mat-spinner>
<div class="content" *ngIf="productvalue?.length == 0 && isread == true &&!loading">
  <div>
    <h1 style="font-weight: 500">Start by creating your first Cluster</h1>
    <p style="font-weight: 500">
      Cluster is a digital model of a physical object. It is used in Devsbot
      platform as a Cluster to be assigned to devices.
    </p>
  </div>
  <!-- <div>
    <button class="btn btn-primary" (click)="open()">+ New Cluster</button>
  </div> -->
</div>

<div style="height: 100%;" *ngIf="!loading">
  <div class="main-head" *ngIf="this.ClusterAnalytics">
    <div class="heading-div">
      <h1 class="heading"> {{ this.clustername + " Analytics" }}</h1>
      <i
        (click)="ExportAnalytics()"
        matTooltip="Export
    Analytics(CSV)"
        id="more-vert1"
        class="fa-solid fa-file-export"
      ></i>
    </div>
    <div class="heading-div-filter" [formGroup]="range">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Select a Cluster</mat-label>
          <mat-select panelClass="myPanelClass"  formControlName="cluster_Id">
            <mat-option
              value="{{ cluster.group_id }}"
              *ngFor="let cluster of productvalue"
              >{{ cluster.group_name }} <span>({{cluster.vertical_name}})</span></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Select a Cluster</mat-label>
        <mat-select panelClass="myPanelClass"  formControlName="cluster_Id">
          <mat-option
            value="{{ cluster.group_id }}"
            *ngFor="let cluster of productvalue"
            >{{ cluster.group_name }} <span>({{cluster.vertical_name}})</span></mat-option>
        </mat-select>
      </mat-form-field> -->
   
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range <span style="color: red;">*</span> </mat-label>
        <mat-date-range-input  [rangePicker]="picker" [max]="this.enddate">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <mat-spinner
    role="progressbar"
    diameter="50"
    aria-hidden="true"
    *ngIf="Finding"
    id="spinner"
  >
  </mat-spinner>

  <div
    class="analytics_main"
    *ngIf="!Finding && ClusterAnalytics && !nodata"
  >
    <div class="charts">


      <div class="label-box">
        <div>
          <div class="card label-card">
            <h5>
              <iconify-icon
                style="background-color: #2b9de2"
                icon="simple-line-icons:energy"
              ></iconify-icon>
              Total Energy
            </h5>
            <h3>{{ this.TotalKwh }} kWh</h3>
          </div>
          <div class="card label-card">
            <h5>
              <iconify-icon
                style="background-color: #ea8a59"
                icon="material-symbols:attach-money"
              ></iconify-icon>
              Total Cost
            </h5>
            <h3>₹ {{ this.TotalCost }}</h3>
          </div>
        </div>
        <div>
          <div  class="card label-card">
            <h5>
              <iconify-icon
                style="background-color: #34ac32"
                icon="mdi:molecule-co2"
              ></iconify-icon>
              Co2 Emission
            </h5>
            <h3>{{ this.TotalCo2 }} tCO2</h3>
          </div>
          <div class="card label-card">
            <h5>
              <iconify-icon  style="background-color: #cb56d8" icon="prime:chart-line"></iconify-icon>
              AVG Energy Consumed
            </h5>
            <h3>{{ this.AvgKwh }} kWh</h3>
          </div>
        </div>
      </div>

    

    </div>
    <!-- label  -->
    <div class="main-box">
     
      <div class="card">
        <h5 style="background-color: white; position: sticky; top: 0px">
          Energy Consumption
        </h5>
        <div class="no-data" *ngIf="novalue">
          <mat-icon> insert_drive_file</mat-icon>
          <h6>No Data</h6>
        </div>
      <ngx-charts-advanced-pie-chart  *ngIf="!novalue" label="Total kWh"  [view]="[680, 300]" [scheme]="colorScheme"   [results]="this.Clusterpiechartdata"
      >
     </ngx-charts-advanced-pie-chart>
<!-- pie chart will come  -->
</div>

      <div class="table-box">
        <div class="tabel">
          <div class="card tabel-card">
            <h5 style="background-color: white; position: sticky; top: 0px">
              High Energy Consumed Devices
            </h5>
            <div style="padding: 0px 30px">
              <table>
                <thead>
                  <tr>
                    <th>Device name</th>
                    <th>kWh</th>
                    <th>Co2</th>
                    <th>Cost</th>
                    <!-- <th>Time</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of Tabledata"
                    (click)="
                      GetDeviceAnalytics(item.device_id, item.device_name)
                    "
                  >
                    <td>{{ item.device_name }}</td>
                    <td>{{ item.kwh + " kWh" }}</td>
                    <td>{{ item.co2 + " tCO2" }}</td>
                    <td>{{ "₹ " + item.cost }}</td>
                    <!-- <td style="text-align: center">
                      {{ item.updated_time | date : "short" }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content" *ngIf="nodata">
    <h1>No Data Found</h1>
  </div>

  
  <ng-container *ngIf="!ClusterAnalytics">
    <app-deviceanalytics
      (messageEvent)="receiveMessage($event)"
      [devicedata]="devicedata"
    >
    </app-deviceanalytics>
  </ng-container>
</div>
