import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from './services/dataservice';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './Authentication/authentication.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { deletepopup } from './mydevices/mydevices.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from './socket.io/socket.service';
import { SwPush } from '@angular/service-worker';
import { subscribeOn } from 'rxjs';
import { HttpErrorInterceptor } from './services/HttpErrorInterceptor'





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Devsbot';
  id: any;
  user_Id: any;
  data: any;
  language: any;
  roles: any;
  locationreload: any;
  productvalue: any;
  path: any;
  // HttpErrorIntercseptor:any;
  constructor(
    private dataservice: Dataservice,
    private router: Router,
    public matdialog: MatDialog,
    public translate: TranslateService,
    private toastr: ToastrService,
    private authentication: AuthenticationService,
    private activateroute: ActivatedRoute,
    private socketService: SocketService,
    private HttpErrorInterceptor:HttpErrorInterceptor


  ) {
    this.locationreload = false;

  }

  ngOnInit(): void {
   

    const data = this.authentication.getUserData();
  
    this.Getproductdata(data)
    this.dataservice.ClusterCreate.subscribe((response: any) => {
      this.Getproductdata(data)
    });


    setInterval(() => {
      
      if (this.locationreload && navigator.onLine) {
        
        window.location.reload();
      
        this.locationreload = false;
      }
      if (!navigator.onLine) {
       
        this.locationreload = true;
      }
    }, 5000);


  }




  Getproductdata(data: any) {


    // GET DEVICEPAGE  PRODUCT DATA
    this.dataservice.getdevicepageproductdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;

        this.productvalue.map((x: any) => {
          this.socketService.emit('EventChannelUser', {
            cluster_api_Id: x.cluster_api_Id,
            user_Id: data.user_Id,
          });
          this.socketService.emit('EventChannel', {
            cluster_api_Id: x.cluster_api_Id,
            user_Id: data.user_Id,
          });
        });
       
      } else {
        this.toastr.error('Error occurred');
      }
    });

    
  }






}
