<!-- digital datastream start-->
<div class="datastream-popup-main widget-create-datastream-body " *ngIf="this.name=='Digital'">
    <div class="datastream-popup-header">
        <div>
            <h1>Digital Datastream</h1>
        </div>
    </div>
    <div>

        <form [formGroup]="digitaldatastream">
            <div class="widget-datastream-popup-body">
                <div class="dbl-input-first">
                    <div class="icon-picker-div" (click)="iconpicker.click()">
                        <div class="input-group" (click)="iconpicker.click()">
                            <span class="input-group-addon" (click)="iconpicker.click()"><i
                                    [ngClass]="iconCss.value"></i></span>
                            <input type="text" #iconpicker class="form-control" style="opacity: 0; cursor: pointer;"
                                formControlName="iconCss" [iconPicker]="iconCss.value" [ipIconPack]="icons"
                                [ipPosition]="'bottom'" [ipWidth]="'250px'" [ipIconSize]="'16px'"
                                [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'"
                                [ipKeepSearchFilter]="'false'" [ipPlaceHolder]="'Choose an icon'"
                                [ipFallbackIcon]="fallbackIcon" (iconPickerSelect)="onIconPickerSelect($event)" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Name</label>
                        <input class="form-control" formControlName="name" type="text">
                        <div *ngIf="digitaldatastream.controls['name'].dirty">
                            <span *ngIf="digitaldatastream.controls['name']?.errors?.['required']" style="color: red;">
                                *Name is required.
                            </span>
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Alias</label>
                        <input class="form-control" formControlName="alias" type="text">
                        <div *ngIf="digitaldatastream.controls['alias'].dirty">
                            <span *ngIf="digitaldatastream.controls['alias']?.errors?.['required']" style="color: red;">
                                *Alias is required.
                            </span>
                        </div>
                    </div>
                    <div class="color-picker-div">
                        <input class="color-picker" [(colorPicker)]="color" [style.background]="color"
                            [cpPresetLabel]="this.color" formControlName="color" [cpCmykEnabled]="true" />
                    </div>
                </div>

                <div class="dbl-input-second">
                    <div class="form-field">
                        <label for="">Pin</label>
                        <select name="" id="" class="form-select" formControlName="pin" onfocus='this.size=5;'
                            onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                            <option class="form-option" [selected]="list=='0'" [value]="list"
                                *ngFor="let list of Digitalpin">{{list}}</option>
                        </select>
                    </div>
                    <div class="form-field">
                        <label for="">PinMode</label>
                        <select class="form-select" name="" id="" formControlName="pinmode">
                            <option class="form-option" [value]="pinout" *ngFor="let pinout of pinmode">{{pinout}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
        <div class="datastream-popup-footer">
            <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
            <button class="btn btn-success" [disabled]="this.digitaldatastream.invalid" (click)="Digital()">Create</button>
        </div>
    </div>
</div>
<!-- digital datastream end -->

<!-- Analog datastream html start  -->
<div class="datastream-popup-main widget-create-datastream-body" *ngIf="this.name=='Analog'">
    <div class="datastream-popup-header">
        <div>
            <h1>Analog Datastream</h1>
        </div>
    </div>
    <div>

        <form [formGroup]="analogdatastream">
            <div class="widget-datastream-popup-body">
                <div class="dbl-input-first">
                    <div class="icon-picker-div">
                        <div class="input-group" (click)="iconpicker.click()">
                            <span class="input-group-addon" (click)="iconpicker.click()"><i
                                    [ngClass]="iconCss.value"></i></span>
                            <input type="text" name="iconCss" #iconpicker class="form-control" style="opacity: 0;"
                                formControlName="iconCss" [iconPicker]="iconCss.value" [ipIconPack]="icons"
                                [ipPosition]="'bottom'" [ipWidth]="'250px'" [ipIconSize]="'16px'"
                                [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'"
                                [ipKeepSearchFilter]="'false'" [ipPlaceHolder]="'Choose an icon'"
                                [ipFallbackIcon]="fallbackIcon" (iconPickerSelect)="onIconPickerSelect($event)" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Name</label>
                        <input class="form-control" formControlName="name" type="text">
                        <div *ngIf="analogdatastream.controls['name'].dirty">
                            <span *ngIf="analogdatastream.controls['name']?.errors?.['required']" style="color: red;">
                                *Name is required.
                            </span>
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Alias</label>
                        <input class="form-control" formControlName="alias" type="text">
                        <div *ngIf="analogdatastream.controls['alias'].dirty">
                            <span *ngIf="analogdatastream.controls['alias']?.errors?.['required']" style="color: red;">
                                *Alias is required.
                            </span>
                        </div>
                    </div>
                    <div class="color-picker-div">
                        <input class="color-picker" formControlName="color" [(colorPicker)]="color"
                            [style.background]="this.color" [cpPresetLabel]="this.color" [cpCmykEnabled]="true" />
                    </div>
                </div>

                <div class="dbl-input-second">
                    <div class="form-field">
                        <label for="">Pin</label>
                        <select name="" id="" *ngIf="this.analogdatastream.controls.pinmode.value=='Output'"
                            class="form-select" formControlName="pin" onfocus='this.size=5;' onblur='this.size=1;'
                            onchange='this.size=1; this.blur();'>
                            <option class="form-option" [selected]="list=='A0'" [value]="list"
                                *ngFor="let list of AnalogpinOut">{{list}}
                            </option>
                        </select>
                        <select *ngIf="this.analogdatastream.controls.pinmode.value=='Input'" name="" id=""
                            class="form-select" formControlName="pin" onfocus='this.size=5;' onblur='this.size=1;'
                            onchange='this.size=1; this.blur();'>
                            <option class="form-option" [selected]="list=='A0'" [value]="list"
                                *ngFor="let list of AnalogpinIn">{{list}}
                            </option>
                        </select>
                    </div>
                    <div class="form-field">
                        <label for="">PinMode</label>
                        <select class="form-select" formControlName="pinmode">
                            <option class="form-option" [value]="pinout" *ngFor="let pinout of pinmode">{{pinout}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-field">
                    <div>
                        <label for="">Units</label>
                        <select class="form-select" formControlName="units">
                            <option value="" class="form-option" [value]="unit.unit" *ngFor="let unit of units">
                                {{unit.unit}}</option>
                        </select>
                    </div>
                </div>
                <div class="three-input">

                    <div class="form-field">
                        <label for="">Min</label>
                        <input class="form-control" type="number" formControlName="min">
                    </div>
                    <div class="form-field">
                        <label for="">Max</label>
                        <input class="form-control" type="number" formControlName="max">
                    </div>

                    <div class="form-field">
                        <div>
                            <label for="">Default Value</label>
                            <input class="form-control" type="number" formControlName="default_value">
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="datastream-popup-footer">
            <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
            <button class="btn btn-success" [disabled]="this.analogdatastream.invalid" (click)="Analog()">Create</button>
        </div>
    </div>
</div>

<!-- Analog datastream html end  -->



<!-- virtualpin datastream start -->

<div class="datastream-popup-main widget-create-datastream-body" *ngIf="this.name=='Virtual Pin'">
    <div class="datastream-popup-header">
        <div>
            <h1>Virtual Pin Datastream</h1>
        </div>
    </div>
    <div>

        <form [formGroup]="virtualpindatastream">
            <div class="widget-datastream-popup-body">
                <div class="dbl-input-first">
                    <div class="icon-picker-div">
                        <div class="input-group" (click)="iconpicker.click()">
                            <span class="input-group-addon" (click)="iconpicker.click()"><i
                                    [ngClass]="iconCss.value"></i></span>
                            <input type="text" name="iconCss" #iconpicker class="form-control" style="opacity: 0;"
                                formControlName="iconCss" [iconPicker]="iconCss.value" [ipIconPack]="icons"
                                [ipPosition]="'bottom'" [ipWidth]="'250px'" [ipIconSize]="'16px'"
                                [ipIconVerticalPadding]="'6px'" [ipIconHorizontalPadding]="'10px'"
                                [ipKeepSearchFilter]="'false'" [ipPlaceHolder]="'Choose an icon'"
                                [ipFallbackIcon]="fallbackIcon" (iconPickerSelect)="onIconPickerSelect($event)" />
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Name</label>
                        <input class="form-control" formControlName="name" type="text">
                        <div *ngIf="virtualpindatastream.controls['name'].dirty">
                            <span *ngIf="virtualpindatastream.controls['name']?.errors?.['required']"
                                style="color: red;">
                                *Name is required.
                            </span>
                        </div>
                    </div>
                    <div class="form-field">
                        <label for="">Alias</label>
                        <input class="form-control" formControlName="alias" type="text">
                        <div *ngIf="virtualpindatastream.controls['alias'].dirty">
                            <span *ngIf="virtualpindatastream.controls['alias']?.errors?.['required']"
                                style="color: red;">
                                *Alias is required.
                            </span>
                        </div>
                    </div>
                    <div class="color-picker-div">
                        <input class="color-picker" formControlName="color" [(colorPicker)]="color"
                            [style.background]="color" [cpPresetLabel]="this.color" [cpCmykEnabled]="true" />
                    </div>
                </div>

                <div class="dbl-input-second">
                    <div class="form-field">
                        <label for="">Pin</label>
                        <select name="" id="" class="form-select" formControlName="pin" onfocus='this.size=5;'
                            onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                            <option class="form-option" [value]="list" [selected]="list=='V0'"
                                *ngFor="let list of Virtualpin">
                                {{list}}</option>
                        </select>
                    </div>
                    <div class="form-field">
                        <label for="">Data Type</label>
                        <select class="form-select" formControlName="datastream_type">
                            <option class="form-option" [value]="type" *ngFor="let type of datastream_type">{{type}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-field">
                    <div>
                        <label for="">Units</label>
                        <select class="form-select" formControlName="units"  [attr.disabled]="state ? '' : null">
                            <option value="" class="form-option" [value]="unit.unit" *ngFor="let unit of units">
                                {{unit.unit}}</option>
                        </select>
                    </div>
                </div>
                <div class="three-input" *ngIf="this.virtualpindatastream.controls.datastream_type.value=='Integer'">
                    <div class="form-field">
                        <label for="">Min</label>
                        <input class="form-control" type="number" formControlName="min">
                    </div>
                    <div class="form-field">
                        <label for="">Max</label>
                        <input class="form-control" type="number" formControlName="max">
                    </div>

                    <div class="form-field">
                        <div>
                            <label>Default Value</label>
                            <input class="form-control" type="number" formControlName="default_value">
                        </div>
                    </div>
                </div>
                <div class="three-input" *ngIf="this.virtualpindatastream.controls.datastream_type.value=='Double'">
                    <div class="form-field">
                        <label for="">Min</label>
                        <input class="form-control" type="number" formControlName="min">
                    </div>
                    <div class="form-field">
                        <label for="">Max</label>
                        <input class="form-control" type="number" formControlName="max">
                    </div>
                    <div class="form-field">
                        <label for="">Decimal</label>
                        <select name="" id="" class="form-select" formControlName="decimal_value">
                            <option [value]="decimal" class="form-option" *ngFor="let decimal of decimallist">
                                {{decimal}}</option>
                        </select>
                    </div>


                    <div class="form-field">
                        <div>
                            <label for="">Default Value</label>
                            <input class="form-control" type="number" formControlName="default_value">
                        </div>
                    </div>
                </div>
                <div class="form-field" *ngIf="this.virtualpindatastream.controls.datastream_type.value=='String'">
                    <div>
                        <label for="">Default Value</label>
                        <input class="form-control" type="text" formControlName="default_value">
                    </div>
                </div>
            </div>
        </form>
        <div class="datastream-popup-footer">
            <button class="btn btn-danger" (click)="MatdialogClose()">Cancel</button>
            <button class="btn btn-success" [disabled]="this.virtualpindatastream.invalid" (click)="Virtual()">Create</button>
        </div>
    </div>
</div>
<!-- virtualpin datastream end -->