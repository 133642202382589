<div class="content" *ngIf="isread == false">
    <div>
      <h1 style="font-weight: 500">Access to this page has been restricted</h1>
      <p style="font-weight: 500;text-transform: capitalize;margin: 0 !important;">
        Sorry, you do not have the necessary privileges to access this feature.
        Please contact the Oraganization's administrator if you require additional
        permissions.
      </p>
    </div>
  </div>

<div class="content" *ngIf="isread == true">
    <div><h1 style="font-weight: 500;">Downloads</h1>
    <p style="font-weight: 500;">Downloads allows you to easily obtain the latest firmware files for your devices.</p></div>
        <button  class="bn632-hover bn26">UPGRADE TO PREMIUM <i class="fas fa-crown"></i></button>
</div>


