<!-- user Information start -->
<div class="info-main-user">
  <div class="close-icon" (click)="backClicked()">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <div class="info-head">
    <div class="info-head-content">
      <div class="userprofile-logo" *ngIf="userdata?.user_profile?.length <= 0">
        {{ userdata?.firstname?.slice(0, 1) | uppercase }}
      </div>
      <div
        class="userprofile-logo-img"
        *ngIf="userdata?.user_profile?.length >= 1"
      >
        <img
          src="../../assets/user/{{ userdata?.user_profile }}"
          class="profile-img-sidenav"
          alt=""
        />
      </div>
      <span class="username">{{ userdata?.firstname }}</span>
      <div>
        <i
          class="fa-solid fa-ellipsis"
          [matMenuTriggerFor]="user"
          id="more-vert"
        ></i>
        <mat-menu #user="matMenu">
          <button mat-menu-item class="mat-menu-button" (click)="useredit()">
            <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit
          </button>
          <!-- <button
            mat-menu-item
            class="mat-menu-button"
            (click)="changepassword()"
          >
            <mat-icon class="menu-btn-icon">lock</mat-icon>Change password
          </button> -->
          <!-- <button mat-menu-item class="mat-menu-button">
                        <mat-icon class="menu-btn-icon">send</mat-icon>Send
                        Device Log
                      </button> -->
        </mat-menu>
      </div>
    </div>
    <div>
      <button class="btn btn-danger" (click)="forcelogout(this.user_Id)">
        <mat-icon>logout</mat-icon>Force Logout
      </button>
    </div>
  </div>
  <div class="info-main-content">
    <mat-tab-group
      [selectedIndex]="selectedTabIndex"
      (selectedTabChange)="tabClick($event)"
    >
      <mat-tab label="Information">
        <div class="info-content">
          <h3>Status</h3>
          <div class="Active" *ngIf="userdata?.isactive == 0">
            <div class="userActive"></div>
            Active
          </div>
          <div class="inActive" *ngIf="userdata?.isactive == 1">
            <div class="userInActive"></div>
            Suspended
          </div>
        </div>
        <div class="info-content">
          <h3>Email</h3>
          <p style="text-transform: none">{{ userdata?.email_id }}</p>
        </div>
        <div class="info-content">
          <h3>Role</h3>
          <p>{{ userdata?.role_name }}</p>
        </div>
        <div class="info-content">
          <h3>Last Login</h3>
          <p>{{ userdata?.lastlogin | date : "medium" }}</p>
        </div>
        <div class="info-content">
          <h3>Registration date</h3>
          <p>{{ userdata?.reg_date | date : "medium" }}</p>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Events Log"> </mat-tab> -->
      <!-- <mat-tab label="Devices">
        <table>
          <thead>
            <tr>
              <th id="checkbox" class="th-sn">S.No</th>
              <th>Device Name</th>
              <th style="text-align: center">Status</th>
              <th>Device Model</th>
              <th>Cluster Name</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                            let data of devicevalue
                              | filter : filterTerm
                              | paginate : usertableconfig;
                            let i = index
                          "
              (click)="deviceinfo(data.device_id,data.cluster_id)"
            >
              <td class="sno">
                {{ (this.usertableconfig.currentPage - 1) * 6 + i + 1 }}
              </td>
              <td>
                <span
                  class="data-info"
                  (click)="deviceinfo(data.device_id, data.cluster_id)"
                  >{{ data.device_name }}</span
                >
              </td>
              <td (click)="deviceinfo(data.device_id, data.cluster_id)">
                <div>
                  <mat-spinner
                    style="margin-left: 40px"
                    role="progressbar"
                    diameter="25"
                    aria-hidden="true"
                    *ngIf="statusloading"
                  >
                  </mat-spinner>
                </div>
                <div
                  class="offline"
                  *ngIf="
                    +this.currenttime - +data.last_online > 100 * data.heartbeat ||
                    data.status_Id == 0
                  "
                  matTooltip="Last Online Since {{
                    data.last_online | date : 'short'
                  }}"
                  matTooltipPosition="left"
                >
                  Offline
                </div>
                <div
                  class="online"
                  *ngIf="
                    +this.currenttime - +data.last_online < 100 * data.heartbeat &&
                    data.status_Id == 1
                  "
                >
                  Online
                </div>
              </td>
              <td (click)="deviceinfo(data.device_id, data.cluster_id)">
                <span>{{ data.hardware_name }}</span>
              </td>
              <td (click)="deviceinfo(data.device_id, data.cluster_id)">
                <span>{{ data.cluster_name }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls
          id="user-device"
          class="pagination"
          (pageChange)="userdevicetable($event)"
        ></pagination-controls>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>

<!-- user Information end -->
