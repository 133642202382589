<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
></mat-spinner>
<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>
<div class="content" *ngIf="this.filterallUservalue?.length<=0 && !loading &&isread == true">
  <div>
    <h1 style="font-weight: 500">All of your My Vertical Users will be here.</h1>
   
  </div>

  <div class="newdev-alldev">
    <button class="btn btn-success"  routerLink="/app/myverticalmembers">
      My Vertical
    </button>
  </div>
</div>
<div class="main" *ngIf="!loading && isread == true">
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <div class="main-head">
        <div>
          <h1>All vertical Members</h1>
        </div>
      </div>
      <div class="count-user">
        <div>
          <span class="count-user-font" *ngIf="this.user_Id.length<=0">
            <h4>
              No Of Users : {{filterallUservalue?.length}} &nbsp;
              <i  class="fa-solid fa-file-export"   matTooltip="Export
              User(CSV)"
              matTooltipPosition="below" (click)="verticalallusercsv()" ></i>
            </h4>
          </span>
          <span class="count-user-font" *ngIf="this.user_Id.length>=1">
            <h4>
              No Of Users Selected : {{this.user_Id.length}} &nbsp;
              <i
                class="fa-solid fa-ellipsis"
                [matMenuTriggerFor]="userinactive"
            
              ></i>
            </h4>
          </span>
        </div>
        <div>
         
          <mat-menu #userinactive="matMenu">
            <button
              mat-menu-item
              class="mat-menu-button"
              (click)="multipleusersuspend()"
            >
              <mat-icon class="menu-btn-icon">not_interested</mat-icon>Suspend
              User
            </button>
            <button
              mat-menu-item
              class="mat-menu-button"
              (click)="multipleuseractive()"
            >
              <mat-icon class="menu-btn-icon">refresh</mat-icon>Reactivate User
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex new-dev-sec">
      <div class="buttons">
        <button class="btn btn-primary new-dev" (click)="inviteuser()">
          Create user <i class="fa-solid fa-plus"></i></button
        ><button
          class="btn btn-success all-dev"
          routerLink="/app/myverticalmembers"
        >
          My Vertical Members
        </button>
      </div>
      <div class="search-div">
        <input
          type="search"
          #search
          class="form-control"
          placeholder="Search Users"
          [(ngModel)]="filterTerm"
          (ngModelChange)="onFilterTermChange()"
        />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      </div>
    </div>
  </div>

  <ng-container *ngIf="filterallUservalue | filter: filterTerm as result">
    
  <div *ngIf="result.length>=1" class="main-body" [ngClass]="{'tableextract':this.config.itemsPerPage>6}">
    <table>
      <thead>
        <tr>
          <th id="checkbox">
            <mat-checkbox
              (click)="validatepermission($event)"
              color="primary"
              (change)="allCheckboxChange($event,result)"
              [indeterminate]="
            this.user_Id.length >= 1 &&
            this.allselect==false
          "
              [checked]="this.allselect==true"
            >
            </mat-checkbox>
          </th>

          <th>User Name</th>
          <th>User Email</th>
          <th>
            User Status
            <button [matMenuTriggerFor]="menu1">
              <iconify-icon *ngIf="this.selectedCategoriesstatus.size!=this.uniquestatus.length" class="search_icon"
                icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div *ngIf="this.selectedCategoriesvertical.size!=this.verticalvalues.length"[ngClass]="{'activefilters':this.selectedCategoriesvertical.size!=this.verticalvalues.length}" ></div> -->
              <iconify-icon *ngIf="this.selectedCategoriesstatus.size==this.uniquestatus.length" class="search_icon"
                icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu class="filter-menus" fittingWidth="fit" class="productfilter" #menu1="matMenu" xPosition="after"
              yPosition="below">
              <div class="drop-value">
                <section style="height: 50px" class="example-section" *ngFor="let value of uniquestatus">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterstatus(value.isactive, $event)">
                    
                    <div *ngIf="value.isactive == 0 &&value.is_verify==1" class="status">
                      <div>Active</div>
                    </div>
                    
                    <div *ngIf="value.isactive == 1 &&value.is_verify==1" class="status">
                      <div>Suspended</div>
                      </div>

                      <div *ngIf="value.is_verify==0" class="alluserPending status">
                   <div>Pending</div> 
                   </div>
                  </mat-checkbox>

                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(true,'status')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(false,'status')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th id="role">
            User Role
            <button [matMenuTriggerFor]="menu2">
              <iconify-icon *ngIf="this.selectedCategoriesroll.size!=this.uniqueRollName.length" class="search_icon"
                icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div *ngIf="this.selectedCategoriesvertical.size!=this.verticalvalues.length"[ngClass]="{'activefilters':this.selectedCategoriesvertical.size!=this.verticalvalues.length}" ></div> -->
              <iconify-icon *ngIf="this.selectedCategoriesroll.size==this.uniqueRollName.length" class="search_icon"
                icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu class="filter-menus" fittingWidth="fit" class="productfilter" #menu2="matMenu" xPosition="after"
              yPosition="below">
              <!-- <button  mat-menu-item></button> -->
              <!-- <mat-radio-button value="1">{{ item.device_name }}</mat-radio-button> -->
              <div class="drop-value">
                <section style="height: 50px" class="example-section" *ngFor="let value of uniqueRollName">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterroleName(value.role_name, $event)"><span
                      class="ellipse">{{
                      value.role_name == null ? "Roles without User" : value.role_name
                      }}</span></mat-checkbox>

                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(true,'role')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(false,'role')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th>
            User Organization
       
            
          </th>
          <th>
            User Vertical
            <button [matMenuTriggerFor]="menu3">
              <iconify-icon *ngIf="this.selectedCategoriesvertical.size!=this.uniqueverticalname.length"
                class="search_icon" icon="material-symbols:filter-alt"></iconify-icon>
              <!-- <div *ngIf="this.selectedCategoriesvertical.size!=this.verticalvalues.length"[ngClass]="{'activefilters':this.selectedCategoriesvertical.size!=this.verticalvalues.length}" ></div> -->
              <iconify-icon *ngIf="this.selectedCategoriesvertical.size==this.uniqueverticalname.length"
                class="search_icon" icon="ion:filter-outline"></iconify-icon>
            </button>
            <mat-menu class="filter-menus" fittingWidth="fit" class="productfilter" #menu3="matMenu" xPosition="after"
              yPosition="below">
              <div class="drop-value">
                <section style="height: 50px" class="example-section" *ngFor="let value of uniqueverticalname">
                  <mat-checkbox color="primary" [checked]="value.ischecked" class="checkbox-ellipse"
                    (click)="$event.stopPropagation()" (change)="filterVerticalName(value.vertical_name, $event)"
                    matTooltip="{{ value.vertical_name }}" matTooltipPosition="left"><span class="ellipse">{{
                      value.vertical_name == null ? 'Proprietor':value.vertical_name
                      }}</span></mat-checkbox>

                  <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
                </section>
              </div>
              <div class="drop-btn d-flex">
                <button class="btn btn-primary" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(true,'vertical')">
                  Select All
                </button>
                <button class="btn btn-danger" (click)="$event.stopPropagation()"
                  (click)="SelectallFilters(false,'vertical')">
                  clear
                </button>
              </div>
            </mat-menu>
          </th>
          <th>User PhoneNumber</th>
          <th>User registraion date</th>

          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of result|paginate:config"
        >
          <td id="checkbox">
            <div
              class="profilelogo"
              *ngIf="item.user_profile?.length <= 0"
              [ngClass]="{
              'profilelogo-hide':
                item.isSelected == true || this.checked == true,
              'profilelogo': item.isSelected == false
            }"
            >
              {{ item.firstname.slice(0, 1) | uppercase }}
            </div>
            <!-- <div *ngIf="item.user_profile?.length>=1" class="profilelogo-img">
                          <img src="../../assets/user/{{item.user_profile}}" class="profile-img-table" alt="">
                      </div> -->
            <mat-checkbox
              (click)="validatepermission($event)"
              class="example-margin"
              (change)="onCheckboxChange($event, item.user_Id,result)"
              [checked]="item.isSelected"
              color="primary"
              [ngClass]="{
              'example-margin': this.user_Id.length <= 0
            }"
            ></mat-checkbox>
          </td>

          <td (click)="userinfo(item.user_Id)">
            <span class="data-info">{{item.firstname}}</span>
          </td>

          <td (click)="userinfo(item.user_Id)">
            <span class="data-content">{{item.email_id}}</span>
          </td>
          <td (click)="userinfo(item.user_Id)">
            <div *ngIf="item.isactive == 0&&item.is_verify==1" class="status">
              <!-- <div class="userActive"></div>Active -->
              <div class="active-btn">Active</div>
            </div>
            <div *ngIf="item.isactive == 1&&item.is_verify==1" class="status">
              <!-- <div class="userInActive"></div>Suspended -->
              <div class="suspend-btn">Suspended</div>
            </div>
            <div *ngIf="item.is_verify==0" class="status">
              <!-- <div class="userInActive"></div>Suspended -->
              <div class="pending-btn">Pending</div>
            </div>
          </td>
          <td class="role" (click)="userinfo(item.user_Id)">
            <span class="data-info" (click)="roles.open()">
              <span [ngClass]="{ 'deleted-role': !item.role_name }">
                {{ item.role_name ? item.role_name : "Role has been deleted" }}
              </span>
            </span>
          </td>
          <td (click)="userinfo(item.user_Id)">
            <span class="data-info">{{item.org_Name}}</span>
          </td>
          <td (click)="userinfo(item.user_Id)">
            <span class="data-info" *ngIf="item.vertical_name != null">{{
              item.vertical_name
              }}</span>
            <span class="data-info" style="font-weight: 600 !important"
              *ngIf="item.vertical_name == null">Proprietor</span>
          </td>
          <!-- <td (click)="info(item.user_Id)" (click)="userinfo.open()"><span
                            class="data-content">{{item.lastlogin|date:"medium"}}</span>
                    </td> -->
          <td (click)="userinfo(item.user_Id)">
            <span class="data-content">{{item.phone_num}}</span>
          </td>
          <td (click)="userinfo(item.user_Id)">
            <span class="data-content">{{item.reg_date|date:"medium"}}</span>
          </td>
          <td class="action">
            <!-- <mat-icon [matMenuTriggerFor]="menu" (click)="info(item.user_Id)" class="action-icon">more_vert
                        </mat-icon> -->
            <i *ngIf="!this.checked"
              [ngClass]="{ 'disabled': user_Id.length === 6 ||this.checked }"
              class="fa-solid fa-ellipsis action-icon"
              [matMenuTriggerFor]="menu"
              id="more-vert"
              (click)="info(item.user_Id)"
            ></i>
            <mat-menu #menu="matMenu" (click)="info(item.user_Id)">
              <button
                mat-menu-item
                class="mat-menu-button"
                (click)="info(item.user_Id)"
                (click)="edituser()"
              >
                <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit
              </button>
              
              <button mat-menu-item class="mat-menu-button" (click)="forcelogout(item.user_Id)">
                <mat-icon class="menu-btn-icon">logout</mat-icon>Force Logout
              </button>
             
              <button
                mat-menu-item
                class="mat-menu-button"
                (click)="suspendeduser(item.user_Id)"
                *ngIf="item.isactive==0"
              >
                <mat-icon class="menu-btn-icon">not_interested</mat-icon>Suspend
                User
              </button>
              <button
              mat-menu-item
              class="mat-menu-button"
              (click)="item.role_name ? activeuser(item.user_Id) : edituser()"
              *ngIf="item.isactive == 1"
            >
              <mat-icon class="menu-btn-icon">refresh</mat-icon>Reactivate
              User
            </button>
              <!-- <button mat-menu-item class="mat-menu-button" >
                                <mat-icon class="menu-btn-icon">delete</mat-icon>Delete Account
                            </button> -->
                            <button mat-menu-item *ngIf="item.isactive == 1 || item.is_verify == 0" class="mat-menu-button"
                            (click)="userRemove(item.user_Id)">
                            <mat-icon class="menu-btn-icon">delete</mat-icon>Delete
                            User
                          </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      class="pagination"
      [ngClass]="{ 'disabled': user_Id.length === 6 || this.checked,paginationoff:this.config.itemsPerPage>6  }"
      (pageChange)="pageChanged($event)"
    ></pagination-controls>

    <span [ngClass]="{ disabled: user_Id.length === 6 || this.checked || this.filterallUservalue?.length <6}" *ngIf="this.config.itemsPerPage<=6" (click)="showall()" class="pagination showall">
      Show All
    </span>
    <span  [ngClass]="{ disabled: user_Id.length === 6 || this.checked,collapsetable:this.config.itemsPerPage>6}" *ngIf="this.config.itemsPerPage>6" (click)="collapse()" class="pagination showall">
      Collapse 
    </span>

  </div>
  <mat-sidenav position="end" #roles style="width: 500px">
    <div class="end-sidenav-header">
      <mat-icon (click)="roles.close()" class="end-sidenav-icon"
        >arrow_back</mat-icon
      >
      <h1>Roles and permission</h1>
    </div>
  </mat-sidenav>

  <div class="nodatacontent" *ngIf="result.length<=0">

    <iconify-icon icon="mdi:user"></iconify-icon>
    <h1>No Data Found</h1>
  </div>
</ng-container>

</div>
