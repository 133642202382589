<!-- formproduct mat dialog view  -->
<!-- main  -->
<!--  -->
<div class="matdialog-head tab-head" *ngIf="!this.flag">
  <div>
    <h1>
      Create New Device
      <mat-icon
        class="instructions"
        matTooltipClass="my-tooltip"
        matTooltip="Create a single or Multiple device with Mac-ID, QR code and Web Zone"
        matTooltipPosition="right"
        >info
      </mat-icon>
    </h1>
    <p
      style="padding: 0px 0px 0px 10px; margin: 0 !important; font-weight: 500"
    >
      Create new devices by filling in the form below
    </p>
  </div>
  <div>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
</div>
<mat-tab-group *ngIf="!this.flag"
  [selectedIndex]="selectedTabIndex"
  mat-stretch-tabs="false"
  mat-align-tabs="center"
  animationDuration="0ms"
>
  <!-- single devices upload -->
  <mat-tab label="Single Device">
    <div class="matdialog-main" style="width: 670px">
      <div class="matdialog-content">
        <form [formGroup]="device">
<!--           
          <div class="form-field">
            <label class="form-label">Device Type</label>
            <select class="form-select" formControlName="Devicetype">
              <option value="" [selected]="true">Select Device Type</option>
              <option
                class="form-option"
                value= "0"
               
              >
                Gateway
              </option>
              <option
                class="form-option"
                value= "1"
               
              >
                Slave
              </option>
            </select>
            <div
              class="validation"
              *ngIf="device.controls['Devicetype'].invalid && (device.controls['Devicetype'].dirty)"
            >
              <span
                *ngIf="device.controls['Devicetype']?.errors"
                style="color: red"
              >
                *Select Device Type
              </span>
            </div>
          </div> -->
          <div class="form-field">
            <label class="form-label">Gateway <span style="color:red">*</span></label>
            <h3 class="sub-heading" *ngIf="productvalue?.length==0">
              This Organization has no Gateway
            </h3>
            <select (change)="onSelectChange($event)" class="form-select" formControlName="product">
              <option value="" [selected]="true">Select the Gateway</option>
              <option
                class="form-option"
                value="{{item.cluster_id}}"
                *ngFor="let item of productvalue"
              >
                {{item.cluster_name}}
              </option>
            </select>
            <div
              class="validation"
              *ngIf="device.controls['product'].invalid && (device.controls['product'].dirty)"
            >
              <span
                *ngIf="device.controls['product']?.errors"
                style="color: red"
              >
                *Select The Gateway
              </span>
            </div>
          </div>


          <!-- <div class="form-field"  *ngIf="device.controls['Devicetype'].value === '1'">
            <label class="form-label">Gateway</label>
            <h3 class="sub-heading" *ngIf="productvalue?.length==0">
              This Organization has no Gateway
            </h3>
            <select (change)="onSelectChange($event)" class="form-select" formControlName="gateway">
              <option value="" [selected]="true">Select the Gateway</option>
              <option
                class="form-option"
                value="{{item.device_auth_token}}"
                *ngFor="let item of gatewayDevice"
              >
                {{item.device_name}}
              </option>
            </select>
            <div
              class="validation"
              *ngIf="device.controls['gateway'].invalid && (device.controls['gateway'].dirty)"
            >
              <span
                *ngIf="device.controls['gateway']?.errors"
                style="color: red"
              >
                *Select The Gateway
              </span>
            </div>
          </div> -->


          <!-- create device form  -->


          <div class="form-field nameandslave">
            <div class="name">
              <label class="form-label">device name <span style="color:red">*</span></label>
              <input
                class="form-control"
                placeholder="New Device"
                formControlName="devicename"
              />
              <div class="validation" *ngIf="device.controls['devicename'].dirty">
                <span *ngIf="device.controls['devicename']?.errors?.['required']" style="color: red">
                  *Device Name is required.
                </span>
                <span *ngIf="device.controls['devicename']?.errors?.['minlength'] && !device.controls['devicename']?.errors?.['pattern'] && !device.controls['devicename']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
                  *Minimum 2 characters
                </span>
                <span *ngIf="device.controls['devicename']?.errors?.['maxlength']" style="color: red">
                  *Maximum 20 characters
                </span>
                <span *ngIf="device.controls['devicename']?.errors?.['pattern']" style="color: red">
                  Special character not allowed
                </span>
                <span *ngIf="device.controls['devicename']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
                  *Spaces are not allowed
                </span>
              </div>
              
            </div>
            <div class="slaveid">
              <!-- Only show if the selected Device Type is 'Slave' -->
              <label class="form-label">Slave Id<span style="color:red">*</span> <iconify-icon *ngIf="this.slaveid.length>0"
                matTooltip="{{'Existing Slaves : ' + this.slaveid }}"
                matTooltipPosition="right" icon="foundation:alert"></iconify-icon></label>
              <input class="form-control"  formControlName="slave_id" type="number" value="1" min="1" max="250" name="foo" placeholder="Slave_id" />
              <div
              class="validation"
              *ngIf=" device.controls['slave_id'].dirty||this.invalidslaveId"
            >
              <span
                *ngIf="device.controls['slave_id']?.errors?.['required']"
                style="color: red"
              >
                *Slave Id is required
              </span>
              <span *ngIf="device.controls['slave_id']?.errors?.['min']" style="color: red">
                *Minimum Slave Id is 1
              </span>
              <span *ngIf="device.controls['slave_id']?.errors?.['max']" style="color: red">
                *Maximum Slave Id is 250
              </span>
              <span
                *ngIf="this.invalidslaveId"
                style="color: red"
              >
                Already Slave Id Used
              </span>
              
            </div>
         
            </div>
          
            
          </div>
          <div class="form-field nameandslave">
            <div class="name">
              <label class="form-label">Device Model<span style="color:red">*</span></label>
              <select  class="form-select" formControlName="deviceModel">
                <option value="" [selected]="true">Select the Device Model</option>
                <option
                  class="form-option"
                  value="{{item.model_id}}"
                  *ngFor="let item of deviceModel"
                >
                  {{item.device_model_name}}
                </option>
              </select>
              <div
              class="validation"
              *ngIf="device.controls['deviceModel'].invalid && (device.controls['deviceModel'].dirty)"
            >
              <span
                *ngIf="device.controls['deviceModel']?.errors"
                style="color: red"
              >
                *Select The Device Model
              </span>
            </div>
              
            </div>
            <div class="slaveid">
              <!-- Only show if the selected Device Type is 'Slave' -->
              <label class="form-label">Model Number</label>
              <input class="form-control" formControlName="modelNumber" type="text"  placeholder="Model Number" />
              <div
              class="validation"
              *ngIf=" device.controls['modelNumber'].dirty||this.invalidslaveId"
            >
              <span
                *ngIf="device.controls['modelNumber']?.errors?.['required']"
                style="color: red"
              >
                *Slave Id is required
              </span>
             
              
            </div>
         
            </div>
          
            
          </div>

          <div class="LatLong">
            <div class="form-field">
              <label class="form-label">Latitude<span style="color:red">*</span></label>
              <input
                class="form-control"
                placeholder="Latitude"
                formControlName="latitude"
                (input)="onLatLongChange()"
              />
              <div class="validation" *ngIf="device.controls['latitude'].dirty">
                <span
                  *ngIf="device.controls['latitude']?.errors?.['required']"
                  style="color: red"
                >
                  *Latitude is required.
                </span>
                <span
                  *ngIf="device.controls['latitude']?.errors?.['minlength']&&!device.controls['latitude']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>

              </div>
            </div>
            <div class="form-field">
              <label class="form-label">Longitude<span style="color:red">*</span></label>
              <input
                class="form-control"
                placeholder="Longitude"
                formControlName="longitude"
                (input)="onLatLongChange()"
              />
              <div class="validation" *ngIf="device.controls['longitude'].dirty">
                <span
                  *ngIf="device.controls['longitude']?.errors?.['required']"
                  style="color: red"
                >
                  *longitude is required.
                </span>
                <span
                  *ngIf="device.controls['longitude']?.errors?.['minlength']&&!device.controls['longitude']?.errors?.['pattern']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
          
              </div>
            </div>
            <div style="padding: 8px 0px 0px 0px;
            width: 10% ;cursor:pointer
        "(click)="pastelatlng($event)">
              <iconify-icon  matTooltip="Paste"
              matTooltipPosition="left" style="font-size:20px" icon="streamline:copy-paste"></iconify-icon>
            </div>
          </div>

          <div class="form-field">
            <label class="form-label">City</label>
            <input
              class="form-control"
              readonly
              placeholder="City"
              formControlName="city"
            />
           
          </div>
          <!-- advanced -->
          <!-- <div class="advanced-slider1">
            <mat-slide-toggle
              (change)="onToggleChange($event)"
              [checked]="this.app_mode_force=='1'||this.app_mode=='1'"
              [disabled]="this.undisable=='1'"
            ></mat-slide-toggle>
            <h4>
              Advanced
              <mat-icon
                class="instructions"
                matTooltip="Create device Mac-ID and QR code ID with Web Zone"
                matTooltipPosition="right"
                matTooltipClass="my-tooltip"
                >info
              </mat-icon>
            </h4>
          </div> -->
          <!-- advanced -->
          <!-- <div
            class="form-field"
            *ngIf="this.app_mode=='1'||this.undisable=='1'"
          >
            <label class="form-label">Device Mac Id</label>
            <div class="mac-val">
              <div class="device-mac-id-filed mac-id">
                <input
                  maxlength="2"
                  class="form-control"
                  formControlName="first"
                  placeholder="XX"
                />:
                <input
                  maxlength="2"
                  class="form-control"
                  placeholder="XX"
                  #second
                  formControlName="second"
                />:
                <input
                  maxlength="2"
                  class="form-control"
                  placeholder="XX"
                  #three
                  formControlName="three"
                />:
                <input
                  maxlength="2"
                  class="form-control"
                  placeholder="XX"
                  #four
                  formControlName="four"
                />:
                <input
                  maxlength="2"
                  class="form-control"
                  placeholder="XX"
                  #five
                  formControlName="five"
                />:
                <input
                  maxlength="2"
                  class="form-control"
                  placeholder="XX"
                  #six
                  formControlName="six"
                />
              </div>
             
            </div>
            <div class="validation">
              <div *ngIf="device.controls['first'].dirty">
                <span
                  *ngIf="device.controls['first']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['first']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['first']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 20 characters
                </span>
              </div>
              <div *ngIf="device.controls['second'].dirty">
                <span
                  *ngIf="device.controls['second']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['second']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['second']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 20 characters
                </span>
              </div>
              <div *ngIf="device.controls['three'].dirty">
                <span
                  *ngIf="device.controls['three']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['three']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['three']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 2 characters
                </span>
              </div>
              <div *ngIf="device.controls['four'].dirty">
                <span
                  *ngIf="device.controls['four']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['four']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['four']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 2 characters
                </span>
              </div>
              <div *ngIf="device.controls['five'].dirty">
                <span
                  *ngIf="device.controls['five']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['five']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['five']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 2 characters
                </span>
              </div>
              <div *ngIf="device.controls['six'].dirty">
                <span
                  *ngIf="device.controls['six']?.errors?.['required']"
                  style="color: red"
                >
                  *macid is required.
                </span>
                <span
                  *ngIf="device.controls['six']?.errors?.['minlength']"
                  style="color: red"
                >
                  *Minimum 2 characters
                </span>
                <span
                  *ngIf="device.controls['six']?.errors?.['maxlength']"
                  style="color: red"
                >
                  *Maximum 2 characters
                </span>
              </div>
            </div>
          </div>
          <div
            class="webzoneQR"
            *ngIf="this.app_mode=='1'||this.undisable=='1'"
          >
            <div class="form-field" style="width: 48%">
              <label class="form-label">Webzone</label>

              <select class="form-select" formControlName="Webzone_Id">
                <option value="" [selected]="true">Select the Webzone</option>
                <option
                  class="form-option"
                  value="{{item.zonename}}"
                  *ngFor="let item of webzonevalue"
                >
                  {{item.zonename}}
                </option>
              </select>
              <div
                class="validation"
                *ngIf="device.controls['Webzone_Id'].invalid && (device.controls['Webzone_Id'].dirty)"
              >
                <span
                  *ngIf="device.controls['Webzone_Id']?.errors"
                  style="color: red"
                >
                  *Select The Webzone
                </span>
              </div>
            </div>

            <div
              class="form-field"
              style="width: 48%"
              *ngIf="this.app_mode=='1'||this.undisable=='1'"
            >
              <label class="form-label">QR Code Id</label>
              <input
                class="form-control"
                #qrcode
                type="text"
                placeholder="WeSio232"
                formControlName="qrcode"
              />
              <div
                *ngIf="device.controls['qrcode'].invalid && (device.controls['qrcode'].dirty)"
              >
                <span
                  class="validation"
                  *ngIf="device.controls['qrcode']?.errors"
                  style="color: red"
                >
                  *Field is Required
                </span>
              </div>
            </div>
          </div> -->
        </form>
      </div>
      <!-- create and cancel button  -->
      <div>
        <div class="btn-divall">
          <button class="btn btn-danger" mat-dialog-close>Cancel</button>
          <button
            
            class="btn btn-success"
            [disabled]="!this.device.valid||this.invalidslaveId"
            (click)="newdevice()"
            mat-dialog-close
          >
            Create
          </button>
          <!-- <button
            *ngIf="this.app_mode=='1'|| this.undisable=='1'"
            class="btn btn-success"
            [disabled]="!this.device.valid||this.invalidslaveId"
            (click)="newdeviceWithMac()"
            mat-dialog-close
          >
            Create
          </button> -->
        </div>
      </div>
    </div>
  </mat-tab>
  <!-- single devices upload -->

  <!-- multiple devices upload -->

  <mat-tab [disabled]="true" label="Multiple Devices">
    <div class="matdialog-main">
      <div class="matdialog-content">
        <div [formGroup]="devicemacidbulkform">
          <div class="form-field">
            <label class="form-label">Cluster</label>

            <select class="form-select" formControlName="product_Id">
              <option value="" [selected]="true">Select the Cluster</option>
              <option
                class="form-option"
                value="{{item.cluster_id}}"
                *ngFor="let item of productvalue"
              >
                {{item.cluster_name}}
              </option>
            </select>
            <div
              *ngIf="devicemacidbulkform.controls['product_Id'].invalid && (devicemacidbulkform.controls['product_Id'].dirty)"
            >
              <span
                class="validation"
                *ngIf="devicemacidbulkform.controls['product_Id']?.errors"
                style="color: red"
              >
                *Select The Cluster
              </span>
            </div>
          </div>
          <div class="form-field">
            <label class="form-label" for="">Device Prefix Name</label>
            <input
              class="form-control"
              formControlName="deviceprefix"
              placeholder="Enter Device Prefix Name"
            />

            <span
              class="validation"
              *ngIf="devicemacidbulkform.controls['deviceprefix'].hasError('maxlength') &&
             (devicemacidbulkform.controls['deviceprefix'].dirty || devicemacidbulkform.controls['deviceprefix'].touched)"
              style="color: red"
            >
              Maximum character limit reached.
            </span>

            <div
              class="validation"
              *ngIf="devicemacidbulkform.controls['deviceprefix'].invalid &&
            devicemacidbulkform.controls['deviceprefix'].errors?.['required'] &&
            (devicemacidbulkform.controls['deviceprefix'].dirty || devicemacidbulkform.controls['deviceprefix'].touched)"
            >
              <span style="color: red"> Prefix Name is required. </span>
            </div>
          </div>
          <div class="form-field">
            <label class="form-label">Choose Excel File</label>
            <input
              class="form-control"
              type="file"
              accept=".xlsx"
              (change)="onFileSelected($event)"
            />
          </div>
          <div class="form-field download">
            <label class="form-label">Sample File : </label>
            <button class="downlaod-btn" (click)="download()">
              <span>Download</span><span>Excel</span>
            </button>

            <!-- <button class="download-button" aria-label="Download Samplefile" (click)="download()"> <div class="button-content">
          <mat-icon>save_alt</mat-icon> Download
        </div>
       
        </button> -->
          </div>
        </div>
      </div>

      <div>
        <div class="btn-divall">
          <button class="btn btn-danger" mat-dialog-close>Cancel</button>
          <button
            class="btn btn-success"
            mat-dialog-close
            [disabled]="this.devicemacidbulkform.invalid||!this.file"
            (click)="upload()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </mat-tab>



  <!-- <mat-tab label="Slave Device">
    <div class="matdialog-main">
      <div class="matdialog-content">
        <div [formGroup]="slavedevice">
          <div class="form-field">
            <label class="form-label">Cluster</label>
            <select class="form-select" formControlName="product_Id">
              <option value="" [selected]="true">Select the Gateway Device</option>
              <option
                class="form-option"
                value="{{item.device_id}}"
                *ngFor="let item of gatewayDevice"
              >
                {{item.device_name}}
              </option>
            </select>
            <div
              *ngIf="slavedevice.controls['product_Id'].invalid && (slavedevice.controls['product_Id'].dirty)"
            >
              <span
                class="validation"
                *ngIf="slavedevice.controls['product_Id']?.errors"
                style="color: red"
              >
                *Select The Cluster
              </span>
            </div>
          </div>
          <div id="newinput" formArrayName="dynamicInputs">
            <div *ngFor="let control of dynamicInputControls.controls; let i = index" [formGroupName]="i">
              <div class="input-group m-3">
                <div class="input-group-prepend">
                  <button class="btn btn-danger" type="button" (click)="removeDynamicInput(i)">
                    <i class="bi bi-trash"></i> Delete
                  </button>
                </div>
                <input placeholder="Enter Slave Device - {{ i + 1 }}" type="text" class="form-control m-input" formControlName="value">
              </div>
            </div>
          </div>
          
          <button (click)="addForm()" id="rowAdder" type="button" class="btn btn-dark">
            <span class="bi bi-plus-square-dotted"></span> ADD
          </button>
                    </div>
                </div>
      <div>
        <div class="btn-divall">
          <button class="btn btn-danger" mat-dialog-close>Cancel</button>
          <button
            class="btn btn-success"
            mat-dialog-close
            [disabled]="this.slavedevice.invalid"
            (click)="submitslavedevice()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </mat-tab> -->
</mat-tab-group>









<!-- sub device  -->
<div class="subdevices" *ngIf="this.flag">
<h1>Create Sub Devices</h1>
<div class="matdialog-main">
  <div class="matdialog-content">
    <div [formGroup]="subdevice">

      <div class="form-field">
        <input
              class="form-control"
              placeholder="New Sub Device"
              formControlName="devicename"
            />
            <div class="validation" *ngIf="subdevice.controls['devicename'].dirty">
              <span *ngIf="subdevice.controls['devicename']?.errors?.['required']" style="color: red">
                *Device Name is required.
              </span>
              <span *ngIf="subdevice.controls['devicename']?.errors?.['minlength'] && !subdevice.controls['devicename']?.errors?.['pattern'] && !subdevice.controls['devicename']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
                *Minimum 2 characters
              </span>
              <span *ngIf="subdevice.controls['devicename']?.errors?.['maxlength']" style="color: red">
                *Maximum 20 characters
              </span>
              <span *ngIf="subdevice.controls['devicename']?.errors?.['pattern']" style="color: red">
                Special character not allowed
              </span>
              <span *ngIf="subdevice.controls['devicename']?.errors?.['noSpaceInFirstTwoCharacters']" style="color: red">
                *Spaces are not allowed
              </span>
            </div>
      </div>
   
    </div>
  </div>

  <div>
    <div class="btn-divall">
      <button class="btn btn-danger" mat-dialog-close>Cancel</button>
      <button
        class="btn btn-success"
        mat-dialog-close
        [disabled]="this.subdevice.invalid"
        (click)="CreateSubdevice()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
</div>



<!-- multiple devices upload -->


