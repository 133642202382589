<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner"></mat-spinner>

<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500; margin: 0 !important">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<div class="startpage" *ngIf="this.update?.length == 0 && !loading && isread == true">
  <div class="heading">
    <h1 style="font-weight: 500">Ship new firmware updates Over-The-Air</h1>
    <p style="font-weight: 500">
      Here you can remotely update millions of your devices with new firmware
      and track shipment progress
    </p>
  </div>
  <div>
    <button class="btn btn-primary new-dev" (click)="firmwareupload()">
      New Update <i class="fa-solid fa-plus"></i>
    </button>
  </div>
</div>

<div class="body" *ngIf="this.update.length >= 1 && !loading && isread == true">
  <div class="body-head">
    <div>
      <h1>OTA update</h1>
    </div>
    <div>
      <button class="btn btn-primary new-dev" (click)="firmwareupload()">
        New Update <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </div>

  <div class="ota-update">
    <div class="otas-container" *ngFor="let item of update; let i = index">
      <div class="ota">
        <div class="ota-preview">
          <!-- <div>
            <h6>
              Latest Version:{{
              item.update_version.split(",")[
              item.update_version.split(",").length - 1
              ]
              }}
            </h6>
          </div> -->
          <div>
            <h2>{{ item.cluster_name }}</h2>
          </div>
          <div class="device-count-div">
            <h5>All Device: {{ item.device_count }}</h5>
            <div class="auto">
              <h5>Auto: {{ item.auto }}</h5>
              <h5>Manual: {{ item.manual }}</h5>
            </div>

            <!-- <h5>Updated Device : {{ item.device_version?.length }}</h5> -->
          </div>
          <!-- <div class="device-count-div">
            <h5>All Device: {{ item.device_count }}</h5>
            <h5>Updated Device : {{ item.device_version?.length }}</h5>
          </div> -->
        </div>
        <div class="ota-info">
          <div>
            <div>
              <!-- <h6> Date : {{item.datetime|date:"medium"}}</h6> -->
              <!-- <h2 *ngIf="item.description">
                Description: {{ item.description }}
              </h2> -->
            </div>
          </div>
          <div class="action">


            <!-- <button style="display: flex;
            align-items: center;
            gap: 7px;
        " class="btn btn-info" (click)="FirmwareInfo(item.cluster_id)">
            <iconify-icon icon="mdi:progress-tag"></iconify-icon>
              View Details
            </button> -->
            <!-- <button style="display: flex;
            align-items: center;
            gap: 7px;
        " class="btn btn-info">
              <iconify-icon style="font-size: 18px;" icon="material-symbols:schedule-outline"></iconify-icon>
              Scheduler
            </button> -->
            <!-- <button class="btn btn-info" (click)="viewdevices(item.cluster_id, i, item.update_version)"
              *ngIf="isCollapsed[i]">
              Show Devices
            </button>
            <button class="btn btn-info" (click)="viewdevices(item.cluster_id, i, item.update_version)"
              *ngIf="!isCollapsed[i]">
              Hide Devices
            </button> -->

            <button class="btn btn-info" *ngIf="showVersion[i]" (click)="toggleVersions(item.cluster_id, i)">
              Show Versions
            </button>
            <button class="btn btn-info" *ngIf="!showVersion[i]" (click)="toggleVersions(item.cluster_id, i)">
              Hide Versions
            </button>
          </div>
        </div>
      </div>
      <div class="versions-container" class="collapse navbar-collapse" [ngbCollapse]="showVersion[i]">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Firmware Name</th>
              <th>Version</th>
              <th>Created On</th>
              <!-- <th>Updated Device</th> -->
              <th>Status</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let version of versionlist; let i = index">
              <tr>
                <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">{{ i + 1 }}</td>
                <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">{{version.firmwarename}}</td>
                <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">{{ version.update_version }}</td>
                <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">
                  {{ version.datetime | date : "medium" }}
                </td>

                <!-- <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">{{ version.updatedevicecount }}</td> -->
                <td (click)="FirmwareInfo(item.cluster_id,version.update_version)">
                  <!-- <div *ngIf="version.schedule==1">
                    <iconify-icon icon="formkit:datetime"></iconify-icon>
                  </div> -->
                  <div class="ota-status">
                     <div  class="ota-status">
                  <iconify-icon [matTooltip]="'Pending'" matTooltipPosition="above" style="color:rgb(1, 48, 102)" icon="mdi:progress-clock"></iconify-icon>
                  <span>{{version.pendingdevice}}</span>
                </div>
                <div  class="ota-status">
                  <iconify-icon [matTooltip]="'Process'" matTooltipPosition="above" style="color:rgb(0, 238, 255)" icon="line-md:uploading-loop"></iconify-icon>
                  <span>{{version.processdevice}}</span>
                </div>
                <div  class="ota-status">
                  <iconify-icon [matTooltip]="'Success'" matTooltipPosition="above" style="color:rgb(0, 255, 98)" icon="mdi:progress-check"></iconify-icon>
                  <span>{{version.updatedevicecount}}</span>
                </div>
                <div  class="ota-status">
                  <iconify-icon [matTooltip]="'Failed'" matTooltipPosition="above" style="color:red" icon="charm:circle-cross"></iconify-icon>
                  <span>{{version.faileddevice}}</span>
                </div>
                  </div>

                </td>
                <td>
                  <div class="versionaction">

                    <!-- <button style="display: flex;
                    align-items: center;
                    gap: 7px;" [disabled]="update_disable" *ngIf="version.autoupdate_ready == '0'" class="btn btn-info"
                    (click)="
                      OTAUPDATE(
                        item.cluster_api_Id,
                        version.update_version,
                        item.cluster_id
                      )
                    ">
                    <iconify-icon icon="uil:file-upload"></iconify-icon>
                    Update Now
                  </button> -->

                    <button [disabled]="update_disable"
                      [ngClass]="{ disabled: version.autoupdate_ready == '1'||version.schedule==1 }"
                      *ngIf="version.autoupdate_ready == '0' || version.autoupdate_ready == '1'" class="btn btn-info"
                      (click)="
                    OTAUPDATE(
                      item.cluster_api_Id,
                      version.update_version,
                      item.cluster_id
                    )
                  " mat-icon-button [matTooltip]="'Update Now'
                " matTooltipPosition="left">
                      <iconify-icon icon="material-symbols:file-upload"></iconify-icon>
                    </button>

                    <button [ngClass]="{ disabled: version.schedule==1 }" [disabled]="update_disable"
                      *ngIf="version.autoupdate_ready == '2'" class="btn btn-info" (click)="
                    OTAUPDATE(
                      item.cluster_api_Id,
                      version.update_version,
                      item.cluster_id
                    )
                  " mat-icon-button [matTooltip]="'Rollback'
                " matTooltipPosition="left">
                      <iconify-icon icon="tdesign:rollback"></iconify-icon>
                    </button>
                    <button class="btn btn-info" [matTooltip]="'Firmware Info'
                  " matTooltipPosition="left" (click)="FirmwareInfo(item.cluster_id,version.update_version)"
                      mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                      <iconify-icon icon="tabler:file-info"></iconify-icon>
                    </button>
                    <!-- <button style="display: flex;
                    align-items: center;
                    gap: 7px;
                " class="btn btn-info" (click)="FirmwareInfo(item.cluster_id,version.update_version)"> -->


                    <!-- </button> -->
                    <button class="btn btn-info" style="display: block;" [matTooltip]="'Delete Firmware'
                    " matTooltipPosition="left" class="delete-icon"
                       mat-icon-button (click)="versionDelete(
                      item.cluster_api_Id,
                      version.update_version,
                      item.cluster_id
                    )
                  "
                  [ngClass]="{ disabled: version.autoupdate_ready == '1' }">
                      <iconify-icon icon="ic:round-delete"></iconify-icon>
                    </button>

                    <button class="btn btn-info" [matTooltip]="'Scheduling'
                    " matTooltipPosition="left" [disabled]="version.autoupdate_ready == '1' "
                      (click)="Schedule(item.cluster_id,version.update_version,version.firmwarename,version.schedule_time,version.schedule)"
                      [ngClass]="{ disabled: version.autoupdate_ready == '1' }" mat-icon-button>
                      <iconify-icon icon="formkit:datetime"></iconify-icon>

                    </button>
                  </div>

                  <!-- <div class="versionaction" [matTooltip]="
                      update_disable ? 'Auto update is disabled' : ''
                    ">
                    <button [disabled]="update_disable" *ngIf="version.autoupdate_ready == '0'" class="btn btn-success"
                      (click)="
                        OTAUPDATE(
                          item.cluster_api_Id,
                          version.update_version,
                          item.cluster_id
                        )
                      ">
                      Update Now
                    </button>

                    <button *ngIf="version.autoupdate_ready == '2'" class="btn btn-danger" (click)="
                        OTAUPDATE(
                          item.cluster_api_Id,
                          version.update_version,
                          item.cluster_id
                        )
                      ">
                      Rollback
                    </button>

                    <button *ngIf="version.autoupdate_ready == '1'" [disabled]="version.autoupdate_ready == '1'"
                      class="btn btn-success Rollback">
                      Updated
                    </button>

                    <mat-icon class="delete" [ngClass]="{ disabled: version.autoupdate_ready == '1' }" (click)="
                        versionDelete(
                          item.cluster_api_Id,
                          version.update_version,
                          item.cluster_id
                        )
                      " [disabled]="version.autoupdate_ready == '1'">delete</mat-icon>
                  </div> -->
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed[i]">
        <table>
          <thead>
            <tr>
              <th>Device Name</th>
              <th>Version</th>
              <th>Device Model</th>
              <th>Last Updated</th>
              <th>Status</th>
              <th>Auto Update</th>
              <th>Select Update</th>
              <th>Operation</th>
            </tr>
          </thead>

          <tbody>
            <td *ngIf="deviceslist?.length === 0" colspan="7" class="text-center">
              <div class="no-device">
                <mat-icon style="color: #0d2e63">info</mat-icon>
                <span class="ml-2">No devices available.</span>
              </div>
            </td>
            <tr *ngFor="let item of deviceslist">
              <td>
                <span class="data-info">{{ item.device_name }}</span>
              </td>

              <td>{{ item.device_version }}</td>
              <td>
                <span>{{ item.hardware_name }}</span>
              </td>
              <td>
                <span>{{ item.last_ota_update | date : "short" }}</span>
              </td>
              <td>
                <div *ngIf="item.update_status==0" class="ota-status">
                  <iconify-icon style="color:rgb(1, 48, 102)" icon="mdi:progress-clock"></iconify-icon>
                  <span>Pending</span>
                </div>
                <div *ngIf="item.update_status==2" class="ota-status">
                  <iconify-icon style="color:red" icon="charm:circle-cross"></iconify-icon>
                  <span>Failed</span>
                </div>
                <div *ngIf="item.update_status==1" class="ota-status">
                  <iconify-icon style="color:rgb(0, 238, 255)" icon="line-md:uploading-loop"></iconify-icon>
                  <span>Progress</span>
                </div>
                <div *ngIf="item.update_status==null" class="ota-status">
                  <iconify-icon style="color:rgb(0, 255, 98)" icon="mdi:progress-check"></iconify-icon>
                  <span>Success</span>
                </div>
              </td>
              <td>
                <mat-slide-toggle color="primary" [checked]="item.update_mode == '0'"
                  (change)="updatemode($event, item.device_id)"></mat-slide-toggle>
              </td>
              <td [formGroup]="updateversion">
                <select class="form-select" (change)="onSelectChange($event.target)">
                  <option value="{{ item.device_version }}">
                    {{ item.device_version }}
                  </option>
                  <option #selectVersion class="form-control" [value]="list"
                    *ngFor="let list of item.update_version; let i = index">
                    {{ list }}
                  </option>
                </select>
              </td>

              <td>
                <div
                  [ngStyle]="{'padding': (item.update_status == '0' || item.update_status == '1') ? '0px 29px' : '0px 0px','position':(item.update_status == '0' || item.update_status == '1') ? 'relative' : 'none','display':(item.update_status == '0' || item.update_status == '1') ? 'flex' : 'block','justify-content':(item.update_status == '0' || item.update_status == '1') ? 'center' : ''}"
                  [matTooltip]="
                item.update_mode != '1' ? 'Auto update is enabled' : ''
                " matTooltipPosition="left">

                  <!-- <button class="btn btn-primary Update" [disabled]="item.update_mode == '0'" *ngIf="!item.update_status&&item.update_status!=0"
                (click)="OTAMANUAL(item.device_auth_token, item.cluster_id)">
                Update Now
                </button> -->
                  <button class="btn btn-info Update"
                    [disabled]="item.update_mode == '0' ||item.update_status == '0'||item.update_status == '1'"
                    (click)="OTAMANUAL(item.device_auth_token, item.cluster_id)" mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon">
                    <iconify-icon icon="material-symbols:file-upload"></iconify-icon>
                  </button>
                  <!-- <mat-spinner role="progressbar" diameter="30" aria-hidden="true" *ngIf="item.update_status =='0'"
                    mode="indeterminate"></mat-spinner>
                  <mat-spinner role="progressbar" diameter="30" aria-label="Uploading" *ngIf="item.update_status =='1'"
                    mode="determinate" [value]="item.progress">
                  </mat-spinner> -->
                  <!-- <span *ngIf="item.update_status =='0'" style="    position: absolute;
                top: -1px;
                right: 43px;
                color: #ffffff;
            " >Processing...</span> -->
                  <!-- <span style="position: absolute; top: 0.5em;" [ngStyle]="{'left': item.progress + '%'}">{{item.progress}}%</span> -->
                  <!-- <button class="btn btn-primary Update"  *ngIf="item.update_status =='0'"
                >
                Processing...
                </button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- <div class="main" *ngIf="mainpage">

  <div class="main-head">
    <h1>New Shipping</h1>
    <mat-icon>keyboard</mat-icon>
  </div>
  <div class="device-content">
    <div class="table-heading">
      <h1>device select</h1>
    </div>
    <div class="table">
      <mat-icon class="empty-icon">inbox</mat-icon>
      <table>
        <thead>
          <tr>
            <th class="checkbox">
              <mat-checkbox class="example-margin" color="primary"></mat-checkbox>
            </th>
            <th class="devicename">Device Name</th>
            <th class="firmware">Firmware Version</th>
            <th class="organization">Oraganization Name</th>
            <th class="update">Firmware updated on</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
  <div class="firmware-content">
    <div class="firmware-head">
      <h1>firmware</h1>
      <mat-icon (click)="otasidenav.toggle()">new_releases</mat-icon>
    </div>
    <div class="logo-input">
      <div class="input-div">
        <input type="file" class="form-control" id="file" #fileupload formControlName="logo" />
      </div>
      <div (click)="fileupload.click()">
        <img src="../../assets/upload.png" class="upload-icon">
      </div>
      <div (click)="fileupload.click()" class="upload-file-text">
        <p>Upload firmware file (.bin, .tar, .zip, .gz)</p>
      </div>

    </div>


  </div>
  <div class="review-content">
    <div class="review-head">
      <h1>Review and start</h1>
    </div>
    <div class="form-field">
      <form>
        <div>
          <label class="form-label">Attempts Limit</label>
          <input type="number" class="form-control">
        </div>
        <div>
          <label class="form-label">Attempts Reset Period</label>
          <input type="time" class="form-control">
        </div>
        <div>
          <label class="form-label">Host</label>
          <input type="text" class="form-control" placeholder="Enter...">
        </div>
      </form>
    </div>
    <div class="btn-div">
      <button class="btn btn-danger">Cancel</button>
      <button class="btn btn-success">Start Shipping</button>
    </div>
  </div>

</div>



<mat-sidenav #otasidenav position="end" class="ota-sidenav" mode="over">
  <div>
    <mat-icon (click)="otasidenav.close()">close</mat-icon>
    <h1>
      OTA: Firmware Over-The-Air updates</h1>
  </div>
</mat-sidenav> -->
</div>
<!-- instruction  -->
<!-- <mat-sidenav class="sidenav-content" #sidenav mode="over" position="end" style="position: fixed; right: 0;">
	
	<div class="instruction">
		<mat-icon class="close" (click)="sidenav.close()">close</mat-icon>
    <h2>Create Single Device</h2>
    <p>Here are the instructions to create single device.</p>
    <ol class="device-instructions">
      <li>Click on New Device and a pop-up window will be displayed.</li>
      <li>By Default the Single Device tab will be selected.</li>
      <li>Select a Cluster from the dropdown.</li>
      <li>Enter the Device Name and click on create button to create the device without mac id.</li>
      <li>The Toggle button can be enabled for advanced option, Where you can enter and verify the device mac-ID.</li>
      <li>Enter the Device Mac ID and select the check box to verify.</li>
      <li>Select the Web Zone from the dropdown.</li>
      <li>Enter the Qr Code ID of the Device.</li>
      <li>Click Create button to create the device.</li>
    </ol>
	</div>
  </mat-sidenav> -->