import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { SocketService } from '../socket.io/socket.service';
import { ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../Authentication/authentication.service';
import { deletepopup } from '../mydevices/mydevices.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-over-the-air',
  templateUrl: './over-the-air.component.html',
  styleUrls: ['./over-the-air.component.css'],
})
export class OverTheAirComponent implements OnInit {
  // Flags to control the page display
  startpage = true;
  mainpage = false;

  // Variable to store the latest version
  latestVersion: string = '';

  // Flag to control loading state
  loading: boolean;

  // Reference to the select element in the template
  @ViewChild('selectVersion') selectVersion!: ElementRef;

  // Flag to control whether the update is disabled
  update_disable: boolean = false;

  // Lifecycle hook after view initialization
  ngAfterViewInit() {
    console.log(this.selectVersion)
    // Accessing the native element of the selectVersion ViewChild
    const selectEl = this.selectVersion;


  }

  public isCollapsed: boolean[] = [];
  public showVersion: boolean[] = [];
  // public isCollapsedver: boolean[] = [true, true];
  // showVersion: boolean = false;
  id: any;
  user_Id: any;
  data: any;
  update: any[] = [];
  deviceslist: any;
  versionlist: any[] = [];
  deviceupdatemode: any[] = [];
  index: any;
  collapesdindex: any = 0;
  versionindex: any = 0;
  runningversion: any;
  refresh: any[] = [];
  deviceupdatecount: any[] = [];
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  OTAProcess: any[] = []

  // Define the FormGroup using FormBuilder
  updateversion = this.formbuilder.group({
    version: ['', Validators.required]
  });
  roles: any;

  selectversion: any;
  devicerefresh: any[] = [];

  /**
 * Constructs an instance of the component.
 * @param {MatDialog} matdialog - The dialog service for displaying dialogs.
 * @param {Dataservice} dataservice - The service used for data operations.
 * @param {FormBuilder} formbuilder - The form builder service for creating and managing forms.
 * @param {ToastrService} toastr - The service for displaying toast notifications.
 * @param {SocketService} socket - The service for socket communication.
 * @param {AuthenticationService} authentication - The service for user authentication operations.
 * @param {ChangeDetectorRef} changeDetectorRef - The change detector reference for manual change detection.
 * @returns {void}
 */
  constructor(
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private socketService: SocketService,
    private socket: SocketService,
    private router: Router,
    private authentication: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // Initialize loading status
    this.loading = false;

    // Get user roles using the authentication service
    this.roles = this.authentication.getUserRole();


    // Extract permissions from roles[7] array
    this.roles = this.roles[7];

    // Map permissions to component properties (iscreate, isread, isdelete)
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })
  }
  /**
 * Implements the Angular lifecycle hook for component initialization.
 * @returns {void}
 */
  ngOnInit(): void {
    this.dataservice.setPaginationState(null, 'all');
    // Notify MyDevicestatus subscribers about changes
    this.dataservice.MyDevicestatus.next();

    // Notify AllDevicestatus subscribers about changes
    this.dataservice.AllDevicestatus.next();

    // Notify singleDevicestatus subscribers about changes
    this.dataservice.singleDevicestatus.next();

    // Set loading to true
    this.loading = true;

    // Initialize an array to hold count data
    let count: any[] = [];

    // Get user data from authentication service
    const data = this.authentication.getUserData();

    // Fetch and load OTA updates data
    this.getotaupdatesdata(data);

    // Subscribe to OTArefresh event for updates
    this.dataservice.OTArefresh.subscribe((response: any) => {
      this.getotaupdatesdata(data);
    });
  }

  onSelectChange(selectedValue: any) {
    this.selectversion = selectedValue.value // Here you can access the selected option value
  }

  /**
  * Fetches OTA updates data using the provided user data.
  * @param {any} data - The user data needed for fetching the OTA updates.
  * @returns {void}
  */
  getotaupdatesdata(data: any): void {
    // Subscribe to the getotaupdates service to fetch OTA updates
    this.dataservice.getotaupdates(data).subscribe((res: any) => {
      // Set loading to false after fetching data
      this.loading = false;

      // Check if the response status is successful
      if (res.status == '200') {
        // Store the fetched data in the component property
        this.data = res.data;
        this.update = this.data;

        // Initialize arrays to control collapsing and version display
        this.update.forEach((row: any) => {
          this.isCollapsed.push(true);
          this.showVersion.push(true);
        });
      } else {
        // Display an error message if fetching data fails
        this.toastr.error('Error occurred');
      }
    });
  }
  /**
  * Lifecycle hook called when the component is about to be destroyed.
  * Clears the interval set for refreshing data, preventing memory leaks.
  * @returns {void}
  */
  ngOnDestroy(): void {

    for (const intervalId of this.refresh) {
      clearInterval(intervalId);
    }
  }

  RealtimeOtaProcess() {

    // Listen for analog input data
    this.socketService.listen('OTAChannel').subscribe((data: any) => {
      // Add received analog data to the existing analog data array
      this.OTAProcess = this.OTAProcess ? [...this.OTAProcess, data] : [data];

    });



  }


  /**
 * Initiates the firmware upload process based on user permissions.
 * Opens the firmware upload dialog if user has create permission.
 * Displays a toastr info message if user doesn't have create permission.
 * @returns {void}
 */
  firmwareupload(): void {
    if (this.iscreate === true) {
      this.showVersion.forEach((showVersion, i) => {

        this.showVersion[i] = true;

      });
      this.isCollapsed.forEach((collapse, i) => {

        this.isCollapsed[i] = true;

      });
      this.changeDetectorRef.detectChanges();
      const MatDialogConfig = this.matdialog.open(OTAmatdialog, {
        disableClose: true, width: '500px'
      });



    } else if (this.iscreate === false) {
      this.toastr.info("User not permitted");
    }
  }

  /**
  * Initiates an OTA update based on user permissions.
  * If the user has create permission, sends an update request to the server,
  * emits a message through the socket, and displays a success toastr.
  * If the user doesn't have create permission, displays a toastr info message.
  * @param {any} Key - The key associated with the OTA update.
  * @param {any} version - The version of the OTA update.
  * @param {any} product_Id - The ID of the product to update.
  * @returns {void}
  */
  OTAUPDATE(Key: any, version: any, product_Id: any): void {
    if (this.iscreate === true) {

      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'Update',
      });

      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const value = { product_Id, version };

          this.dataservice.updatenow(value).subscribe((res: any) => {
            if (res.status == '201') {
              this.toastr.success('OTA Update In Progress!');
              const data = { Key, Method: 'OTAUpdate', Version: version };
              this.socket.emit('message', data);
            } else if (res.status == '0') {
              this.toastr.info("Already Device Running Same Version")
            } else {
              this.toastr.error('Error occurred');
            }
          });
     

        }
      });

    } else if (this.iscreate === false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
  * Initiates a manual OTA update based on user permissions.
  * If the user has create permission, retrieves the selected version from the form,
  * sends an update request to the server, emits a message through the socket,
  * and displays a success toastr.
  * If the user doesn't have create permission, displays a toastr info message.
  * @param {any} Key - The key associated with the OTA update.
  * @param {any} product_Id - The ID of the product to update.
  * @returns {void}
  */
  OTAMANUAL(Key: any, product_Id: any): void {
    if (this.iscreate === true) {

      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'Update',
      });

      MatDialogConfig.afterClosed().subscribe(async (result: any) => {
        if (result.confirmation == true) {
          const version = this.selectversion
          let checking = true;
          const value = { product_Id, version, "device_auth_token": Key };

          await Promise.all(this.deviceslist.map((c: any) => {

            if (c.device_auth_token == Key) {
              if (c.device_version == version) {
                checking = true
              } else {
                checking = false
              }
            }
          }))

          if (!checking) {
            this.dataservice.devicemanualupdate(value).subscribe((res: any) => {

              if (res.status == '201') {
                this.toastr.success('OTA Update In Progress!');
                const data = { Key, Method: 'OTAUpdate', Version: version };
                this.socket.emit('message', data);
              } else {
                this.toastr.error('Error occurred');
              }
            });
          } else {
            this.toastr.info('Already Running Version');
          }



        }
      });


    } else if (this.iscreate === false) {
      this.toastr.info('User not permitted');
    }
  }

  /**
  * Retrieves and displays the list of devices for a specific product.
  * Toggles the device list's collapse state, fetches device data, and updates the UI.
  * @param {any} product_Id - The ID of the product for which to view devices.
  * @param {any} index - The index of the clicked device item.
  * @param {any} version - The version of the selected device.
  * @returns {void}
  */
  viewdevices(product_Id: any, index: any, version: any): void {
    this.collapesdindex = index;
    for (const intervalId of this.refresh) {
      clearInterval(intervalId);
    }
    for (const intervalId of this.devicerefresh) {
      clearInterval(intervalId);
    }
    this.dataservice.getproductdevices(product_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.deviceslist = this.data;
        let device_version;
        this.deviceslist.map((x: any, index: any) => {
          let device_Id = x.device_id;
          let updatemode = x.update_mode;
          device_version = x.device_version;
          x.update_version = x.update_version.split(',');
          x.update_version = x.update_version.filter((v: string) => v !== x.device_version); // exclude default value
          let value = { device_Id, updatemode };
          this.deviceupdatemode.push(value);
        });
        this.updateversion.controls.version.setValue('');
        // Toggle the current collapse
        this.isCollapsed[index] = !this.isCollapsed[index];
        // Close all other collapses
        this.isCollapsed.forEach((collapse, i) => {
          if (i !== index) {
            this.isCollapsed[i] = true;
          }
        });
        this.showVersion[this.versionindex] = true;
        this.changeDetectorRef.detectChanges();
      } else {
        this.toastr.error('Error occurred');
      }
    });

    this.devicerefresh.push(
      setInterval(() => {
        this.dataservice.getproductdevices(product_Id).subscribe((res: any) => {
          if (res.status == '200') {
            this.data = res.data;

            this.data.map((x: any) => {
              this.deviceslist.map((y: any) => {
                if (x.device_id == y.device_id) {

                  if (x.device_version != y.device_version) {
                    y.device_version = x.device_version;
                  }
                  if (x.update_status != y.update_status) {
                    y.update_status = x.update_status
                  }
                  x.update_version = x.update_version.split(',');

                  x.update_version = x.update_version.filter((v: string) => v !== x.device_version); // exclude default value
                  if (x.update_version.length != y.update_version.length) {
                    y.update_version = x.update_version

                  }
                }
              })
            })

          } else {
            this.toastr.error('Error occurred');
          }
        });
      }, 2000)
    )



  }

  /**
   * Toggles the visibility of the version list for a specific product.
   * Retrieves version and device data, and updates the UI accordingly.
   * @param {any} product_Id - The ID of the product for which to toggle versions.
   * @param {any} index - The index of the clicked version item.
   * @returns {void}
   */
  toggleVersions(product_Id: any, index: any): void {
    // Reset the update_disable flag
    this.update_disable = false;

    for (const intervalId of this.devicerefresh) {
      clearInterval(intervalId);
    }
    for (const intervalId of this.refresh) {
      clearInterval(intervalId);
    }
    // Fetch device data for the selected product
    this.dataservice.getproductdevices(product_Id).subscribe((res: any) => {

      if (res.status == '200') {
        this.data = res.data;
        // Check if only one device exists for the product
        if (this.data.length === 1) {
          // If update_mode is 1, set update_disable to true
          if (this.data[0].update_mode === 1) {
            this.update_disable = true;
          } else {
            this.update_disable = false;
          }
        }
      }
    });

    // Set the index of the clicked version item
    this.versionindex = index;

    // Fetch version list for the selected product
    this.dataservice.versionlist(product_Id).subscribe((res: any) => {

      if (res.status == '200') {
        let version: any;
        this.data = res.data;
        this.versionlist = this.data;


        // Step 1: Find the running version where autoupdate_ready is 1
        const runningVersionInfo = this.versionlist.find((x: any) => x.autoupdate_ready == 1);
        if (runningVersionInfo) {
          this.runningversion = runningVersionInfo.update_version;

          // Step 2: Update the autoupdate_ready property for each version based on the running version
          this.versionlist.forEach((x: any) => {
            if (x.update_version > this.runningversion) {
              x.autoupdate_ready = "0";
            } else if (x.update_version < this.runningversion) {
              x.autoupdate_ready = "2";
            }
          });
        }
        console.log(this.versionlist)
        // Check if versions need updating based on runningversion

      } else {
        this.toastr.error('Error occurred');
      }
    });

    // Set up a refresh interval for device update counts
    this.refresh.push(setInterval(() => {
      this.dataservice.versionlist(product_Id).subscribe((res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.deviceupdatecount = this.data.map((x: any) => x.updatedevicecount);
          const runningVersionInfo = this.data.find((x: any) => x.autoupdate_ready == 1);
          if (runningVersionInfo) {
            this.runningversion = runningVersionInfo.update_version;

            // Step 2: Update the autoupdate_ready property for each version based on the running version
            this.data.forEach((x: any) => {
              if (x.update_version > this.runningversion) {
                x.autoupdate_ready = "0";
              } else if (x.update_version < this.runningversion) {
                x.autoupdate_ready = "2";
              }
            });
          }
          this.data.map((x: any) => {
            const find = this.versionlist.some((z: any) => z.otaupdate_id == x.otaupdate_id)
            if (find) {
              this.versionlist.map((y: any) => {
                if (y.update_version == x.update_version) {
                  if (y.updatedevicecount != x.updatedevicecount) {
                    y.updatedevicecount = x.updatedevicecount;
                  }

                  if (y.autoupdate_ready != x.autoupdate_ready) {
                    y.autoupdate_ready = x.autoupdate_ready
                  }

                  if (y.schedule != x.schedule) {
                    y.schedule = x.schedule
                  }

                  if (y.pendingdevice != x.pendingdevice) {
                    y.pendingdevice = x.pendingdevice
                  }

                  if (y.faileddevice != x.faileddevice) {
                    y.faileddevice = x.faileddevice
                  }

                  if (y.processdevice != x.processdevice) {
                    y.processdevice = x.processdevice
                  }

                }

              })
            } else {
              this.versionlist.push({ ...x })
            }

          })

        } else {
          this.toastr.error('Error occurred');
        }
      });
    }, 10000))

    // Toggle the visibility of the version list
    setTimeout(() => {
      this.showVersion[index] = !this.showVersion[index];

      // Trigger change detection after the toggle
      this.changeDetectorRef.detectChanges();
    }, 100);

    // Close all other version collapses
    this.showVersion.forEach((showVersion, i) => {
      if (i !== index) {
        this.showVersion[i] = true;
      }
    });

    // Collapse the device list
    this.isCollapsed[this.collapesdindex] = true;
    this.changeDetectorRef.detectChanges();
  }




  /**
   * Handles the change in device update mode.
   * @param {any} event - The event object containing information about the change (checked or unchecked).
   * @param {any} device_Id - The ID of the device for which the update mode is being changed.
   * @returns {void}
   */
  updatemode(event: any, device_Id: any) {
    if (this.iscreate == true) {
      // When the update mode is set to true (checked)
      if (event.checked == true) {
        // Update the deviceslist and deviceupdatemode arrays to reflect the change
        this.deviceslist.map((x: any) => {
          if (x.device_id == device_Id) {
            x.update_mode = 0;
          }
        });
        this.deviceupdatemode.map((x: any) => {
          if (x.device_Id == device_Id) {
            x.updatemode = 0;
          }
        });
        let update_mode = 0;

        // Prepare data for API call
        const data = { update_mode, device_Id };
        // Call the API to change the device update mode
        this.dataservice.devicemodechange(data).subscribe((res: any) => {
          if (res.status == '201') {
            this.toastr.success('Device Mode Changed');
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
      // When the update mode is set to false (unchecked)
      else if (event.checked == false) {
        // Update the deviceslist and deviceupdatemode arrays to reflect the change
        this.deviceupdatemode.map((x: any) => {
          if (x.device_Id == device_Id) {
            x.updatemode = 1;
          }
        });
        this.deviceslist.map((x: any) => {
          if (x.device_id == device_Id) {
            x.update_mode = 1;
          }
        });
        let update_mode = 1;

        // Prepare data for API call
        const data = { update_mode, device_Id };
        // Call the API to change the device update mode
        this.dataservice.devicemodechange(data).subscribe((res: any) => {
          if (res.status == '201') {
            this.toastr.success('Device Mode Changed');
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
    }
    // When the user doesn't have permission
    else if (this.iscreate == false) {
      this.toastr.info("User not permitted");
    }
  }

  versionDelete(Key: any, version: any, product_Id: any) {
    if(this.isdelete){
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'delete',
      });
  
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          const data = { product_Id, version };
  
          this.dataservice.DeleteVersion(data).subscribe((res: any) => {
            if (res.status == "201") {
  
              this.toastr.success("Firmware deleted");
              this.showVersion.forEach((showVersion, i) => {
  
                this.showVersion[i] = true;
  
              });
              this.isCollapsed.forEach((collapse, i) => {
  
                this.isCollapsed[i] = true;
  
              });
              this.changeDetectorRef.detectChanges();
            } else if (res.status == "0") {
              this.toastr.info(res.data)
            } else {
              this.toastr.error("error occured")
            }
          })
  
  
  
        }
      })
  
  
  
    }else{
      this.toastr.info("User not permitted");
    }
    
  }

  FirmwareInfo(cluster_Id: any, version: any) {
    this.router.navigate(['/app/firmwareinfo/', cluster_Id, version])
  }

  Schedule(cluster_Id: any, version: any, firmwarename: any, scheduletime: any, schedule_action: any): void {
    console.log(cluster_Id, version, firmwarename, scheduletime, schedule)
    const dialogRef = this.matdialog.open(schedule, {
      data: { startDate: scheduletime == null ? new Date() : scheduletime, cluster_Id, version, firmwarename, schedule_action }, disableClose: true, width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



}

@Component({
  selector: 'OTAmatdialog',
  templateUrl: 'otamatdialog.html',
  styleUrls: ['matdialog.css'],
})
export class OTAmatdialog implements OnInit {
  loading: boolean;
  id: any;
  user_Id: any;
  data: any;
  productvalue: any;
  fileUploadForm: any;
  selectedFile: any;
  fileInputLabel: any;
  fileSizeDisplay: any;

  /**
  * Constructs an instance of the component.
  * @param {Dataservice} dataservice - The service used for data operations.
  * @param {FormBuilder} formbuilder - The form builder service for creating and managing forms.
  * @param {ToastrService} toastr - The service for displaying toast notifications.
  * @param {MatDialog} matdialog - The dialog service for displaying dialogs.
  * @param {SocketService} socket - The service for handling socket communication.
  * @param {AuthenticationService} authentication - The service for user authentication operations.
  * @returns {void}
  */
  constructor(
    private dataservice: Dataservice,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private matdialog: MatDialog,
    private socket: SocketService,
    private authentication: AuthenticationService
  ) {
    this.loading = false;
  }

  /**
  * Represents a form group for OTA update information.
  */
  otaupdateform = this.formbuilder.group({
    /**
     * The selected product for OTA update.
     */
    product: ['', Validators.required],

    /**
     * The current version of the product.
     */
    current: [''],


    /**
     * The selected version type for the OTA update.
     */
    versiontype: ['', Validators.required],

    /**
     * The selected version for the OTA update.
     */
    version: ['', Validators.required],

    firmwarename: ['', Validators.required],

    attempts: ['3', [Validators.required, Validators.min(1), Validators.max(10)]],
    attempts_reset_period: ['3', [Validators.required, Validators.min(1)]],
    interval_type: ['60', Validators.required],

    /**
     * Description of the OTA update (optional).
     */
    description: ['', Validators.maxLength(75)],
  });

  /**
   * Lifecycle hook called when the component is initialized.
   * Initializes the component's properties and subscribes to form control changes.
   * @returns {void}
   */
  ngOnInit(): void {
    this.loading = true;

    // Fetch user data for the current session
    const data = this.authentication.getUserData();

    // Fetch the list of products
    this.dataservice.getproduct(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });

    // Subscribe to product selection changes
    this.otaupdateform.controls.product.valueChanges.subscribe((x: any) => {
      const product_Id = x;
      if (product_Id) {
        // Fetch device version for the selected product
        this.dataservice.getdeviceversion(product_Id).subscribe((res: any) => {
          if (res.status == '200') {
            let version = res?.device_version;

            // Set the current version in the form
            if (version) {
              this.otaupdateform.controls.current.setValue(version);
            } else if (!version) {
              this.otaupdateform.controls.current.setValue("1.0");
            }

            const versiontype = this.otaupdateform.value.versiontype;

            // Automatically generate new version based on version type
            if (versiontype) {
              if (versiontype == 'Minor') {
                let version: any = this.otaupdateform.value.current;
                let sum;
                let lastversion;
                lastversion = version.slice(2, 3);
                if (lastversion == '0') {
                  sum = +version + 0.1;
                } else if (lastversion != '0') {
                  if (lastversion == '9') {
                    sum = +version.slice(0, 1) + 1 + '.0';
                  } else {
                    sum = version.slice(0, 2) + (+lastversion + 1);
                  }

                }
                this.otaupdateform.controls.version.setValue(String(sum));
              } else if (versiontype == 'Major') {
                let version: any = this.otaupdateform.value.current;
                let sum;
                let lastversion;
                lastversion = version.slice(2, 3);
                if (lastversion == '0') {
                  sum = +version + 1 + '.0';
                } else if (lastversion != '0') {
                  sum = +version.slice(0, 1) + 1 + '.0';
                }
                this.otaupdateform.controls.version.setValue(String(sum));
              } else {
                this.otaupdateform.controls.version.setValue('');
              }
            }

          } else {
            this.toastr.error('Error occurred');
          }
        });

        this.otaupdateform.controls.versiontype.valueChanges.subscribe(
          (x: any) => {
            if (x == 'Minor') {
              let version: any = this.otaupdateform.value.current;
              let sum;
              let lastversion;
              lastversion = version.slice(2, 3);
              if (lastversion == '0') {
                sum = +version + 0.1;
              } else if (lastversion != '0') {
                if (lastversion == '9') {
                  sum = +version.slice(0, 1) + 1 + '.0';
                } else {
                  sum = version.slice(0, 2) + (+lastversion + 1);
                }

              }
              this.otaupdateform.controls.version.setValue(String(sum));
            } else if (x == 'Major') {
              let version: any = this.otaupdateform.value.current;
              let sum;
              let lastversion;
              lastversion = version.slice(2, 3);
              if (lastversion == '0') {
                sum = +version + 1 + '.0';
              } else if (lastversion != '0') {
                sum = +version.slice(0, 1) + 1 + '.0';
              }
              this.otaupdateform.controls.version.setValue(String(sum));
            } else {
              this.otaupdateform.controls.version.setValue('');
            }
          }
        );
      }


    });

    // Initialize file upload form
    this.fileUploadForm = this.formbuilder.group({
      File: ['', Validators.required],
    });
  }

  /**
  * Event handler triggered when a file is selected for upload.
  * Updates the selected file in the form and updates the file input label.
  * @param {any} event - The event object containing the selected file.
  * @returns {void}
  */
  onFileChanged(event: any): void {

    const files: FileList = event.target.files;

    if (files.length > 0) {
      const fileSize = files[0].size;
    

      const fileSizeInKB = fileSize / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      this.fileSizeDisplay = `File Size: ${fileSizeInKB.toFixed(2)} KB, ${fileSizeInMB.toFixed(2)} MB`;
    } else {
      this.fileSizeDisplay = ''; // Reset the display if no file is selected
    }

    // Get the selected file from the event
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
    // Update the file input label with the selected file name
    this.fileInputLabel = this.selectedFile.name;


    // Set the selected file in the fileUploadForm control
    this.fileUploadForm.get('File').setValue(this.selectedFile);

    // Update the title of the file input element
    var title = document.getElementsByClassName('file')['0'];
    title.innerHTML = this.selectedFile.name;
  }

  /**
 * Initiates the OTA update process with the provided firmware file and update details.
 * Uploads the selected firmware file and related update information.
 * @returns {void}
 */
  Otaupdate(): void {
    // Set loading state to true
    this.loading = true;

    // Get form data from otaupdateform
    const otaupdateform = this.otaupdateform.value;

    // Get user data for user and organization information
    const user = this.authentication.getUserData();
    const user_Id = user.user_Id;
    const org_Id = user.org_Id;

    // Create a new FormData instance
    const formData = new FormData();

    // Extract version and product Id from the form data
    let version = this.otaupdateform.value.version;
    let product_Id = this.otaupdateform.value.product;

    // Append file to FormData with a specific name format
    formData.append(
      'File',
      this.fileUploadForm.get('File').value,
      String(product_Id + '-' + version) + '.bin'
    );

    // Append other update information to FormData
    formData.append('product_Id', String(product_Id));
    formData.append('versiontype', String(this.otaupdateform.value.versiontype));
    formData.append('firmwarename', String(this.otaupdateform.value.firmwarename));
    formData.append('interval_type', String(this.otaupdateform.value.interval_type));
    formData.append('attempts_reset_period', String(this.otaupdateform.value.attempts_reset_period));
    formData.append('attempts', String(this.otaupdateform.value.attempts));
    formData.append('version', String(version));
    formData.append(
      'description',
      String(this.otaupdateform.value.description).replace(/[^\S\r\n]{2,}/g, ' ')
    );
    formData.append('user_Id', String(user_Id));
    formData.append('Org_Id', String(org_Id));

    // Send FormData to trigger the OTA update process
    this.dataservice.Otaupdatefile(formData).subscribe((res: any) => {
      // Reset loading state
      this.loading = false;

      // Check response status and show appropriate toast message
      if (res.status == '201') {
        this.toastr.success('File Uploaded Successfully');
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }
}








@Component({
  selector: 'firmwareinfo',
  templateUrl: 'Firmwareinfo.html',
  styleUrls: ['./frimwareinfo.css'],
})
export class firmwareinfo implements OnInit, AfterViewInit {
  @ViewChild('selectVersion') selectVersion!: ElementRef;
  loading: any;
  FirmwareVersion: any;
  cluster_id: any;
  Versionlist: any[] = []
  clustername: any;
  Firmwareinfo: any;
  refresh: any[] = [];
  taskrefresh: any[] = []
  devicesfilter: any;
  data: any[] = [];
  deviceslist: any;
  devicehistroy: any[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  devicedataconfig: { id: string; itemsPerPage: number; currentPage: number };
  devicehistroyconfig: { id: string; itemsPerPage: number; currentPage: number };
  updatestatics: any; selectedTabIndex = 0;
  devicehistroyfilter: any;
  Firmware = this.formbuilder.group({
    firmware_version: ['', Validators.required],

  })
  selectversion: any;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  // Lifecycle hook after view initialization
  ngAfterViewInit() {
    this.deviceslist.paginator = this.paginator;
    // Accessing the native element of the selectVersion ViewChild
    const selectEl = this.selectVersion;

    console.log(selectEl);
  }
  updateversion = this.formbuilder.group({
    version: ['', Validators.required]
  });
  constructor(private _location: Location, private route: ActivatedRoute,
    private dataservice: Dataservice, private toastr: ToastrService,
    private formbuilder: FormBuilder, private matdialog: MatDialog, private socket: SocketService,private router:Router) {
    this.loading = true;
    this.devicedataconfig = {
      id: 'devicedata',
      itemsPerPage: 4,
      currentPage: 1,
    };
    this.devicehistroyconfig = {
      id: 'devicehistroy',
      itemsPerPage: 4,
      currentPage: 1,
    };
  }

  devicedatapageChanged(event: any) {
    this.devicedataconfig.currentPage = event;
  }

  devicehistroypageChanged(event: any) {
    this.devicehistroyconfig.currentPage = event;
  }

  ngOnInit(): void {
    this.cluster_id = this.route.snapshot.paramMap.get('id1');
    this.FirmwareVersion = this.route.snapshot.paramMap.get('id2');



    this.Firmware.controls.firmware_version.setValue(this.FirmwareVersion)

    this.GetFirwareinfo(this.cluster_id, this.FirmwareVersion);

    this.Firmware.controls.firmware_version.valueChanges.subscribe((res: any) => {
      if (res) {
        this.GetFirwareinfo(this.cluster_id, res)
      }
    })


  }


  async GetFirwareinfo(cluster_Id: any, version: any) {
    this.loading = true;
    const data = { cluster_Id, version }
    this.dataservice.FirmwareInfo(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.loading = false;
        this.clustername = res.data.cluster_name
        this.Firmwareinfo = res.data
        this.Versionlist = res.data.update_version
      } else {
        this.toastr.error('error occurred');
      }
    })
  }




  tabClick(event: any) {


    // if (event.index == 1) {

    //   const data = { cluster_Id: this.cluster_id, version: this.Firmwareinfo.Version }
    //   this.GetStatics(data)

    //   this.refresh.push(setInterval(() => {
    //     const data = { cluster_Id: this.cluster_id, version: this.Firmwareinfo.Version }
    //     this.GetStatics(data)
    //   }, 10000))

    // } else
     if (event.index == 1) {
      const data = { cluster_Id: this.cluster_id, version: this.Firmwareinfo.Version }
      this.GetDevices(data)
      this.taskrefresh.push(setInterval(() => {
        const data = { cluster_Id: this.cluster_id, version: this.Firmwareinfo.Version }
        this.GetDevices(data)
      }, 10000))
    } else if (event.index == 2) {
      const data = { cluster_Id: this.cluster_id, version: this.Firmwareinfo.Version };
      this.GetDeviceshistroy(data)
    } else {
      for (const intervalId of this.refresh) {
        clearInterval(intervalId);
      }

    }
  }

  ngOnDestroy() {
    for (const intervalId of this.refresh) {
      clearInterval(intervalId);
    }
    for (const intervalId of this.taskrefresh) {
      clearInterval(intervalId);
    }
  }

  GetDeviceshistroy(data: any) {
    this.dataservice.Getdevicehistroy(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.devicehistroy = res.data;

      }
      else {
        this.toastr.error('error occurred');
      }



    })
  }

  GetDevices(data: any) {
    this.dataservice.GetFirmwareinfodevice(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.data = res.data;

        if (this.deviceslist?.length >= 1) {
          this.data.map((x: any) => {
            this.deviceslist.map((y: any) => {
              if (x.device_id == y.device_id) {
                if (x.device_version != y.device_version) {
                  y.device_version = x.device_version
                }
                if (x.otahistory_update_version != y.otahistory_update_version) {
                  y.otahistory_update_version = x.otahistory_update_version
                }

                if (x.last_ota_update != y.last_ota_update) {
                  y.last_ota_update = x.last_ota_update
                }

                if (x.update_status != y.update_status) {
                  y.update_status = x.update_status
                }

                if (x.attempt_try != y.attempt_try) {
                  y.attempt_try = x.attempt_try
                }

                if (x.attempt_time != y.attempt_time) {
                  y.attempt_time = x.attempt_time
                }

                if (x.update_mode != y.update_mode) {
                  y.update_mode = x.update_mode
                }



              }
            })
          })

        } else {
          this.deviceslist = this.data;
          console.log(this.deviceslist)
        }

      } else {
        this.toastr.error('error occurred');
      }
    })

  }

  GetStatics(data: any) {

    this.dataservice.GetOtaStatics(data).subscribe((res: any) => {
      if (res.status == "200") {
        this.updatestatics = res.data
      } else {
        this.toastr.error('error occurred');
      }
    })

  }

  close() {
    this._location.back()
  }

  updatemode(event: any, device_Id: any) {
    // if (this.iscreate == true) {
    // When the update mode is set to true (checked)
    if (event.checked == true) {
      // Update the deviceslist and deviceupdatemode arrays to reflect the change

      let update_mode = 0;
      this.deviceslist.map((x: any) => {
        if (x.device_id == device_Id) {
          x.update_mode = 0;
        }
      });
      // Prepare data for API call
      const data = { update_mode, device_Id };
      // Call the API to change the device update mode
      this.dataservice.devicemodechange(data).subscribe((res: any) => {
        if (res.status == '201') {
          this.toastr.success('Device Mode Changed');
        } else {
          this.toastr.error('Error occurred');
        }
      });
    }
    // When the update mode is set to false (unchecked)
    else if (event.checked == false) {
      // Update the deviceslist and deviceupdatemode arrays to reflect the change

      let update_mode = 1;
      this.deviceslist.map((x: any) => {
        if (x.device_id == device_Id) {
          x.update_mode = 1;
        }
      });
      // Prepare data for API call
      const data = { update_mode, device_Id };
      // Call the API to change the device update mode
      this.dataservice.devicemodechange(data).subscribe((res: any) => {
        if (res.status == '201') {
          this.toastr.success('Device Mode Changed');
        } else {
          this.toastr.error('Error occurred');
        }
      });
    }
    // }
    // When the user doesn't have permission
    // else if (this.iscreate == false) {
    //   this.toastr.info("User not permitted");
    // }
  }


  /**
  * Initiates a manual OTA update based on user permissions.
  * If the user has create permission, retrieves the selected version from the form,
  * sends an update request to the server, emits a message through the socket,
  * and displays a success toastr.
  * If the user doesn't have create permission, displays a toastr info message.
  * @param {any} Key - The key associated with the OTA update.
  * @param {any} product_Id - The ID of the product to update.
  * @returns {void}
  */
  OTAMANUAL(Key: any, product_Id: any): void {


    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true,
      data: 'Update',
    });

    MatDialogConfig.afterClosed().subscribe(async (result: any) => {
      if (result.confirmation == true) {
        const version = this.selectversion
        let checking = true;
        const value = { product_Id, version, "device_auth_token": Key };

        await Promise.all(this.deviceslist.map((c: any) => {

          if (c.device_auth_token == Key) {
            if (c.device_version == version) {
              checking = true
            } else {
              checking = false
            }
          }
        }))

        if (!checking) {
          this.dataservice.devicemanualupdate(value).subscribe((res: any) => {

            if (res.status == '201') {
              this.toastr.success('OTA Update In Progress!');
              const data = { Key, Method: 'OTAUpdate', Version: version };
              this.socket.emit('message', data);
            } else {
              this.toastr.error('Error occurred');
            }
          });
        } else {
          this.toastr.info('Already Running Version');
        }


      }
    });



  }



  OTAUPDATE(Key: any, version: any, product_Id: any): void {


    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true,
      data: 'Update',
    });

    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
         version = this.selectversion
        const value = { product_Id, version };

        this.dataservice.updatenow(value).subscribe((res: any) => {

          if (res.status == '201') {
            this.toastr.success('OTA Update In Progress!');
            const data = { Key, Method: 'OTAUpdate', Version: version };
            this.socket.emit('message', data);
          } else if (res.status == '0') {
            this.toastr.info("Already Devices Running Same Version")
          } else {
            this.toastr.error('Error occurred');
          }
        });
       

      }
    });

  }

  onSelectChange(selectedValue: any) {
    this.selectversion = selectedValue.value // Here you can access the selected option value
  }

  Schedule(cluster_Id: any, version: any, firmwarename: any, scheduletime: any, schedule_action: any): void {
    console.log(cluster_Id, version, firmwarename, scheduletime, schedule)
    const dialogRef = this.matdialog.open(schedule, {
      data: { startDate: scheduletime == null ? new Date() : scheduletime, cluster_Id, version, firmwarename, schedule_action }, disableClose: true,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  versionDelete(Key: any, version: any, product_Id: any) {
    const MatDialogConfig = this.matdialog.open(deletepopup, {
      disableClose: true,
      data: 'delete',
    });

    MatDialogConfig.afterClosed().subscribe((result: any) => {
      if (result.confirmation == true) {
        const data = { product_Id, version };

        this.dataservice.DeleteVersion(data).subscribe((res: any) => {
          if (res.status == "201") {

            this.toastr.success("Firmware deleted");
         
            this.router.navigate(['/app/ota']);
     
          } else if (res.status == "0") {
            this.toastr.info(res.data)
          } else {
            this.toastr.error("error occured")
          }
        })



      }
    })



  }

}

@Component({
  selector: 'schedule',
  templateUrl: 'schedule.html',
  styleUrls: ['./matdialog.css'],
})
export class schedule implements OnInit {

  startDate: string;
  minDate: string;
  maxDate: string;
  constructor(
    public dialogRef: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const currentDate: any = new Date();
    const nextYear: any = new Date(currentDate);
    nextYear.setFullYear(nextYear.getFullYear() + 1);

    this.minDate = this.convertToDateTimeLocal(currentDate);
    this.maxDate = this.convertToDateTimeLocal(nextYear);
    this.data = data
    this.startDate = this.convertToDateTimeLocal(data.startDate);
  }
  ngOnInit(): void {
    console.log(this.data)
  }

  onCancelClick(): void {
    this.dialogRef.closeAll();
  }


  convertToDateTimeLocal(isoString: string): string {
    const date = new Date(isoString);
    const pad = (n: number) => n.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }



  onToggleChange(event: any) {

    event.checked;

    if (event.checked) {
      this.data.schedule_action = '1';
    } else {

      this.data.schedule_action = '0';
      // this.toastr.info("Production Mode")
    }
  }






  onApplyClick(): void {
    const data = { "scheduletime": this.startDate, ...this.data }
    this.dataservice.CreateSchedule(data).subscribe((res: any) => {
      if (res.status == "201") {
        this.toastr.success("Schedule Created");
        this.dialogRef.closeAll();
      } else if (res.status == "0") {
        this.toastr.success("Schedule created but no auto-update devices.");
        this.dialogRef.closeAll();
      }
       else if (res.status == "1") {
        this.toastr.info("Already Devices Running Same Version")
        this.dialogRef.closeAll();
      }
      else {
        this.toastr.error('error occurred');
      }
    })



  }
}




