import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../Authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { GoogleLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
import { Clipboard } from '@angular/cdk/clipboard';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  loading: boolean;
  isLogin: boolean = false;
  hide = true;
  errorMessage = '';
  submitted = false;
  public data: any;
  user_id: any;
  user: any;
  showGoogleSignup: boolean;
  loggedIn: any;
  googleauthentication: any;
  private accessToken = '';
  receivedMessage = '';
  newusersignin: boolean;
  username: any;

  /**
   * Creates an instance of the component.
   * @param {Router} router - A service that provides navigation among views and URL manipulation capabilities.
   * @param {AuthenticationService} authentication - The authentication service for user authentication.
   * @param {UntypedFormBuilder} formbuilder - The FormBuilder service for creating forms.
   * @param {ToastrService} toastr - The Toastr service for displaying messages.
   * @param {Dataservice} dataservice - The data service for managing data.
   * @param {SocialAuthService} authService - The service for social authentication.
   * @param {AppComponent} appcomponent - The main app component.
   * @param {ActivatedRoute} route - The active route.
   */

  constructor(
    //Router A service that provides navigation among views and URL manipulation capabilities.
    private router: Router,
    private authentication: AuthenticationService,
    private formbuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private dataservice: Dataservice,
    private authService: SocialAuthService,
    private appcomponent: AppComponent,
    private route: ActivatedRoute,
   
  ) {
    // Set initial loading state
    this.loading = false;

    // Perform user sign-out actions
    this.signOut();

    // Set Google sign-up display to false
    this.showGoogleSignup = false;

    // Set new user sign-in state to false
    this.newusersignin = false;

  }


  signin = this.formbuilder.group({
    // Signin form validation
    email: ['', [Validators.required, Validators.email]], // Email form control with required and email validators
    password: ['', [Validators.required]], // Password form control with required validator
  });

  /**
 * Implements the ngOnInit lifecycle hook.
 * This hook is called when the component is initialized.
 */
  ngOnInit(): void {
    
    this.dataservice.setPaginationState(null, 'all');
    // Update device status information using the dataservice
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();

    // Subscribe to the Google authentication state
    this.googleauthentication = this.authService.authState.subscribe((user: any) => {
      this.user = user;
      if (this.user) {
        // Prepare data for Google sign-in
        const data = { "email": user.email, "id": user.id };

        // Call the dataservice to sign in using Google
        this.dataservice.googlesignin(data).subscribe((res: any) => {


          if (res.status == "201" && res.data) {
            // Extract user data from the response
            let user_Id = res.data?.user_Id;
            let role = res.data?.role_name;
            let lastlogin = res.data?.lastlogin;
            let username = res.data?.firstname;
            let isactive = res.data?.isactive;
            let org_Id = res.data?.org_Id;
            let vertical_Id = res.data?.vertical_Id;
            let any_changes = res.data?.any_changes;
            let f_logout = res.data?.f_logout;

            // Prepare user data for saving
            const userData = {
              user_Id, role, org_Id, vertical_Id, username, isactive, any_changes, f_logout
            };

            const roles = res.data?.group_permission;

            // Save user data and roles using the authentication service
            this.authentication.saveUserData(userData, roles);


            if (!lastlogin) {
              this.toastr.success("Successfully Logged In");
              // Navigate to the appropriate route based on last login
              this.appcomponent.Getproductdata(userData);
              this.router.navigate(['/quickstart']);
            } else {
              this.toastr.success("Successfully Logged In");

              this.appcomponent.Getproductdata(userData);
              if (userData.vertical_Id) {
                this.router.navigate(['/app/devices']);
              } else {
                this.router.navigate(['/app/mydevices']);
              }

            }
          } else if (res.status == "200") {
            this.toastr.info("Oops! Looks like you haven't signed up yet. Please do so before trying to sign in.");
            this.router.navigate(['/app/signup']);
          } else if (res.status == "202") {
            this.toastr.info("Oops! It seems like you've already signed up using our application. Please use the login option to access your account.");
          } else {
            this.toastr.error("An error occurred");
          }
        });
      }
    });

    // Create the 'signin' form group with validation rules
    this.signin = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    // Check if 'id' is present in the route parameters
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      localStorage.clear();
    } else {
      this.router.navigate(['/app/Verticaldash']);
    }
  }

  /**
   * Receives a message from an event.
   * @param {any} $event - The received event message.
   */
  receiveMessage($event: any): void {
    // Update received message
    this.receivedMessage = $event;

    // Parse received message as boolean and update 'showGoogleSignup'
    this.showGoogleSignup = JSON.parse(this.receivedMessage);

    // Reset the user information
    this.user = '';
  }


  /**
 * Get a dictionary of form controls.
 * @returns { { [key: string]: AbstractControl } } - A dictionary containing form controls.
 */
  get f(): { [key: string]: AbstractControl } {
    return this.signin.controls;
  }
  /**
  * Handles the form submission.
  */
  onSubmit(): void {
    this.submitted = true;
    if (this.signin.invalid) {
      return;
    } else { 
      if(navigator.onLine){
        this.signIn();
      }else{
        this.toastr.error("No Internet Connection")
        
      }
      
    }
  }

  /**
 * Handles the user sign-in process.
 */
  public signIn(): void {
    // Set loading state to indicate sign-in process is in progress
    this.loading = true;

    // Retrieve user input data from the signin form
    const data = this.signin.value;

    // Make a sign-in request using dataservice
    this.dataservice.signin(data).subscribe((res: any) => {

      // Turn off loading state
      this.loading = false;

      // Store response data
      this.data = res.data;

      // Check response status
      if (res.status == "200" && res.data) {

        // Extract user-related information from response data
        let lastlogin = this.data?.lastlogin;
        let username = this.data?.firstname;
        let user_Id = this.data?.user_Id;
        let role = this.data?.role_name;
        let isactive = this.data?.isactive;
        let org_Id = this.data?.org_Id;
        let vertical_Id = this.data?.vertical_Id;
        let any_changes = this.data?.any_changes;
        let f_logout = this.data?.f_logout;
        let gateway = this.data?.gateway_id;
        let user_type = this.data?.user_type;
        let superadmin = false
        if(vertical_Id == null){
          superadmin  = true 
        }
        // Create user data object
        const userData = {
          user_Id,
          role,
          org_Id,
          vertical_Id,
          username,
          isactive,
          any_changes,
          f_logout,superadmin, gateway,user_type
        };

        // Extract roles data from response
        const roles = res.data?.group_permission;

        // Save user data and roles using authentication service
        this.authentication.saveUserData(userData, roles);
        this.dataservice.filterreset()
        // Navigate user based on last login status
        if (!lastlogin) {

          this.appcomponent.Getproductdata(userData);
          // Display success message using toastr
          this.toastr.success("Successfully Logged In");
          this.router.navigate(['/app/Verticaldash']);
        } else {

          this.appcomponent.Getproductdata(userData);
          if (userData.vertical_Id) {
            // Display success message using toastr
            this.toastr.success("Successfully Logged In");
            this.router.navigate(['/app/Verticaldash']);
          } else {
            // Display success message using toastr
            this.toastr.success("Successfully Logged In");
            this.router.navigate(['/app/Verticaldash']);
          }

        }
      }
      // Handle different error scenarios
      else if (res.status == "400") {
        this.errorMessage = "error occurred";
        this.toastr.error("error occurred");
      }
      else if (res.status == "204" || res.status == "206") {
        this.toastr.error("Incorrect Email/Password");
        this.errorMessage = "Incorrect Email/Password";
      } else {
        this.toastr.error("Incorrect Email/Password");
        this.errorMessage = "Incorrect Email/Password";
      }
    });
  }


  /**
  * Lifecycle hook that is called when the component is being destroyed.
  * It unsubscribes from the Google authentication subscription.
  */
  ngOnDestroy(): void {
    // Unsubscribe from the Google authentication subscription
    this.googleauthentication.unsubscribe();
  }

  /**
  * Signs the user out by using the authentication service's `signOut` method.
  * Clears the user data and sets the user to `null`.
  */
  signOut(): void {
    /**
     * Calls the authentication service's `signOut` method to sign the user out.
     * Once signed out, the user data is cleared and the user property is set to `null`.
     * @returns {Promise<void>} A promise that resolves when the sign out process is complete.
     * @throws {any} Throws an error if there's an issue during the sign out process.
     */
    this.authService.signOut().then(() => {
      this.user = null;
    }).catch((error) => {
      console.log(error);
    });
  }
}

//new user signin
@Component({
  selector: 'newusersignin',
  templateUrl: 'newusersignin.html',
  styleUrls: ['./signin.component.css'],
})
export class newusersignin implements OnInit {
  hardwaretype: any
  page: number = 1;
  deviceName: string = '';
  selectedHardware: string = '';
  device_auth_token: any;
  product_api_Id: any;
  initialpage: boolean = true;
  hardwareSelected: boolean = false;


  /**
 * FormGroup for managing device name and cluster name input fields.
 */
  devicename = this.formbuilder.group({
    /**
     * The input field for the device name.
     */
    deviceName: ['', [Validators.required]],
    /**
     * The input field for the cluster name.
     */
    clusterName: ['', [Validators.required]],
  });
  formvalue: any;
  userid: any;
  user_data: any;
  showTutorial!: boolean;
  user_name: any;

  /**
 * Creates an instance of the component.
 * @param {Router} router - A service that provides navigation among views and URL manipulation capabilities.
 * @param {Dataservice} dataservice - The data service for managing data.
 * @param {ToastrService} toastr - The Toastr service for displaying messages.
 * @param {UntypedFormBuilder} formbuilder - The FormBuilder service for creating forms.
 * @param {AuthenticationService} authentication - The authentication service for user authentication.
 * @param {Clipboard} clipboard - The Clipboard service for copying content to the clipboard.
 */
  constructor(
    private router: Router,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formbuilder: UntypedFormBuilder,
    private authentication: AuthenticationService,
    private clipboard: Clipboard
  ) {

  }


  /**
   * Navigates to the main page using the router outlet.
   */
  onNoClick(): void {
    this.router.navigate(['/app/mydevices']);
  }
  /**
  * Handles the "Yes" button click.
  * Sets the `showTutorial` variable to true to display the tutorial.
  */
  onYesClick(): void {
    this.showTutorial = true;
  }
  /**
  * Lifecycle hook called after the component's view has been initialized.
  * Initializes data and retrieves hardware types from the data service.
  */
  ngOnInit(): void {
    // Initialize data for MyDevicestatus, AllDevicestatus, and singleDevicestatus
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();

    // Get the user name from the authentication service
    this.user_name = this.authentication.getUserData().username;

    // Retrieve hardware types from the data service
    this.dataservice.hardwaretype().subscribe((res: any) => {
      if (res.status == '200') {
        this.hardwaretype = res.data;
      } else {
        this.toastr.error("Error Occurred");
      }
    });
  }

  /**
  * Event handler for when a hardware type is selected.
  * @param event - The event object containing the selected hardware type.
  */
  onHardwareSelect(event: any): void {
    // Update the selectedHardware with the chosen hardware type
    this.selectedHardware = event.target.value;

    // Indicate that a hardware is selected
    this.hardwareSelected = true;

    // Set the value of the clusterName control based on the selected hardware
    this.devicename.controls['clusterName'].setValue("Default" + this.selectedHardware);
  }

  /**
 * Copies a formatted text containing authentication token and product API ID to the clipboard.
 * @param authtoken - The authentication token value.
 * @param product_api_Id - The product API ID value.
 */
  copyText(authtoken: any, product_api_Id: any): void {
    // Set authtoken to an empty string if it's falsy
    if (!authtoken) {
      authtoken = '';
    }

    // Compose the value to be copied to the clipboard
    const value =
      '#include <Devsbot.h>\n\n' +
      '#define DBOT_AUTH_TOKEN "' + authtoken + '"\n' +
      '#define DBOT_CLUSTER_ID "' + product_api_Id + '"\n' +
      '#define WIFI_SSID "" //Replace Your WIFI_SSID\n' +
      '#define WIFI_PASSWORD "" //Replace Your WIFI_PASSWORD\n\n' +
      'void setup()\n' +
      '{\n' +
      '  Serial.begin(115200);\n' +
      '  dBot.begin(DBOT_CLUSTER_ID, DBOT_AUTH_TOKEN, WIFI_SSID, WIFI_PASSWORD);\n' +
      '}\n\n' +
      'void loop()\n' +
      '{\n' +
      '  // put your main code here, to run repeatedly\n' +
      '  dBot.Loop();\n' +
      '}';

    // Copy the composed value to the clipboard
    this.clipboard.copy(value);

    // Display a success message
    this.toastr.success('Copied to Clipboard');
  }



  /**
 * Increments the current page number if it's less than 5.
 * Used for navigating to the next page.
 */
  onNextPage(): void {
    if (this.page < 5) {
      this.page++;
    }
  }

  /**
   * Decrements the current page number if it's greater than 1.
   * Used for navigating to the previous page.
   */
  onPreviousPage(): void {
    if (this.page > 1) {
      this.page--;
    }
  }

  /**
   * Initiates the download of a ZIP file using the `downloadzip` method of the `dataservice`.
   * The method is responsible for handling the actual download process.
   */
  downloadzip(): void {
    this.dataservice.downloadzip();
  }
  /**
  * Handles the form submission to create a new device.
  * Retrieves user data, form values, and selected hardware to create the device.
  * Makes a request to the data service to create a new user device.
  */
  onSubmit(): void {
    // Get user data and form values
    this.user_data = this.authentication.getUserData();
    this.formvalue = this.devicename.value;

    // Prepare data for device creation
    const data = {
      formvalue: this.formvalue,
      data: this.user_data,
      hardware: this.selectedHardware
    };

    // Send request to create new user device
    this.dataservice.CreateNewUserDevice(data).subscribe((res: any) => {
      if (res.status == '201') {

        this.device_auth_token = res.device_auth_token;
        this.product_api_Id = res.product_Id;
        // this.toastr.success("Device created successfully")
      } else {
        this.toastr.error("Error Occurred");
      }
    });
  }

  /**
  * Calculates the width of the progress bar based on the current page.
  * Each page represents 25% progress, so the width is calculated as (page - 1) * 25%.
  * @returns {string} The calculated width as a CSS string value.
  */
  progressWidth(): string {
    return `${(this.page - 1) * 25}%`;
  }


}








