import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from './dataservice';
import { HomeComponent } from '../home/home.component';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private app_unsubscribe = false;
  private loop=false;

  constructor(private toastr: ToastrService,private dataservice:Dataservice) {
   
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Register event listeners for focus and blur events on the window object
    this.registerEventListeners();
    
    //  window.addEventListener('blur',(event:any)=>{
    //   this.app_unsubscribe = true;
     
    //  })
    //  window.addEventListener('focus',(event:any)=>{
    //   this.app_unsubscribe=false;
      
    //  })
    
    this.loop=this.dataservice.getadsloop()
   
    
    if (!this.app_unsubscribe||this.loop) {
      // console.log("working")
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            // this.toastr.error(error.message, 'Error');
            console.error('An error occurred:', error.message);
            return throwError(error);
          })
        )
    } else {
      // console.log("stop")
      return EMPTY;
    }

  }

  private registerEventListeners(): void {
    
    // Check if event listeners are already registered to avoid duplicates
    if (!this.eventListenersRegistered()) {
      // Register focus event listener
      window.addEventListener('focus', this.onFocus.bind(this));
      // Register blur event listener
      window.addEventListener('blur', this.onBlur.bind(this));
    }
  }

  private eventListenersRegistered(): boolean {
    // console.log(window.onfocus);
    // console.log(window.onblur)
    // Check if focus and blur event listeners are already registered
    return window.onfocus !== null && window.onblur !== null;
    
  }

  private onFocus(event: FocusEvent): void {
    this.app_unsubscribe = false;
    // console.log('Window focused:', event);
  }

  private onBlur(event: FocusEvent): void {
    this.app_unsubscribe = true;
    // console.log('Window blurred:', event);
  }



}


