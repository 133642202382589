<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>

<div class="info-main" *ngIf="!loading">
  <div class="right-side">
    <div class="right-side-heading">
      <div class="close-icon">
        <mat-icon class="mat-icon-close" (click)="close()">arrow_back</mat-icon>
        <h1 class="head-name">
          {{this.clustername}}
        </h1>
      </div>
      <div class="select-version" [formGroup]="Firmware">
        <mat-form-field appearance="fill">
          <mat-label>Select a Version</mat-label>
          <mat-select formControlName="firmware_version">
            <mat-option value="{{ item.version }}" *ngFor="let item of Versionlist">{{ item.version }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>




    <div class="device-dashbrd">
      <div id="right-side" class="right-side-head">
        <div class="device-icon">
          <iconify-icon icon="pepicons-pop:file"></iconify-icon>
        </div>


        <div>
          <div class="right-side-title-one">
            <span class="devicename">{{ Firmwareinfo.firmwarename }}</span>
            <!-- <div>
             
              <i class="fa-solid fa-ellipsis" [matMenuTriggerFor]="menu" id="more-vert"></i>
              <mat-menu #menu="matMenu">
                <button [ngClass]="{ disabled: Firmwareinfo.autoupdate_ready == '1'||Firmwareinfo.schedule==1 }"
                  *ngIf="Firmwareinfo.autoupdate_ready == '0' || Firmwareinfo.autoupdate_ready == '1'" (click)="OTAUPDATE(
                  Firmwareinfo.cluster_api_Id,
                  Firmwareinfo.Version,
                  Firmwareinfo.cluster_id
                )" mat-menu-item class="mat-menu-button">
                  <mat-icon class="menu-btn-icon">keyboard</mat-icon>Update now
                </button>

                <button mat-menu-item
                  (click)="Schedule(Firmwareinfo.cluster_id,Firmwareinfo.Version,Firmwareinfo.firmwarename,Firmwareinfo.schedule_time,Firmwareinfo.schedule)"
                  [ngClass]="{ disabled: Firmwareinfo.autoupdate_ready == '1' }" class="mat-menu-button">
                  <mat-icon class="menu-btn-icon">query_builder</mat-icon>Schedule
                </button>
                <button mat-menu-item class="mat-menu-button" (click)="versionDelete(Firmwareinfo.cluster_id,Firmwareinfo.Version,Firmwareinfo.cluster_id)">
                  <mat-icon class="menu-btn-icon">delete</mat-icon>Delete
                </button>
              </mat-menu>
            </div> -->
          </div>
          <div class="right-side-title-two">
            <div class="device-info">
              <iconify-icon icon="mingcute:version-line"></iconify-icon>
              <h3 style="color: #3f51b5">{{ Firmwareinfo.Version }}</h3>
            </div>
            <div class="device-info" style="cursor: default">

              <iconify-icon
              class="sidenav-icon"
              icon="bxs:microchip"
            ></iconify-icon>
              <h3 style="color: #3f51b5">{{clustername }}</h3>
            </div>
          </div>
        </div>
      </div>

    </div>




    <div class="mat-tab-group">
      <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="tabClick($event)">
        <mat-tab label="Info">
          <div class="info-body">
            <div>
              <div class="info-content">
                <h3>Version</h3>
                <p>{{ Firmwareinfo.Version}}</p>
              </div>
              <div class="info-content">
                <h3>Gateway</h3>
                <p>{{clustername}}</p>
              </div>
              <div class="info-content">
                <h3>Created On</h3>
                <p>{{Firmwareinfo.Createdon |date:'short' }}</p>
              </div>
              <div class="info-content" *ngIf="Firmwareinfo.description">
                <h3>Description</h3>
                <p>{{Firmwareinfo.description }}</p>
              </div>

              <div class="info-content">
                <h3>Attempts Limit</h3>
                <p>{{Firmwareinfo.attempt }}</p>
              </div>
              <div class="info-content">
                <h3>Attempts Reset Period</h3>
                <p>{{Firmwareinfo.attempts_reset_period}} {{Firmwareinfo.interval_type == 60? 'MIN':'HOUR' }}</p>
              </div>
            </div>
            <div>
              <div class="info-content">
                <h3>Schedule</h3>
                <mat-slide-toggle [disabled]="true" [color]="'primary'" [checked]="Firmwareinfo.schedule==1">

                </mat-slide-toggle>
              </div>
              <div class="info-content">
                <h3>Schedule Time</h3>
                <p>{{Firmwareinfo.schedule_time ==null ? '--:--':Firmwareinfo.schedule_time |date:'short' }}</p>
              </div>
            </div>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Statistics">
          <div class="info-body">
            <div>
              <div class="info-content">
                <h3>All Device</h3>
                <p>{{updatestatics?.alldevice}}</p>
              </div>
              <div class="info-content">
                <h3>Auto Device</h3>
                <p>{{updatestatics?.autoupdate}}</p>
              </div>
              <div class="info-content">
                <h3>Manual Device</h3>
                <p>{{updatestatics?.manualupdate}}</p>
              </div>
              <div class="info-content">
                <h3>Updated Devices Count</h3>
                <p>{{updatestatics?.version_1_2}}</p>
              </div>
              <div class="info-content">
                <h3>Process Devices Count</h3>
                <p>{{updatestatics?.processdevice}}</p>
              </div>
            </div>
            <div>

              <div class="info-content">
                <h3>Failed Devices Count</h3>
                <p>{{updatestatics?.faileddevice}}</p>
              </div>
            </div>


          </div>
        </mat-tab> -->
        <mat-tab label="Task">
          <div *ngIf="this.deviceslist?.length>=1" class="datastream-header">
            <div class="datastreamsearch">
              <div class="datastream-length" style="position:sticky;left:10px">
                <h3>No Of Gateway: {{ this.deviceslist?.length }}</h3>
              </div>
              <!-- <div class="search">
                <mat-icon class="search-icon" (click)="datastreamsearch.focus()">search</mat-icon>
                <input class="form-control datastream-search-input" type="search" #datastreamsearch
                  placeholder="Search Gateways" [(ngModel)]="devicesfilter" />
              </div> -->

            </div>
          </div>
          <div  *ngIf="this.deviceslist?.length>=1" style="height: 44vh;">
            <div class="devices-table devicedata">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Gateway Name</th>
                    <th>Runing Version </th>
                    <th>Update Version</th>
                    <th>Attempt Count</th>
                    <th>Attempt Time</th>
                    <th>Last Updated</th>
                    <th>Status</th>
                    <!-- <th>Update Mode</th> -->
                    <th>Select Update</th>
                    <th>Opertion</th>
                  </tr>
                </thead>

                <tr *ngIf="deviceslist?.length==0" class="no-data">
                  <td colspan="7">
                    <mat-icon> insert_drive_file</mat-icon>
                    <h5>No Data</h5>
                  </td>
                </tr>
                <tr *ngFor="
                        let item of deviceslist
                          | filter : devicesfilter
                          | paginate : devicedataconfig;
                        let i = index
                      ">
                  <td>
                    {{ (this.devicedataconfig.currentPage - 1) * 4 + i + 1 }}
                  </td>
                  <td>{{ item.cluster_name }}</td>
                  <td>{{ item.device_version }}</td>
                  <td>{{ item.otahistory_update_version }}</td>
                  <td>
                    {{item.attempt_try==null ? '0':item.attempt_try}} of {{item.attempt}}
                  </td>
                  <td>
                    {{item.attempt_time | date:'short'}}
                  </td>
                  <td>{{ item.last_ota_update | date:'short' }}</td>
                  <td>
                    <div *ngIf="item.update_status==0" class="ota-status">
                      <iconify-icon style="color:rgb(1, 48, 102)" icon="mdi:progress-clock"></iconify-icon>
                      <span>Pending</span>
                    </div>
                    <div *ngIf="item.update_status==2" class="ota-status">
                      <iconify-icon style="color:red" icon="charm:circle-cross"></iconify-icon>
                      <span>Failed</span>
                    </div>
                    <div *ngIf="item.update_status==1" class="ota-status">
                      <iconify-icon style="color:rgb(0, 238, 255)" icon="line-md:uploading-loop"></iconify-icon>
                      <span>Progress</span>
                    </div>
                    <div *ngIf="item.update_status==null" class="ota-status">
                      <iconify-icon style="color:rgb(0, 255, 98)" icon="mdi:progress-check"></iconify-icon>
                      <span>Success</span>
                    </div>
                  </td>
                  <!-- <td> <mat-slide-toggle color="primary" [checked]="item.update_mode == '0'"
                      (change)="updatemode($event, item.device_id)"></mat-slide-toggle></td> -->
                  <td [formGroup]="updateversion">
                    <select class="form-select" (change)="onSelectChange($event.target)">
                      <option value="{{ item.device_version }}">
                        {{ item.device_version }}
                      </option>
                      <option #selectVersion class="form-control" [value]="list"
                        *ngFor="let list of item.allversion; let i = index">
                        {{ list }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <div
                      [ngStyle]="{'padding': (item.update_status == '0' || item.update_status == '1') ? '0px 29px' : '0px 0px','position':(item.update_status == '0' || item.update_status == '1') ? 'relative' : 'none','display':(item.update_status == '0' || item.update_status == '1') ? 'flex' : 'block','justify-content':(item.update_status == '0' || item.update_status == '1') ? 'center' : ''}"
                      [matTooltip]="
                    item.update_mode != '1' ? 'Auto update is enabled' : ''
                    " matTooltipPosition="left">


                      <button class="btn btn-info Update"
                        [disabled]="item.update_status == '0'||item.update_status == '1'"
                        (click)="OTAUPDATE(item.cluster_api_Id,'', item.cluster_id)" mat-icon-button
                        aria-label="Example icon button with a vertical three dot icon">
                        <iconify-icon icon="material-symbols:file-upload"></iconify-icon>
                      </button>
                      <!-- <mat-spinner role="progressbar" diameter="30" aria-hidden="true" *ngIf="item.update_status =='0' &&item.update_status !='2'"
                        mode="indeterminate"></mat-spinner>
                      <mat-spinner role="progressbar" diameter="30" aria-label="Uploading"
                        *ngIf="item.update_status =='1' && item.update_status !='2'" mode="determinate" [value]="item.progress">
                      </mat-spinner> -->

                    </div>
                  </td>
                </tr>
              </table>
             <!-- <pagination-controls class="pagination" id="devicedata" (pageChange)="devicedatapageChanged($event)"> -->
              <!-- </pagination-controls> -->



            </div>
          </div>
          <div style="display: flex;
          justify-content: center;
          height: 43vh;
          align-items: center;" *ngIf="this.deviceslist?.length<=0">
            <h1 style="font-weight: 500;">
              No Task
            </h1>
          </div>

        </mat-tab>
        <mat-tab label="Gateway Statistics">
          <div *ngIf="this.devicehistroy.length>=1" class="datastream-header">
            <div class="datastreamsearch">
              <div class="datastream-length" style="position:sticky;left:10px">
                <h3>No Of Gateways: {{ this.devicehistroy.length }}</h3>
              </div>
              <!-- <div class="search">
                <mat-icon class="search-icon" (click)="datastreamsearch.focus()">search</mat-icon>
                <input class="form-control datastream-search-input" type="search" #datastreamsearch
                  placeholder="Search Devices" [(ngModel)]="devicehistroyfilter" />
              </div> -->

            </div>
          </div>
          <div *ngIf="this.devicehistroy.length>=1" style="height: 44vh;">
            <div class="deviceshistroy-table devicedata">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Gateway Name</th>
                    <th>Version</th>
                    <th>Attempt Count</th>
                    <th>Attempt Time</th>
                    <th>Failed Time</th>
                    <th>Last Updated</th>
                    <th>Status</th>

                  </tr>
                </thead>

                <tr *ngIf="devicehistroy?.length==0" class="no-data">
                  <td colspan="7">
                    <mat-icon> insert_drive_file</mat-icon>
                    <h5>No Data</h5>
                  </td>
                </tr>
                <tr *ngFor="
                        let item of devicehistroy
                          | filter : devicehistroyfilter
                          | paginate : devicehistroyconfig;
                        let i = index
                      ">
                  <td>
                    {{ (this.devicehistroyconfig.currentPage - 1) * 4 + i + 1 }}
                  </td>
                  <td>{{ item.cluster_name }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.attempt }}</td>

                  <td>
                    {{item.attempt_time | date:'short'}}
                  </td>
                  <td>{{ item.failed_time | date:'short' }}</td>
                  <td>{{ item.last_ota_update | date:'short' }}</td>
                  <td>
                    <div *ngIf="item.status==0" class="ota-status">
                      <iconify-icon style="color:rgb(1, 48, 102)" icon="mdi:progress-clock"></iconify-icon>
                      <span>Pending</span>
                    </div>
                    <div *ngIf="item.status==2" class="ota-status">
                      <iconify-icon style="color:red" icon="charm:circle-cross"></iconify-icon>
                      <span>Failed</span>
                    </div>
                    <div *ngIf="item.status==1" class="ota-status">
                      <iconify-icon style="color:rgb(0, 255, 98)" icon="mdi:progress-check"></iconify-icon>
                      <span>Success</span>
                    </div>
                  </td>



                </tr>
              </table>
              <!-- <pagination-controls class="pagination" id="devicehistroy"
                (pageChange)="devicehistroypageChanged($event)">
              </pagination-controls> -->



            </div>
          </div>
          <div style="display: flex;
          justify-content: center;
          height: 43vh;
          align-items: center;" *ngIf="this.devicehistroy.length<=0">
            <h1 style="font-weight: 500;">
              No data
            </h1>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

</div>