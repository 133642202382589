<div class="content-matdialog-head">
  <div class="matdialog-head">
    <div>
      <h1>Create New Gateway</h1>
    </div>
    <div>
      <div>
        <mat-icon mat-dialog-close>close</mat-icon>
      </div>
    </div>
  </div>
  <div class="matdialog-content">
    <div *ngIf="loading" style="height:25vh">
      <mat-spinner role="progressbar" diameter="50" aria-hidden="true"  id="spinner"></mat-spinner>
    </div>

    <form *ngIf="!loading" [formGroup]="productform">


      <div class="form-field">
        <label class="form-label">name</label><span style="font-size: 10px; color: red; position: absolute"> *</span>
        <input class="form-control" formControlName="name" placeholder="Enter the name" />
        <div *ngIf="productform.controls['name'].dirty">
          <span *ngIf="productform.controls['name']?.errors?.['required']"
            style="font-size: 10px; color: red; position: absolute">
            *Name is required.
          </span>
          <span
            *ngIf="productform.controls['name']?.errors?.['minlength']&&!productform.controls['name']?.errors?.['pattern']"
            style="font-size: 10px; color: red; position: absolute">
            *Minimum 2 characters
          </span>
          <span *ngIf="productform.controls['name']?.errors?.['maxlength']"
            style="font-size: 10px; color: red; position: absolute">
            *Maximum 20 characters
          </span>
          <span *ngIf="productform.controls['name']?.errors?.['pattern']"
            style="font-size: 10px; color: red; position: absolute">
            Special character not allowed
          </span>
        </div>
      </div>



      <div class="hardware">
        <div class="form-field">
          <label class="form-label">hardware</label><span style="font-size: 10px; color: red; position: absolute">
            *</span>
          <select class="form-select" formControlName="hardware">
            <option value="" [selected]="true">Select Hardware</option>
            <option class="form-option" *ngFor="let type of hardwaretype" value="{{type.hardware_name}}">
              {{type.hardware_name}}
            </option>
          </select>
        </div>
        <div class="form-field">
          <label class="form-label">connection type</label><span
            style="font-size: 10px; color: red; position: absolute">
            *</span>
          <select class="form-select" formControlName="connection">
            <option class="form-option" value="WiFi">WiFi</option>
          </select>
        </div>
      </div>



      <!-- <div class="form-field"><label class="form-label">heartbeat interval</label>
                <input type="number" class="form-control" formControlName="heartbeat">
            </div> -->
      <div class="form-field">
        <label class="form-label">Vertical<span style="font-size: 10px; color: red; position: absolute">
            *</span></label>
        <div class="createvertical">
          <input class="form-control" type="text" readonly="true" *ngIf="vertname.length<=0"
            value="Create the Vertical" />
          <mat-icon *ngIf="vertname.length<=0" class="launch-icon" matTooltip="Go to Vertical Page" mat-dialog-close
            routerLink="/app/vertical" matTooltipPosition="above">launch</mat-icon>
        </div>

        <select class="form-select" *ngIf="vertname.length>=1" formControlName="vertical">
          <option value="" [selected]="true">Select Vertical</option>
          <option *ngFor="let item of vertname" class="form-option" value="{{item.vertical_Id}}">
            {{item.vertical_name}}
          </option>
        </select>
        <div class="validation"
          *ngIf="productform.controls['vertical'].invalid && (productform.controls['vertical'].dirty)">
          <span *ngIf="productform.controls['vertical']?.errors" style="color: red">
            *Select The Vertical
          </span>
        </div>
        <div class="advanced-slider1">
          <mat-slide-toggle (change)="onToggleChange($event)" [checked]="this.app_mode=='0'"
            [disabled]="this.app_mode_force=='1'" color="primary"></mat-slide-toggle>
          <h4>
            Developer Mode
            <mat-icon class="instructions" matTooltip="Strictly Validate is turned on in the general page"
              matTooltipPosition="right" matTooltipClass="my-tooltip">info
            </mat-icon>
          </h4>
        </div>
      </div>
      
      <div class="form-field" *ngIf="this.app_mode!='0'||this.app_mode_force=='1'"
      >
        <label class="form-label">Device Mac Id <span style="font-size: 10px; color: red; position: absolute"> *</span></label>
        <div class="mac-val">
          <div class="device-mac-id-filed mac-id">
            <input
              maxlength="2"
              class="form-control"
              formControlName="first"
              placeholder="XX"
            />:
            <input
              maxlength="2"
              class="form-control"
              placeholder="XX"
              #second
              formControlName="second"
            />:
            <input
              maxlength="2"
              class="form-control"
              placeholder="XX"
              #three
              formControlName="three"
            />:
            <input
              maxlength="2"
              class="form-control"
              placeholder="XX"
              #four
              formControlName="four"
            />:
            <input
              maxlength="2"
              class="form-control"
              placeholder="XX"
              #five
              formControlName="five"
            />:
            <input
              maxlength="2"
              class="form-control"
              placeholder="XX"
              #six
              formControlName="six"
            />
          </div>
        
        </div>
        <div class="validation">
          <div *ngIf="getAnyMacIdError()&&productform.controls['first'].dirty" style="color: red">
            {{ getAnyMacIdError() }}
          </div>
        </div>
      </div>

      <div class="form-field" *ngIf="this.app_mode!='0'||this.app_mode_force=='1'" >
        <label class="form-label">Webzone(Optional)</label>
      
        <select class="form-select" formControlName="Webzone_Id">
          <option value="" [selected]="true">Select the Webzone</option>
          <option
            class="form-option"
            value="{{item.zonename}}"
            *ngFor="let item of webzonevalue"
          >
            {{item.zonename}}
          </option>
        </select>
      </div>



      <div class="form-field">
        <label class="form-label">description</label>
        <!-- <input class="form-control" id="description" formControlName="description"> -->
        <textarea formControlName="description" placeholder="Enter the description" class="form-control"
          id="description" rows="3" cols="50"></textarea>
        <div *ngIf="productform.controls['description'].dirty">
          <span *ngIf="productform.controls['description']?.errors?.['maxlength']"
            style="font-size: 10px; color: red; position: absolute">
            Maximum 75 characters only
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="btn-div">
    <button class="btn btn-danger" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" [disabled]="!this.productform.valid" type="submit" mat-dialog-close
      (click)="onSubmit()">
      Create
    </button>
  </div>
</div>