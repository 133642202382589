import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Validators, UntypedFormBuilder, AbstractControl, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { AuthenticationService } from '../Authentication/authentication.service';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  // (property) SignupComponent
  loading: boolean;
  isLogin: boolean = false
  isConfirm: boolean;
  user: any
  showGoogleSignup: boolean;
  googleauthentication: any;
  hide = true;
  submitted = false;
  errorMessage = '';
  successmessage = '';
  receivedMessage = '';
  verification_code: any;
  showEmailVerification: boolean;
  countrycode: any = "in";
  dialcode: any = "+91"


  /**
 * Represents the FormGroup for confirmation code verification.
 * It includes the code field for entering the confirmation code.
 * @property {FormGroup} CF - The FormGroup instance for confirmation code verification.
 * @property {FormControl} code - The FormControl for entering the confirmation code.
 */
  CF = this.formBuilder.group({
    /**
     * The confirmation code input field.
     * @type {FormControl}
     */
    code: ['', Validators.required],
  });
  /**
  * Represents the FormGroup for user signup.
  * It includes fields for Name, number, email, password, country code, and dial code.
  * @property {FormGroup} Signup - The FormGroup instance for user signup.
  * @property {FormControl} Name - The FormControl for entering the user's name.
  * @property {FormControl} number - The FormControl for entering the user's phone number.
  * @property {FormControl} email - The FormControl for entering the user's email.
  * @property {FormControl} password - The FormControl for entering the user's password.
  * @property {FormControl} country_code - The FormControl for entering the country code.
  * @property {FormControl} dial_code - The FormControl for entering the dial code.
  */
  Signup = this.formBuilder.group({
    /**
     * The input field for the user's name.
     * @type {FormControl}
     */
    Name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(26),
        Validators.pattern('^[A-Za-z0-9 ]*$')
      ],
    ],
    /**
     * The input field for the user's phone number.
     * @type {FormControl}
     */
    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/)
      ],
    ],
    /**
     * The input field for the user's email.
     * @type {FormControl}
     */
    email: ['', [Validators.required, Validators.email]],
    /**
     * The input field for the user's password.
     * @type {FormControl}
     */
    password: [
      '',
      [Validators.required, Validators.minLength(6), Validators.maxLength(15)],
    ],
    /**
     * The input field for the country code.
     * @type {FormControl}
     */
    country_code: [''],
    /**
     * The input field for the dial code.
     * @type {FormControl}
     */
    dial_code: [''],
    /**
   * The input field for the dial code.
   * @type {FormControl}
   */
    timezone: ['']
  });








  /**
  * Creates an instance of the component.
  * @param {Router} router - A service that provides navigation among views and URL manipulation capabilities.
  * @param {Location} location - A service that represents the current URL.
  * @param {Dataservice} dataservice - The data service for managing data.
  * @param {ToastrService} toastr - The Toastr service for displaying messages.
  * @param {UntypedFormBuilder} formBuilder - The FormBuilder service for creating forms.
  * @param {SocialAuthService} authService - The service for social authentication.
  * @param {ActivatedRoute} route - The active route.
  * @param {AuthenticationService} authentication - The authentication service for user authentication.
  * @param {AppComponent} appcomponent - The main app component.
  */
  constructor(
    private router: Router,
    private location: Location,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private authService: SocialAuthService,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private appcomponent: AppComponent
  ) {
    /**
     * A boolean flag indicating the loading state.
     * @type {boolean}
     */
    this.loading = false;

    /**
     * Sign out the user.
     */
    this.signOut();

    /**
     * A boolean flag indicating whether the confirmation is required.
     * @type {boolean}
     */
    this.isConfirm = false;

    /**
     * A boolean flag indicating whether to show Google signup.
     * @type {boolean}
     */
    this.showGoogleSignup = false;

    /**
     * A boolean flag indicating whether to show email verification.
     * @type {boolean}
     */
    this.showEmailVerification = false;
  }

  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit() {
    this.dataservice.setPaginationState(null,'all');
    /**
  * Emit a new value for MyDevicestatus subject.
  */
    this.dataservice.MyDevicestatus.next()
    /**
  * Emit a new value for AllDevicestatus subject.
  */
    this.dataservice.AllDevicestatus.next()
    /**
   * Emit a new value for singleDevicestatus subject.
   */
    this.dataservice.singleDevicestatus.next()


    /**
     * Get the value of the 'id' parameter from the route snapshot.
     */
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      /**
    * Assign the 'id' parameter value to the verification_code property.
    */
      this.verification_code = id;
      /**
     * Show the email verification section.
     */
      this.showEmailVerification = true
    } else if (!id) {
      /**
    * Hide the email verification section.
    */
      this.showEmailVerification = false;
    }

    /**
 * Subscribe to the authentication state changes using the GoogleAuthService.
 */
    this.googleauthentication = this.authService.authState.subscribe((user: any) => {
      this.user = user;
      if (this.user) {
        // Prepare data for Google sign-in
        const data = { "email": user.email, "id": user.id };
        // Call the dataservice to sign in using Google
        this.dataservice.googlesignin(data).subscribe((res: any) => {
          if (res.status == "201") {
            // Handle successful Google signin
            // Extract user data from the response

            let user_Id = res.data?.user_Id;
            let role = res.data?.role_name;
            let lastlogin = res.data?.lastlogin;
            let username = res.data?.firstname;
            let isactive = res.data?.isactive;
            let org_Id = res.data?.org_Id;
            let vertical_Id = res.data?.vertical_Id;
            let any_changes = res.data?.any_changes;
            let f_logout = res.data?.f_logout;
            // Prepare user data for saving
            const data = { user_Id, role, org_Id, vertical_Id, username, isactive, any_changes, f_logout }
            const roles = res.data?.group_permission;
            // Save user data and roles using the authentication service
            this.authentication.saveUserData(data, roles);
            this.toastr.success("Successfully Logged In");
            if (!lastlogin) {
              // Navigate to the appropriate route based on last login
              const data = this.authentication.getUserData();
              this.appcomponent.Getproductdata(data);
              this.router.navigate(['/quickstart'])
            } else {
              const data = this.authentication.getUserData();
              this.appcomponent.Getproductdata(data);
              this.router.navigate(['/app/mydevices']);
            }
          } else if (res.status == "200") {
            // Handle Google signup
            this.showGoogleSignup = true
          }
          else if (res.status == "202") {
            // Handle already signed up
            this.toastr.info("Oops! it seems like you've already signed up using our application. Please use the login option to access your account")
          }
          else {
            // Handle other cases
            this.toastr.error("error occurred")
          }
        })
      } else if (!this.user) {
        // Handle case when user is not authenticated
        this.showGoogleSignup = false;
      }

    });

  }


  /**
  * Lifecycle hook that is called when the component is about to be destroyed.
  * Unsubscribes from the Google authentication subscription.
  */
  ngOnDestroy(): void {
    // Unsubscribe from the Google authentication subscription
    this.googleauthentication.unsubscribe();
  }

  /**
 * Receives a message from an event and updates the showGoogleSignup and user properties.
 * @param {any} $event - The event data containing the message.
 */
  receiveMessage($event: any): void {
    // Update showGoogleSignup and user properties based on the received message
    this.receivedMessage = $event;
    this.showGoogleSignup = JSON.parse(this.receivedMessage);
    this.user = '';
  }

  /**
  * Handles the form submission. If the Signup form is invalid, returns; otherwise, calls the signUp() function.
  */
  onSubmit(): void {
    // Set submitted to true to indicate form submission
    this.submitted = true;

    // Check if the Signup form is invalid
    if (this.Signup.invalid) {
      return; // Return if form is invalid
    } else {
      this.signUp(); // Call the signUp() function if form is valid
    }
  }








  /**
 * Signs the user out by invoking the signOut() method of the authentication service.
 */
  signOut(): void {
    // Call the signOut() method of the authentication service
    this.authService.signOut()
      .then(() => {
        this.user = null; // Set user to null after successful sign out
      })
      .catch((error) => {
        console.log(error); // Log any errors that occur during sign out
      });
  }

  /**
   * Handles the event when the selected country changes in the country selection dropdown.
   * Updates the dial code and country code based on the selected country.
   * @param {any} event - The event object containing information about the selected country.
   */
  onCountryChange(event: any): void {
    // Update the dial code and country code based on the selected country
    this.dialcode = '+' + event.dialCode;
    this.countrycode = event.iso2;
  }




  /**
  * Handles the user signup process.
  * Performs user registration by sending signup data to the dataservice.
  * Displays appropriate messages based on the response status.
  */
  public signUp(): void {
    this.loading = true;

    // Store the original number before modifying it
    let number = this.Signup.value.number;

    // Update the phone number with dial code and country code
    this.Signup.value.number = this.dialcode + this.Signup.value.number;
    this.Signup.value.country_code = this.countrycode;
    this.Signup.value.dial_code = this.dialcode;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.Signup.value.timezone = timezone
    const data = this.Signup.value;

    // Call the dataservice for user signup
    this.dataservice.usersignup(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == "201") {
        this.verification_code = res.data;
        this.toastr.success("Kindly Check your email inbox");
        this.showEmailVerification = true;
      } else if (res.status == "200") {
        this.errorMessage = "Email Already Exist";
        this.toastr.error("Email Already Exist");
        this.Signup.value.number = number;
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      } else if (res.status == "0") {
        this.errorMessage = "Signup Failed";
        this.toastr.error("Signup Failed");
        this.Signup.value.number = number;
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      } else {
        this.toastr.error("error occurred");
        this.Signup.value.number = number;
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      }
    });
  }

  /**
   * Navigates back in the platform's history using the browser's back functionality.
   */
  goback() {
    this.location.back();
  }

  /**
  * Provides access to the controls of the SignUp form for convenient form validation.
  * @returns {Record<string, AbstractControl>} An object containing form controls by their names.
  */
  get f(): Record<string, AbstractControl> {
    return this.Signup.controls;
  }

  /**
   * Provides access to the controls of the CF (confirmation code verification) form for convenient form validation.
   * @returns {Record<number, AbstractControl>} An object containing form controls by their numeric keys.
   */
  get code(): Record<number, AbstractControl> {
    return this.CF.controls;
  }
}


@Component({
  selector: 'app-googlesignup',
  templateUrl: 'googlesignup.html',
  styleUrls: ['./signup.component.css'],
})

export class googlesignup implements OnInit {

  countrycode: any = "in";
  dialcode: any = "+91"
  @Input() user: any;

  @Output() messageEvent = new EventEmitter<string>();
  message = "false";

  submitted = false;

  /**
 * Creates an instance of the component.
 * @param {UntypedFormBuilder} formBuilder - The FormBuilder service for creating forms.
 * @param {Dataservice} dataservice - The data service for managing data.
 * @param {ToastrService} toastr - The Toastr service for displaying messages.
 * @param {AuthenticationService} authentication - The authentication service for user authentication.
 * @param {Router} router - A service that provides navigation among views and URL manipulation capabilities.
 * @param {AppComponent} appcomponent - The main app component.
 */
  constructor(private formBuilder: UntypedFormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService,
    private router: Router, private appcomponent: AppComponent) {

  }

  /**
   * Represents the GoogleSignup form group.
   */
  GoogleSignup = this.formBuilder.group({
    Name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(15),
        Validators.pattern('^[+_A-z0-9_ ]*((-|s)*[+_A-z0-9_ ])*$'),
      ],
    ],
    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/),
      ],
    ],
    country_code: [''],
    dial_code: [''],
  });
  /**
  * Lifecycle hook called after component initialization.
  * Initializes device status data.
  */
  ngOnInit(): void {
    // Initialize device status data
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
  }

  /**
  * Lifecycle hook called before component destruction.
  * Emits a message using the messageEvent EventEmitter.
  */
  ngOnDestroy(): void {
    // Emit a message using the messageEvent EventEmitter
    this.messageEvent.emit(this.message);
  }

  /**
  * Handles the form submission.
  * Sets the submitted flag to true and validates the GoogleSignup form.
  * If the form is valid, calls the signUp() function.
  */
  onSubmit(): void {
    // Set the submitted flag to true
    this.submitted = true;

    // Check if the GoogleSignup form is invalid
    if (this.GoogleSignup.invalid) {
      return; // Exit the function if the form is invalid
    } else {
      // Call the signUp() function if the form is valid
      this.signUp();
    }
  }

  /**
 * Handles the user sign-up process with Google account.
 * Constructs the necessary data for sign-up and sends it to the server.
 * Handles different response statuses and displays corresponding toastr messages.
 */
  signUp(): void {
    // Save the original phone number value
    let number = this.GoogleSignup.value.number;

    // Construct data for sign-up with Google account
    this.GoogleSignup.value.number = this.dialcode + this.GoogleSignup.value.number;
    this.GoogleSignup.value.country_code = this.countrycode;
    this.GoogleSignup.value.dial_code = this.dialcode;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      "email": this.user.email,
      "Name": this.GoogleSignup.value.Name,
      "id": this.user.id,
      "number": this.GoogleSignup.value.number,
      "country_code": this.GoogleSignup.value.country_code,
      "dial_code": this.GoogleSignup.value.dial_code,
      "timezone": timezone
    };

    // Send sign-up data to the server
    this.dataservice.googlesignup(data).subscribe((res: any) => {
      if (res.status == "200") {
        // Display toastr message for already registered email
        this.toastr.error("Email Already Registered");
        this.GoogleSignup.value.number = number;
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      } else if (res.status == "201") {
        // Successfully logged in after sign-up
        this.toastr.success("Successfully Login");
        let user_Id = res.data?.user_Id;
        let role = res.data?.role_name;
        let lastlogin = res.data?.lastlogin;
        let username = res.data?.firstname;
        let isactive = res.data?.isactive;
        let org_Id = res.data?.org_Id;
        let vertical_Id = res.data?.vertical_Id;
        let any_changes = res.data?.any_changes;
        let f_logout = res.data?.f_logout;
        const userData = { user_Id, role, org_Id, vertical_Id, username, isactive, any_changes, f_logout };
        const roles = res.data.group_permission;

        // Save user data and roles to authentication service
        this.authentication.saveUserData(userData, roles);

        // Navigate to appropriate page based on last login status
        if (!lastlogin) {
          const data = this.authentication.getUserData();
          this.appcomponent.Getproductdata(data);
          this.router.navigate(['/quickstart']);
        } else {
          const data = this.authentication.getUserData();
          this.appcomponent.Getproductdata(data);
          this.router.navigate(['/app/mydevices']);
        }
      } else {
        // Display toastr message for other error statuses
        this.toastr.error("Error occurred");
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      }
    });
  }

  /**
  * Handles the change of selected country in the country code dropdown.
  * Updates the dial code and country code based on the selected country.
  * @param event - The event object containing information about the selected country.
  */
  onCountryChange(event: any): void {
    // Update the dial code and country code based on the selected country
    this.dialcode = '+' + event.dialCode;
    this.countrycode = event.iso2;
  }

  /**
 * Getter method to access the controls of the GoogleSignup form group.
 * @returns An object containing the controls of the GoogleSignup form group.
 */
  get g(): {
    [key: string]: AbstractControl;
  } {
    return this.GoogleSignup.controls;
  }


}


@Component({
  selector: 'app-emailverification',
  templateUrl: 'emailverification.html',
  styleUrls: ['./signup.component.css'],
})

export class emailverification implements OnInit {
  result: any;
  refresh: any


  @Input() verification_code: any;


  /**
  * Creates an instance of the component.
  * @param {Dataservice} dataservice - The data service for managing data.
  * @param {ToastrService} toastr - The Toastr service for displaying messages.
  * @param {Router} router - The Router service for navigation.
  */
  constructor(
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router
  ) {
    // Constructor body
  }

  /**
   * Initializes the component and performs tasks upon component initialization.
   */
  ngOnInit(): void {
    // Initiating MyDevicestatus, AllDevicestatus, and singleDevicestatus
    this.dataservice.MyDevicestatus.next()
    this.dataservice.AllDevicestatus.next()
    this.dataservice.singleDevicestatus.next()
    // Check email verification status periodically
    if (this.result != "200") {
      this.refresh = setInterval(() => {
        const data = { "verification_code": this.verification_code }
        this.dataservice.emailverfication(data).subscribe((res: any) => {
          if (res.status != this.result) {
            if (res.status == "200") {
              this.result = res.status
              this.toastr.success("Email Verified Successfully")
            } else if (res.status == "0") {
              this.result = res.status
            } else {
              this.toastr.error("error occurred")
            }

          }
        })
      }, 3000)
      // Redirect after successful verification
      this.refresh = setInterval(() => {
        if (this.result == "200") {
          this.router.navigate(['/app/signin/', this.verification_code])
        }
      }, 6000)

    }
  }



  /**
 * Cleans up resources and performs tasks before the component is destroyed.
 */
  ngOnDestroy(): void {
    // Clear the interval for periodic tasks
    clearInterval(this.refresh);
  }


}

//Forgot password
@Component({
  selector: 'forgotpassword',
  templateUrl: 'forgotpassword.html',
  styleUrls: ['./signup.component.css'],
})

export class forgotpassword implements OnInit {
  submitted: any;
  token: any;
  interval: any;
  result: any;
  disabled: boolean;


  /**
  * Creates an instance of the component.
  * @param {FormBuilder} formBuilder - The FormBuilder service for creating forms.
  * @param {Dataservice} dataservice - The data service for managing data.
  * @param {ToastrService} toastr - The Toastr service for displaying messages.
  * @param {Router} router - A service that provides navigation among views and URL manipulation capabilities.
  */
  constructor(
    private formBuilder: FormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.disabled = false;
  }

  /**
  * Represents the form group for handling the forgot email functionality.
  */
  forgotemail = this.formBuilder.group({
    /**
     * Represents the user's email for password recovery.
     * @type {AbstractControl}
     */
    email: ['', [Validators.required, Validators.email]]
  });


  /**
  * Lifecycle hook that is called after Angular has initialized all data-bound properties of the directive/component.
  * It is a good place to initialize component properties and perform any necessary setup logic.
  */
  ngOnInit(): void {
    // Initialize device status data
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
  }
  /**
  * Handler for the form submission.
  * Checks if the form is valid, and if so, triggers the confirmation process.
  */
  onSubmit(): void {
    // Mark the form as submitted
    this.submitted = true;

    // Check if the form is valid
    if (this.forgotemail.invalid) {
      return; // Do not proceed if the form is invalid
    } else {
      // Proceed with the confirmation process
      this.confirm();
    }
  }

  /**
 * Getter for form controls, providing access to individual form controls.
 * @returns An object containing AbstractControl instances for each form control.
 */
  get f(): { [key: string]: AbstractControl } {
    return this.forgotemail.controls;
  }
  /**
   * Initiates the password reset process by sending a request to the server
   * with the user's email address. Displays success or error messages based on the response.
   * If successful, navigates to the sign-in page after a short delay.
   */
  confirm() {
    const data = { "email": this.forgotemail.value.email };
    this.dataservice.forgotpassword(data).subscribe((res: any) => {
      if (res.status == '0') {
        console.log(res);
        console.log(data);
        
        this.toastr.error("Email is invalid");
      } else if (res.status == "201") {
        console.log(res);
   
        const data = res.data;
        this.token = res.data;
        this.disabled = true;
        this.toastr.success("Check Email to reset your password.");
        setTimeout(() => {
          this.router.navigate(['/app/signin'])
        }, 5000)

      }
      else {
        this.toastr.error("Error occurred")
      }

    })

  }


}

@Component({
  selector: 'resetpassword',
  templateUrl: 'resetpassword.html',
  styleUrls: ['./signup.component.css'],
})
export class resetpassword implements OnInit {
  submitted: any;
  confirmhide = true;
  newhide = true;
  token: any;
  /**
 * Represents a form group for resetting a user's password. Contains fields for entering
 * the new password and confirming the password. Provides validation rules for password length.
 */
  resetpassword = this.formBuilder.group({
    newpassword: [
      '',
      [Validators.required, Validators.minLength(6), Validators.maxLength(15)],
    ],
    confirmpassword: [
      '',
      [Validators.required, Validators.minLength(6), Validators.maxLength(15)],
    ]
  })

  /**
 * Represents the constructor of a component or service.
 *
 * @param formBuilder - An instance of FormBuilder used for creating and managing forms.
 * @param dataservice - An instance of Dataservice for interacting with data-related operations.
 * @param toastr - An instance of ToastrService for displaying toast messages.
 * @param route - An instance of ActivatedRoute for accessing route-related information.
 * @param router - An instance of Router for navigation between different views.
 */
  constructor(private formBuilder: FormBuilder, private dataservice: Dataservice, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {

  }

  /**
  * Lifecycle hook called after the component's data-bound properties have been initialized.
  * It initializes necessary data and performs operations when the component is initialized.
  */
  ngOnInit(): void {
    // Trigger MyDevicestatus, AllDevicestatus, and singleDevicestatus updates
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();

    // Get the token parameter from the route snapshot
    this.token = this.route.snapshot.paramMap.get('id');
  }

  /**
  * Handles the form submission for resetting the password.
  * If the form is valid, it checks for password match and then initiates the password reset process.
  */
  onSubmit(): void {
    // Mark the form as submitted
    this.submitted = true;

    // Check if the resetpassword form is invalid
    if (this.resetpassword.invalid) {
      return;
    } else if (this.resetpassword.value.confirmpassword !== this.resetpassword.value.newpassword) {
      // Check if the new password and confirm password match
      this.toastr.error("Password Mismatch");
    } else {
      // If the form is valid and passwords match, initiate the password reset process
      this.reset();
    }
  }



  /**
   * Provides access to the controls of the resetpassword form.
   * @returns An object containing AbstractControl instances for each form control.
   */
  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.resetpassword.controls;
  }
  /**
   * Resets the user's password using the provided token and new password.
   */
  reset() {

    const data = { "password": this.resetpassword.value.newpassword, "token": this.token };

    this.dataservice.resetpassword(data).subscribe((res: any) => {
      if (res.status == "201") {
        this.toastr.success("Password Successfully Changed")
        setTimeout(() => {
          this.router.navigate(['/app/signin'])
        }, 2000)
      }
      else if (res.status == "0") {
        this.toastr.error("Process Failed")
      } else if (res.status == "100") {
        this.toastr.info("Old password New password are Same")
      }
      else if (res.status != "100" || "0" || "201") {
        this.toastr.error("Error occurred")
      }
    })

  }
}


@Component({
  selector: 'Validation',
  templateUrl: 'Validation.html',
  styleUrls: ['./signup.component.css'],
})
export class Validation implements OnInit {


  constructor() {

  }

  ngOnInit(): void {

  }

}



