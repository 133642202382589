<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
></mat-spinner>
<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>

<div class="content" *ngIf="verticalvalue?.length == 0 && isread == true">
  <div>
    <h1 style="font-weight: 500">vertical</h1>
    <p style="font-weight: 500">
      Create the tree-like structure of Vertical and invite to them users who
      will use the same sets of Cluster and Devices
    </p>
  </div>
  <div>
    <button class="btn btn-primary" (click)="open()">+ Create Vertical</button>
  </div>
</div>
<!-- <mat-icon>rocket_launch</mat-icon> -->

<div
  style="padding: 10px"
  *ngIf="!loading && verticalvalue?.length >= 1 && isread == true"
>
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <div class="main-head">
        <div class="d-flex" style="align-items: center; gap:5px">
          <!-- <h1>Verticals  <mat-icon
          class="help" (click)="sidenav.toggle()"
          matTooltipClass="my-tooltip"
          matTooltip="Help"
          matTooltipPosition="right"
          >info
        </mat-icon></h1> -->
          <h1>Verticals</h1>
        </div>

      

      </div>
      <div class="count-vertical">
        <div>
          <span class="count-vertical-font">
            <h4>No Of Verticals : {{ verticalvalue?.length }}</h4>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex new-dev-sec">
      <div class="buttons">
        <button class="btn btn-primary new-dev" (click)="open()">
          New Vertical <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="search-div">
        <input
          type="search"
          #search
          class="form-control autocomplete"
          placeholder="Search Verticals"
          [(ngModel)]="filterTerm"
        />
        <mat-icon class="search-icon" (click)="search.focus()">search</mat-icon>
      </div>
    </div>
  </div>

  <ng-container *ngIf="verticalvalue | filter: filterTerm as result">
    
  <div   *ngIf="result.length>=1" class="vertical-body">
    <div
      class="vertical"
      *ngFor="let vertical of result "
      (click)="editopen(vertical.vertical_id)"
    >
      <div class="vertical-info">
        <h6>{{ vertical.regdate | date : "medium" }}</h6>

        <!-- <img class="fir-author-image fir-clickcircle" src="../../assets/vertical/vertical.png"
                        alt="vertical"> -->
        <div class="verticallogo">
          <!-- profilelogo -->
          {{ vertical.vertical_name.slice(0, 1) | uppercase }}
        </div>
      </div>
      <div class="vertical-preview">
        <h6>{{ vertical.location_name }}</h6>
        <div *ngIf="vertical.user_count == 0">No Users</div>
        <div *ngIf="vertical.user_count >= 1">
          {{ vertical.user_count }} -Users
        </div>
        <h2>Vertical : {{ vertical.vertical_name }}</h2>
        <!-- <h2></h2> -->
      </div>
    </div>
   
  
  </div>
  <div class="nodatacontent" *ngIf="result.length<=0">

    <iconify-icon icon="ph:building-office"></iconify-icon>
    <h1>No Data Found</h1>
  </div>
</ng-container>
</div>



  
 
  
  
 
  