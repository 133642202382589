<!-- delete popup html  -->

<div class="main" *ngIf="this.data=='delete'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you confirm the deletion of this data?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Delete</button>
  </div>
</div>

<div class="main" *ngIf="this.data=='removeuser'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to Remove this user?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-success" (click)="deletefun()">Confirm</button>
  </div>
</div>

<!-- delete cluster  -->

<div class="main" *ngIf="this.data=='Cluster'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you confirm the deletion of this Cluster?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Delete</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='Gateway'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you confirm the deletion of this Gateway?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Delete</button>
  </div>
</div>

<!-- delete Vertical  -->

<div class="main" *ngIf="this.data=='Vertical'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you confirm the deletion of this Vertical?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Delete</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='suspendeduser'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to Suspend this user?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-success" (click)="deletefun()">Confirm</button>
  </div>
</div>

<div class="main" *ngIf="this.data=='activeuser'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to Reactivate this user?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-success" (click)="deletefun()">Confirm</button>
  </div>
</div>

<div class="main" *ngIf="this.data=='reboot'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to Reboot this Device?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-success" (click)="deletefun()">Confirm</button>
  </div>
</div>

<div class="main" *ngIf="this.data=='Routes'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to clear the data?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Clear</button>
  </div>
</div>

<div class="main" *ngIf="this.data=='Newwindow'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <!-- <p>
      Devsbot is open in another window. Click "Use here" ti use Devsbot in this
      window.
    </p> -->
  </div>
  <div class="matdialog-body-delete">
    <span>
      Devsbot is open in another window. Click "Use here" ti use Devsbot in this
      window.</span
    >
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-danger" (click)="cancelfun()">Close</button>
    <button class="btn btn-primary" (click)="deletefun()">Use here</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='InValidUser'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Alert</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Sorry, there seems to be an issue. Please sign in again.</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="deletefun()">SignIn</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='logout'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Alert</p>
  </div>
  <div class="matdialog-body-delete">
    <span>Do you want to Force logout to this user?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Force Logout</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='Update'">
  <div class="matdialog-head">
    <mat-icon style="color: skyblue">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-info">
    <span>Do you want to Update the Device?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-success" (click)="deletefun()">Confirm</button>
  </div>
</div>
<div class="main" *ngIf="this.data=='Remove'">
  <div class="matdialog-head">
    <mat-icon style="color: red">info</mat-icon>
    <p>Are You Sure?</p>
  </div>
  <div class="matdialog-body-info">
    <span>Do you want to Remove the Device?</span>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-primary" (click)="cancelfun()">Cancel</button>
    <button class="btn btn-danger" (click)="deletefun()">Confirm</button>
  </div>
</div>
