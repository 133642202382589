<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
></mat-spinner>
<div *ngIf="!loading" class="cluster_dashboard">
  <div class="cluster_name">
    <div style="display: flex; gap: 20px; align-items: center">
      <mat-icon class="close-icon" (click)="back()">arrow_back</mat-icon>
      <h1>{{this.productname}}</h1>
    </div>
    <button class="btn btn-primary" *ngIf="this.iscreate" (click)="info()">
      Cluster Info
    </button>
  </div>

  <div class="cards">
    <div *ngIf="clusterdahsboardData.length == 0" class="nodevice">
      <i class="fas fa-exclamation-circle"></i>
      <h3>No Devices Data Found</h3>
    </div>

    <div
      class="card"
      (mouseenter)="onCardHover(true)"
      (mouseleave)="onCardHover(false)"
      (click)="gotodeviceDB(item.device_id)"
      *ngFor="let item of clusterdahsboardData"
    >
      <div class="device_name">
        {{ item.device_name }}
        <span class="online_device" *ngIf="item.deviceonline == '#2ecc71'"
          >Online</span
        >
      </div>
      <div class="gauge">
        <ngx-gauge
          *ngIf="item.deviceonline == '#2ecc71'"
          class="kwhgauge"
          id="gauge-text-color"
          [size]="150"
          [readonly]="true"
          [strokeWidth]="5"
          [min]="0"
          [max]="1000"
          [value]="item?.V1?.kwh_thismonth"
          cap="round"
          type="full"
          rangeColor="lightgray"
          foregroundColor="#673ab7"
          append="kWh"
          label="This Month"
          [thick]="10"
        >
        </ngx-gauge>
        <ngx-gauge
          matTooltip="Last Online {{
    item.last_online | date : 'short'
  }}"
          *ngIf="item.deviceonline == '#e74c3c'"
          class="kwhgauge"
          id="gauge-text-color"
          [size]="150"
          [readonly]="true"
          [strokeWidth]="5"
          [min]="0"
          [max]="1000"
          [value]="item?.V1?.kwh_thismonth"
          cap="round"
          type="full"
          rangeColor="lightgray"
          foregroundColor="#D1D1D1"
          append="kWh"
          label="This Month"
          [thick]="10"
        >
        </ngx-gauge>
        <hr />
        <div class="hour_kwh">
          <div class="first">
            <h2>{{item?.cost?.toFixed(1)}}</h2>
            <p>This Month Cost</p>
          </div>
          <div class="second">
            <h2>{{item?.V1?.current_kwh}}</h2>
            <p style="text-transform: none;">Today's kWh</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
