<!-- newdevice page view  -->
<div class="content">
    
    <!-- content-head  -->
    <div class="content-head">

        <div>
            <h1>New Device</h1>
            <p style="font-weight: 500;">Choose a way to create new device</p>
        </div>
        <div>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <!-- box-head  -->
    <div class="box-content">
        <!-- box   -->
        <!-- product box -->
        <div class="box visible" (click)="product()" mat-dialog-close>
            <h3 style="font-weight: 500;margin: 0 !important;">From Cluster</h3>
            <mat-icon class="icon">grid_on</mat-icon>
            <div class="text">
                <!-- <mat-icon>add</mat-icon> -->
                <p style="font-weight: 500;">create a device by filling in a simple form</p>
            </div>
        </div>
        <!-- qr code box  -->
        <div class="box" style="filter: opacity(0.7);" >
     
            <h3 style="font-weight: 500;margin: 0 !important;">Upcoming feature</h3>
            <mat-icon class="icon">qr_code</mat-icon>
            <div class="text">
              
                <p style="font-weight: 500;">scan the QR code on your device using the camera</p>
            </div>
        </div>
        <!-- keyboard box  -->
        <div class="box"  style="filter: opacity(0.7);"  >
            <!-- <h3 style="font-weight: 500;margin: 0 !important;">Manual Entry</h3> -->
            <h3 style="font-weight: 500;margin: 0 !important;">Upcoming feature</h3>
            <mat-icon class="icon">keyboard</mat-icon>
            <div class="text">
                <!-- <mat-icon>keyboard</mat-icon> -->
                <p style="font-weight: 500;">enter the code provided with the device(it's usually placed below Qr code)
                </p>
            </div>

        </div>
    </div>
    <!-- btn-div  -->
    <div class="btn-div">
        <button class="btn btn-danger" focus="false" mat-dialog-close>Cancel</button>
    </div>
</div>