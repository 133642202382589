<!-- device information start -->
<mat-spinner
  role="progressbar"
  diameter="50"
  aria-hidden="true"
  *ngIf="loading"
  id="spinner"
>
</mat-spinner>
<div class="info-main" *ngIf="!loading">
  <div class="right-side">
    <div class="close-icon" (click)="close()">
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div class="device-dashbrd">
      <div id="right-side" class="right-side-head">
        <div class="device-icon" *ngIf="item.cluster_logo?.length <= 0">
          <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          id="device-icon"
          style="width: 43px"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#3f51b5"
          stroke-width="2"
          *ngIf="!show || item.cluster_logo?.length <= 0"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
          />
        </svg>  -->
          <img
            src="../../assets/icons/microcontroller.png"
            alt=""
            style="width: 50px; min-height: 7vh"
          />
        </div>
        <div class="device-icon" *ngIf="item.cluster_logo?.length >= 1">
          <img
            src="../../assets/product/{{ item.cluster_logo }}"
            alt=""
            style="width: 50px; min-height: 7vh"
          />
        </div>

        <div>
          <div class="right-side-title-one">
            <span class="devicename">{{ item.device_name }}</span>
            <span
              class="status-online"
              *ngIf="
                  +this.currenttime - +item.last_online <
                    100 * item.heartbeat && (item.status_Id == 1 || item.status_Id==2)
                "
              >Online</span
            >
            <iconify-icon class="highlighted" *ngIf="+this.currenttime - +item?.last_online <
            100 * item?.heartbeat &&item.status_Id == 2" style="font-size: 23px;color: red;" icon="fluent:plug-disconnected-28-regular"></iconify-icon>
            <span
              class="status-offline"
              *ngIf="
+this.currenttime - +item.last_online >
                    100 * item.heartbeat &&
                    (item.status_Id == 1  || item.status_Id == 2 ) 
                "
              matTooltip="Last Online Since {{
                  item.last_online | date : 'short'
                }}"
              matTooltipPosition="left"
              >Offline</span
            >
            <span
            class="status-inactive"
            *ngIf="
           (item.status_Id == 0 )
        "
            >Inactive</span
          >
            <div>
              <!-- <mat-icon [matMenuTriggerFor]="menuone">more_vert</mat-icon> -->
              <i
                class="fa-solid fa-ellipsis"
                [matMenuTriggerFor]="menu"
                id="more-vert"
              ></i>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  class="mat-menu-button"
                  (click)="rename(item)"
                >
                  <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit
                </button>
                <button
                  mat-menu-item
                  class="mat-menu-button"
                  (click)="devicereport(item.device_id, item.device_name)"
                >
                  <mat-icon class="menu-btn-icon">sim_card</mat-icon>Download
                  Report
                </button>

                <button  *ngIf="item.slave_id === 1"
                  mat-menu-item
                  class="mat-menu-button"
                  (click)="devicereboot(item.cluster_api_Id, item.last_online,item.heartbeat)"
                >
                  <mat-icon class="menu-btn-icon">autorenew</mat-icon>Reboot
                </button>

                <button
                  mat-menu-item
                  class="mat-menu-button"
                  (click)="erasedevicedata(item.device_id, item.device_name)"
                >
                  <mat-icon class="menu-btn-icon">highlight_off</mat-icon>
                  Delete Events log Data
                </button>
                <button *ngIf="this.iscreate"
                  mat-menu-item
                  class="mat-menu-button"
                  (click)="deletedevice(item.device_id,item.cluster_id)"
                >
                  <mat-icon class="menu-btn-icon">delete</mat-icon>Delete
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="right-side-title-two">
            <div class="device-info" (click)="userinfo(item.user_id)">
              <mat-icon>person_outline</mat-icon>
              <h3 style="color: #3f51b5">{{ item.firstname }}</h3>
            </div>
            <div class="device-info" style="cursor: default">
              <mat-icon>business</mat-icon>
              <h3 style="color: #3f51b5">{{ item.org_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="design_DB">
        <button class="btn btn-primary" (click)="opendahsboard()">
          <img src="../../assets/icons/gauge.png" alt="Gauge" />
          Dashboard
        </button>
      </div> -->
    </div>
    <div class="mat-tab-group">
      <mat-tab-group
        (selectedTabChange)="tabClick($event, item.device_id)"
        [selectedIndex]="selectedTabIndex"
      >
       
        <mat-tab label="Device Info">
          <div class="info-body">
            <div>
              <div class="info-content">
                <h3>Status</h3>
                <div
                  class="userActive"
                  *ngIf="
                  +this.currenttime - +item.last_online <
                    100 * item.heartbeat && (item.status_Id == 1 || item.status_Id==2)
                "
                >
                  <p>Online</p>
                  <iconify-icon class="highlighted" *ngIf="+this.currenttime - +item?.last_online <
            100 * item?.heartbeat &&item.status_Id == 2" style="font-size: 23px;color: red;" icon="fluent:plug-disconnected-28-regular"></iconify-icon>
                </div>
                <div
                  class="userInActive"
                  *ngIf="
                  +this.currenttime - +item.last_online >
                    100 * item.heartbeat &&
                    (item.status_Id == 1  || item.status_Id == 2 ) 
                "
                >


                  <p>Offline</p>
                </div>
                <div
                class="statusInActive"
                *ngIf="
           (item.status_Id == 0 )
        "
              >
                <p>Inactive</p>
              </div>
              </div>
              <div class="info-content">
                <h3>Slave Id</h3>
                <p>{{ item.slave_id}}</p>
              </div>
              <div class="info-content">
                <h3>Device Created</h3>
                <p>{{ item.device_activation | date : "medium" }}</p>
              </div>
              <div class="info-content">
                <h3>Auth Token</h3>
                <p class="firmware-config">{{ item.device_auth_token }}</p>
              </div>
              <!-- <div class="info-content">
                <h3>Device Model</h3>
                <p>{{ item.hardware_name }}</p>
              </div> -->
              <div class="info-content">
                <h3>Gateway Id</h3>
                <p class="firmware-config">{{ item.cluster_api_Id }}</p>
              </div>
              <div class="info-content">
                <h3>Gateway name</h3>
                <p>{{ item.cluster_name }}</p>
              </div>
            </div>
            <div>
              <div class="info-content" *ngIf="item.device_model ">
                <h3>Model</h3>
                <p>{{ item.device_model }}</p>
              </div>
              <div class="info-content" *ngIf="item.model_number ">
                <h3>Model Number</h3>
                <p>{{ item.model_number }}</p>
              </div>
              <div class="info-content" *ngIf="item.device_mac_Id ">
                <h3>Mac Id</h3>
                <p>{{ item.device_mac_Id }}</p>
              </div>
              <div class="info-content" *ngIf="item.zonename">
                <h3>Zone Name</h3>
                <p *ngIf="!item.zonename">{{ "-" }}</p>
                <p *ngIf="item.zonename">{{ item.zonename }}</p>
              </div>

              <div class="info-content">
                <h3>organization</h3>
                <p>{{ item.org_Name }}</p>
              </div>
              <div class="info-content">
                <h3>Last update</h3>
                <p>{{item.last_online | date : "medium" }}</p>
              </div>

              <!-- <h3>FIRMWARE CONFIGURATION</h3>
              <div
                class="clipboard"
                (click)="
                      copyText(
                        item.device_auth_token,
                        
                        item.product_api_Id
                      )
                    "
                ngbTooltip="Click to copy Code"
              >
                <div class="clipboard-content">
                  <span>#define</span>
                  <span class="copytitle">NTS_device_auth_token</span>
                  <span class="copyvalue" *ngIf="!item.device_auth_token">"{{ item.device_auth_token }}" //Not yet Provisioned</span>
                  <span class="copyvalue" *ngIf="item.device_auth_token">"{{ item.device_auth_token }}"</span>
                </div>
                <div class="clipboard-content">
                  <span>#define</span>
                  <span class="copytitle">NTS_device_name</span>
                  <span class="copyvalue">"{{ item.device_name }}"</span>
                </div>
                <div class="clipboard-content">
                  <span>#define</span>
                  <span class="copytitle">NTS_Cluster_Id</span>
                  <span class="copyvalue">"{{ item.product_api_Id }}"</span>
                </div>
              </div>
              <h4>
                Cluster ID, Device Name, and AuthToken should be declared at the
                very top of the firmware code.
              </h4> -->
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Integration">
          <div class="Integration-body">
            <div class="Integration-left">
              <div>
                <h1 class="Integration-head">Device Integration in Devsbot</h1>
              </div>
              <div class="Integration-content">
                <span>
                  <span class="row-no">1 =></span> Open Arduino IDE and create
                  new File from the File Menu
                </span>
                <br />
                <span>
                  <span class="row-no">2 =></span> Goto -> Tools -> Manage
                  Libraries and search for DevsBot and install the Latest
                  Version (or) Download the Zip file
                  <span
                    style="color: rgba(9, 123, 223, 0.848); cursor: pointer"
                    (click)="downloadzip()"
                    >here.</span
                  >
                </span>
                <br />
                <span>
                  <span class="row-no">3 =></span> Goto -> Tools -> Boards ->
                  Esp32 -> choose your board[Mostly you need to choose Esp32 Dev
                  module/NodeMCU 1.0]
                </span>
                <br />
                <span>
                  <span class="row-no">4 =></span> Copy the code from this App
                  and paste into the newly created file. [Make sure you have
                  cleared all the contents in new file before pasting]
                </span>
                <br />
                <span>
                  <span class="row-no">5 =></span> Compile and upload the code
                  into your device.
                </span>
                <br />
                <span>
                  <span class="row-no">6 =></span> Check Serial Monitor for the
                  connection message. If it is succesfull you will see the
                  following messages.
                </span>
              </div>
            </div>
            <div class="Integration-right">
              <div class="clipboard-integration">
                <div class="clipboard-integration-head">
                  <h3
                    style="
                      margin-bottom: 0;
                      font-size: 21px;
                      font-weight: 500;
                      color: #c5c5c5;
                    "
                  >
                    FIRMWARE FILE
                  </h3>
                  <mat-icon
                    class="copy-icon"
                    (click)="
                copyText(
                  item.device_auth_token,item.cluster_api_Id
                 
                )"
                    ngbTooltip="Click to copy Code"
                    >file_copy</mat-icon
                  >
                </div>
                <div class="clipboard-integration-body">
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">#include</span>
                    <span class="clipboard-content-text copyvalue"
                      ><span><</span>Devsbot<span>></span></span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">#define</span>
                    <span class="copytitle">DBOT_GATEWAY_ID</span>
                    <span class="copyvalue">"{{ item.cluster_api_Id }}"</span>
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">#define</span>
                    <span class="copytitle">DBOT_AUTH_TOKEN</span>
                    <span class="copyvalue"
                      >"{{ item.device_auth_token }}"</span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">#define</span>
                    <span class="copytitle">WIFI_SSID</span>
                    <span class="copyvalue"
                      >""&nbsp;&nbsp;&nbsp;//Replace Your WIFI_SSID</span
                    >
                  </div>
                  <div class="clipboard-content" style="margin-bottom: 5px">
                    <span class="clipboard-content-text">#define</span>
                    <span class="copytitle">WIFI_PASSWORD</span>
                    <span class="copyvalue"
                      >""&nbsp;&nbsp;&nbsp;//Replace Your WIFI_PASSWORD</span
                    >
                  </div>

                  <div class="clipboard-content">
                    <span class="clipboard-content-text" style="color: #dbdb7b"
                      >void setup<span style="color: yellow">()</span></span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">{{"{"}}</span>
                  </div>
                  <div class="clipboard-content"></div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text"
                      >&nbsp;&nbsp;&nbsp;Serial.begin<span style="color: yellow"
                        >(</span
                      >115200<span style="color: yellow">)</span>;</span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text"
                      >&nbsp;&nbsp;&nbsp; dBot.begin<span style="color: yellow"
                        >(</span
                      ><span class="copytitle">DBOT_GATEWAY_ID</span>,
                      <span class="copytitle">DBOT_AUTH_TOKEN</span>,
                      <span class="copytitle">WIFI_SSID</span>,
                      <span class="copytitle">WIFI_PASSWORD</span
                      ><span style="color: yellow">)</span><span>;</span></span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">{{"}"}}</span>
                  </div>

                  <div class="clipboard-content">
                    <span class="clipboard-content-text" style="color: #dbdb7b"
                      >void loop<span style="color: yellow">()</span></span
                    >
                  </div>

                  <div class="clipboard-content">
                    <span class="clipboard-content-text">{{"{"}}</span>
                  </div>
                  <div class="clipboard-content">
                    <span style="color: #39b840"
                      >// Put your main code here to run repeatedly</span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text"
                      >&nbsp;&nbsp;&nbsp;dBot.Loop<span style="color: yellow"
                        >()</span
                      >;</span
                    >
                  </div>
                  <div class="clipboard-content">
                    <span class="clipboard-content-text">{{"}"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        
        <!-- <mat-tab label="Device data">
         
          <div class="datastream-header">
            <div class="datastreamsearch">
              <div class="search">
                <mat-icon class="search-icon" (click)="datastreamsearch.focus()"
                  >search</mat-icon
                >
                <input
                  class="form-control datastream-search-input"
                  type="search"
                  #datastreamsearch
                  placeholder="Search Device data"
                  [(ngModel)]="devicedatafilter"
                />
              </div>
              <div class="datastream-length">
                <h3>No Of Device data : {{ this.metadatalist?.length }}</h3>
              </div>
            </div>
          </div>
          <div class="devices-table devicedata">
            <table>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Value</th>
                <th>Description</th>
              </tr>
              <tr *ngIf="metadatalist?.length==0" class="no-data">
                <td colspan="7">
                  <mat-icon> insert_drive_file</mat-icon>
                  <h5>No Data</h5>
                </td>
              </tr>
              <tr
                *ngFor="
                      let item of metadatalist
                        | filter : devicedatafilter
                        | paginate : devicedataconfig;
                      let i = index
                    "
              >
                <td>
                  {{ (this.devicedataconfig.currentPage - 1) * 4 + i + 1 }}
                </td>
                <td>{{ item.meta_key }}</td>
                <td>{{ item.meta_value }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </table>
            <pagination-controls
              class="pagination"
              id="devicedata"
              (pageChange)="devicedatapageChanged($event)"
            >
            </pagination-controls>
          </div>

          
        </mat-tab> -->

        <mat-tab label="Events" class="events">
          <!-- <mat-form-field class="mat-form">
            <mat-label>Sort By</mat-label>
            <mat-select>
              <mat-option value="latest">Latest</mat-option>
              <mat-option value="last-hour">Last Hour</mat-option>
              <mat-option value="6-hour">6 Hour</mat-option>
              <mat-option value="one-day">One Day</mat-option>
              <mat-option value="one-week">One Week</mat-option>
            </mat-select>
          </mat-form-field> -->
          <mat-tab-group animationDuration="0ms" [selectedIndex]="eventtabindex">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #0dcaf0">info</mat-icon>
                Info
              </ng-template>
              <div class="timeline-container">
                <div class="timeline">
                  <div class="timeline-item" *ngFor="let event of Infomessage">
                    <div class="timeline-item-content">
                      <h3 class="timeline-title">{{event.message}}</h3>
                      <p class="timeline-description">Device Name: {{event.device_name}}</p>
                      <span class="timeline-time"
                        ><i class="far fa-clock"></i>
                        {{event.created_on | date:"long"}}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #ffc107">announcement</mat-icon>
                Warning
              </ng-template>
              <div class="timeline-container">
                <div class="timeline">
                  <div
                    class="timeline-item"
                    *ngFor="let event of Warningmessage"
                  >
                    <div class="timeline-item-content">
                      <h3 class="timeline-title">{{event.message}}</h3>
                      <p class="timeline-description">Device Name: {{event.device_name}}</p>
                      <span class="timeline-time"
                        ><i class="far fa-clock"></i>
                        {{event.created_on|date:"short"}}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #dc3545">report_problem</mat-icon>
                Critical
              </ng-template>
              <div class="timeline-container">
                <div class="timeline">
                  <div
                    class="timeline-item"
                    *ngFor="let event of Criticalmessage"
                  >
                    <div class="timeline-item-content">
                      <h3 class="timeline-title">{{event.message}}</h3>
                      <p class="timeline-description">Device Name: {{event.device_name}}</p>
                      <span class="timeline-time"
                        ><i class="far fa-clock"></i>
                        {{event.created_on|date:"short"}}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab   *ngIf="item.slave_id === 1" label="Events log">
          <div class="action-log" *ngIf="this.devicelog.length <= 0">
            <div>
              <h3>No Events log</h3>
            </div>
          </div>

          <mat-spinner
            role="progressbar"
            diameter="50"
            aria-hidden="true"
            *ngIf="this.logrefresh"
            id="spinner"
          >
          </mat-spinner>
          <div *ngIf="this.devicelog.length >= 1 && !this.logrefresh">
            <div  class="d-flex logrefresh">
              <h2>Events log</h2>
              <!-- <button
                class="btn btn-primary"
                (click)="refreshdevicelog(item.device_id)"
              >
                refresh
              </button> -->
            </div>
            <div class="devicelog">
              <div
                *ngFor="let log of devicelog"
                style="display: flex; padding: 10px; gap: 10px"
              >
                <p>{{ log.date_time | date : "medium" }} ==></p>
                <p>{{ log.device_log }}</p>
              </div>
            </div>
          </div>
        </mat-tab>
<!-- 
        <mat-tab label="Sub Devices">
          <div class="action-log" *ngIf="this.subdevices.length <= 0">
            <div>
              <h3>No Sub Devices found</h3>
              <button class="btn btn-primary" (click)="newsubdevice()">Create Sub Devices</button>
            </div>
          </div>
        </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>
  <!-- DASHBOARD SIDENAV  -->

  <!-- <mat-sidenav #dbdesign_sidenav class="sidenav-dbdesign" position="end">
    <div class="darkmode" [ngClass]="{'dark-mode': darkMode}">
      <div class="dbsidenav">

        <div class="db-side">
          <div class="routes-buttons">
            <div class="dashboard-heading">
              <h1 [ngClass]="{'dark-mode': darkMode}">
                {{ item.device_name }} Dashboard
              </h1>
              <span
                class="status-online"
                *ngIf="
              +this.currenttime - +item.last_online <
                1000 * item.heartbeat && item.status_Id == 1
            "
                >Online</span
              >
              <span
                class="status-offline"
                *ngIf="
              +this.currenttime - +item.last_online >
                1000 * item.heartbeat || item.status_Id == 0 && !item.isOnline
            "
                matTooltip="Last Online Since {{
              item.last_online | date : 'short'
            }}"
                matTooltipPosition="right"
                >Offline</span
              >
            </div>
            <div
              [ngClass]="{'dark-mode': darkMode}"
              class="btn-clear cancel-db db-cancel"
              (click)="dbdesign_sidenav.close()"
            >
              Back
            </div>
            <iconify-icon
              *ngIf="!darkMode"
              (click)="toggleDarkMode()"
              class="btn-clear night-mode"
              icon="line-md:sunny-filled-loop-to-moon-alt-filled-loop-transition"
            ></iconify-icon>
            <iconify-icon
              *ngIf="darkMode"
              (click)="toggleDarkMode()"
              class="btn-clear night-mode"
              style="color: rgb(255, 255, 255)"
              icon="line-md:moon-filled-alt-to-sunny-filled-loop-transition"
            ></iconify-icon>

            <iconify-icon
              (click)="tooglefullscreen()"
              *ngIf="!darkMode"
              class="btn-clear full-screenn"
              icon="material-symbols:fullscreen"
            ></iconify-icon>
            <iconify-icon
              (click)="tooglefullscreen()"
              *ngIf="darkMode"
              class="btn-clear full-screenn"
              style="color: rgb(255, 255, 255)"
              icon="material-symbols:fullscreen"
            ></iconify-icon>
            
          </div>

          <div class="widgets">
            <div class="wed-dash-right">
              <div class="dash-right-body">
                <gridster
                  *ngIf="this.devicedashboard.length>=1"
                  #gridster
                  class="gridster"
                  [options]="gridsterOptions"
                >
                  <gridster-item
                    [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                    class="dashboard-widgets"
                    *ngFor="let widget of devicedashboard"
                    [item]="widget"
                  >
                    <div class="widgets">
                      <div class="box-head drag-handler">
                        <h2 *ngIf="widget?.title?.length <= 0">
                          {{ widget.widgetname }}
                        </h2>
                        <h2 *ngIf="widget?.title?.length >= 1">
                          {{ widget.title }}
                        </h2>
                        <span></span>
                      </div>
                    </div>
                    <div
                      style="margin-top: 8px"
                      class="widget-body"
                      *ngIf="widget.widgetname == 'Switch'"
                    >
                      <div
                        (mousedown)="$event.stopPropagation()"
                        (touchstart)="$event.stopPropagation()"
                      >
                        <ui-switch
                          [disabled]="!item.isOnline"
                          (change)="
                      onEnter(
                        $event,
                        item.device_auth_token,
                        item.last_online,
                        widget.onvalue,
                        widget.offvalue,
                        widget.pin,
                        widget.pinmode,
                        widget.datastream_name,
                        item.isOnline
                      )
                    "
                          [checked]="widget.devicedata==1"
                        >
                        </ui-switch>
                       
                      </div>
                      <div *ngIf="widget.max==1">
                        <h2>{{ widget.onlabel }}</h2>
                      </div>
                      <div *ngIf="widget.max==0">
                        <h2>{{ widget.offlabel }}</h2>
                      </div>
                    </div>
                    <div *ngIf="widget.widgetname == 'Chart'" [class]="dark">
                      <mat-form-field
                        *ngIf="!this.ChartRefresh"
                        [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                        class="chart-filter-select"
                      >
                        <mat-label>Select an option</mat-label>
                        <mat-select
                          [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode,'mat-option-dark': darkMode}"
                          [(ngModel)]="widget.chartfilter"
                          (ngModelChange)="Chartfilter(widget.widget_Id,widget.chartfilter)"
                        >
                          <mat-option
                            [ngClass]="{'mat-option-dark': darkMode}"
                            value="Day"
                            >1 Day</mat-option
                          >
                          <mat-option
                            [ngClass]="{'mat-option-dark': darkMode}"
                            value="Week"
                            >1 Week</mat-option
                          >
                          <mat-option
                            [ngClass]="{'mat-option-dark': darkMode}"
                            value="Month"
                            >1 Month</mat-option
                          >
                        </mat-select>
                      </mat-form-field>

                      <mat-spinner
                        role="progressbar"
                        diameter="50"
                        aria-hidden="true"
                        *ngIf="this.ChartRefresh"
                        id="spinner"
                      >
                      </mat-spinner>
                      <ngx-charts-line-chart
                        *ngIf="widget?.chart_type === 'line-chart'&&!this.ChartRefresh"
                        [view]="[540,180]"
                        class="chart-container"
                        [scheme]="widget?.color_scheme"
                        [schemeType]="schemeType"
                        [results]="widget?.devicedata"
                        [animations]="false"
                        [legend]="false"
                        [strokeColor]="strokeColor"
                        [legendTitle]="widget?.label"
                        [legendPosition]="legendPosition"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="widget?.xAxisLabel"
                        [yAxisLabel]="widget?.yAxisLabel"
                        [autoScale]="false"
                        [xScaleMin]="widget?.xScaleMin"
                        [xScaleMax]="widget?.xScaleMax"
                        [yScaleMin]="widget?.Min"
                        [yScaleMax]="widget?.Max"
                        [roundDomains]="roundDomains"
                        [showGridLines]="showGridLines"
                        [xAxisTicks]="widget.xAxisTicks"
                        [xAxisTickFormatting]="xAxisTickFormattingFunction"
                        [rangeFillOpacity]="0.50"
                      >
                      </ngx-charts-line-chart>
                    </div>

                    <div class="label-wid" *ngIf="widget.widgetname == 'Cost'">
                      <h3 [style.color]="widget?.color" *ngIf="!item.isOnline">
                        --
                      </h3>
                      <div *ngIf="item.isOnline">
                        <h3
                          style="text-transform: none"
                          [style.color]="widget?.color"
                          *ngIf="widget.params && widget.pin ==='V1'"
                        >
                          {{ widget.params }}
                          <span
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                            >$</span
                          >
                        </h3>
                        <h3
                          style="text-transform: none"
                          [style.color]="widget?.color"
                          *ngIf="widget.devicedata && widget.pin !== 'V1'"
                        >
                          {{ widget.devicedata}}
                          <span
                            [ngClass]="{'gridster-itemm': darkMode, 'reading-affix:last-child': darkMode}"
                          ></span>
                        </h3>
                      </div>
                    </div>

                    

                    <div
                      class="ledon ledoff"
                      *ngIf="widget.widgetname == 'LED'"
                    >
                      <div
                        *ngIf="widget.devicedata == 1"
                        [style.color]="widget.color"
                      >
                        <label class="switch1">
                          <input class="chk" type="checkbox" />
                          <span class="slider"
                            ><span class="slider-on">ON</span>
                          </span>
                        </label>
                      </div>
                      <div *ngIf="widget.devicedata == 0">
                        <label class="switch1">
                          <input class="chk" type="checkbox" />
                          <span class="slider"
                            ><span class="slider-off">OFF</span>
                          </span>
                        </label>
                      </div>
                      <div class="ledoff" *ngIf="widget.devicedata == null">
                        <label class="switch1">
                          <input class="chk" type="checkbox" />
                          <span class="slider"
                            ><span class="slider-off">OFF</span>
                          </span>
                        </label>
                      </div>
                    </div>

                    

                    <div
                      *ngIf="widget.widgetname == 'Slider'"
                      (mousedown)="$event.stopPropagation()"
                      (touchstart)="$event.stopPropagation()"
                    >
                      <ngx-slider
                        [ngClass]="{'ngx-slider-pointer': darkMode}"
                        (valueChange)="
            onInputChange(
              $event,
              item.device_auth_token,
              item.last_online,
              widget.pin,
              widget.pinmode,
              widget.datastream_name,
              item.isOnline
            )
          "
                        [value]="widget.devicedata||widget.Min"
                        [options]="{'floor':widget.Min,'disabled':!item.isOnline,'ceil':widget.Max,'step':widget.handlestep,'showSelectionBar':true,'selectionBarGradient': {
              from: 'white',
              to: '#1e84ff'
            }}"
                      ></ngx-slider>
                    </div>
                    <div
                      class="widget-body-gauge"
                      (mousedown)="$event.stopPropagation()"
                      (touchstart)="$event.stopPropagation()"
                      *ngIf="widget.widgetname == 'Gauge'"
                    >
                      <div
                        class="gauge-container"
                        *ngIf="widget?.gaugeType === 'arch'"
                      >
                        <ngx-gauge
                          id="gauge-text-color"
                          style="bottom: 18px"
                          [style]="'bottom: 18px; color: ' + (widget?.color || 'defaultColor')"
                          [size]="230"
                          [readonly]="true"
                          [strokeWidth]="5"
                          [foregroundColor]="widget?.color"
                          [min]="widget?.Min"
                          [max]="widget?.Max"
                          [value]="widget?.pin === 'V1' ? widget?.params : widget?.devicedata"
                          [type]="widget?.gaugeType"
                          rangeColor="lightgray"
                          append="{{ widget?.pin === 'V2' ? 'Volt (phase)' : (widget?.pin === 'V3' ? 'Voltage (line)' : (widget?.pin === 'V4' ? 'A' : 'kWh')) }}"
                          [thick]="25"
                          cap="butt"
                        >
                        </ngx-gauge>
                        <div class="value-container">
                          <p class="min-value">{{widget?.Min}}</p>
                          <p class="max-value">{{widget?.Max}}</p>
                        </div>
                      </div>
                      <div
                        class="gauge-container"
                        *ngIf="widget?.gaugeType != 'arch'"
                      >
                    
                        <ngx-gauge
                          id="gauge-text-color"
                          style="bottom: 18px"
                          [style]="'bottom: 18px; color: ' + (widget?.color || 'defaultColor')"
                          [size]="200"
                          [readonly]="true"
                          [strokeWidth]="5"
                          [foregroundColor]="widget?.color"
                          [min]="widget?.Min"
                          [max]="widget?.Max"
                          [value]="widget?.pin === 'V1' ? widget?.params : widget?.devicedata"
                          [type]="widget?.gaugeType"
                          rangeColor="lightgray"
                          append="{{ widget?.pin === 'V2' ? 'Volt (phase)' : (widget?.pin === 'V3' ? 'Voltage (line)' : (widget?.pin === 'V4' ? 'A' : 'kWh')) }}"
                          [thick]="25"
                          cap="butt"
                        >
                        </ngx-gauge>
                        <div class="value-container1">
                          <p class="min-value">{{widget?.Min}}</p>
                          <p class="max-value">{{widget?.Max}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="label-wid" *ngIf="widget.widgetname == 'Label'">
                      <h3 [style.color]="widget?.color" *ngIf="!item.isOnline">
                        --
                      </h3>
                      <div *ngIf="item.isOnline">
                        <h3
                          [style.color]="widget?.color"
                          *ngIf="widget.params && widget.pin ==='V1'"
                        >
                          {{ widget.params }}
                          <span
                            [ngClass]="{'gridster-itemm': darkMode,'reading-affix:last-child': darkMode}"
                          ></span>
                        </h3>
                        <h3
                          [style.color]="widget?.color"
                          *ngIf="widget.devicedata && widget.pin !== 'V1'"
                        >
                          {{ widget.devicedata}}
                          <span
                            [ngClass]="{'gridster-itemm': darkMode, 'reading-affix:last-child': darkMode}"
                            >A</span
                          >
                        </h3>
                      </div>
                    </div>
                    <div
                      class="terminal-div"
                      *ngIf="widget.widgetname == 'Terminal'"
                    >
                      <div
                        *ngIf="widget.screencolor"
                        class="widget-body widget-terminal"
                      >
                        <div
                          class="success-msg"
                          [style.background]="widget.screencolor"
                        >
                          <ul>
                            <li
                              *ngFor="let terminal of terminalmsg"
                              [style.color]="widget.textcolor"
                            >
                              >{{ terminal }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        *ngIf="!widget.screencolor"
                        class="widget-body widget-terminal"
                      >
                        <div
                          class="success-msg"
                          style="background-color: black"
                        >
                          <ul>
                            <li
                              *ngFor="let terminal of terminalmsg"
                              style="color: white"
                            >
                              >{{ terminal }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div [formGroup]="terminalform">
                        <input
                          *ngIf="widget.screencolor"
                          type="text"
                          class="form-control"
                          [style.color]="widget.textcolor"
                          [style.background]="widget.screencolor"
                          [placeholder]="widget.inputhint"
                          formControlName="terminal"
                          (keydown.enter)="
                  sendmsg(
                    $event,
                    item.device_auth_token,
                    item.last_online,
                    widget.pin,
                    widget.pinmode,
                    widget.datastream_name,
                    item.isOnline
                  )
                "
                        />
                        <input
                          type="text"
                          formControlName="terminal"
                          (keydown.enter)="
                sendmsg(
                  $event,
                  item.device_auth_token,
                  item.last_online,
                  widget.pin,
                  widget.pinmode,
                  widget.datastream_name,
                  item.isOnline
                )
              "
                          placeholder="Input Hint"
                          style="background-color: black; color: white"
                          class="form-control"
                          *ngIf="!widget.screencolor"
                        />
                      </div>
                    </div>
                  </gridster-item>


                </gridster>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav> -->
</div>
<!-- device information end -->
