<div class="matdialog">
    <div class="matdialog-header">
        <div>
            <h1>Change Password</h1>
        </div>
        <div>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="matdialog-body">
        <form action="" [formGroup]="passwordform">
            <div class="form-field">
              <label for="" class="form-label">Old Password</label>
              <div class="eye">
                <input [type]="show ? 'password' : 'text'" class="form-control" formControlName="oldpassword"
                  placeholder="Enter the password" #password />
          
                <mat-icon style="margin-left: 2px" class="hideeye" (click)="show = !show"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="show">{{ show ? "visibility_off" : "visibility" }}</mat-icon>
              </div>
              <div *ngIf="passwordform.controls['oldpassword'].dirty">
                <span *ngIf="passwordform.controls['oldpassword']?.errors?.['required']" style="color: red;">*Password is required.</span>
                <span *ngIf="passwordform.controls['oldpassword']?.errors?.['minlength']&&!passwordform.controls['oldpassword']?.errors?.['noSpace']" style="color: red;">*Minimum 6 characters</span>
                <span *ngIf="passwordform.controls['oldpassword']?.errors?.['maxlength']&&!passwordform.controls['oldpassword']?.errors?.['noSpace']" style="color: red;">*Maximum 20 characters</span>
                <span *ngIf="passwordform.controls['oldpassword']?.errors?.['noSpace']" style="color: red;">*Spaces are not allowed</span>
              </div>
            </div>
            <div class="form-field">
              <label for="" class="form-label">New Password</label>
              <div class="eye">
                <input [type]="hide ? 'password' : 'text'" class="form-control" formControlName="newpassword"
                  placeholder="Enter the password" #password />
          
                <mat-icon style="margin-left: 2px" class="hideeye" (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </div>
              <div *ngIf="passwordform.controls['newpassword'].dirty">
                <span *ngIf="passwordform.controls['newpassword']?.errors?.['required']" style="color: red;">*Password is required.</span>
                <span *ngIf="passwordform.controls['newpassword']?.errors?.['minlength']&&!passwordform.controls['newpassword']?.errors?.['noSpace']" style="color: red;">*Minimum 6 characters</span>
                <span *ngIf="passwordform.controls['newpassword']?.errors?.['maxlength']&&!passwordform.controls['newpassword']?.errors?.['noSpace']" style="color: red;">*Maximum 20 characters</span>
                <span *ngIf="passwordform.controls['newpassword']?.errors?.['noSpace']" style="color: red;">*Spaces are not allowed</span>
              </div>
            </div>
          </form>
          
    </div>
    <div class="matdialog-footer">
        <div><button class="btn btn-danger" mat-dialog-close>Cancel</button></div>
        <div><button class="btn btn-success" [disabled]="!this.passwordform.valid" (click)="changepassword()">Save</button>
        </div>
    </div>

</div>