<div *ngIf="this.loading" class="loading">
  <iconify-icon icon="svg-spinners:blocks-shuffle-3"></iconify-icon>
  <h4>Loading</h4>
</div>
<div *ngIf="!this.loading && !this.Devicecount && !this.kwh" class="loading">
  <iconify-icon icon="material-symbols:dashboard"></iconify-icon>
  <h4>No Data Found</h4>
</div>
<section
  *ngIf="!this.loading && this.Devicecount && this.kwh"
  class="home-section"
>
  <div class="dashboard-head">
    <h2>{{ this.verticalname }} Dashboard</h2>
    <mat-form-field appearance="fill">
      <mat-label>Color Scheme</mat-label>
      <mat-select
        [(ngModel)]="selectedColor"
        (selectionChange)="OnColorChange($event.value)"
        panelClass="myPanelClass"
      >
        <mat-option
          [selectd]
          [value]="color"
          *ngFor="let color of RandomColor; let i = index"
          >{{ color }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="home-content">
    <div class="overview-boxes">
      <div class="box">
        <div class="icon">
          <iconify-icon
            class="cart one"
            icon="carbon:iot-platform"
          ></iconify-icon>
        </div>

        <div class="right-side">
          <div class="number">{{ this.Devicecount }}</div>
          <div class="box-topic">Total Devices</div>
          <div class="status">
            <div class="indicator">
              <span
                class="online"
                icon="heroicons-outline:status-online"
              ></span>
              <span class="text">{{ this.online || 0 }} Online</span>
            </div>
            <div class="indicator">
              <span class="online offline"></span>
              <span class="text">{{ this.offline || 0 }} Offline</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="icon">
          <iconify-icon
            class="cart two"
            icon="mdi:energy-circle"
          ></iconify-icon>
        </div>

        <div class="right-side">
          <div class="number">{{ this.kwh }} kWh</div>
          <div class="box-topic">Total kWh Used</div>

          <!-- <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">234 kWh</span>
            </div> -->
        </div>
      </div>
      <div class="box">
        <div class="icon">
          <iconify-icon
            class="cart three"
            icon="ri:money-rupee-circle-fill"
          ></iconify-icon>
        </div>

        <div class="right-side">
          <div class="number">{{ this.cost }}</div>
          <div class="box-topic">Total Cost</div>

          <!-- <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from yesterday</span>
            </div> -->
        </div>
      </div>
      <div class="box">
        <div class="icon">
          <!-- <iconify-icon class="cart four" icon="iwwa:co2"></iconify-icon> -->
          <iconify-icon
            class="cart four"
            icon="material-symbols:energy-savings-leaf-rounded"
          ></iconify-icon>
        </div>
        <div class="right-side">
          <div class="number">{{ this.co2 }} tCo2</div>
          <div class="box-topic">Total Co2 Emission</div>
          <!-- <div class="indicator">
              <i class='bx bx-down-arrow-alt down'></i>
            </div> -->
        </div>
      </div>
    </div>

    <div class="sales-boxes">
      <div class="recent-sales box">
        <h5>kWh Usage (Last 7 Days)</h5>
        <div class="cardd">
          <div class="no-data" *ngIf="novalue">
            <mat-icon> insert_drive_file</mat-icon>
            <h6>No Data</h6>
          </div>
          <!-- <p-chart type="bar" [data]="data" [options]="options"></p-chart> -->
          <ngx-charts-bar-vertical-stacked *ngIf="!novalue"
            class="chart-container"
            [view]="[750, 200]"
            [scheme]="colorScheme"
            [results]="this.advancebarchart"
            [animations]="true"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [legendTitle]="''"
            [legendPosition]="barchart"
            [noBarWhenZero]="false"
            [showGridLines]="false"
            [barPadding]="10"
            [showDataLabel]="false"
            
          >
          </ngx-charts-bar-vertical-stacked>
        </div>
      </div>
      <div class="top-sales box">
        <h5>kWh Usage (This Month)</h5>
        <div class="carddd piechart" style="width: 100%; margin-top: 0px">
          <div class="no-data" *ngIf="novalue">
            <mat-icon> insert_drive_file</mat-icon>
            <h6>No Data</h6>
          </div>
          <!-- <p-chart type="doughnut" [data]="piedata" [options]="pieoptions"></p-chart> -->
          <ngx-charts-pie-chart
            *ngIf="!novalue"
            label="kWh"
            [scheme]="colorScheme"
            [animations]="true"
            [legend]="true"
            [view]="[250, 230]"
            [arcWidth]="0.4"
            [legendTitle]="''"
            [legendPosition]="legendPosition"
            [labels]="false"
            [doughnut]="true"
            [results]="this.advancepie"
          >
          </ngx-charts-pie-chart>
        </div>
      </div>
    </div>

    <div class="table-data" *ngIf="this.tabledata?.length >= 1">
      <div class="order">
        <div class="head">
          <h5>Top 10 Energy Consumed Devices</h5>
          <i class="bx bx-search"></i>
          <i class="bx bx-filter"></i>
        </div>
        <table>
          <thead>
            <tr>
              <th>Device_name</th>
              <th>Status</th>
              <th>Max kWh</th>
              <th>Max CO2</th>
              <th>Max Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tabledata">
              <td>
                <p>{{ item.device_name }}</p>
              </td>
              <td>
                <span *ngIf="item.status == '1'" class="status completed"
                  >online</span
                >
                <span *ngIf="item.status == '0'" class="status pending"
                  >Offline</span
                >
              </td>
              <td>{{ item.currentMonthKWH }}</td>
              <td>{{ item.CO2 }}</td>
              <td>{{ item.cost }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>