<div class="matdialog">
  <div class="matdialog-head">
    <div>
      <h1>OTA Update</h1>
    </div>
    <div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div class="matdialog-body">
    <form action="" [formGroup]="otaupdateform">
      <div class="form-field">
        <label for="">Select Gateway</label>
        <select name="" id="" class="form-select" formControlName="product">
          <option value="" [selected]="true">Select the Gateway</option>
          <option value="{{item.cluster_id}}" *ngFor="let item of productvalue" class="form-option">
            {{item.cluster_name}}
          </option>
        </select>
        <div
          *ngIf="otaupdateform.controls['product'].invalid && (otaupdateform.controls['product'].dirty ||otaupdateform.controls['product'].touched)">
          <span class="validation-errors" *ngIf="otaupdateform.controls['product']?.errors"
            style="color: red; position: absolute; font-size: 11px">
            Please Select the Gateway
          </span>
        </div>
      </div>

      <div class="form-field">
        <label for="">Select version type</label>
        <select name="" id="" class="form-select" formControlName="versiontype">
          <option value="" [selected]="true">Select version type</option>
          <option value="Minor">Minor</option>
          <option value="Major">Major</option>
        </select>
        <div
          *ngIf="otaupdateform.controls['versiontype'].invalid && (otaupdateform.controls['versiontype'].dirty ||otaupdateform.controls['versiontype'].touched)">
          <span *ngIf="otaupdateform.controls['versiontype']?.errors"
            style="color: red; position: absolute; font-size: 11px">
            Please Select version type
          </span>
        </div>
      </div>
      <div class="versions">
        <div class="form-field" style="padding: 10px 10px 10px 24px;">
          <label for="">Current Version</label>
          <input [readOnly]="true" type="text" class="form-control" formControlName="current" placeholder="Version" />
        </div>
        <div class="form-field" style="padding: 10px 24px 10px 10px;">
          <label for="">New Version</label>
          <input [readOnly]="true" type="text" class="form-control" formControlName="version" placeholder="Version" />
        </div>
      </div>
      <div class="form-field filediv" [formGroup]="fileUploadForm">
        <label for="">Upload Firmware File</label>
        <input type="file" name="File" id="" #fileupload class="form-control files" (change)="onFileChanged($event)"
          accept=".bin,.zip" />
        <p *ngIf="fileSizeDisplay" class="file-size-display">{{ fileSizeDisplay }}</p>

        <!-- <p class="form-control file" (click)="fileupload.click()"></p> -->
      </div>
      <div class="form-field">
        <label for="">Firmware Name</label>
        <input type="text" formControlName="firmwarename" placeholder="Firmware-1" class="form-control">

      </div>
      <div class="versions">
        <div class="form-field" style="padding: 10px 10px 10px 24px;">
          <label for="">Attempts Limit</label>
          <input type="number" formControlName="attempts" class="form-control">
          <div
            *ngIf="otaupdateform.controls['attempts'].invalid && (otaupdateform.controls['attempts'].dirty || otaupdateform.controls['attempts'].touched)">
            <span class="validation-errors" *ngIf="otaupdateform.controls['attempts'].errors?.['min']"
              style="color: red; position: absolute; font-size: 11px">
              Minimum 1
            </span>
            <span class="validation-errors" *ngIf="otaupdateform.controls['attempts'].errors?.['max']"
              style="color: red; position: absolute; font-size: 11px">
              Maximum 10
            </span>
          </div>

        </div>
        <div class="form-field" style="padding: 10px 24px 10px 10px;">
          <label for="">Attempts Reset Period</label>
          <div style="display: flex;gap:10px;
          ">
            <input type="number" formControlName="attempts_reset_period" class="form-control">
            <select style="    width: 57%;
            " class="form-select" formControlName="interval_type">
              <option class="form-option" value="60">
                Minute
              </option>
              <option class="form-option" value="3600">
                Hour
              </option>
            </select>
          </div>
          <div
            *ngIf="otaupdateform.controls['attempts_reset_period'].invalid && (otaupdateform.controls['attempts_reset_period'].dirty || otaupdateform.controls['attempts_reset_period'].touched)">
            <span class="validation-errors" *ngIf="otaupdateform.controls['attempts_reset_period'].errors?.['min']"
              style="color: red; position: absolute; font-size: 11px">
              Minimum 1
            </span>

          </div>


        </div>
      </div>

      <div class="form-field">
        <label for="">Description(Optional)</label>
        <textarea name="" id="" cols="30" rows="5" class="form-control" formControlName="description"
          placeholder="Enter the description"></textarea>
        <div *ngIf="
                        
                    otaupdateform.controls['description'].dirty 
                          
                      ">
          <span *ngIf="otaupdateform.controls['description']?.errors?.['maxlength']"
            style="color: red; position: absolute; font-size: 11px">
            *Maximum 75 characters only
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="matdialog-footer">
    <button class="btn btn-danger" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" [disabled]="this.otaupdateform.invalid||this.fileUploadForm.invalid||this.selectedFile==undefined"
      (click)="Otaupdate()" mat-dialog-close>
      Upload
    </button>
  </div>
</div>