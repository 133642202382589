import { Component, OnInit } from '@angular/core';
import { Dataservice } from '../services/dataservice';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cronjobs',
  templateUrl: './cronjobs.component.html',
  styleUrls: ['./cronjobs.component.css']
})
export class CronjobsComponent implements OnInit {

  cronJobs: any;
  data: any;
  loading!: boolean;
  filterTerm!: string;
  cronjobtableconfig: { id: string; itemsPerPage: number; currentPage: number };

  constructor(private dataservice: Dataservice, private toastr: ToastrService) {
    this.cronjobtableconfig = {
      id: 'job-table',
      itemsPerPage: 6,
      currentPage: 1,
    };
    this.loading = true;
  }

  ngOnInit(): void {

    this.GetJobs();
    // Subscribe to Devicerefresh event
    this.dataservice.Cronrefresh.subscribe((response: any) => {
      this.GetJobs();
    });


  }


  async GetJobs() {

    this.dataservice.GetJobs().subscribe(async (res: any) => {
      this.loading = false;
      if (res.status == "200") {
        this.data = await res.data;
        this.cronJobs = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    })
  }

  crontable(event: any) {
    this.cronjobtableconfig.currentPage = event;

  }


  onFilterTermChange() {
    this.cronjobtableconfig.currentPage = 1;
  }


  Reruncron(scheduletime: any, reference: any, job_type: any, Job_token: any) {

    this.cronJobs.map((x: any) => {
      if (x.Job_token == Job_token) {
        x.Actions = 0
      }
    })
    const data = { scheduletime, reference, job_type, Job_token }
    this.dataservice.ReRunCron(data).subscribe((res: any) => {
      if (res.status == '201') {
        this.toastr.success("Update Succesfully")
      } else if (res.status == "304") {
        this.toastr.info(res.data)
      }else if(res.status=="0"){
        this.toastr.error(res.data)
      }
      else {
        this.toastr.error('Error occurred');
      }
    })



  }

}
