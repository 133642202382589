import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deletepopup } from '../mydevices/mydevices.component';
import { Dataservice } from '../services/dataservice';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthenticationService } from '../Authentication/authentication.service';
import { Ng2TelInput } from 'ng2-tel-input';

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.css'],
})
export class VerticalComponent implements OnInit {
  data: any;
  loading: boolean;
  isconfirm: boolean;
  productinfo: any;
  product_id: any;
  productdata: any;
  devicedata: Object | undefined;
  id: any;
  verticalvalue: any;
  user_Id: any;
  selectedFile: any;
  fileInputLabel: any;
  fileUploadForm: any;
  url: any;
  vertical_logo: any;
  vertical_Id: any;
  verticaldata: any;
  verticalname: any;
  description: any;
  phone: any;
  person: any;
  org_name: any;
  filterTerm: any;
  regdate: any;
  value = 0;
  location: any;
  location_Id: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;


  /**
 * Creates an instance of the component.
 * @param {MatDialog} matdialog - The MatDialog service for opening dialogs.
 * @param {Dataservice} dataservice - The data service for managing data.
 * @param {ToastrService} toastr - The Toastr service for displaying messages.
 * @param {Router} router - The Router service for navigation.
 * @param {FormBuilder} formbuilder - The FormBuilder service for creating forms.
 * @param {AuthenticationService} authentication - The authentication service for user authentication.
 */
  constructor(
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router,
    private formbuilder: FormBuilder,
    private authentication: AuthenticationService
  ) {
    /**
     * Indicates whether a confirmation is required.
     * @type {boolean}
     */
    this.isconfirm = false;

    /**
     * Indicates the loading state.
     * @type {boolean}
     */
    this.loading = false;

    /**
     * User roles obtained from authentication service.
     * @type {number[]}
     */
    this.roles = this.authentication.getUserRole();

    /**
     * Role for create permission.
     * @type {number}
     */
    this.roles = this.roles[2];

    /**
     * Iterate through roles and set permission flags accordingly.
     */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          /**
           * Indicates whether user has create permission.
           * @type {boolean}
           */
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          /**
           * Indicates whether user has read permission.
           * @type {boolean}
           */
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          /**
           * Indicates whether user has delete permission.
           * @type {boolean}
           */
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }

  /**
   * Angular lifecycle hook - called after initialization.
   */
  ngOnInit(): void {
    this.dataservice.setPaginationState(null, 'all');
    // Triggering the update of various device statuses.
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();

    // Getting user data and initializing loading state.
    const data = this.authentication.getUserData();
    this.loading = true;

    // Fetching vertical data based on user data.
    this.getverticaldata(data);

    // Subscribing to Verticalrefresh event to update data.
    this.dataservice.Verticalrefresh.subscribe((response: any) => {
      this.getverticaldata(data);
    });
  }

  /**
   * Fetches vertical data based on the provided user data.
   * @param {any} data - The user data required for fetching vertical data.
   * @returns {void}
   */
  getverticaldata(data: any): void {
    /**
     * Fetch vertical data using the provided user data.
     * @param {any} res - The response received from the API call.
     * @returns {void}
     */
    this.dataservice.getverticaldata(data).subscribe((res: any) => {
      // Update loading state
      this.loading = false;

      // Check the response status
      if (res.status == '200') {
        // Update the data and vertical value
        this.data = res.data;
        this.verticalvalue = this.data;
      } else {
        // Display an error message
        this.toastr.error('Error occurred');
      }
    });
  }

  /**
   * Opens the edit view for a specific vertical.
   * @param {any} id - The ID of the vertical to be edited.
   * @returns {void}
   */
  editopen(id: any): void {
    /**
     * Update the selected vertical ID.
     * @type {any}
     */
    this.vertical_Id = id;

    /**
     * Navigate to the edit view for the selected vertical.
     * @type {any}
     */
    this.router.navigate(['/app/verticalinfo', this.vertical_Id]);
  }


  /**
   * Opens a dialog based on the create permission status.
   * @returns {void}
   */
  open(): void {
    /**
     * Check if the user has create permission.
     * @type {boolean}
     */
    if (this.iscreate === true) {
      /**
       * Configuration for opening the new vertical dialog.
       * @type {MatDialogRef<any>}
       */
      const MatDialogConfig = this.matdialog.open(newvertical, {
        disableClose: true,
      });
    } else if (this.iscreate === false) {
      /**
       * Display an info message if user does not have create permission.
       * @type {void}
       */
      this.toastr.info("User not permitted");
    }
  }




}


@Component({
  selector: 'newvertical',
  templateUrl: 'newvertical.html',
  styleUrls: ['newvertical.css'],
})
export class newvertical implements OnInit {
  submitted: any;
  loading: boolean;
  id: any;
  user_Id: any;
  data: any;
  location: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;

  options = {
    types: ["establishment"],
    componentRestrictions: { country: ['IND', 'US', 'AUS', 'UK', 'RU'] },
    mapTypeId: 'hybrid',
  } as unknown as Options;
  @ViewChild("placesRef")
  placesRef!: GooglePlaceDirective;
  Address!: Address;
  city!: string;
  state!: string;
  country!: string;
  zip!: string;
  latitude!: number;
  longitude!: number;
  isAddress: any;
  searchValue: any;
  countrycode: any = "in";
  dialcode: any = "+91";
  /**
 * Creates an instance of the component responsible for creating new verticals.
 * @param {UntypedFormBuilder} formbuilders - The FormBuilder service for creating forms.
 * @param {Dataservice} dataservice - The data service for managing data.
 * @param {ToastrService} toastr - The Toastr service for displaying messages.
 * @param {Router} router - The Router service for navigation.
 * @param {AuthenticationService} authentication - The authentication service for user authentication.
 */
  constructor(
    private formbuilders: UntypedFormBuilder,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router,
    private authentication: AuthenticationService
  ) {
    /**
     * Indicates the loading state.
     * @type {boolean}
     */
    this.loading = false;

    /**
     * Indicates whether search value is active.
     * @type {boolean}
     */
    this.searchValue = true;

    /**
     * User roles obtained from authentication service.
     * @type {number[]}
     */
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[2];

    /**
     * Map user roles to permission flags.
     */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          /**
           * Indicates whether user has create permission.
           * @type {boolean}
           */
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          /**
           * Indicates whether user has read permission.
           * @type {boolean}
           */
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          /**
           * Indicates whether user has delete permission.
           * @type {boolean}
           */
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }
  /**
  * Represents the FormGroup for creating a new vertical.
  */
  verticalform = this.formbuilders.group({
    /**
     * The name of the vertical.
     */
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'),
      ],
    ],
    
    /**
     * The address of the vertical.
     */
    address: [''],
    /**
     * The city of the vertical's location.
     */
    city: [''],
    /**
     * The state of the vertical's location.
     */
    state: [''],
    /**
     * The country of the vertical's location.
     */
    country: [''],
    /**
     * The name of the location.
     */
    location_name: [''],
    /**
     * The ZIP code of the vertical's location.
     */
    zip: [''],
    /**
     * The latitude of the vertical's location.
     */
    latitude: [''],
    /**
     * The longitude of the vertical's location.
     */
    longitude: [''],
    /**
     * The contact number associated with the vertical.
     */
    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/),
      ],
    ],
    /**
     * The contact person's name for the vertical.
     */
    person: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_ ]+$'),
      ],
    ],
    /**
     * The search value for location autocomplete.
     */
    searchValue: ['', [Validators.required]],
    /**
     * A brief description of the vertical.
     */
    description: ['', Validators.maxLength(75)],
    /**
     * The country code for the contact number.
     */
    country_code: [''],
    /**
     * The dial code for the contact number.
     */
    dial_code: ['']
  });
  /**
   * Getter for accessing the individual form controls of the verticalform.
   * @returns {Object.<string, AbstractControl>} An object containing the individual form controls of the verticalform.
   */
  get verticalformcontrol(): { [key: string]: AbstractControl } {
    return this.verticalform.controls;
  }
  /**
  * Angular lifecycle hook - called after initialization.
  */
  ngOnInit(): void {
    /**
     * Subscribe to changes in the 'searchValue' form control.
     * @param {any} x - The new value of the 'searchValue' control.
     * @returns {void}
     */
    this.verticalform.controls['searchValue'].valueChanges.subscribe((x: any) => {
      /**
       * Indicates whether the search value is empty.
       * @type {boolean}
       */
      if (x.length === 0) {
        this.searchValue = true;
      } else if (x.length >= 1) {
        this.searchValue = false;
      }
    });
  }


  /**
  * Handles the change event when the selected country is changed.
  * @param {any} event - The event object containing information about the selected country.
  * @returns {void}
  */
  onCountryChange(event: any): void {
    /**
     * Update the dial code with the selected country's dial code.
     * @type {string}
     */
    this.dialcode = '+' + event.dialCode;

    /**
     * Update the country code with the selected country's ISO2 code.
     * @type {string}
     */
    this.countrycode = event.iso2;
  }
  /**
  * Handles the form submission when creating a new vertical.
  * If user has create permission, the new vertical data is submitted to the server.
  * @returns {void}
  */
  onSubmit(): void {
    if (this.iscreate === true) {
      /**
       * Indicates that the form has been submitted.
       * @type {boolean}
       */
      this.submitted = true;

      /**
       * Indicates that the form submission is in progress.
       * @type {boolean}
       */
      this.loading = true;

      /**
       * User data obtained from authentication service.
       */
      const user = this.authentication.getUserData();
      let role = user.role;
      const user_Id = user.user_Id;
      const org_Id = user.org_Id;

      // Sanitize form values
      this.verticalform.value.name = this.verticalform.value.name.replace(/[^\S\r\n]{2,}/g, ' ');
      this.verticalform.value.description = this.verticalform.value.description.replace(/[^\S\r\n]{2,}/g, ' ');
      this.verticalform.value.person = this.verticalform.value.person.replace(/[^\S\r\n]{2,}/g, ' ');

      // Set location details
      this.verticalform.value.city = this.city;
      this.verticalform.value.state = this.state;
      this.verticalform.value.country = this.country;
      this.verticalform.value.zip = this.zip;
      this.verticalform.value.latitude = this.latitude;
      this.verticalform.value.longitude = this.longitude;
      this.verticalform.value.location_name = this.placesRef.place.name;
      this.verticalform.value.address = this.placesRef.place.vicinity;

      // Store the original number before modification
      let number = this.verticalform.value.number;

      // Update country code and dial code
      this.verticalform.value.country_code = this.countrycode;
      this.verticalform.value.dial_code = this.dialcode;
      this.verticalform.value.number = this.dialcode + this.verticalform.value.number;
      
      // Prepare the vertical data and user data for submission
      const verticaldata = this.verticalform.value;
      const data = { role, org_Id, user_Id, verticaldata };
      this.verticalform.reset()

      // Submit new vertical data to the server
      this.dataservice.newvertical(data).subscribe((res: any) => {
        if (res.status == '200') {
          this.toastr.error('User cannot create vertical');
          this.verticalform.value.number = number;
          this.dialcode = this.dialcode;
          this.countrycode = this.countrycode;
        } else if (res.status == '201') {
          this.toastr.success('Vertical Successfully Created');
     
      
        } else {
          this.toastr.error('Error occurred');
          this.verticalform.value.number = number;
          this.dialcode = this.dialcode;
          this.countrycode = this.countrycode;
        }
      });
    } else if (this.iscreate === false) {
      this.toastr.info("User not permitted");
    }
  }


  /**
   * Handles the change event when the selected address changes.
   * Updates various location-related properties based on the selected address.
   * @param {Address} address - The selected address object.
   * @returns {void}
   */
  public handleAddressChange(address: Address): void {
    /**
     * The selected address object.
     * @type {Address}
     */
    this.Address = address;

    if (this.Address) {
      /**
       * Indicates whether a selected address is available.
       * @type {boolean}
       */
      this.searchValue = true;
    }

    /**
     * Clear location-related properties.
     */
    this.city = "";
    this.state = "";
    this.country = "";
    this.zip = "";

    /**
     * Update latitude with the latitude of the selected address.
     * @type {number}
     */
    this.latitude = address.geometry.location.lat();

    /**
     * Update longitude with the longitude of the selected address.
     * @type {number}
     */
    this.longitude = address.geometry.location.lng();

    /**
     * Iterate through the address components and update location properties.
     */
    address.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        if (addType == "route") {
          /**
           * The name of the street or route from the address.
           * @type {string}
           */
          this.isAddress = add.long_name;
        }
        if (addType == "locality" || addType == "sublocality_level_1") {
          /**
           * The city or locality name from the address.
           * @type {string}
           */
          this.city = add.long_name;
        }
        if (addType == "administrative_area_level_1") {
          /**
           * The state or administrative area level 1 name from the address.
           * @type {string}
           */
          this.state = add.long_name;
        }
        if (addType == "country") {
          /**
           * The country name from the address.
           * @type {string}
           */
          this.country = add.long_name;
        }
        if (addType == "postal_code") {
          /**
           * The ZIP code from the address.
           * @type {string}
           */
          this.zip = add.long_name;
        }
        if (addType == 'latitude') {
          /**
           * The latitude from the address.
           * @type {number}
           */
          this.latitude = add.long_name;
        }
        if (addType == 'longitude') {
          /**
           * The longitude from the address.
           * @type {number}
           */
          this.longitude = add.long_name;
        }
      });
    });
  }

}
@Component({
  selector: 'verticalInfo',
  templateUrl: 'vertical-Info.html',
  styleUrls: ['./vertical.component.css'],
})
export class verticalInfo implements OnInit {
  data: any;
  loading: boolean;
  isconfirm: boolean;
  productinfo: any;
  product_id: any;
  productdata: any;
  devicedata: Object | undefined;
  id: any;
  verticalvalue: any;
  user_Id: any;
  selectedFile: any;
  fileInputLabel: any;
  fileUploadForm: any;
  url: any;
  vertical_logo: any;
  vertical_Id: any;
  verticaldata: any;
  verticalname: any;
  description: any;
  phone: any;
  person: any;
  org_name: any;
  filterTerm: any;
  regdate: any;
  value = 0;
  location_name: any;
  countrycode: any = "in";
  dialcode: any = "+91";


  verticalinfo: boolean = false;
  Address: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  @ViewChild("telInput") telInput!: Ng2TelInput

  /**
 * Creates an instance of the component responsible for managing new verticals.
 * @param {MatDialog} matdialog - The MatDialog service for opening dialog modals.
 * @param {Dataservice} dataservice - The data service for managing data.
 * @param {ToastrService} toastr - The Toastr service for displaying messages.
 * @param {Router} router - The Router service for navigation.
 * @param {ActivatedRoute} route - The ActivatedRoute service for retrieving route information.
 * @param {FormBuilder} formbuilder - The FormBuilder service for creating forms.
 * @param {AuthenticationService} authentication - The authentication service for user authentication.
 */
  constructor(
    private matdialog: MatDialog,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private authentication: AuthenticationService
  ) {
    /**
     * Indicates whether the confirmation state is active.
     * @type {boolean}
     */
    this.isconfirm = false;

    /**
     * Indicates the loading state.
     * @type {boolean}
     */
    this.loading = false;

    /**
     * User roles obtained from authentication service.
     * @type {number[]}
     */
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[2];

    /**
     * Map user roles to permission flags.
     */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          /**
           * Indicates whether user has create permission.
           * @type {boolean}
           */
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          /**
           * Indicates whether user has read permission.
           * @type {boolean}
           */
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          /**
           * Indicates whether user has delete permission.
           * @type {boolean}
           */
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
  }
  /**
 * Represents the FormGroup for creating a new vertical.
 */
  verticalform = this.formbuilder.group({
    /**
     * The name of the vertical.
     */
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_ .\\-]*$'),
      ],
    ],
    /**
     * The name of the contact person for the vertical.
     */
    person: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z_ ]+$'),
      ],
    ],
    /**
     * The contact number associated with the vertical.
     */
    number: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/),
      ],
    ],
    /**
     * A brief description of the vertical.
     */
    description: ['', Validators.maxLength(75)],
    /**
     * The location information of the vertical.
     */
    location: [''],
    /**
     * The name of the location.
     */
    location_name: [''],
    /**
     * The manufacturer of the vertical.
     */
    manufacturer: [''],
    /**
     * The country code for the contact number.
     */
    country_code: [''],
    /**
     * The dial code for the contact number.
     */
    dial_code: [''],
    
  });
  /**
  * Angular lifecycle hook - called after initialization.
  */
  ngOnInit(): void {
    /**
     * Extracts the vertical ID from the route snapshot parameter.
     * @type {string | null}
     */
    this.vertical_Id = this.route.snapshot.paramMap.get('id');

    /**
     * Subscribes to the Verticalrefresh event from the data service.
     * When triggered, invokes the 'editopen' method with the extracted vertical ID.
     */
    this.dataservice.Verticalrefresh.subscribe((response: any) => {
      if (this.vertical_Id) {
        this.editopen(this.vertical_Id);
      }

    });

    // Initial call to 'editopen' method with the extracted vertical ID.
    this.editopen(this.vertical_Id);
  }

  /**
   * Handles the change event when the selected country changes.
   * Updates the dial code and country code based on the selected country.
   * @param {any} event - The event object containing information about the selected country.
   * @returns {void}
   */
  onCountryChange(event: any): void {
    /**
     * Update the dial code with the selected country's dial code.
     * @type {string}
     */
    this.dialcode = '+' + event.dialCode;

    /**
     * Update the country code with the selected country's ISO2 code.
     * @type {string}
     */
    this.countrycode = event.iso2;
  }

  ngOnDestroy() {
    this.vertical_Id = ''
  }


  /**
  * Opens the editor for an existing vertical with the provided ID.
  * Fetches the vertical data and populates the form fields for editing.
  * @param {any} id - The ID of the vertical to be edited.
  * @returns {void}
  */
  editopen(id: any): void {
    /**
     * Set the vertical ID for the current editing session.
     * @type {any}
     */
    this.vertical_Id = id;

    /**
     * Fetch the data of the specified vertical using the dataservice.
     */
    this.dataservice
      .getsinglevertical(this.vertical_Id)
      .subscribe((res: any) => {
        if (res.status == "0") {
          /**
           * Redirect to the vertical listing page if the specified vertical does not exist.
           */
          this.router.navigate(['/app/vertical']);
        } else if (res.status == '200') {
          /**
           * Extract relevant data from the response and populate form fields for editing.
           */
          this.data = res.data;
          this.verticaldata = this.data;
          this.verticalname = this.verticaldata?.vertical_name;
          this.description = this.verticaldata?.description;
          this.dialcode = this.verticaldata?.dial_code;
          this.countrycode = this.verticaldata?.country_code;
          this.vertical_logo = this.verticaldata.vertical_logo;
          this.person = this.verticaldata.contact_person;
          this.org_name = this.verticaldata.org_Name;
          this.vertical_Id = this.verticaldata.vertical_Id;
          this.regdate = this.verticaldata.regdate;
          this.Address = this.verticaldata.address;
          this.location_name = this.verticaldata.location_name;
        
          /**
                    * Remove dial code from phone number and set it for display.
                    */
          this.phone = String(this.verticaldata?.phone_num).replace(this.dialcode, "").trim();
          /**
           * Set form values using retrieved data.
           */
          this.verticalform.get('name')?.setValue(this.verticalname);
          this.verticalform.get('description')?.setValue(this.description);
          this.verticalform.get('number')?.setValue(this.phone);
          this.verticalform.get('person')?.setValue(this.person);
          this.verticalform.get('manufacturer')?.setValue(this.org_name);
          this.verticalform.get('location')?.setValue(this.Address);
          this.verticalform.get('location_name')?.setValue(this.location_name);
    


        } else {
          /**
           * Display an error message if an error occurs during data retrieval.
           */
          this.toastr.error('Error occurred');
        }
      });
  }

  /**
 * Opens the vertical information panel based on user permissions.
 * @returns {void}
 */
  open(): void {
    /**
     * Check if the user has permission to create.
     */
    if (this.iscreate == true) {
      /**
       * Set the state of the vertical information panel to true.
       */
      this.verticalinfo = true;
    } else if (this.iscreate == false) {
      /**
       * Display an informational message if the user does not have permission.
       */
      this.toastr.info("User not permitted");
    }
  }

  /**
  * Updates the vertical information based on user permissions and form validity.
  * If user has permission and the form is valid, calls the 'verticalupdate' method.
  * @returns {void}
  */
  update(): void {
    /**
     * Check if the user has permission to create.
     */
    if (this.iscreate == true) {
      /**
       * Check if the vertical form is invalid.
       */
      if (this.verticalform.invalid) {
        /**
         * Display an error message if the form is invalid.
         */
        this.toastr.error('Field is Required');
        return;
      } else {
        /**
         * Call the 'verticalupdate' method if the form is valid.
         */
        this.verticalupdate();
      }
    } else if (this.iscreate == false) {
      /**
       * Display an informational message if the user does not have permission.
       */
      this.toastr.info("User not permitted");
    }
  }

  /**
  * Updates the vertical information with the provided data.
  * @returns {void}
  */
  verticalupdate(): void {
    /**
     * Set loading state to indicate update operation in progress.
     */
    this.loading = true;

    /**
     * Replace extra spaces from person, name, and description fields.
     */
    this.verticalform.value.person = this.verticalform.value.person?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.verticalform.value.name = this.verticalform.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
    this.verticalform.value.description = this.verticalform.value.description?.replace(/[^\S\r\n]{2,}/g, ' ');

    /**
     * Set country code and dial code from the selected country.
     */
    this.verticalform.value.country_code = this.countrycode;
    this.verticalform.value.dial_code = this.dialcode;

    /**
     * Combine dial code with the contact number.
     */
    this.verticalform.value.number = this.dialcode + this.verticalform.value.number;

    /**
     * Extract vertical form data and vertical ID for updating.
     */
    const verticalform = this.verticalform.value;
    const vertical_Id = this.vertical_Id;

    /**
     * Prepare data for updating the vertical.
     */
    const data = { vertical_Id, verticalform };

    /**
     * Call the dataservice to update the vertical data.
     */
    this.dataservice.updatevertical(data).subscribe((res: any) => {
      /**
       * Update loading state after the update operation.
       */
      this.loading = false;

      /**
       * Check the status of the response and display appropriate messages.
       */
      if (res.status == '201') {
        /**
         * Display success message and hide the vertical info panel.
         */
        this.toastr.success('Vertical Successfully Changed');
        this.verticalinfo = false;

        // Additional logic can be added here if needed
        // For example, refreshing the page after a delay

      } else {
        /**
         * Display error message and reset the contact number and codes.
         */
        this.toastr.error('Error occurred');
        this.verticalform.value.number = this.phone;
        this.dialcode = this.dialcode;
        this.countrycode = this.countrycode;
      }
    });
  }

  /**
  * Deletes a vertical based on the provided vertical ID and user permissions.
  * Opens a confirmation dialog and performs the deletion if confirmed.
  * @param {any} vertical_Id - The ID of the vertical to be deleted.
  * @returns {void}
  */
  verticaldelete(vertical_Id: any): void {
    /**
     * Check if the user has permission to delete.
     */
    if (this.isdelete == true) {
      /**
       * Configure the dialog for deleting a vertical and disable closing the dialog.
       */
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true, data: "Vertical"
      });

      /**
       * Subscribe to the dialog's 'afterClosed' event to handle the user's confirmation choice.
       */
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          /**
           * Get the user's role and prepare data for deletion.
           */
          const user = this.authentication.getUserData();
          let role = user.role;
          const data = { vertical_Id, role };

          /**
           * Call the dataservice to delete the vertical.
           */
          this.dataservice.deletevertical(data).subscribe((res: any) => {
            if (res.status == '202') {
              /**
               * Display error message if the vertical has active users and can't be deleted.
               */
              this.toastr.error("User Currently Active, Couldn't Delete");
            } else if (res.status == '201') {
              /**
               * Display success message and navigate to the vertical listing page.
               */
              this.toastr.success('Vertical Successfully Deleted');
              this.router.navigate(['/app/vertical']);
            } else if (res.status != "201" || "204" || "202") {
              /**
               * Display error message if an error occurs during deletion.
               */
              this.toastr.error('Error occurred');
            }
          });
        }
      });
    } else if (this.isdelete == false) {
      /**
       * Display an informational message if the user does not have permission.
       */
      this.toastr.info("User not permitted");
    }
  }

  /**
 * Handles the action of leaving the page with or without saving changes.
 * If user confirms leaving without saving, closes the vertical information panel.
 * If user cancels leaving, keeps the vertical information panel open.
 * @returns {void}
 */
  exit(): void {
    /**
     * Display a confirmation dialog asking the user if they want to leave without saving.
     */
    if (window.confirm('Leave this page without saving your changes?')) {
      /**
       * Close the vertical information panel and refresh its content.
       */
      this.verticalinfo = false;
      this.editopen(this.vertical_Id);
    } else {
      /**
       * Keep the vertical information panel open.
       */
      this.verticalinfo = true;
    }
  }

}
