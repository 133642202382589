import { Injectable } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import {GoogleAuthProvider} from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject, map, pipe, tap } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from '../Authentication/authentication.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class Dataservice {
  mydevicepaginationState: any;
  alldevicepaginationState: any;
  myverticaluserpaginationState: any;
  allverticaluserpaginationState: any;
  mydevicefilterhistroy: any;
  allDevicefilterhistroy: any;
  myuserfilterhistroy: any;
  alluserfilterhistroy: any;
  exitpage: any;

  /**
   * Options object used for HTTP requests.
   * It includes headers with 'Content-Type' set to 'application/json'.
   */
  httpOptions = {
    /**
     * Headers for the HTTP request.
     */
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  /**
   * Constructs an instance of the component.
   * @param {HttpClient} http - The HTTP client service for making HTTP requests.
   * @param {Router} router - The router service for navigation between routes.
   * @returns {void}
   */
  constructor(private http: HttpClient, private router: Router) {
    // Initialize class properties or perform setup tasks here
  }

  /**
   * Reference to the environment configuration.
   */
  env = environment;

  /**
   * Reference to the API URL from the environment configuration.
   */
  api = this.env.api;
  SECRET_KEY = this.env.SECRET_KEY;

  //  /**
  //  * Subject for triggering refresh required events.
  //  */
  // private _refreshrequired = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _devicerefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _clusterrefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _rolesrefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _routesrefresh = new Subject<void>();
  private notirefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _generalrefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _verticalrefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _userrefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _webzonerefresh = new Subject<void>();
  /**
   * Subject for triggering refresh  events.
   */
  private _otarefresh = new Subject<void>();

  /**
   * Subject for triggering sidenav open events.
   */
  private _sidenavopen = new Subject<void>();

  /**
   * Subject for triggering sidenav close events.
   */
  private _sidenavclose = new Subject<void>();

  /**
   * Subject for triggering loader events.
   */
  private _loader = new Subject<void>();

  /**
   * Subject for triggering router link active events.
   */
  private _routerlinkActive = new Subject<void>();

  /**
   * Subject for triggering router link in-active events.
   */
  private _routerlinkInActive = new Subject<void>();

  /**
   * Subject for triggering cluster create events.
   */
  private _clustercreate = new Subject<void>();

  /**
   * Subject for triggering device info events.
   */
  private _deviceinfo = new Subject<void>();

  /**
   * Subject for triggering my device status events.
   */
  private _mydevicestatus = new Subject<void>();

  /**
   * Subject for triggering all device status events.
   */
  private _alldevicestatus = new Subject<void>();

  /**
   * Subject for triggering single device status events.
   */
  private _singledevicestatus = new Subject<void>();
  /**
   * Subject for triggering single device status events.
   */
  private _cronjob = new Subject<void>();
  private _refreshcluster = new Subject<void>();
  private _removefullScreen = new Subject<void>();
  private _fullScreen = new Subject<void>();

  // /**
  //  * Service for managing refresh events.
  //  * @returns {Observable<void>} An observable that emits when a refresh is required.
  //  */
  //   get Refreshrequired() {
  //     return this._refreshrequired;
  //   }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Devicerefresh() {
    return this._devicerefresh;
  }

  get RemovefullScreen() {
    return this._removefullScreen;
  }
  get fullScreen() {
    return this._fullScreen;
  }

  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Clusterrefresh() {
    return this._clusterrefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Rolesrefresh() {
    return this._rolesrefresh;
  }
  get refreshcluster() {
    return this._refreshcluster;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Routesrefresh() {
    return this._routesrefresh;
  }
  get notifi_refresh() {
    return this.notirefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Generalrefresh() {
    return this._generalrefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Verticalrefresh() {
    return this._verticalrefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Userrefresh() {
    return this._userrefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get Webzonerefresh() {
    return this._webzonerefresh;
  }
  /**
   * Service for managing refresh events.
   * @returns {Observable<void>} An observable that emits when a refresh is required.
   */
  get OTArefresh() {
    return this._otarefresh;
  }

  /**
   * Service for managing device information events.
   * @returns {Observable<void>} An observable that emits when device information is updated.
   */

  get Deviceinfo() {
    return this._deviceinfo;
  }
  /**
   * Service for managing sidenav close events.
   * @returns {Observable<void>} An observable that emits when the sidenav needs to be closed.
   */
  get Sidenavclose() {
    return this._sidenavclose;
  }

  /**
   * Service for managing sidenav open events.
   * @returns {Observable<void>} An observable that emits when the sidenav needs to be opened.
   */
  get Sidenavopen() {
    return this._sidenavopen;
  }

  /**
   * Service for managing loader events.
   * @returns {Observable<void>} An observable that emits when a loader action is required.
   */
  get Loader() {
    return this._loader;
  }

  /**
   * Service for managing router link active events.
   * @returns {Observable<void>} An observable that emits when a router link becomes active.
   */
  get RouterlinkActive() {
    return this._routerlinkActive;
  }

  /**
   * Service for managing router link in-active events.
   * @returns {Observable<void>} An observable that emits when a router link becomes in-active.
   */
  get RouterlinkInActive() {
    return this._routerlinkInActive;
  }

  /**
   * Service for managing cluster create events.
   * @returns {Observable<void>} An observable that emits when a cluster creation is triggered.
   */

  get ClusterCreate() {
    return this._clustercreate;
  }

  /**
   * Service for managing my device status events.
   * @returns {Observable<void>} An observable that emits when my device status is updated.
   */
  get MyDevicestatus() {
    return this._mydevicestatus;
  }

  /**
   * Service for managing all device status events.
   * @returns {Observable<void>} An observable that emits when all device status is updated.
   */
  get AllDevicestatus() {
    return this._alldevicestatus;
  }
  /**
   * Service for managing single device status events.
   * @returns {Observable<void>} An observable that emits when single device status is updated.
   */
  get singleDevicestatus() {
    return this._singledevicestatus;
  }

  get Cronrefresh() {
    return this._cronjob;
  }

  encryptData(data: any) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.SECRET_KEY
    ).toString();
    return encryptedData;
  }
  decryptData(data: any) {
    const decryptedData = CryptoJS.AES.decrypt(data, this.SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedData;
  }

  /**
   * Retrieves user data for a specific user.
   * @param {any} data - The user data to be used for querying.
   * @returns {Observable<any>} An observable that emits the user data.
   */
  getuserdata(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id)
    const options = {
      params: { user_Id: data.user_Id },
    };
    return this.http.get(`${this.api}/user/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  //   /**
  //  * Retrieves data of a specific user and emits a refresh event.
  //  * @param {any} data - The data for fetching the user.
  //  * @returns {Observable<any>} An observable representing the user data.
  //  */
  getAlluser(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id)
    // const encryptrole = this.encryptData(data.role)
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
      },
    };
    return this.http.get(`${this.api}/setting/Alluser`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  dummyapihit() {
    setInterval(() => {
      let arr: any = [];
      arr = [
        'jO7DteEan5fMuBk5EgRR',
        'Bsf8l7KzZ2tLBrCN7VkL',
        'InqbuPmMsAHX4qEgzQn3',
        'WTXQltmHIGOpxlVztPBB',
        'lrkrG6uY7cVqYPMvUvqa',
        'Vls5SRgCLbRCHaFlX5El',
        'e1gaMiO18vty3ggOqPuM',
        'qEbwW8kqTeLjG5zv238F',
        'PRx9PmgD5SjjpGQoLTRe',
        '8MdMAvGAZv4AePmPGiLp',
      ];

      for (let i = 0; i < arr.length; i++) {
        const options = {
          params: {
            device_auth_token: arr[i],
          },
        };
        console.log('authtoken', arr[i]);
        this.http
          .get('https://energy.devsbot.com/api/device/provision/data', options)
          .subscribe((res: any) => {
            console.log('result', res);
          });
      }
    }, 1000);
  }

  /**
   * Retrieves device data specific to the logged-in user.
   * @param {any} data - The user data to be used for querying.
   * @returns {Observable<any>} An observable that emits the device data.
   */
  getmydevicedata(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id)
    const options = {
      params: { user_Id: data.user_Id },
    };
    // this.dummyapihit()

    return this.http.get(`${this.api}/mydevice/data/table`, options);

    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves device status data specific to the logged-in user.
   * @param {any} data - The user data to be used for querying.
   * @returns {Observable<any>} An observable that emits the device status data.
   */
  getmydevicestatusdata(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id)
    const options = {
      params: { user_Id: data.user_Id },
    };
    return this.http.get(`${this.api}/mydevice/status/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }));
  }

  /**
   * Retrieves product data for device page based on user information.
   * @param {any} data - The user data to be used for querying.
   * @returns {Observable<any>} An observable that emits the product data.
   */
  getdevicepageproductdata(data: any): Observable<any> {
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/devicepage/product/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves vertical data for device page based on user information.
   * @param {any} data - The user data to be used for querying.
   * @returns {Observable<any>} An observable that emits the vertical data.
   */
  getdevicepageverticaldata(data: any): Observable<any> {
    const options = {
      params: {
        org_Id: data.org_Id,
        role: data.role,
        user_Id: data.user_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/devicepage/vertical/data`, options);
  }

  /**
   * Retrieves device logs based on the provided device ID.
   * @param {any} device_Id - The ID of the device for which logs are to be retrieved.
   * @returns {Observable<any>} An observable that emits the device logs.
   */
  getdevicelog(device_Id: any): Observable<any> {
    // const encryptdevice_Id = this.encryptData(device_Id)
    const options = {
      params: { device_Id: device_Id },
    };
    return this.http.get(`${this.api}/get/devicelog`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves dashboard data for a specific device.
   * @param {any} device_Id - The ID of the device for which dashboard data is to be retrieved.
   * @returns {Observable<any>} An observable that emits the device dashboard data.
   */
  Getdashboarddata(device_Id: any): Observable<any> {
    // const encryptdevice_Id = this.encryptData(device_Id)
    const options = {
      params: { device_Id: device_Id },
    };
    return this.http.get(`${this.api}/dashboard/data/get`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves status data for all devices within a specific vertical and organization.
   * @param {any} data - The data containing vertical and organization information.
   * @returns {Observable<any>} An observable that emits the status data for all devices.
   */
  getalldevicestatusdata(data: any): Observable<any> {
    // const encryptvertical_Id = this.encryptData(data.vertical_Id);
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { vertical_Id: data.vertical_Id, org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/alldevice/status/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves data for all devices within a specific vertical and organization.
   * @param {any} data - The data containing vertical and organization information.
   * @returns {Observable<any>} An observable that emits the device data for all devices.
   */
  getalldevicedata(data: any): Observable<any> {
    // const encryptvertical_Id = this.encryptData(data.vertical_Id);
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { vertical_Id: data.vertical_Id, org_Id: data.org_Id },
    };

    return this.http.get(`${this.api}/alldevices/data/table`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves product data for creating a device.
   * @param {any} data - The data containing user, role, organization, and vertical information.
   * @returns {Observable<any>} An observable that emits the product data for device creation.
   */
  getdevicecreateproductdata(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)

    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };

    return this.http.get(`${this.api}/devicecreate/product/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Creates a new device using the provided data and emits a refresh event.
   * @param {any} data - The data for creating the new device.
   * @returns {Observable<any>} An observable representing the creation of the device.
   */
  createdevice(data: any): Observable<any> {
    // data.devicedata = this.encryptData(data.devicedata);
    // data.org_Id = this.encryptData(data.org_Id);
    // data.user_id = this.encryptData(data.user_id)
    return this.http.post(`${this.api}/devices/create`, data).pipe(
      tap((res: any) => {
        if (res.status == '201') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }
  createsubdevice(data: any): Observable<any> {
    // data.devicedata = this.encryptData(data.devicedata);
    // data.org_Id = this.encryptData(data.org_Id);
    // data.user_id = this.encryptData(data.user_id)

    return this.http.post(`${this.api}/subdevices/create`, data).pipe(
      tap(() => {
        this.Devicerefresh.next();
      })
    );
  }

  /**
   * Creates a new device using the provided data and emits a refresh event.
   * @param {any} data - The data for creating the new device.
   * @returns {Observable<any>} An observable representing the creation of the device.
   */
  createdevicesWithMac(data: any): Observable<any> {
    // data.devicedata = this.encryptData(data.devicedata);
    // data.org_Id = this.encryptData(data.org_Id);
    // data.user_id = this.encryptData(data.user_id)
    return this.http.post(`${this.api}/devices/create/devicemacid`, data).pipe(
      tap((res: any) => {
        if (res.status == '201') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }

  /**
   * Updates the name of a device and emits a refresh event.
   * @param {any} data - The data for updating the device name.
   * @returns {Observable<any>} An observable representing the device name update.
   */
  updatedevicename(data: any): Observable<any> {
    // data.device_id = this.encryptData(data.device_id);
    // data.name = this.encryptData(data.name)
    return this.http.post(`${this.api}/device/name/update`, data).pipe(
      tap(() => {
        this.mydevicefilterhistroy = null;
        this.allDevicefilterhistroy = null;
        this.Devicerefresh.next();

      })
    );
  }

  /**
   * Deletes a device and emits a refresh event.
   * @param {any} data - The data for deleting the device.
   * @returns {Observable<any>} An observable representing the device deletion.
   */
  deletedevice(data: any): Observable<any> {
    // data.id = this.encryptData(data.id);
    return this.http.post(`${this.api}/devices/delete`, data).pipe(
      tap((res: any) => {
        if (res.status == '201') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }

  /**
   * Downloads a report for a specific device.
   * @param {any} data - The data for downloading the device report.
   * @returns {Observable<Blob>} An observable representing the device report download.
   */
  devicereport(data: any): Observable<Blob> {
    // const encryptdevice_Id = this.encryptData(data.device_Id)
    const options = {
      params: { device_Id: data.device_Id },
      responseType: 'blob' as const,
    };
    return this.http.get(`${this.api}/device/report/download`, options);
  }

  /**
   * Erases data from a specific device.
   * @param {any} data - The data for erasing device data.
   * @returns {Observable<any>} An observable representing the device data erasure.
   */
  erasedevicedata(data: any): Observable<any> {
    // data.id = this.encryptData(data.id);
    return this.http.post(`${this.api}/device/data/erase`, data);
  }

  /**
   * Downloads CSV data for devices associated with the current user.
   * @param {any} data - The data containing the user's ID.
   * @returns {Observable<Blob>} An observable representing the CSV download.
   */
  mydevicedatacsv(data: any): Observable<Blob> {
    // const encryptuser_Id = this.encryptData(data.user_Id)
    const options = {
      params: { user_Id: data.user_Id },
      responseType: 'blob' as const,
    };
    return this.http.get(`${this.api}/mydevice/data/table/csv`, options);
  }

  /**
   * Downloads CSV data for all devices within a specific vertical and organization.
   * @param {any} data - The data containing vertical and organization information.
   * @returns {Observable<Blob>} An observable representing the CSV download.
   */
  alldevicedatacsv(data: any): Observable<Blob> {
    // const encryptvertical_Id = this.encryptData(data.vertical_Id);
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { vertical_Id: data.vertical_Id, org_Id: data.org_Id },
      responseType: 'blob' as const,
    };
    return this.http.get(`${this.api}/alldevices/data/table/csv`, options);
  }

  /**
   * Deletes multiple devices and emits a refresh event.
   * @param {any} data - The data for deleting multiple devices.
   * @returns {Observable<any>} An observable representing the deletion of multiple devices.
   */
  multipledevicesdelete(data: any): Observable<any> {
    let ddata: any = {};
    data = data.map((x: any) => {
      return {
        device_id: x,
      };
    });

    ddata['device_ids'] = this.encryptData(data);
    // console.log(ddata)
    return this.http.post(`${this.api}/multiple/devices/delete`, ddata).pipe(
      tap((res: any) => {
        if (res.status == '200') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }

  /**
   * Retrieves organization data for a specific organization.
   * @param {any} data - The data containing organization information.
   * @returns {Observable<any>} An observable that emits the organization data.
   */
  getorganization(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/organization/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }
  /**
   * Uploads an image for the specified organization and emits a refresh event.
   * @param {any} data - The image data to upload.
   * @param {any} org_Id - The organization ID.
   * @returns {Observable<any>} An observable representing the image upload.
   */
  updateOrgImage(data: any, org_Id: any): Observable<any> {
    const options = {
      params: { org_Id: org_Id },
    };
    return this.http
      .post(`${this.api}/organization/image/upload`, data, options)
      .pipe(
        tap(() => {
          this.Generalrefresh.next();
          this.Userrefresh.next();
        })
      );
  }

  /**
   * Removes the organization image associated with the given data.
   * @param {any} data - The data for removing the organization image.
   * @returns {Observable<any>} An observable representing the image removal.
   */
  removeOrgImage(data: any): Observable<any> {
    return this.http.post(`${this.api}/remove/orgImage`, data);
  }

  /**
   * Changes the password for the user.
   * @param {any} data - The data for changing the password.
   * @returns {Observable<any>} An observable representing the password change.
   */
  changepassword(data: any): Observable<any> {
    return this.http.post(`${this.api}/password/change`, data);
  }

  /**
   * Retrieves vertical data and user count for a specific organization and user.
   * @param {any} data - The data containing organization, user, and vertical information.
   * @returns {Observable<any>} An observable that emits the vertical data.
   */
  getverticaldata(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)

    const options = {
      params: {
        org_Id: data.org_Id,
        role: data.role,
        user_Id: data.user_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/vertical/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves data for a single vertical using the provided vertical ID.
   * @param {any} vertical_Id - The ID of the vertical.
   * @returns {Observable<any>} An observable that emits the data of the single vertical.
   */
  getsinglevertical(vertical_Id: any): Observable<any> {
    // const encryptvertical_Id = this.encryptData(vertical_Id)
    const options = {
      params: { vertical_Id: vertical_Id },
    };
    return this.http.get(`${this.api}/single/vertical`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }));
  }

  /**
   * Updates a vertical's information and emits a refresh event.
   * @param {any} data - The data for updating the vertical.
   * @returns {Observable<any>} An observable representing the vertical update.
   */
  updatevertical(data: any): Observable<any> {
    // data.vertical_Id = this.encryptData(data.vertical_Id);
    // data.verticalform = this.encryptData(data.verticalform)
    return this.http.post(`${this.api}/vertical/update`, data).pipe(
      tap(() => {
        this.Verticalrefresh.next();
      })
    );
  }

  /**
   * Deletes a vertical and emits a refresh event.
   * @param {any} data - The data for deleting the vertical.
   * @returns {Observable<any>} An observable representing the vertical deletion.
   */
  deletevertical(data: any): Observable<any> {
    // data.vertical_Id = this.encryptData(data.vertical_Id)
    return this.http.post(`${this.api}/delete/vertical`, data);
  }

  /**
   * Creates a new vertical and emits a refresh event.
   * @param {any} data - The data for creating the new vertical.
   * @returns {Observable<any>} An observable representing the creation of the new vertical.
   */
  newvertical(data: any): Observable<any> {
    // data.org_Id = this.encryptData(data.org_Id);
    // data.user_Id = this.encryptData(data.user_Id);
    // data.verticaldata = this.encryptData(data.verticaldata)
    return this.http.post(`${this.api}/vertical/create`, data).pipe(
      tap(() => {
        this.Verticalrefresh.next();
      })
    );
  }

  /**
   * Retrieves users associated with the user's vertical.
   * @param {any} data - The data containing user and vertical information.
   * @returns {Observable<any>} An observable that emits the vertical users' data.
   */
  getmyverticaluser(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id);
    // const encryptorg_Id = this.encryptData(data.org_Id);

    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/myvertical/user`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Suspends a user's account and emits a refresh event.
   * @param {any} data - The data for suspending the user.
   * @returns {Observable<any>} An observable representing the user suspension.
   */
  suspendeduser(data: any): Observable<any> {
    // data.user_Id = this.encryptData(data.user_Id);
    return this.http.post(`${this.api}/user/suspended`, data).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }
  /**
   * Activates a user and emits a refresh event.
   * @param {any} data - The data of the user to be activated.
   * @returns {Observable<any>} An observable representing the user activation.
   */
  activeuser(data: any): Observable<any> {
    // data.user_Id = this.encryptData(data.user_Id);
    return this.http.post(`${this.api}/user/active`, data).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }

  /**
   * Suspends multiple users and emits a refresh event.
   * @param {any} data - The data of users to be suspended.
   * @returns {Observable<any>} An observable representing the user suspension.
   */
  multipleusersuspend(data: any): Observable<any> {
    let ddata: any = {};

    data = data.user_Id.map((x: any) => {
      return {
        user_Id: x,
      };
    });

    ddata['user_Ids'] = this.encryptData(data);

    return this.http.post(`${this.api}/multiple/user/suspended`, ddata).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }

  /**
   * Activates multiple users and emits a refresh event.
   * @param {any} data - The data of users to be activated.
   * @returns {Observable<any>} An observable representing the user activation.
   */
  multipleuseractive(data: any): Observable<any> {
    let ddata: any = {};

    data = data.user_Id.map((x: any) => {
      return {
        user_Id: x,
      };
    });

    ddata['user_Ids'] = this.encryptData(data);
    return this.http.post(`${this.api}/multiple/user/active`, ddata).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }

  /**
   * Downloads a CSV file containing data of users within the user's vertical.
   * @param {any} data - The data for generating the CSV file.
   * @returns {Observable<any>} An observable representing the CSV file download.
   */
  myverticalusercsv(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
      responseType: 'blob' as const,
    };
    return this.http.get(`${this.api}/myvertical/user/csv`, options);
  }

  /**
   * Retrieves all users within the user's vertical and emits a refresh event.
   * @param {any} data - The data for fetching all vertical users.
   * @returns {Observable<any>} An observable representing the vertical users.
   */
  getverticalalluser(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/vertical/all/user`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Downloads a CSV file containing data of all users within the user's vertical.
   * @param {any} data - The data for generating the CSV file.
   * @returns {Observable<any>} An observable representing the CSV file download.
   */
  allverticalusercsv(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
      responseType: 'blob' as const,
    };
    return this.http.get(`${this.api}/vertical/all/user/csv`, options);
  }

  /**
   * Retrieves data of a specific user and emits a refresh event.
   * @param {any} data - The data for fetching the user.
   * @returns {Observable<any>} An observable representing the user data.
   */
  getuser(data: any): Observable<any> {
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
      },
    };
    return this.http.get(`${this.api}/setting/user`, options);
  }

  /**
   * Creates a new user and emits a refresh event.
   * @param {any} data - The data for creating a user.
   * @returns {Observable<any>} An observable representing the user creation.
   */
  createuser(data: any): Observable<any> {
    // data.userform = this.encryptData(data.userform);
    // data.org_Id = this.encryptData(data.org_Id);
    return this.http.post(`${this.api}/user/create`, data).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }

  /**
   * Updates a user's data and emits a refresh event.
   * @param {any} data - The data for updating a user.
   * @returns {Observable<any>} An observable representing the user update.
   */
  updateuser(data: any): Observable<any> {
    // data.userdata = this.encryptData(data.userdata);
    // data.user_Id = this.encryptData(data.user_Id);
    return this.http.post(`${this.api}/user/update`, data).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );
  }

  /**
   * Retrieves product data based on the user's organization and vertical.
   * @param {any} data - The data for fetching product data.
   * @returns {Observable<any>} An observable representing the product data.
   */
  getproduct(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptrole = this.encryptData(data.role);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    console.log(options);
    return this.http.get(`${this.api}/product/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Creates a new product (cluster) and emits a cluster creation event.
   * @param {any} data - The data for creating a product.
   * @returns {Observable<any>} An observable representing the product creation.
   */
  createproduct(data: any): Observable<any> {
    // data.productdata = this.encryptData(data.productdata);
    // data.org_Id = this.encryptData(data.org_Id);
    // data.app_mode = this.encryptData(data.app_mode);
    // data.user_Id = this.encryptData(data.user_Id);

    return this.http.post(`${this.api}/create/cluster`, data).pipe(
      tap(() => {
        this.ClusterCreate.next();
        this.mydevicefilterhistroy = null;
        this.allDevicefilterhistroy = null;
      })
    );
  }

  /**
   * Retrieves the list of widget boxes for the product dashboard.
   * @returns {Observable<any>} An observable representing the widget box list.
   */
  Getwidgetbox(): Observable<any> {
    return this.http.get(`${this.api}/product/widgetbox/list`);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Performs an over-the-air (OTA) update by uploading a file and emits a refresh event.
   * @param {any} formdata - The form data containing the update information.
   * @returns {Observable<any>} An observable representing the OTA update.
   */
  Otaupdatefile(formdata: any): Observable<any> {
    return this.http.post(`${this.api}/ota/update/file`, formdata).pipe(
      tap(() => {
        this.OTArefresh.next();
      })
    );
  }

  /**
   * Retrieves OTA update information for the user's vertical.
   * @param {any} data - The data for fetching OTA update information.
   * @returns {Observable<any>} An observable representing the OTA update information.
   */
  getotaupdates(data: any): Observable<any> {
    // const encryptuser_Id = this.encryptData(data.user_Id);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id);
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: {
        user_Id: data.user_Id,
        vertical_Id: data.vertical_Id,
        org_Id: data.org_Id,
      },
    };
    return this.http.get(`${this.api}/get/ota/updates`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves data of a single product.
   * @param {any} product_id - The ID of the product to fetch data for.
   * @returns {Observable<any>} An observable representing the product data.
   */
  getproductdata(product_id: any): Observable<any> {
    // const encryptcluster_id = this.encryptData(product_id)
    const options = {
      params: { product_Id: product_id },
    };
    return this.http.get(`${this.api}/product/single/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }));
  }

  /**
   * Retrieves datastream data for a product.
   * @param {any} product_id - The ID of the product to fetch datastream data for.
   * @returns {Observable<any>} An observable representing the datastream data.
   */
  getdatastreamdata(product_id: any): Observable<any> {
    const options = {
      params: { product_Id: product_id },
    };
    return this.http.get(`${this.api}/datastream/data`, options);
  }

  /**
   * Retrieves product event data for a specific product.
   * @param {any} id - The ID of the product to fetch event data for.
   * @returns {Observable<any>} An observable representing the product event data.
   */
  Getproductevent(id: any): Observable<any> {
    const options = {
      params: { product_Id: id },
    };
    return this.http.get(`${this.api}/product/event/get`, options);
  }
  /**
   * Retrieves widget data for a specific product's dashboard.
   * @param {any} id - The ID of the product to fetch dashboard widget data for.
   * @returns {Observable<any>} An observable representing the dashboard widget data.
   */
  GetDashboardWidget(id: any): Observable<any> {
    const options = {
      params: { product_Id: id },
    };
    return this.http.get(`${this.api}/product/dashboard/get`, options);
  }

  /**
   * Deletes a product and emits a refresh event.
   * @param {any} data - The data for deleting a product.
   * @returns {Observable<any>} An observable representing the product deletion.
   */
  deleteproduct(data: any): Observable<any> {
    // data.product_Id = this.encryptData(data.product_Id)/
    return this.http.post(`${this.api}/product/delete`, data).pipe(tap((res: any) => {
      this.mydevicefilterhistroy = null;
      this.allDevicefilterhistroy = null;
    }));
  }

  /**
   * Updates a product's data and emits a refresh event.
   * @param {any} data - The data for updating a product.
   * @returns {Observable<any>} An observable representing the product update.
   */
  Updateproduct(data: any): Observable<any> {
    // data.productform = this.encryptData(data.productform);
    // data.app_mode = this.encryptData(data.app_mode);
    // data.product_id = this.encryptData(data.product_id);
    return this.http.post(`${this.api}/product/Update`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Retrieves datastream units.
   * @returns {Observable<any>} An observable representing the datastream units data.
   */
  getdatastreamunits(): Observable<any> {
    return this.http.get(`${this.api}/units/data`);
  }

  /**
   * Creates a new datastream for a product and emits a refresh event.
   * @param {any} data - The data for creating a datastream.
   * @param {any} id - The ID of the product.
   * @returns {Observable<any>} An observable representing the datastream creation.
   */
  Datastreamcreate(data: any, id: any): Observable<any> {
    const options = {
      params: { product_Id: id },
    };
    return this.http.post(`${this.api}/datastream/create`, data, options).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Retrieves data of a single datastream.
   * @param {any} datastream_Id - The ID of the datastream to fetch data for.
   * @returns {Observable<any>} An observable representing the datastream data.
   */
  getsingledatastreamdata(datastream_Id: any): Observable<any> {
    const options = {
      params: { datastream_Id: datastream_Id },
    };
    return this.http.get(`${this.api}/datastream/single/data`, options);
  }

  /**
   * Deletes a product's datastream and emits a refresh event.
   * @param {any} data - The data for deleting a datastream.
   * @returns {Observable<any>} An observable representing the datastream deletion.
   */
  Deletedatastream(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/datastream/delete`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Deletes a dashboard widget and emits a refresh event.
   * @param {any} data - The data for deleting a dashboard widget.
   * @returns {Observable<any>} An observable representing the dashboard widget deletion.
   */
  DeletedashboardWidget(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/dashboard/delete`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Creates a new datastream for a widget associated with a product and emits a refresh event.
   * @param {any} data - The data for creating a datastream for the widget.
   * @param {any} product_Id - The ID of the associated product.
   * @returns {Observable<any>} An observable representing the datastream creation for the widget.
   */
  widgetdatastreamcreate(data: any, product_Id: any): Observable<any> {
    const options = {
      params: { product_Id: product_Id },
    };
    return this.http
      .post(`${this.api}/widget/datastream/create`, data, options)
      .pipe(
        tap(() => {
          this.Clusterrefresh.next();
        })
      );
  }

  /**
   * Retrieves location data for a user.
   * @param {any} data - The data for fetching location data for a user.
   * @returns {Observable<any>} An observable representing the location data for the user.
   */
  getmylocation(data: any): Observable<any> {
    const options = {
      params: { user_Id: data.user_Id },
    };
    return this.http.get(`${this.api}/mylocation/data`, options);
  }

  /**
   * Retrieves data of a single location.
   * @param {any} data - The data for fetching location data.
   * @returns {Observable<any>} An observable representing the location data.
   */
  getlocationdata(data: any): Observable<any> {
    const options = {
      params: { location_Id: data.location_Id },
    };
    return this.http.get(`${this.api}/single/location/data`, options);
  }

  /**
   * Retrieves data of all locations for a user.
   * @param {any} data - The data for fetching all location data for a user.
   * @returns {Observable<any>} An observable representing all location data for the user.
   */
  getalllocation(data: any): Observable<any> {
    const options = {
      params: { user_Id: data.user_Id, role: data.role, Org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/all/location`, options);
  }

  /**
   * Creates a new location and emits a refresh event.
   * @param {any} data - The data for creating a location.
   * @returns {Observable<any>} An observable representing the location creation.
   */
  createlocation(data: any): Observable<any> {
    return this.http.post(`${this.api}/create/location`, data);
  }

  /**
   * Updates a location's data and emits a refresh event.
   * @param {any} data - The data for updating a location.
   * @returns {Observable<any>} An observable representing the location update.
   */
  updatelocation(data: any): Observable<any> {
    return this.http.post(`${this.api}/location/update`, data);
  }

  /**
   * Deletes a location and emits a refresh event.
   * @param {any} data - The data for deleting a location.
   * @returns {Observable<any>} An observable representing the location deletion.
   */
  deletelocation(data: any): Observable<any> {
    return this.http.post(`${this.api}/location/delete`, data);
  }

  /**
   * Retrieves vertical value data for a specific organization and user.
   * @param {any} data - The data for fetching vertical value data.
   * @returns {Observable<any>} An observable representing the vertical value data.
   */
  getverticalvalue(data: any): Observable<any> {
    const options = {
      params: {
        org_Id: data.org_Id,
        user_Id: data.user_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/vertical/get/value`, options);

    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves vertical value data for a specific organization.
   * @param {any} data - The data for fetching vertical value data.
   * @returns {Observable<any>} An observable representing the vertical value data.
   */
  getlocationvalue(data: any): Observable<any> {
    const options = {
      params: { org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/location/get/value`, options);
  }
  /**
   * Retrieves the current version of a device associated with a product.
   * @param {any} product_Id - The ID of the associated product.
   * @returns {Observable<any>} An observable representing the current device version.
   */
  getdeviceversion(product_Id: any): Observable<any> {
    // const encryptcluster_id = this.encryptData(product_Id);
    const options = {
      params: { product_Id: product_Id },
    };
    return this.http.get(`${this.api}/device/version`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.device_version = this.decryptData(res.device_version);
    //     res.device_version = JSON.parse(res.device_version)
    //   }
    // }))
  }

  /**
   * Retrieves the datastream pin information for a specific datastream.
   * @param {any} data - The data for fetching datastream pin information.
   * @returns {Observable<any>} An observable representing the datastream pin information.
   */
  getdatastreampin(data: any): Observable<any> {
    const options = {
      params: { datastream_Id: data },
    };
    return this.http.get(`${this.api}/datastream/pin`, options);
  }

  /**
   * Retrieves data of devices associated with a product.
   * @param {any} data - The data for fetching device data associated with a product.
   * @returns {Observable<any>} An observable representing the device data associated with the product.
   */
  getproductdevices(data: any): Observable<any> {
    // const encryptcluster_id = this.encryptData(data)
    const options = {
      params: { product_Id: data },
    };
    return this.http.get(`${this.api}/product/devices/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Initiates an OTA (Over-The-Air) update for devices.
   * @param {any} data - The data for initiating the OTA update.
   * @returns {Observable<any>} An observable representing the OTA update initiation.
   */
  updatenow(data: any): Observable<any> {
    // data.product_Id = this.encryptData(data.product_Id);
    // data.version = this.encryptData(data.version);
    return this.http.post(`${this.api}/devices/updatenow`, data);
  }

  /**
   * Changes the update mode of devices and emits a refresh event.
   * @param {any} data - The data for changing the device update mode.
   * @returns {Observable<any>} An observable representing the device update mode change.
   */
  deviceupdatemodechange(data: any): Observable<any> {
    return this.http.post(`${this.api}/devices/updatemode/change`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Retrieves the server's IP address.
   * @returns {Observable<string>} An observable representing the server's IP address.
   */
  getIpAddress(): Observable<string> {
    return this.http
      .get<{ ip: string }>('http://api.ipify.org/?format=json')
      .pipe(map((data) => data.ip));
  }

  /**
   * Retrieves the metadata of a product.
   * @param {any} data - The data for fetching product metadata.
   * @returns {Observable<any>} An observable representing the product metadata.
   */
  getproductmetadata(data: any): Observable<any> {
    const options = {
      params: { product_Id: data },
    };
    return this.http.get(`${this.api}/product/metadata`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Creates new device data and emits a refresh event.
   * @param {any} data - The data for creating new device data.
   * @returns {Observable<any>} An observable representing the creation of new device data.
   */
  createdevicedata(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/devicedata/create`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Duplicates a product and emits a refresh event.
   * @param {any} data - The data for duplicating a product.
   * @returns {Observable<any>} An observable representing the duplication of a product.
   */
  dublicateproduct(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/dublicate`, data);
  }

  /**
   * Initiates the pre-provisioning process for a product.
   * @param {any} data - The data for initiating pre-provisioning for a product.
   * @returns {Observable<any>} An observable representing the pre-provisioning initiation.
   */
  productpreprvision(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/preprovion`, data);
  }

  /**
   * Retrieves data of a single device associated with a product's metadata.
   * @param {any} data - The data for fetching data of a single device.
   * @returns {Observable<any>} An observable representing the data of a single device.
   */
  getsingledevicedata(data: any): Observable<any> {
    const options = {
      params: { meta_Id: data },
    };
    return this.http.get(`${this.api}/product/single/metadata`, options);
  }

  /**
   * Deletes device data and emits a refresh event.
   * @param {any} data - The data for deleting device data.
   * @returns {Observable<any>} An observable representing the deletion of device data.
   */
  deletedevicedata(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/devicedata/delete`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Initiates a manual OTA (Over-The-Air) update for devices.
   * @param {any} data - The data for initiating the manual OTA update.
   * @returns {Observable<any>} An observable representing the manual OTA update initiation.
   */
  devicemanualupdate(data: any): Observable<any> {
    // data.product_Id = this.encryptData(data.product_Id);
    // data.version = this.encryptData(data.version)
    return this.http.post(`${this.api}/devices/manual/updatenow`, data);
  }

  /**
   * Imports a single device using its MAC ID and emits a refresh event.
   * @param {any} data - The data for importing a single device.
   * @returns {Observable<any>} An observable representing the single device import.
   */
  singledeviceimport(data: any): Observable<any> {
    return this.http.post(`${this.api}/devices/import/macid`, data).pipe(
      tap((res: any) => {
        if (res.status == '201') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }

  /**
   * Imports multiple devices and emits a refresh event.
   * @param {any} data - The data for importing multiple devices.
   * @returns {Observable<any>} An observable representing the multiple devices import.
   */
  multipledeviceimport(data: any): Observable<any> {
    return this.http.post(`${this.api}/multiple/devices/import`, data).pipe(
      tap((res: any) => {
        if (res.status == '201') {
          this.mydevicefilterhistroy = null;
          this.allDevicefilterhistroy = null;
          this.Devicerefresh.next();
        }
      })
    );
  }

  /**
   * Retrieves the device QR code management table data.
   * @param {any} data - The data for fetching the device QR code management table data.
   * @returns {Observable<any>} An observable representing the device QR code management table data.
   */
  devicemacid(data: any): Observable<any> {
    const options = {
      params: {
        user_Id: data.user_Id,
        role: data.role,
        org_Id: data.org_Id,
        vertical_Id: data.vertical_Id,
      },
    };
    return this.http.get(`${this.api}/devicemacid/table`, options);
  }

  /**
   * Retrieves datastream pin information for a specific hardware.
   * @param {any} data - The data for fetching datastream pin information.
   * @returns {Observable<any>} An observable representing the datastream pin information.
   */
  hardwaredatastreampin(data: any): Observable<any> {
    const encrypthardware = this.encryptData(data);
    const options = {
      params: { hardware: data },
    };

    return this.http.get(`${this.api}/hardware/datastream`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves the types of hardware.
   * @returns {Observable<any>} An observable representing the hardware types.
   */
  hardwaretype(): Observable<any> {
    return this.http.get(`${this.api}/hardware/type`);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Changes the mode of a device and emits a refresh event.
   * @param {any} data - The data for changing the device mode.
   * @returns {Observable<any>} An observable representing the device mode change.
   */
  devicemodechange(data: any): Observable<any> {
    // data.update_mode = this.encryptData(data.update_mode);
    // data.device_Id = this.encryptData(data.device_Id);
    return this.http.post(`${this.api}/device/mode/change`, data).pipe(
      tap(() => {
        this.OTArefresh.next();
      })
    );
  }

  /**
   * Creates an event associated with a product and emits a refresh event.
   * @param {any} data - The data for creating an event.
   * @returns {Observable<any>} An observable representing the creation of an event.
   */
  eventcreate(data: any): Observable<any> {
    return this.http.post(`${this.api}/product/event/create`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Retrieves user email based on vertical and organization IDs.
   * @param {any} data - The data for fetching user email.
   * @returns {Observable<any>} An observable representing the user email.
   */
  useremail(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)
    const options = {
      params: { vertical_Id: data.vertical_Id, Org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/user/email`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves user phone number based on vertical and organization IDs.
   * @param {any} data - The data for fetching user phone number.
   * @returns {Observable<any>} An observable representing the user phone number.
   */
  usernumber(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id);
    // const encryptvertical_Id = this.encryptData(data.vertical_Id)

    const options = {
      params: { vertical_Id: data.vertical_Id, Org_Id: data.org_Id },
    };

    return this.http.get(`${this.api}/user/number`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }));
  }
  /**
   * Deletes an event and emits a refresh event.
   * @param {any} data - The data for deleting an event.
   * @returns {Observable<any>} An observable representing the deletion of an event.
   */
  deleteevent(data: any): Observable<any> {
    return this.http.post(`${this.api}/event/delete`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Retrieves the list of OTA (Over-The-Air) update versions for a cluster.
   * @param {any} data - The data for fetching OTA update versions.
   * @returns {Observable<any>} An observable representing the list of OTA update versions.
   */
  versionlist(data: any): Observable<any> {
    // const encryptcluster_id = this.encryptData(data)

    const options = {
      params: { product_Id: data },
    };

    return this.http.get(`${this.api}/cluster/ota/version`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves the timezone information based on latitude and longitude.
   * @param {number} latitude - The latitude value.
   * @param {number} longitude - The longitude value.
   * @returns {Observable<any>} An observable representing the timezone information.
   */
  getTimeZone(latitude: number, longitude: number): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
      Date.now() / 1000
    )}&key=${this.env.googleMapApi}`;

    return this.http.get(url);
  }

  /**
   * Creates a webzone and emits a refresh event.
   * @param {any} data - The data for creating a webzone.
   * @returns {Observable<any>} An observable representing the creation of a webzone.
   */
  createWebZone(data: any): Observable<any> {
    // data.webzonedata = this.encryptData(data.webzonedata);
    // data.org_Id = this.encryptData(data.org_Id);
    // data.user_Id = this.encryptData(data.user_Id);
    return this.http.post(`${this.api}/webzone/create`, data).pipe(
      tap(() => {
        this.Webzonerefresh.next();
      })
    );
  }

  /**
   * Updates a webzone and emits a refresh event.
   * @param {any} data - The data for updating a webzone.
   * @returns {Observable<any>} An observable representing the update of a webzone.
   */
  updateWebZone(data: any): Observable<any> {
    // data.webzonedata = this.encryptData(data.webzonedata);
    // data.user_Id = this.encryptData(data.user_Id)
    return this.http.post(`${this.api}/webzone/update`, data).pipe(
      tap(() => {
        this.Webzonerefresh.next();
      })
    );
  }

  /**
   * Retrieves webzone data based on organization ID.
   * @param {any} data - The data for fetching webzone data.
   * @returns {Observable<any>} An observable representing the webzone data.
   */
  getwebzone(data: any): Observable<any> {
    console.log(data)
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { org_Id: data.org_Id, vertical_Id: data.vertical_Id },
    };
    return this.http.get(`${this.api}/webzone/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves webzone data for importing based on organization ID.
   * @param {any} data - The data for fetching webzone data for importing.
   * @returns {Observable<any>} An observable representing the webzone data for importing.
   */
  getwebzonedata(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { org_Id: data.org_Id, vertical_Id: data.vertical_Id },
    };

    return this.http.get(`${this.api}/import/webzone/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Deletes a webzone and emits a refresh event.
   * @param {any} data - The data for deleting a webzone.
   * @returns {Observable<any>} An observable representing the deletion of a webzone.
   */
  deletewebzone(data: any): Observable<any> {
    // data.Id = this.encryptData(data.Id)
    return this.http.post(`${this.api}/webzone/erase/delete`, data).pipe(
      tap(() => {
        this.Webzonerefresh.next();
      })
    );
  }

  /**
   * Retrieves information about a single device.
   * @param {any} data - The data for fetching information about a single device.
   * @returns {Observable<any>} An observable representing the information about a single device.
   */
  singledeviceinfo(data: any): Observable<any> {
    // const encryptdevice_Id = this.encryptData(data.device_id)
    const options = {
      params: { device_Id: data.device_id },
    };

    return this.http.get(`${this.api}/single/deviceinfo`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }
  subdeviceinfo(data: any): Observable<any> {
    const options = {
      params: { device_Id: data.device_id },
    };

    return this.http.get(`${this.api}/device/subdeviceinfo`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves status information about a single device.
   * @param {any} data - The data for fetching status information about a single device.
   * @returns {Observable<any>} An observable representing the status information about a single device.
   */
  singledevicestatus(data: any): Observable<any> {
    // const encryptdevice_Id = this.encryptData(data.device_id)
    const options = {
      params: { device_Id: data.device_id },
    };

    return this.http.get(`${this.api}/single/devicestatus`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Downloads a sample file.
   */
  samplefile(): void {
    const url = './assets/samplefile/devsbot.xlsx';
    this.http.get(url, { responseType: 'blob' }).subscribe((res: Blob) => {
      // Convert the response to a Blob object
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Use the FileSaver library to save the file
      FileSaver.saveAs(blob, 'Devsbot.xlsx');
    });
  }
  /**
   * Imports webzone data for devices and emits a refresh event.
   * @param {any} data - The data for importing webzone data for devices.
   * @returns {Observable<any>} An observable representing the importing of webzone data for devices.
   */
  deivcewebzoneimport(data: any): Observable<any> {
    return this.http.post(`${this.api}/webzone/import`, data).pipe(
      tap(() => {
        this.Clusterrefresh.next();
      })
    );
  }

  /**
   * Updates pre-provisioning settings and devices.
   * @param {any} data - The data for updating pre-provisioning settings and devices.
   * @returns {Observable<any>} An observable representing the update of pre-provisioning settings and devices.
   */
  preprovisionupdate(data: any): Observable<any> {
    return this.http.post(`${this.api}/preprovision/update`, data);
  }

  /**
   * Signs up a user.
   * @param {any} data - The data for user signup.
   * @returns {Observable<any>} An observable representing the user signup.
   */
  usersignup(data: any): Observable<any> {
    return this.http.post(`${this.api}/user/signup`, data);
  }

  /**
   * Signs up a user using Google authentication.
   * @param {any} data - The data for Google user signup.
   * @returns {Observable<any>} An observable representing the Google user signup.
   */
  googlesignup(data: any): Observable<any> {
    return this.http.post(`${this.api}/google/signup`, data);
  }

  /**
   * Signs in a user using Google authentication.
   * @param {any} data - The data for Google user signin.
   * @returns {Observable<any>} An observable representing the Google user signin.
   */
  googlesignin(data: any): Observable<any> {
    return this.http.post(`${this.api}/google/signin`, data);
  }

  /**
   * Verifies email using a verification code.
   * @param {any} data - The data for email verification.
   * @returns {Observable<any>} An observable representing the email verification.
   */
  emailverfication(data: any): Observable<any> {
    const options = {
      params: { verification_code: data.verification_code },
    };
    return this.http.get(`${this.api}/email/verification`, options);
  }

  /**
   * Retrieves role data.
   * @returns {Observable<any>} An observable representing the role data.
   */
  getRoleData(): Observable<any> {
    return this.http.get(`${this.api}/devsbot/menu`);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves data for a single role.
   * @param {any} data - The data for fetching data for a single role.
   * @returns {Observable<any>} An observable representing the data for a single role.
   */
  getSingleRoleData(data: any): Observable<any> {
    // const encryptGroup_id = this.encryptData(data)

    const options = {
      params: { group_Id: data },
    };

    return this.http.get(`${this.api}/user/group/single`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Retrieves user role group data based on organization ID.
   * @param {any} data - The data for fetching user role group data.
   * @returns {Observable<any>} An observable representing the user role group data.
   */
  getroleGroupdata(data: any): Observable<any> {
    // const encryptorg_Id = this.encryptData(data.org_Id)
    const options = {
      params: { org_Id: data.org_Id },
    };

    return this.http.get(`${this.api}/user/group`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data)
    //   }
    // }))
  }

  /**
   * Creates a role and emits a refresh event.
   * @param {any} data - The data for creating a role.
   * @returns {Observable<any>} An observable representing the creation of a role.
   */
  CreateRoleData(data: any): Observable<any> {
    return this.http.post(`${this.api}/user/role/create`, data).pipe(
      tap(() => {
        this.Rolesrefresh.next();
      })
    );
  }

  /**
   * Deletes a role and emits a refresh event.
   * @param {any} data - The data for deleting a role.
   * @returns {Observable<any>} An observable representing the deletion of a role.
   */
  DeleteRoleData(data: any): Observable<any> {
    // data.group_Id = this.encryptData(data.group_Id);

    return this.http.post(`${this.api}/user/role/delete`, data);
  }

  /**
   * Updates a role and emits a refresh event.
   * @param {any} data - The data for updating a role.
   * @returns {Observable<any>} An observable representing the update of a role.
   */
  UpdateRoleData(data: any): Observable<any> {
    return this.http.post(`${this.api}/user/role/update`, data).pipe(
      tap(() => {
        this.Rolesrefresh.next();
      })
    );
  }

  /**
   * Creates a new user device.
   * @param {any} data - The data for creating a new user device.
   * @returns {Observable<any>} An observable representing the creation of a new user device.
   */
  CreateNewUserDevice(data: any): Observable<any> {
    // data.formvalue = this.encryptData(data.formatvalue);
    // data.data = this.encryptData(data.data);
    // data.hardware = this.encryptData(data.hardware)
    return this.http.post(`${this.api}/default/cluster/device`, data);
  }

  /**
   * Sends a password reset request to the provided email address.
   * @param {any} data - The data for requesting a password reset.
   * @returns {Observable<any>} An observable representing the password reset request.
   */
  forgotpassword(data: any): Observable<any> {
    const options = {
      params: { email: data.email },
    };

    return this.http.get(`${this.api}/forgotpassword`, options);
  }

  /**
   * Resets the password using the provided data.
   * @param {any} data - The data for resetting the password.
   * @returns {Observable<any>} An observable representing the password reset.
   */
  resetpassword(data: any): Observable<any> {
    data.password = data.password;
    return this.http.post(`${this.api}/password/reset`, data);
  }

  /**
   * Retrieves the response of a password reset.
   * @param {any} data - The data for getting the reset password response.
   * @returns {Observable<any>} An observable representing the reset password response.
   */
  getresetpasswordresponse(data: any): Observable<any> {
    const options = {
      params: { token: data },
    };
    return this.http.get(`${this.api}/user/response`, options);
  }

  /**
   * Triggers an event using the provided data.
   * @param {any} data - The data for triggering an event.
   * @returns {Observable<any>} An observable representing the event trigger.
   */
  eventtrigger(data: any): Observable<any> {
    return this.http.post(`${this.api}/event/trigger`, data);
  }

  /**
   * Downloads the Devsbot zip file.
   */
  downloadzip(): void {
    const url = './assets/Devsbot.zip';
    this.http.get(url, { responseType: 'blob' }).subscribe((res: Blob) => {
      const blob = new Blob([res], {
        type: 'application/zip',
      });
      FileSaver.saveAs(blob, 'Devsbot.zip');
    });
  }

  /**
   * Retrieves the hardware name based on the cluster ID.
   * @param {any} data - The data for fetching the hardware name.
   * @returns {Observable<any>} An observable representing the hardware name retrieval.
   */
  gethardwarename(data: any): Observable<any> {
    const options = {
      params: { cluster_id: data },
    };
    return this.http.get(`${this.api}/cluster/hardware`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  /**
   * Creates routes using the provided data and emits a refresh event.
   * @param {any} data - The data for creating routes.
   * @returns {Observable<any>} An observable representing the creation of routes.
   */
  createroutes(data: any): Observable<any> {
    return this.http.post(`${this.api}/create/routes`, data).pipe(
      tap(() => {
        this.Routesrefresh.next();
      })
    );
  }

  /**
   * Retrieves routes based on the cluster ID.
   * @param {any} data - The data for fetching routes.
   * @returns {Observable<any>} An observable representing the route retrieval.
   */
  getRoutes(data: any): Observable<any> {
    const options = {
      params: { cluster_Id: data.product_Id,device_Id:data?.device_id },
    };
    return this.http.get(`${this.api}/get/routes`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  /**
   * Notifies that the side navigation should be closed.
   */
  sidenavclose(): void {
    this._sidenavclose.next();
  }

  /**
   * Notifies that the side navigation should be opened.
   */
  sidenavopen(): void {
    this._sidenavopen.next();
  }

  /**
   * Marks a notification as read using the provided data.
   * @param {any} data - The data for marking a notification as read.
   * @returns {Observable<any>} An observable representing the notification read status.
   */
  deletenotification(data: any): Observable<any> {
    // data.user_Id = data.user_Id;
    // data.notification_id = this.encryptData(data.notification_id)
    return this.http.post(`${this.api}/delete/notification`, data).pipe(
      tap(() => {
        this.notirefresh.next();
      })
    );
  }
  deleteallnotification(data: any): Observable<any> {
    // data.user_Id = data.user_Id;
    // data.notification_id = this.encryptData(data.notification_id)
    return this.http.post(`${this.api}/delete/notification`, data).pipe(
      tap(() => {
        this.notirefresh.next();
      })
    );
  }
  readNotification(data: any): Observable<any> {
    // data.user_Id = data.user_Id;
    // data.notification_id = this.encryptData(data.notification_id)
    return this.http.post(`${this.api}/read/notification`, data).pipe(
      tap(() => {
        this.notirefresh.next();
      })
    );
  }

  /**
   * Retrieves data for events of a single device based on the device ID.
   * @param {any} data - The data for fetching events data for a single device.
   * @returns {Observable<any>} An observable representing the events data for a single device.
   */
  Getsingledeviceevents(data: any): Observable<any> {
    // const encryptdevice_Id = this.encryptData(data)
    const options = {
      params: { device_Id: data },
    };
    return this.http.get(`${this.api}/singledevice/events/data`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  /**
   * Signs in a user using the provided data.
   * @param {any} data - The data for user signin.
   * @returns {Observable<any>} An observable representing the user signin.
   */
  signin(data: any): Observable<any> {
    // data.password = this.encryptData(data.password);
    // data.email = this.encryptData(data.email)
    return this.http.post(`${this.api}/user/signin`, data);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  /**
   * Fetches user data using the provided data.
   * @param {any} data - The data for fetching user data.
   * @returns {Observable<any>} An observable representing the user data retrieval.
   */
  fetchUserdata(data: any): Observable<any> {
    // const encryptuser_Id =  this.encryptData(data.user_Id)
    const options = {
      params: { user_Id: data.user_Id },
    };
    return this.http.get(`${this.api}/fetch/userdata`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  /**
   * Logs out a user forcefully using the provided data.
   * @param {any} data - The data for forceful user logout.
   * @returns {Observable<any>} An observable representing the forceful user logout.
   */
  forcelogout(data: any): Observable<any> {
    data.user_Id = data.user_Id;

    return this.http.post(`${this.api}/force/logout`, data);
  }

  /**
   * Stores pagination state.
   * @param {any} state - The pagination state to be stored.
   */
  setPaginationState(state: any, page: any): void {
    if (page == 'mydevice') {
      this.mydevicepaginationState = state;
    } else if (page == 'alldevice') {
      this.alldevicepaginationState = state;
    } else if (page == 'myverticaluser') {
      this.myverticaluserpaginationState = state;
    } else if (page == 'allverticaluser') {
      this.allverticaluserpaginationState = state;
    } else if (page == 'all') {
      this.allverticaluserpaginationState = state;
      this.myverticaluserpaginationState = state;
      this.alldevicepaginationState = state;
      this.mydevicepaginationState = state;
    }
  }

  /**
   * Retrieves stored pagination state.
   * @returns {any} The stored pagination state.
   */
  getPaginationState(page: any): void {
    if (page == 'mydevice') {
      return this.mydevicepaginationState;
    } else if (page == 'alldevice') {
      return this.alldevicepaginationState;
    } else if (page == 'myverticaluser') {
      return this.myverticaluserpaginationState;
    } else if (page == 'allverticaluser') {
      return this.allverticaluserpaginationState;
    }
  }
  private isDarkMode = false;

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    this.applyDarkModeStyles();
  }

  getHistoricaldata(data: any) {
    const options = {
      params: { device_Id: data.device_Id, pin: data.pin },
    };
    return this.http.get(`${this.api}/historicalData/data/`, options);
  }

  private applyDarkModeStyles() {
    if (this.isDarkMode) {
      document.body.classList.add('dark-mode'); // Add a CSS class to the body
    } else {
      document.body.classList.remove('dark-mode'); // Remove the CSS class
    }
  }

  downloadReport() {
    const url = './assets/Report.xlsx';
    this.http.get(url, { responseType: 'blob' }).subscribe((res: Blob) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'Report.xlsx');
    });
  }

  getchartdata(data: any) {
    const options = {
      params: {
        device_Id: data.device_Id,
        pin: data.pin,
        startdate: data.start,
        enddate: data.end,
        chartfilter: data.chartfilter,
      },
    };
    return this.http.get(`${this.api}/chart/data/`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {

    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);

    //   }
    // }))
  }

  // downloadAnalytics(data: any) {
  //   const options = {
  //     params: { data: data },
  //     responseType: 'blob' as const,
  //   }
  //   return this.http.get(`${this.api}/download/analytics/csv`, options)
  // }

  GetClusterAnalytics(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        startdate: data.start,
        enddate: data.end,
      },
    };
    return this.http.get(`${this.api}/cluster/analytics/dashboard/`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);
    //     res.Tabledata = this.decryptData(res.Tabledata);
    //     res.Tabledata = JSON.parse(res.Tabledata);
    //   }
    // }))
  }

  GetDeviceAnalytics(data: any) {
    const options = {
      params: {
        device_Id: data.device_id,
        startdate: data.start,
        enddate: data.end,
      },
    };
    return this.http.get(`${this.api}/device/analytics/dashboard/`, options);
  }

  ExportClusterAnalytics(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        startdate: data.start,
        enddate: data.end,
        cluster_name: data.clustername,
      },
      responseType: 'blob' as const,
    };

    return this.http.get(`${this.api}/export/cluster/analytics/csv`, options);
  }

  ExportDeviceAnalytics(data: any) {
    const options = {
      params: {
        device_Id: data.device_id,
        startdate: data.start,
        enddate: data.end,
        devicename: data.devicename,
      },
      responseType: 'blob' as const,
    };

    return this.http.get(`${this.api}/export/device/analytics/csv`, options);
  }

  GetClusterDashboard(data: any) {
    // const encryptcluster_id = this.encryptData(data)
    const options = {
      params: { cluster_Id: data },
    };

    return this.http.get(`${this.api}/cluster/dashboarddata/`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);
    //   }
    // }))
  }

  GethistoricaldayChart(data: any) {
    const options = {
      params: {
        device_Id: data.device_Id,
        startdate: data.start,
        enddate: data.end,
        chartfilter: data.chartfilter,
      },
    };
    return this.http.get(`${this.api}/day/chart/data/`, options);
  }

  addPushSubscriber(sub: any) {
    return this.http.post(`${this.api}/user/service/subscription/`, sub);
  }

  getnotification(data: any): Observable<any> {
    const options = {
      params: {
        user_Id: data.user_Id,
        cluster_api_Id: data.cluster_api_Id,
      },
    };
    return this.http.get(`${this.api}/get/Notifications`, options);
  }

  Getkey() {
    return this.http.get(`${this.api}/get/key/`);
  }

  getForecasting(data: any) {
    // const encryptcluster_id = this.encryptData(data.product_Id);
    // const encryptdevice_Id =this.encryptData(data.device_id)
    const options = {
      params: {
        device_Id: data.device_id,
        cluster_Id: data.product_Id,
      },
    };
    return this.http.get(`${this.api}/forecasting/kwhcost`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);
    //   }
    // }))
  }

  GetJobs() {
    return this.http.get(`${this.api}/cronjobs`);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);
    //   }
    // }))
  }

  ReRunCron(data: any) {
    return this.http.post(`${this.api}/rerun/cron`, data).pipe(
      tap(() => {
        this.Cronrefresh.next();
      })
    );
  }
  GetDeviceDashboardData(data: any, device_Id: any, slave_id: any) {
    // const encryptData = this.encryptData(data)
    const options = {
      params: {
        data: JSON.stringify(data),
        device_Id: device_Id,
        slave_id: slave_id,
      },
    };
    return this.http.get(`${this.api}/device/dashboard/data/`, options);
    // .pipe(tap((res: any) => {
    //   if (res.status == "200") {
    //     res.data = this.decryptData(res.data);
    //     res.data = JSON.parse(res.data);
    //   }
    // }))
  }
  getCityName(latitude: any, longitude: any): Observable<any> {
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      latitude +
      ',' +
      longitude +
      '&key=' +
      this.env.googleMapApi;

    return this.http.get(url);
  }

  getGatewayDevice(data: any): Observable<any> {
    const datas = { params: data };
    console.log(datas);

    return this.http.get(`${this.api}/devices/gatewaydevice`, datas);
  }
  DeleteVersion(data: any) {
    return this.http.post(`${this.api}/ota/version/delete`, data).pipe(
      tap(() => {
        this.OTArefresh.next();
      })
    );

  }

  Storefilter(data: any, page: any) {
    // console.log(data);
    if (page == 'My') {
      this.mydevicefilterhistroy = data;
    } else if (page == 'All') {
      this.allDevicefilterhistroy = data;
    } else if (page == 'Myuser') {
      this.myuserfilterhistroy = data
    } else if (page == 'Alluser') {
      this.alluserfilterhistroy = data
    }
  }

  Getfilter(page: any) {
    if (page == 'My') {
      return this.mydevicefilterhistroy;
    } else if (page == 'All') {
      return this.allDevicefilterhistroy;
    } else if (page == 'Myuser') {
      return this.myuserfilterhistroy;
    } else if (page == 'Alluser') {
      return this.alluserfilterhistroy;
    }
  }

  filterreset() {
    this.mydevicefilterhistroy = null;
    this.allDevicefilterhistroy = null;
    this.alluserfilterhistroy = null;
    this.myuserfilterhistroy = null
  }

  slavedeviceexist(cluster_id: any) {
    const datas = { params: { cluster_id: cluster_id } };

    return this.http.get(`${this.api}/devices/slaveids`, datas);
  }
  geslavedevice(cluster_id: any) {
    const datas = { params: { cluster_id: cluster_id } };

    return this.http.get(`${this.api}/devices/slave`, datas);
  }

  FakeDeviceonline() {
    let FakeDeviceARR = ['nMG8bV0dqQTcAvyvQmLH', '4eoGx7Pb7hErIhkyFtUQ'];
    for (let i = 0; i < FakeDeviceARR.length; i++) {
      const data = {
        status_Id: 1,
        device_auth_token: FakeDeviceARR[i],
        wifi: -30,
      };
      this.http
        .post(`https://sem-demo.devsbot.com/api/device/status`, data)
        .subscribe((res: any) => {
          // console.log(res)
        });
    }
  }

  FirmwareInfo(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        Version: data.version,
      },
    };

    return this.http.get(`${this.api}/Firmwareinfo`, options);
  }

  CreateSchedule(data: any) {
    return this.http.post(`${this.api}/create/schedule`, data);
  }

  GetOtaStatics(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        version: data.version,
      },
    };

    return this.http.get(`${this.api}/ota/statics`, options);
  }

  GetFirmwareinfodevice(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        version: data.version,
      },
    };

    return this.http.get(`${this.api}/get/devices/firmwaretask`, options);
  }

  getclusterdata(vertical_Id: any) {
    const data = { params: { vertical_Id: vertical_Id } };
    return this.http.get(`${this.api}/get/groupData`, data);
  }
  getsingleclusterdata(cluster_id: any) {
    const data = { params: { cluster_id: cluster_id } };
    return this.http.get(`${this.api}/get/groupSingleData`, data);
  }
  getdevicedata(dataa: any) {
    const data = { params: dataa };
    return this.http.get(`${this.api}/get/singleDeviceData`, data);
  }
  createCluster(data: any) {
    return this.http.post(`${this.api}/create/group`, data).pipe(
      tap(() => {
        this.refreshcluster.next();
      })
    );
  }
  Getdevicehistroy(data: any) {
    const options = {
      params: {
        cluster_Id: data.cluster_Id,
        version: data.version,
      },
    };

    return this.http.get(`${this.api}/device/histroy`, options);
  }
  deletecluster(data: any) {
    const dataa = { cluster_id: data };
    return this.http.post(`${this.api}/delete/group`, dataa);
  }
  deletesingleDevice(device_id: any, cluster_id: any) {
    const dataa = { device_id: device_id, cluster_id: cluster_id };
    return this.http.post(`${this.api}/delete/SingleDevice`, dataa).pipe(
      tap(() => {
        this.refreshcluster.next();
      })
    );
  }
  updateCluster(data: any) {
    return this.http.post(`${this.api}/update/group`, data).pipe(
      tap(() => {
        this.refreshcluster.next();
      })
    );
  }
  GetOverallDashboardData(data: any) {
    let dataa = {
      params: { vertical_id: data.vertical_Id, org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/Get/OverallDBData`, dataa);
  }
  GetOverallDeviceData(data: any) {
    let dataa = {
      params: { vertical_id: data.vertical_Id, org_Id: data.org_Id },
    };
    return this.http.get(`${this.api}/Get/overallDeviceData`, dataa);
  }
  selectedDevices(): Observable<any> {
    return this.http.get(`${this.api}/group/selectedDevice`);
  }

  setadsloop(data: any) {
    this.exitpage = data;
  }

  getadsloop() {
    return this.exitpage;
  }

  getDeviceModel() {
    return this.http.get(`${this.api}/Get/deviceModel`);
  }


  Removeuser(data: any) {
    const params = { user_Id: data }
    return this.http.post(`${this.api}/remove/user`, params).pipe(
      tap(() => {
        this.Userrefresh.next();
      })
    );

  }


  // Convert array of objects to CSV string
  convertDeviceToCSV(objArray: any[]): string {
    let currenttime = new Date();
    const header = 'Device Name,Gateway Name,Status,Wifi Status,Slave Id,Vertical,Device Version,Device Model,Activation Date';
    const csvRows = objArray.map((row: any) => {
      // Parse the original date string
      const originalDate = new Date(row.device_activation);
      // Get the day, month, and year
      const day = originalDate.getDate();
      const month = originalDate.toLocaleString("default", {
        month: "short",
      }); // Get the month name abbreviation
      const year = originalDate.getFullYear();
      // Get the hours and minutes
      const hours = originalDate.getHours().toString().padStart(2, "0");
      const minutes = originalDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      // Assemble the formatted date string
      const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;
      if (Number(row.heartbeat) > 20) {
        row.heartbeat = Number(row.heartbeat) + 5;
        row.heartbeat = Number(row.heartbeat) * 12.5;
      }
      if (
        +currenttime - +row.last_online < 100 * row.heartbeat &&
        (row.status_Id == 1 || row.status_Id == 2)
      ) {
        row.status = "Online";
        if (
          Number(row.wifi_status) >= -50 &&
          row.wifi_status !== null
        ) {
          row.wifiStrength = "Excellent";
        } else if (
          Number(row.wifi_status) < -50 &&
          Number(row.wifi_status) >= -60
        ) {
          row.wifiStrength = "Good";
        } else if (
          Number(row.wifi_status) < -60 &&
          Number(row.wifi_status) > -70
        ) {
          row.wifiStrength = "Average";
        } else if (Number(row.wifi_status) <= -70) {
          row.wifiStrength = "Bad";
        }
      } else if (row.status_Id == 0) {
        row.status = "Inactive";
        row.wifiStrength = "Disconnected";
      } else {
        row.status = "Offline";
        row.wifiStrength = "Disconnected";
      }
      return `${row.device_name} ,${row.cluster_name},${row.status},${row.wifiStrength
        },${row.slave_id},${row.vertical_name
        },${row.device_version} ,${row.device_model},${formattedDate}  `;

    });
    return [header, ...csvRows].join('\r\n');
  }

  convertUserToCSV(objArray: any[]) {

    const header = 'User Name,User Email,User Status,User Role,User Organization,User Vertical,User PhoneNumber,User Registraion Date';
    const csvRows = objArray.map((row: any) => {
      // Parse the original date string
      const originalDate = new Date(row.reg_date);

      // Get the day, month, and year
      const day = originalDate.getDate();
      const month = originalDate.toLocaleString("default", {
        month: "short",
      }); // Get the month name abbreviation
      const year = originalDate.getFullYear();
      // Get the hours and minutes
      const hours = originalDate.getHours().toString().padStart(2, "0");
      const minutes = originalDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      // Assemble the formatted date string
      const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;
      if (row.role_name == null) {
        row.role_name = 'Proprietor'
      }
      return ` ${row.firstname} ,${row.email_id},${row.is_verify == 0 ? 'Pending' : row.isactive == 0 ? 'Active' : 'Suspended'} ,${row.role_name} ,${row.org_Name}, ${row.vertical_name} ,"PH:"${row.phone_num} ,${formattedDate}`;

    });

    return [header, ...csvRows].join('\r\n');
  }

  devicedownloadCSV(csvData: string, filename: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  userdownloadCSV(csvData: string, filename: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }





}
