<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
  
</mat-spinner>
<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500;text-transform: capitalize;margin: 0 !important;">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>
<div class="content" *ngIf="webzonedata?.length == 0 && isread == true">
  <div>
    <h1 style="font-weight: 500">
      Use Web Zones to organize your devices better
    </h1>
    <p style="font-weight: 500">
      Start by creating Web Zones. Once created, you can assign Wifi password
      and location to the devices
    </p>
  </div>
  <div class="newloc-allloc new-dev">
    <button class="btn btn-primary" (click)="open()">
      Create Web Zone <i class="fa-solid fa-plus"></i>
    </button>
  </div>
</div>
<div class="main" *ngIf="webzonedata?.length >= 1 && isread == true">
  <div *ngIf="!loading">
    <div class="col-md-12 d-flex">
      <div class="col-md-6">
        <div class="main-head">
          <div class="d-flex" style="align-items: center; gap:5px">
            <!-- <h1>Web Zones <mat-icon
              class="help" (click)="sidenav.toggle()"
              matTooltipClass="my-tooltip"
              matTooltip="Help"
              matTooltipPosition="right"
              >info
            </mat-icon></h1> -->
            <h1>Web Zones</h1>
          </div>

        

        </div>
        <div class="count-device">
          <div>
            <span class="count-device-font"
              ><h4>No Of Web Zones : {{ webzonedata?.length }} &nbsp;</h4>
            </span>
          </div>
          <div>
            <!-- <mat-icon [matMenuTriggerFor]="list" class="more-vert">more_vert</mat-icon> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex new-dev-sec">
        <div class="buttons">
          <button class="btn btn-primary new-dev" (click)="open()">
            New Web Zone <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="search-div">
          <mat-icon class="search-icon" (click)="search.focus()"
            >search</mat-icon
          ><input style="width: 100%;"
            type="search"
            #search
            class="form-control"
            placeholder="Search Web Zones"
            [(ngModel)]="filterTerm"
            (ngModelChange)="onFilterTermChange()"
          />
        </div>
      </div>
    </div>
    <ng-container *ngIf="webzonedata | filter: filterTerm as result"> 
    <div *ngIf="result.length>=1">
      <div class="main-body">
        <table>
          <thead>
            <tr>
              <th id="checkbox">S.no</th>
              <th>Name</th>
              <th>Zone Reference</th>
              <th>Location</th>
              <th>SSID</th>
              <th>Password</th>
              <th>Vertical</th>

              <th>Time Zone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of result| paginate : config;
                let i = index
              "
            >
              <td
                id="checkbox"
                (click)="webzoneinfo.open()"
                (click)="info(item.location_id)"
              >
                {{ (this.config.currentPage - 1) * 5 + i + 1 }}
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="data-info">{{ item.webzone_name }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="">{{ item.zonename }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="data-content">{{ item.address }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="data-info">{{ item.SSID }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="">{{ item.password }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="">{{ item.vertical_name  }}</span>
              </td>
              <td (click)="webzoneinfo.open()" (click)="info(item.webzone_Id)">
                <span class="">{{ item.timeZone }}</span>
              </td>
              <td class="action">
                
                <i
                  class="fa-solid fa-ellipsis action-icon"
                  id="more-vert"
                  [matMenuTriggerFor]="menu"
                ></i>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    class="mat-menu-button"
                    (click)="editwebzone(item)"
                  >
                    <mat-icon class="menu-btn-icon">keyboard</mat-icon>Edit Web
                    Zone
                  </button>
                  <button
                    mat-menu-item
                    class="mat-menu-button"
                    (click)="deletewebzone(item.webzone_Id)"
                  >
                    <mat-icon class="menu-btn-icon">delete</mat-icon>Delete Web
                    Zone
                  </button>
                </mat-menu>
              </td>
              <mat-sidenav class="sidenav" #webzoneinfo position="end" style="width: 100%">
                <div class="info-main">
                  <div class="right-side">
                    <div (click)="webzoneinfo.close()" class="close-icon">
                      <mat-icon>arrow_back</mat-icon>
                    </div>
                    <div class="right-side-head">
                      <div class="zone-content">
                        <div>
                          <span class="zonename">{{ item.zonename }}</span>
                        </div>
                        <div class="buttons">
                          <button
                            class="btn btn-success"
                            (click)="editwebzone(item)"
                          >
                            Edit Web Zone
                          </button>
                          <button
                            class="btn btn-danger"
                            (click)="deletewebzone(item.webzone_Id)"
                          >
                            Delete Web Zone
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <div class="content-body">
                      <mat-tab-group selectedIndex="0">
                        <mat-tab label="Info">
                          <div class="info-body">
                            <div
                              style="
                                width: 30%;
                                border-right: 1px solid #d7d7d7;
                              "
                            >
                              <div class="info-content">
                                <h3>Webzone Name</h3>
                                <p>{{ item.webzone_name }}</p>
                              </div>
                              <div class="info-content">
                                <h3>Zone Reference</h3>
                                <p>{{ item.zonename }}</p>
                              </div>

                              <div class="info-content">
                                <h3>Address</h3>
                                <p>{{ item.address }}</p>
                              </div>
                              <div class="info-content">
                                <h3>SSID</h3>
                                <p>{{ item.SSID }}</p>
                              </div>
                              <div class="info-content">
                                <h3>Password</h3>
                                <p>{{ item.password }}</p>
                              </div>
                              <div class="info-content">
                                <h3>Vertical</h3>
                                <p>{{ item.vertical_name }}</p>
                              </div>
                              <div class="info-content">
                                <h3>latitude</h3>
                                <p>{{ item.latitude }}</p>
                              </div>
                              <div class="info-content">
                                <h3>longitude</h3>
                                <p>{{ item.longitude }}</p>
                              </div>
                            </div>
                            <div
                              style="
                                width: 50%;
                                margin: 0 auto;
                                padding: 20px 0px 0px 0px;
                              "
                            >
                              <div>
                                <agm-map
                                  [latitude]="item.latitude"
                                  [longitude]="item.longitude"
                                  [zoom]="8"
                                  mapTypeId="hybrid"
                                  (mapClick)="infoWindow.close()"
                                  style="height: 450px; width: 650px"
                                >
                                  <agm-marker
                                    [latitude]="item.latitude"
                                    [longitude]="item.longitude"
                                    (markerClick)="infoWindow.open()"
                                  >
                                    <agm-info-window #infoWindow>
                                      <div>
                                        <h4 style="font-weight: 700">
                                          Location
                                        </h4>
                                        <p>{{ item.address }}</p>
                                      </div>
                                    </agm-info-window>
                                  </agm-marker>
                                </agm-map>
                              </div>
                            </div>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>
                </div>
              </mat-sidenav>
            </tr>
          </tbody>
        </table>
        <pagination-controls
          class="pagination"
          (pageChange)="pageChanged($event)"
        ></pagination-controls>
        <span  [ngClass]="{ disabled:  this.webzonedata?.length <6  }" *ngIf="this.config.itemsPerPage<=6" (click)="showall()" class="pagination showall">
          Show All
        </span>
        <span  [ngClass]="{ collapsetable:this.config.itemsPerPage>6}" *ngIf="this.config.itemsPerPage>6" (click)="collapse()" class="pagination showall">
          Collapse 
        </span>

      </div>

    </div>
    <div class="nodatacontent" *ngIf="result.length<=0">

      <iconify-icon icon="fontisto:wifi"></iconify-icon>
      <h1>No Data Found</h1>
    </div>
    </ng-container>

  </div>

 

</div>
 