<div class="main-head">
  <div class="heading-div">
    <div class="close-icon" (click)="close()">
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div class="sub-title">
      <h1>{{ this.devicename + " Analytics" }}</h1>

      <i
        (click)="ExportAnalytics()"
        matTooltip="Export
      Analytics(CSV)"
        id="more-vert1"
        class="fa-solid fa-file-export"
      ></i>
    </div>
  </div>
  <div class="heading-div-filter" [formGroup]="range">
    <mat-form-field appearance="fill" style="pointer-events: none; opacity: 0.5;">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>



<div class="analytics_main sigledevice" *ngIf="!Finding">
  <div class="charts">
    <div class="card chart-card">
      <h5>Energy Consumption</h5>
      <div class="linechart">
        <mat-spinner
          role="progressbar"
          diameter="50"
          aria-hidden="true"
          *ngIf="Finding"
          id="spinner"
        >
        </mat-spinner>
        <ngx-charts-line-chart
          *ngIf="this.LineChartData && !Finding"
          class="chart-container"
          [view]="this.KwhRYBpiechartdata[0]?.value <= 0 ? [1200, 180] : [600, 180]"
          [scheme]="colorScheme"
          [schemeType]="schemeType"
          [results]="this.LineChartData"
          [animations]="false"
          [legend]="false"
          [legendTitle]="'legend'"
          [legendPosition]="legendPosition"
          [xAxis]="true"
          [yAxis]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="'Time'"
          [yAxisLabel]="'kWh'"
          [autoScale]="false"
          [xScaleMin]="this.Xaxismin"
          [xScaleMax]="this.Xaxismax"
          [roundDomains]="true"
          [showGridLines]="false"
          [xAxisTicks]="this.Xaxisticks"
          [rangeFillOpacity]="0.5"
          [xAxisTickFormatting]="xAxisTickFormattingFunction"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
    <!-- <div class="card chart-card"  *ngIf="this.KwhRYBpiechartdata[0]?.value == 0">
      <h5>Energy Consuption</h5>
      <div class="barchart">
  

        <div
          style="display: flex; justify-content: center; margin: 50px"
          *ngIf="this.KwhRYBpiechartdata[0]?.value <= 0"
        >
          <h1>No Data Found</h1>
        </div>

        <ngx-charts-pie-chart
          [view]="[700, 200]"
          *ngIf="this.KwhRYBpiechartdata[0]?.value && !Finding"
          class="chart-container"
          [scheme]="colorScheme"
          [results]="this.KwhRYBpiechartdata"
          [animations]="true"
          [labels]="true"
          [legend]="true"
          [legendPosition]="legendPosition"
        >
        </ngx-charts-pie-chart>
      </div>
    </div> -->
  </div>
  <!-- label  -->
  <div class="main-box">
    <div class="label-box">
      <div>
        <div class="card label-card">
          <h5>
            <iconify-icon
              style="background-color: #2b9de2"
              icon="simple-line-icons:energy"
            ></iconify-icon>
            Total Energy
          </h5>
          <h3>{{ this.TotalKwh }} kWh</h3>
        </div>
        <div class="card label-card">
          <h5>
            <iconify-icon
              style="background-color: #ea8a59"
              icon="material-symbols:attach-money"
            ></iconify-icon>
            Total Cost
          </h5>
          <h3>₹ {{ this.TotalCost }}</h3>
        </div>
      </div>

      <div>
        <div class="card label-card">
          <h5>
            <iconify-icon
              style="background-color: #34ac32"
              icon="mdi:molecule-co2"
            ></iconify-icon>
            Co2 Emission
          </h5>
          <h3>{{ this.TotalCo2 }} tCO2</h3>
        </div>
        <div class="card label-card">
          <h5>
            <iconify-icon  style="background-color: #cb56d8" icon="prime:chart-line"></iconify-icon>AVG Energy Consumed
          </h5>
          <h3>{{ this.AvgKwh }} kWh</h3>
        </div>
      </div>
    </div>

    <div class="table-box">
      <div class="tabel">
        <div class="card tabel-card">
          <h5 style="background-color: white; position: sticky; top: 0px">
            Hourly Energy Consumed Devices
          </h5>
          <div style="padding: 0px 30px">
            <table>
              <thead>
                <tr>
                  <th>Device name</th>
                  <th>kWh</th>
                  <th>CO2</th>
                  <th style="text-align: center">Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of Tabledata">
                  <td>{{ item.device_name }}</td>
                  <td>{{ item.kwh }}</td>
                  <td>{{ item.co2 }}</td>
                  <td style="text-align: center">
                    {{ item.updated_time|date:"medium" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="main-body" *ngIf="!Finding">
  <div style="width: 60%">
    <div>
      <div class="box-head"><h2>Total Kwh</h2></div>
      <div class="box-body">
        <h1>{{ this.TotalKwh }} Kwh</h1>
      </div>
    </div>
    <div>
      <div class="box-head"><h2>Total Cost</h2></div>
      <div class="box-body">
        <h1>{{ this.TotalCost }} ₹</h1>
      </div>
    </div>
    <div>
      <div class="box-head"><h2>Total Co2</h2></div>
      <div class="box-body">
        <h1>{{ this.TotalCo2 }} µmol</h1>
      </div>
    </div>
    <div>
      <div class="box-head"><h2>AVG Kwh</h2></div>
      <div class="box-body">
        <h1>{{ this.AvgKwh }} AVG Kwh</h1>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div class="box-head"><h2>Timeline</h2></div>
      <div class="box-body">
        <ngx-charts-line-chart
          class="chart-container"
          [view]="[470, 180]"
          [scheme]="colorScheme"
          [schemeType]="schemeType"
          [results]="this.LineChartData"
          [animations]="false"
          [legend]="false"
          [legendTitle]="'legend'"
          [legendPosition]="legendPosition"
          [xAxis]="true"
          [yAxis]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="'Time'"
          [yAxisLabel]="'kwh'"
          [autoScale]="false"
          [xScaleMin]="this.Xaxismin"
          [xScaleMax]="this.Xaxismax"
          [roundDomains]="true"
          [showGridLines]="true"
          [xAxisTicks]="this.Xaxisticks"
          [rangeFillOpacity]="0.5"
          [xAxisTickFormatting]="xAxisTickFormattingFunction"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
    <div style="gap: 0px">
      <div class="box-head">
        <h2>Kwh</h2>
      </div>
      <div style="width: 100%; overflow: auto; height: 35vh">
        <table>
          <thead>
            <tr>
              <th>Device name</th>
              <th>Kwh</th>
              <th>Co2</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td>{{ item.device_name }}</td>
              <td>{{ item.kwh }}</td>
              <td>{{ item.co2 }}</td>
              <td>{{ item.updated_time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div style="width: 75%">
    <div>
      <div class="box-head"><h2>Kwh RYB Chart</h2></div>
      <div class="box-body" style="display: flex; align-items: center">
        <mat-spinner
          role="progressbar"
          diameter="50"
          aria-hidden="true"
          *ngIf="Finding"
          id="spinner"
        >
        </mat-spinner>
        <ngx-charts-pie-chart
          *ngIf="this.KwhRYBpiechartdata && !Finding"
          class="chart-container"
          [view]="[400, 250]"
          [scheme]="colorScheme"
          [results]="this.KwhRYBpiechartdata"
          [animations]="true"
          [legend]="true"
          [legendTitle]="'legend'"
          [legendPosition]="legendPosition"
          [explodeSlices]="false"
          [labels]="true"
          [doughnut]="false"
          [arcWidth]="0.25"
          [gradient]="false"
          [tooltipDisabled]="false"
        >
        </ngx-charts-pie-chart>
      </div>
    </div>
  </div>
</div> -->
