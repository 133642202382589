import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Dataservice } from '../services/dataservice';
import { AuthenticationService } from '../Authentication/authentication.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { deviceinfo } from '../mydevices/mydevices.component';
import { SocketService } from '../socket.io/socket.service';
import { buffer, delay } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { F } from '@angular/cdk/keycodes';
import { ChangeDetectorRef } from '@angular/core';
import { FaLayersComponent } from '@fortawesome/angular-fontawesome';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  loading: boolean;
  id: any;
  imagepath: any;
  data: any;
  user_id: any;
  userdata: any;
  user: any;
  isMenuOpen = true;
  isMenuClose = true;
  contentMargin = 240;
  transtion = 0.3;
  collapse!: string;
  events: any[] = [];
  sidenav: any;
  newnotify: any[] = [];
  page: any;
  @ViewChild('sidenav') sidenav1: MatSidenav | undefined;
  fullScreenLoader: boolean;
  disabled!: boolean;
  email: any;

  opendashboard: any = 'closedashboard';
  productvalue: any;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  Firstflag!: boolean;
  readuser: any[] = [];
  messagecount!: number;
  username: any;
  verticalvalue: any;
  vertical_id: any;
  userrole: any;
  superadmin!: boolean;
  UserData: any;
  vertical_name: any;
  removescreen: any;
  timer: any;
  showIcon = false;
  private hideIconTimeout: any;
  /**
   * Creates an instance of HomeComponent.
   * @param {SocketService} socketService - Service for managing socket connections.
   * @param {Dataservice} dataservice - Service for managing data.
   * @param {ToastrService} toastr - Service for showing toast messages.
   * @param {AuthenticationService} authentication - Service for user authentication.
   * @param {MatDialog} dialog - Service for opening dialog windows.
   * @param {Router} router - Angular router service.
   * @param {ActivatedRoute} activateroute - Angular activated route service.
   */
  constructor(
    private socketService: SocketService,
    private dataservice: Dataservice,
    private toastr: ToastrService,
    private authentication: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private activateroute: ActivatedRoute,
    private cdr: ChangeDetectorRef



  ) {
    this.loading = false;
    this.fullScreenLoader = true;
    this.disabled = false;
    this.Firstflag = false;
    this.removescreen = false;
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[1];
    /**
    * Checks the roles and permissions of the current user.
    * Sets flags indicating whether the user has permission for create, read, and delete actions.
    */
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        }
        else {
          this.iscreate = false;
        }
      } if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      } if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    })

  }

  isHovered: boolean = false;


  exit() {
    this.loop = false;
    this.isHovered = false;
    this.dataservice.setadsloop(false);
    this.router.navigate(['/app/devices']);
  }

  loopstate(state: any) {
    this.loop = state;
  }


  // @HostListener('window:mousemove', ['$event'])
  // onWindowMouseMove(event: MouseEvent) {
  //   if (this.loop) {
  //     this.isHovered = true;
  //     console.log('Mouse moved over the window', event);
  //   }
  // }

  // @HostListener('document:mouseleave', ['$event'])
  // onDocumentMouseLeave(event: MouseEvent) {
  //   if (this.loop) {
  //     this.isHovered = false;
  //     if (this.timer) {
  //       clearTimeout(this.timer);
  //     }
  //     console.log('Mouse left the document', event);
  //   }
  // }
  @HostListener('mousemove')
  onMouseMove() {
    this.showIcon = true;
    this.resetHideIconTimeout();
  }

  private resetHideIconTimeout() {
    clearTimeout(this.hideIconTimeout);
    this.hideIconTimeout = setTimeout(() => {
      this.showIcon = false;
    }, 2000);
  }

  loop: any;

  /**
   * Angular lifecycle hook - called after initialization.
   */
  ngOnInit(): void {
    this.dataservice.setadsloop(false);
    setInterval(() => {
      this.loop = this.dataservice.getadsloop()
    }, 10000)
    this.dataservice.RemovefullScreen.subscribe((response: any) => { this.removescreen = true })
    this.dataservice.fullScreen.subscribe((response: any) => { this.removescreen = false })
    // Subscribe to RouterlinkInActive and RouterlinkActive events
    this.dataservice.RouterlinkInActive.subscribe((response: any) => {
      this.disabled = true;
    })
    this.dataservice.RouterlinkActive.subscribe((response: any) => {
      this.disabled = false;
    })


    // this.GetserverPublicKey()
    const dropdownMenu = document.querySelector('.dropdown-menu');
    const dropdownToggle = document.querySelector('.dropdown-toggle');

    dropdownToggle?.addEventListener('click.bs.dropdown', (e: Event) => {
      e.stopPropagation();
      dropdownMenu?.classList.toggle('show');
    });

    dropdownMenu?.addEventListener('click.bs.dropdown', (e: Event) => {
      e.stopPropagation();
    });

    const notificationTabs = document.querySelectorAll(
      '.nav-link[data-toggle="tab"]'
    );
    notificationTabs?.forEach((tab) => {
      tab.addEventListener('click', (event) => {
        event.preventDefault(); // prevent the default behavior of switching tabs
        event.stopPropagation(); // prevent the event from bubbling up and closing the dropdown
        // remove active class from all tabs and add it to the clicked tab
        notificationTabs.forEach((t) => t.classList.remove('active'));
        const clickedTab = event.target as HTMLElement;
        clickedTab.classList.add('active');

        // remove active class from all tab contents and add it to the corresponding tab content
        const clickedTabId = clickedTab.getAttribute('href');
        if (clickedTabId) {
          const clickedTabContent = document.querySelector(
            clickedTabId
          ) as HTMLElement;
          const tabContents =
            clickedTabContent.parentElement?.querySelectorAll('.tab-pane');
          tabContents?.forEach((content) =>
            content.classList.remove('active', 'show')
          );
          clickedTabContent.classList.add('active', 'show');
          clickedTab.focus(); // set focus to the clicked tab
        }
      });
    });

    this.loading = true;
    this.collapse = '1';
    this.UserData = this.authentication.getUserData();
    this.data = this.authentication.getUserData();
    this.userrole = this.authentication.getUserRole();


    this.username = this.data.username
    let data = this.authentication.getUserData();
    // console.log(this.userrole);

    this.vertical_name = data.vertical_name
    this.vertical_id = data.vertical_Id



    this.superadmin = data.superadmin
    this.Getproductdata(data);
    this.getverticaldata(data)
    setInterval(() => {
      this.Firstflag = true
      this.Getproductdata(data)
    }, 10000)
    this.getuserdata(data);
    this.dataservice.Userrefresh.subscribe((response: any) => {
      this.getuserdata(data);
    });
    this.dataservice.notifi_refresh.subscribe((response: any) => {
      this.Getproductdata(data);
    });

    setTimeout(() => {
      if (navigator.onLine)
        this.fullScreenLoader = false;
      else if (!navigator.onLine)
        this.fullScreenLoader = true
    }, 3000);
    this.activateroute.queryParams.subscribe(params => {
      const verticalId = params['verticalId'];
      const verticalName = params['verticalName'];
      if (verticalId && verticalName) {
        // Handle the selected verticalId and verticalName as needed

      }
    });
  }
  getverticaldata(data: any): void {
    /**
     * Fetch vertical data using the provided user data.
     * @param {any} res - The response received from the API call.
     * @returns {void}
     */
    this.dataservice.getverticaldata(data).subscribe((res: any) => {
      this.loading = false;
      if (res.status == '200') {
        // Update the data and vertical value
        this.verticalvalue = res.data;

      } else {
        // Display an error message
        this.toastr.error('Error occurred');
      }
    });
  }

  GetVertical(event: any) {
    if (event.value === 0) {
      // When "All" is selected, set vertical_id to null
      this.vertical_id = null;
      this.UserData.vertical_Id = null; // Also update UserData if needed
      this.UserData.vertical_name = 'All';
      this.authentication.UpdateVertical(this.UserData);

      this.router.navigate([], {
        relativeTo: this.activateroute,
        queryParams: {
          verticalId: this.vertical_id,
          verticalName: 'All'
        },
        queryParamsHandling: 'merge',
      });

      // Reload current route
      window.location.reload();
    } else {
      this.vertical_id = event.value; // Use event.value to get the selected value
      const selectedVertical = this.verticalvalue.find((item: { vertical_id: any; }) => item.vertical_id === this.vertical_id);
      if (selectedVertical) {
        this.UserData.vertical_Id = this.vertical_id;
        this.UserData.vertical_name = selectedVertical.vertical_name;
        this.authentication.UpdateVertical(this.UserData);

        this.router.navigate([], {
          relativeTo: this.activateroute,
          queryParams: {
            verticalId: this.vertical_id,
            verticalName: selectedVertical.vertical_name
          },
          queryParamsHandling: 'merge',
        });

        // Reload current route
        window.location.reload();
      }
    }
  }




  GetserverPublicKey() {
    this.dataservice.Getkey().subscribe((res: any) => {
      if (res) {
        let PublicKey = res.publicKey;
        // this.requestSubscription(PublicKey)
      } else {
        this.toastr.error('Error occurred');
      }
    })
  }

  // requestSubscription = (PublicKey: any) => {
  //   console.log(PublicKey)
  //   if (!this._swPush.isEnabled) {
  //     console.log("Notification is not enabled.");
  //     return;
  //   }
  //   this._swPush.requestSubscription({
  //     serverPublicKey: PublicKey
  //   }).then((sub) => {

  //     const data = this.authentication.getUserData();
  //     let subscriptiondata = { "sub": JSON.stringify(sub), ...data }

  //     this.dataservice.addPushSubscriber(subscriptiondata).subscribe()
  //   }).catch((_) => console.log);
  // };


  /**
    * Handles navigation confirmation and opens links.
    * @param {any} routerlink - The link to navigate to.
    */
  Confirm(routerlink: any) {

    if (this.disabled == true) {
      if (window.confirm('Leave this page without saving your changes?')) {
        this.disabled = false;
        this.dataservice.RouterlinkActive.next();
        if (routerlink == "https://docs.devsbot.com/devsbot/") {
          this.router.navigate([]).then(result => { window.open(routerlink, '_blank'); });
          this.disabled = false;
          this.dataservice.RouterlinkActive.next();
        } else if (routerlink != "https://docs.devsbot.com/devsbot/") {
          this.sidenavclose()
          this.router.navigate([routerlink]);
        }
      } else {
        this.router.navigate(['/app/routes'])
      }
    }

  };

  /**
    * Closes the sidenav if the default route is "routes".
    */
  default() {
    if (this.activateroute.snapshot.children[0].url[0].path === "routes") {
      this.sidenavclose();
    }
  }




  /**
   * Marks notifications as read and updates the newnotify array.
   */

  /**
  * Navigates to the device information page.
  * @param {any} device_Id - The ID of the device.
  * @param {any} product_Id - The ID of the product associated with the device.
  * @param {any} index - The index of the event in the events array.
  */
  deviceinfo(device_Id: any, product_Id: any, index: any, notification_id: any, eventType: any) {
    // Get the current page from the activated route
    this.page = this.activateroute.snapshot.children[0].url[0].path;
    // Log the current page for debugging

    // Get user data for the request
    const data = this.authentication.getUserData();

    // Update the read_user property of the event
    // this.events[index]['read_user'] = data.user_Id;

    this.events.map((x:any)=>{
if(x.Notification_id == notification_id){
  x.read_user = data.user_Id
}
    })
 
    

    this.readNotification(notification_id)

    // Navigate to the device information page and notify subscribers
    this.router.navigate(['/app/deviceinfo', device_Id, product_Id, '2', eventType]).then((Response: any) => {
      this.dataservice.Deviceinfo.next();

    });
  }

  /**
   * Retrieves user data from the server and updates the userdata property.
   * @param {any} data - The user data for the request.
   */
  getuserdata(data: any) {
    // Make a request to get user data
    this.dataservice.getuserdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        // Update the userdata property with the received data
        this.userdata = res.data;
        this.email = this.userdata.email_id
      } else {
        // Display an error message if getting user data fails
        this.toastr.error('Error occurred');
      }
    });
  }
  /**
   * Toggles the sidenav and restricts the sidenav from opening when it is the "routes" page.
   */
  onToolbarMenuopen() {
    // Get the current page from the activated route
    this.page = this.activateroute.snapshot.children[0].url[0].path;


    // Check if the current page is not the "routes" page
    if (this.page !== "routes" && this.page !== "analytics") {
      // Toggle the sidenav state
      this.isMenuOpen = this.isMenuClose;
      if (!this.isMenuOpen) {
        this.contentMargin = 70;
        this.collapse = 'true';
      } else {
        this.contentMargin = 240;
        this.collapse = 'false';
      }
    } else {

      if (this.page == 'analytics') {
        // Navigate to the general page and toggle the sidenav state
        this.router.navigate(['/app/general']);
        this.isMenuOpen = this.isMenuClose;
        if (!this.isMenuOpen) {
          this.contentMargin = 70;
          this.collapse = 'true';
        } else {
          this.contentMargin = 240;
          this.collapse = 'false';
        }


      } else {
        if (!this.disabled) {
          // Navigate to the general page and toggle the sidenav state
          this.router.navigate(['/app/general']);
          this.isMenuOpen = this.isMenuClose;
          if (!this.isMenuOpen) {
            this.contentMargin = 70;
            this.collapse = 'true';
          } else {
            this.contentMargin = 240;
            this.collapse = 'false';
          }
        }
      }
    }
  }
  /**
     * Toggles the sidenav 
     */
  /**
   * Closes the sidenav and sets the appropriate content margins for the "routes" page.
   */
  routes() {

    this.isMenuOpen = false;
    this.contentMargin = 70;
    this.collapse = 'true';
  }

  /**
   * Closes the sidenav and adjusts the content margins.
   * This method is used to close the sidenav.
   */
  sidenavclose() {
    this.isMenuOpen = true;
    this.contentMargin = 240;
    this.collapse = 'false';
  }

  /**
    * Toggles the sidenav and restricts the sidenav from opening when it is the "routes" page.
    */

  onToolbarMenuToogle() {
    this.page = this.activateroute.snapshot.children[0].url[0].path;

    if (this.page !== "routes" && this.page !== "analytics") {

      this.isMenuOpen = !this.isMenuOpen;

      if (!this.isMenuOpen) {
        this.contentMargin = 70;
        this.collapse = 'true';
        this.dataservice.sidenavclose()

      } else {
        this.contentMargin = 240;
        this.collapse = 'false';
        this.dataservice.sidenavopen()
      }
    } else {
      this.toastr.info("This option is restricted in this page")
    }
  }

  OpenDashboard(event: any) {
    if (event) {
      this.opendashboard = 'opendashboard';
    } else {
      this.opendashboard = 'closedashboard';
    }
  }


  getColorForEventType(eventType: string): string {
    // console.log(eventType);

    switch (eventType) {
      case 'info':
        return 'blue';
      case 'warning':
        return 'orange';
      case 'critical':
        return 'red';
      default:
        return ''; // or specify a default color if needed
    }
  }

  tendata: any = []


  async Getproductdata(data: any) {
    // GET DEVICEPAGE  PRODUCT DATA
    this.dataservice.getdevicepageproductdata(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
        this.productvalue.map(async (x: any) => {
          let noti_data = {
            cluster_api_Id: x.cluster_api_Id,
            user_Id: data.user_Id,
          }
          this.dataservice.getnotification(noti_data).subscribe(async (res: any) => {
            const allData = res;
            // if (this.Firstflag) {
            //   allData.reverse();

            // }
            await Promise.all(allData.map(async (x: any, i: any) => {

              if (!String(x.read_user).includes(data.user_Id)) {
                x.read_user = null;
              }
              const eventDate = new Date(x.time);
              const currentDate = new Date();
              const isToday = (
                eventDate.getDate() === currentDate.getDate() &&
                eventDate.getMonth() === currentDate.getMonth() &&
                eventDate.getFullYear() === currentDate.getFullYear()
              )

              if (isToday) {
                // Display only the time if the event occurred today
                x.timeDifference = eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
              } else {
                const timeDifference = currentDate.getTime() - eventDate.getTime();
                const seconds = Math.floor(timeDifference / 1000);
                const minutes = Math.floor(seconds / 60);
                const hours = Math.floor(minutes / 60);
                const days = Math.floor(hours / 24);

                const timeDifferenceString = (
                  (days > 0 ? `${days} day${days > 1 ? 's' : ''}, ` : '') +
                  (hours > 0 ? `${hours % 24} hr${hours % 24 > 1 ? 's' : ''}, ` : '') +
                  (minutes > 0 ? `${minutes % 60} min${minutes % 60 > 1 ? 's' : ''}` : '')
                );

                x.timeDifference = timeDifferenceString;
              }

          

              const Find = this.events.find((y: any) => y.Notification_id === x.Notification_id);


              if (!Find) {
                this.events.push(x);
                this.newnotify.push(x);
                if (this.Firstflag) {
                  this.tendata.unshift(x) 
                }
              }







            }))
     

            this.events.map((x) => {
              const Find = this.tendata.find((y: any) => y.Notification_id === x.Notification_id);
              if (!Find) {
                if (this.tendata.length < 10) {
                  this.tendata.push(x)
                }
              }
            })
          

            this.readuser = this.events.filter(y => {
              if (typeof y.read_user === 'string' && y.read_user.includes(',')) {
                const userIDs = y.read_user.split(',').map((z: any) => Number(z.trim()));
                const containsUserId = userIDs.includes(data.user_Id);

                return !containsUserId;
              } else {
                const readUserId = Number(y.read_user);
                const isDifferentUserId = readUserId !== data.user_Id;

                return isDifferentUserId;
              }
            });




            this.updateBadge();

          });
        });
        // this.dataservice.testserver(this.productvalue[0].cluster_api_Id)
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }



  updateBadge() {
    this.messagecount = this.readuser.filter(Boolean).length;

  }
  // dropdownOpen: boolean = false;
  deletenotification(notification_id: any) {
    document.getElementsByClassName('dropdown-menu')[0].addEventListener('click', (event) => {
      event.stopPropagation()
    })
    // Get user data for the request
    const userdata = this.authentication.getUserData();
    const data = { ...userdata, notification_id }
    // Make a request to mark notifications as read
    this.dataservice.deletenotification(data).subscribe((res: any) => {
      if (res.status == "201") {
        // Clear the newnotify array since notifications are read
        this.newnotify.pop();
        this.events.map((x: any, i: any) => {
          if (x.Notification_id == notification_id) {
            x.read_user = userdata.user_Id
            this.events.splice(i, 1);
            this.events.push(x)
          }
        });

        const deletedatainevent = this.events.find((y: any) => y.Notification_id === notification_id);
        const deletedatainTen = this.tendata.find((x: any) => x.Notification_id === notification_id)


        if (deletedatainevent) {
          const index = this.events.indexOf(deletedatainevent)
          this.events.splice(index, 1)

        }
        //  console.log(this.events);

        if (deletedatainTen) {
          const index = this.tendata.indexOf(deletedatainTen)
          this.tendata.splice(index, 1)

        }
        // console.log(this.tendata);



      } else {
        // Display an error message if marking notifications as read fails
        this.toastr.error('Error occurred');
      }
    });
  }
  deleteallnotification() {
    document.getElementsByClassName('dropdown-menu')[0].addEventListener('click', (event) => {
      event.stopPropagation()
    })
    const userdata = this.authentication.getUserData();
    const data = userdata
    this.dataservice.deleteallnotification(data).subscribe((res: any) => {
      if (res.status == "201") {
        this.newnotify.pop();
        this.tendata = []
        this.events = []
      } else {
        this.toastr.error('Error occurred');
      }
    });
  }
  readNotification(notification_id: any) {
    console.log(notification_id);

    document.getElementsByClassName('dropdown-menu')[0].addEventListener('click', (event) => {
      event.stopPropagation()
    })
    // Get user data for the request
    const userdata = this.authentication.getUserData();
    const data = { ...userdata, notification_id }
    // Make a request to mark notifications as read
    this.dataservice.readNotification(data).subscribe((res: any) => {
      if (res.status == "201") {

      } else {
        // Display an error message if marking notifications as read fails
        this.toastr.error('Error occurred');
      }
    });
  }

  Load() {

    document.getElementsByClassName('dropdown-menu')[0].addEventListener('click', (event) => {
      event.stopPropagation()
    })

    let num = this.tendata.length + 10;
    if (this.events.length >= num) {
      for (let i = this.tendata.length; i < num; i++) {
        this.tendata[i] = this.events[i]
      }
    } else if (this.events.length < num) {
      let slicedata = this.events.slice(this.tendata.length);

      this.tendata.push(...slicedata)
    }

  }



  // openConfirmationDialog() {
  //   const dialogRef = this.dialog.open(tutorial, {
  //     width: '1100px',
  //     height: '570px',
  //     data: 'new user'
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'show tutorial') {
  //       this.openTutorialPopup();
  //     }
  //   });
  // }

  // openTutorialPopup() {
  //   this.dialog.open(confirmation, {
  //     width: '600px'
  //   });
  // }
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation-dialog.html',
  styleUrls: ['./home.component.css'],
})
export class confirmation implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial-popup.html',
  styleUrls: ['./home.component.css'],
})
export class tutorial implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;

  isLastStep: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<tutorial>,
    private _formBuilder: FormBuilder
  ) { }

  next(stepper: MatStepper) {
    stepper.next();
    this.isLastStep = stepper.selectedIndex === 2; // Change the index based on the number of steps
  }

  previous(stepper: MatStepper) {
    stepper.previous();
    this.isLastStep = stepper.selectedIndex === 2; // Change the index based on the number of steps
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onLetsGoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
